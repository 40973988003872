import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { DebtChartContainer } from "./TotalDeptChart.styled";
import PropTypes from "prop-types";
import { months } from "constants/helpers";
import selectedTheme from "themes";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function TotalDebtChart({ debts }) {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const last12Months = [];

  for (let i = 0; i < 12; i++) {
    const monthIndex = (currentMonth + 1 + i) % 12;
    const month = months[monthIndex];
    last12Months.push(month);
  }

  const labels = last12Months;
  const data = {
    labels,
    datasets: [
      {
        label: currentDate.getFullYear(),
        data: debts,
        borderColor: selectedTheme.colors.error[600],
        backgroundColor: 'transparent',
        pointRadius: 0,
        fill: true,
        tension: 0.3
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        align: "end",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: false,
          text: "Profit u dinarima", // Set the label for the y-axis
        },
      },
      x: {
        beginAtZero: true,
        title: {
          display: false,
          text: "Mesec 2023", // Set the label for the x-axis
        },
      },
    },
  };

  return (
    <DebtChartContainer>
      <Line data={data} options={options} />
    </DebtChartContainer>
  );
}

TotalDebtChart.propTypes = {
  debts: PropTypes.arrayOf(PropTypes.number),
};
export default TotalDebtChart;
