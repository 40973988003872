import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import {loginFormikConstants} from "../../../../initialValues/loginInitialValues";
import PasswordField from "components/shared/InputFields/PasswordField/PasswordField";

const LoginPasswordField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;

  return (
      <PasswordField
        id={loginFormikConstants.password}
        name={loginFormikConstants.password}
        placeholder={t("login.passwordPlaceholder")}
        label={t("login.passwordLabel")}
        value={formik.values.password}
        onChange={props?.onChange}
        type="password"
        onBlur={formik.handleBlur}
        error={formik.touched?.[loginFormikConstants.password] && formik.errors?.[loginFormikConstants.password]}
        helperText={i18n.login && i18n.login?.passwordHintText && i18n.login?.passwordHintText !== "" && i18n.t("login.passwordHintText")}
        loginAttempt={props?.loginAttempt}
        apiError={props?.apiError}
      />
  )
};

LoginPasswordField.propTypes = {
  formik: PropTypes.object,
  onChange: PropTypes.func,
  loginAttempt:PropTypes.bool,
  apiError:PropTypes.object,
};

export default LoginPasswordField;
