import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";

export const LoginHeaderContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 11px;
  padding: 32px;
  width: 100%;
  justify-content: flex-start;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;
export const LoginTitle = styled(Typography)`
  //template
  font-family: ${selectedTheme.type.textXl.semibold.fontFamily};
  font-size: ${selectedTheme.type.textXl.semibold.fontSize};
  font-weight: ${selectedTheme.type.textXl.semibold.fontWeight};
  font-style: ${selectedTheme.type.textXl.semibold.fontStyle};
  line-height: ${selectedTheme.type.textXl.semibold.lineHeight};
  //
  color: #292160;
  text-align: center;
`;
export const Logo = styled.img`
  width: 32px;
  height: 32px;
`;
