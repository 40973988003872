import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  AllFinancesContainer,
  AllFinancesSubcontainer,
  FilterContainer,
} from "./AllFinancesComponent.styled";
import { PrimaryButton } from "components/shared/Buttons/Buttons.styled";
//import FilterIcon from "components/Icon/Icons/FilterIcon";
import SearchInputField from "components/shared/SearhInputField/SearchInputField";
import selectedTheme from "../../themes";
import useMediaQuery from "@mui/material/useMediaQuery";
import FinancesTable from './../shared/Tables/FinancesTable/FinancesTable';
import UploadCloudIcon from "components/Icon/Icons/UploadCloudIcon";
import { ButtonsContainer } from "components/shared/Tables/FinancesTable/FinancesTable.styled";
import ImportFinances from "components/ImportFinances/ImportFinances";

const AllFinancesComponent = ({
  finances,
}) => {
  const matches = useMediaQuery("(max-width:768px)");

  // input for searching by address
  const [searchValue, setSearchValue] = useState("");
  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const buildings = finances;

  // setup for table
  const columns = ["ID", "Adresa", "Grad", "Profit"];
  finances = finances?.map(
    ({ id, buildingAddress, city, sumProfit }) => [
      id,
      buildingAddress,
      city,
      sumProfit
    ]
  );
  const pageSize = 8;

  // const applyFiltering = () => {
  //   // apply filtering on building address
  //   return finances?.filter((d) =>
  //     d[1].toLowerCase().includes(searchValue.toLowerCase())
  //   );
  // };

    // dialog for import Finances
    const [open, setOpen] = React.useState(false);

    const handleOpen = (event) => {
      event.stopPropagation();
      //setBuildingToDeleteId(buildingId);
      setOpen(true);
    };
    const handleClose = () => {
      //setBuildingToDeleteId(null);
      setOpen(false);
    };
    // eslint-disable-next-line no-unused-vars
    const handleImportFinances = () => {
      //deleteBuilding(buildingToDeleteId);
      setOpen(false);
    };

  return (
    <>
      <ImportFinances
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        buildings={buildings}
        //importTenants={importTenants}
        //isLoading={isLoading}
        //isError={isError}
      />
      <AllFinancesContainer>
        <AllFinancesSubcontainer>
        <FilterContainer>
          {/* <PrimaryButton>
            <FilterIcon />
            {matches ? "" : "Filter"}
          </PrimaryButton> */}
          <SearchInputField
            style={{
              width: matches && "100%",
              marginRight: matches && "16px",
              marginLeft: !matches && "16px",
            }}
            searchValue={searchValue}
            handleInputChange={handleInputChange}
          />
        </FilterContainer>
        <ButtonsContainer>
        <PrimaryButton
            onClick={handleOpen}
            style={{
              marginRight: matches ? "12px" : "28px",
              flex: matches && 1,
              width: matches && "100%",
              marginBottom: matches && "8px",
            }}
          >
            <UploadCloudIcon />
            Importuj uplate
          </PrimaryButton>
        </ButtonsContainer>
        </AllFinancesSubcontainer>
       
        <FinancesTable
          //data={applyFiltering()}
          data={finances}
          columns={columns}
          pageSize={pageSize}
          style={{
            marginTop: "24px",
            border: `1px solid ${selectedTheme.colors.gray[200]}`,
            boxShadow: `${selectedTheme.effects.shadow.sm.boxShadow}`,
          }}
        />
      </AllFinancesContainer>
    </>
  );
};

AllFinancesComponent.propTypes = {
  finances: PropTypes.array,
};

export default AllFinancesComponent;
