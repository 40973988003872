import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  ImportTenantsContainer,
  ImportTenantsSubContainer,
  ImportTenantsMainText,
  TextContainer,
  TopContainer,
  ImportTenantsText,
  DownloadContainer,
  DownloadMainText,
  DownloadText,
  DownloadTextContainer,
  FileContainer,
  LeftFileContainer,
  FileDetailsContainer,
  FileName,
  FileSize,
  CheckIconContainer,
  RightFileContainer,
  ButtonsContainer,
} from "./ImportTenantsFirstStep.styled";
import UploadIcon from "components/Icon/Icons/UploadIcon";
import CloseIcon from "components/Icon/Icons/CloseIcon";
import { PrimaryButton, SecondaryButton } from "components/shared/Buttons/Buttons.styled";
import DownloadIcon from "components/Icon/Icons/DownloadIcon";
import selectedTheme from "../../themes";
import { useFileForImportingTenantsMutation } from "store/exportApiSlice";
import { useParams } from "react-router-dom";
import FileIcon from "components/Icon/Icons/FileIcon";
import BinIcon from "components/Icon/Icons/BinIcon";
import CheckIcon from "components/Icon/Icons/CheckIcon";
import File2Icon from "components/Icon/Icons/File2Icon";
import {
  makeErrorToastMessage,
  makeToastMessage,
} from "../../util/toastMessage";
import UploadInput from "components/shared/UploadInput/UploadInput";

const ImportTenantsFirstStep = ({
  open,
  handleClose,
  handleOpen,
  importTenants,
  isLoading,
  isError,
}) => {
  const { id } = useParams();
  const [getFileForImportingTenants] = useFileForImportingTenantsMutation();
  const [file, setFile] = useState(null);

  const hiddenFileInput = useRef(null);

  const handleFileChange = (e) => {
    const maxFileSize = 300 * 1048576; // 300 MB
    if (e.target.files[0].size > maxFileSize) {
      makeErrorToastMessage("Fajl ne sme biti veći od 300 MB!");
      hiddenFileInput.current.value = null;
      return;
    }
    setFile(e.target.files[0]);
  };

  const handleCloseDialog = () => {
    handleClose();
    hiddenFileInput.current.value = null;
    setFile(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", file);
    formData.append("id", id);

    importTenants(formData)
      .unwrap()
      .then((res) => {
        handleCloseDialog();
        if (res.length > 0) {
          // some tenants are not successufully imported (open another dialog)
          handleOpen();
        } else {
          makeToastMessage("Uspešno ste izvršili dodavanje stanara");
        }
      })
      .catch(() => {
        handleCloseDialog();
        makeErrorToastMessage("Nije uspešno izvršen upload stanara!");
      });
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const removeFile = () => {
    hiddenFileInput.current.value = null;
    setFile(null);
  };

  return isLoading ? (
    <p>Uploading...</p>
  ) : (
    <ImportTenantsContainer
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "653px",
          },
        },
      }}
    >
      <ImportTenantsSubContainer>
        <TopContainer>
          <UploadIcon />
          <CloseIcon
            onClick={handleCloseDialog}
            style={{ cursor: "pointer" }}
          />
        </TopContainer>
        <TextContainer>
          <ImportTenantsMainText>Importuj stanare</ImportTenantsMainText>
          <ImportTenantsText>
            Moguće je izmeniti neuploadovane stanove u sledećem koraku
          </ImportTenantsText>
        </TextContainer>
        <DownloadContainer>
          <FileIcon />
          <DownloadTextContainer>
            <DownloadMainText>Preuzmite templejt</DownloadMainText>
            <DownloadText>
              Nakon popunjavanja templejta možete ga postaviti u polje ispod
            </DownloadText>
          </DownloadTextContainer>
          <PrimaryButton
            style={{ marginLeft: "24px", height: "36px" }}
            onClick={() => getFileForImportingTenants()}
          >
            <DownloadIcon />
            Preuzmi
          </PrimaryButton>
        </DownloadContainer>
        <UploadInput
          mainText="Klikni da uploaduješ stanare"
          helperText="EXCEL (max. 300MB)"
          handleClick={handleClick}
          handleFileChange={handleFileChange}
          hiddenFileInput={hiddenFileInput}
          file={file}
          accept=".xlsx"
          isError={isError}
          style={{ marginTop: "18px" }}
          changeNameAfterUpload={false}
        />
        {file === null ? null : (
          <FileContainer>
            <LeftFileContainer>
              <File2Icon />
              <FileDetailsContainer>
                <FileName>{file.name}</FileName>
                <FileSize>
                  {(file.size / 1048576).toString().slice(0, 4)} MB
                </FileSize>
              </FileDetailsContainer>
            </LeftFileContainer>
            <RightFileContainer>
              <BinIcon
                style={{ marginRight: "25px", cursor: "pointer" }}
                onClick={removeFile}
              />
              <CheckIconContainer>
                <CheckIcon />
              </CheckIconContainer>
            </RightFileContainer>
          </FileContainer>
        )}

        <ButtonsContainer>
          <PrimaryButton style={{ width: "50%" }} onClick={handleCloseDialog}>
            Odustani
          </PrimaryButton>
          <SecondaryButton
            onClick={handleSubmit}
            style={{
              width: "50%",
              marginLeft: "12px",
              color: selectedTheme.colors.base.white,
            }}
            backgroundcolor={file === null
              ? selectedTheme.colors.primary[200]
              : selectedTheme.colors.primary[600]}
            disabled={file === null ? true : false}
          >
            Importuj
          </SecondaryButton>
        </ButtonsContainer>
      </ImportTenantsSubContainer>
    </ImportTenantsContainer>
  );
};

ImportTenantsFirstStep.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOpen: PropTypes.func,
  importTenants: PropTypes.func,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};

export default ImportTenantsFirstStep;
