import * as React from "react";
import PropTypes from "prop-types";
import {
  MainTable,
  MainTableCell,
  MainTableRow,
  MainTableRow2,
  MainTableRow3,
  MainTableBody,
  IconsContainer,
  RoleContainer,
  MainCollapse,
  UserContainer,
  UserMainContainer,
  EmailText,
  AgencyName,
  UserName,
  NameContainer,
} from "./UsersTable.styled";
import selectedTheme from "../../themes";
import BinIcon from "components/Icon/Icons/BinIcon";
import LockIcon from "components/Icon/Icons/LockIcon";
import UnconfirmedIcon from "components/Icon/Icons/UnconfirmedIcon";
import ArrowDownIcon from "components/Icon/Icons/ArrowDownIcon";
import ArrowUpIcon2 from "components/Icon/Icons/ArrowUpIcon2";
import UnlockIcon from "components/Icon/Icons/UnlockIcon";
import ConfirmedIcon from "components/Icon/Icons/ConfirmedIcon";
import Edit2Icon from "components/Icon/Icons/Edit2Icon";

// row which is shown when we collapse a row of UsersTable
export const TableRow = (props) => {
  const { row } = props;

  const isCollapsed = (row) => {
    return row.id === props.open || props.searchValue !== "";
  };

  // style cell based on content of cell
  const getUserCellContent = (cell, index) => {
    if (index === 1) {
      if (cell === "Administrator") {
        return (
          <RoleContainer
            style={{
              backgroundColor: selectedTheme.colors.error[50],
              color: selectedTheme.colors.error[700],
            }}
          >
            Administrator
          </RoleContainer>
        );
      } else if (cell === "Manager") {
        return (
          <RoleContainer
            style={{ backgroundColor: "#fdf4ff", color: "#9f1ab1" }}
          >
            Upravitelj idk
          </RoleContainer>
        );
      } else if (cell === "Operator") {
        return (
          <RoleContainer
            style={{
              backgroundColor: "#FFFAEB",
              color: "#B54708",
            }}
          >
            Operator
          </RoleContainer>
        );
      } else if (cell === "FinanceOperator") {
        return (
          <RoleContainer
            style={{
              backgroundColor: selectedTheme.colors.error[50],
              color: selectedTheme.colors.error[700],
            }}
          >
            Finansijski operator
          </RoleContainer>
        );
      }
    } else {
      if (cell === true) {
        return <ConfirmedIcon style={{ alignText: "center" }} />;
      } else {
        return <UnconfirmedIcon />;
      }
    }
  };

  return (
    <>
      <MainTableRow>
        <MainTableCell>
          <UserContainer>
            {/*row.workers === null means that administrator is loged in so all workers from his agency are shown */}
            {row.workers === null ? null : isCollapsed(row) ? (
              <ArrowUpIcon2
                onClick={() => props.setOpen("0")}
                style={{ cursor: "pointer", marginRight: "24px" }}
              />
            ) : (
              <ArrowDownIcon
                onClick={() => props.setOpen(row.id)}
                style={{ cursor: "pointer", marginRight: "24px" }}
              />
            )}
            <UserMainContainer>
              <NameContainer>
                <UserName>
                  {row.firstName} {row.lastName}
                </UserName>
                {row.workers === null ? null : (
                  <AgencyName>
                    {row.agencyName.length > 14
                      ? row.agencyName.slice(0, 7) + "..."
                      : row.agencyName}
                  </AgencyName>
                )}
              </NameContainer>
              <EmailText>{row.email}</EmailText>
            </UserMainContainer>
          </UserContainer>
        </MainTableCell>
        <MainTableCell>{getUserCellContent(row.roles[0], 1)}</MainTableCell>
        <MainTableCell>
          {getUserCellContent(row.emailConfirmed, 2)}
        </MainTableCell>
        <MainTableRow3>
          <IconsContainer>
            <Edit2Icon
              style={{ marginRight: "16px", cursor: "pointer" }}
              onClick={() => props.handleOpenUserDialog(row.id)}
            />
            {row.isActive ? (
              <UnlockIcon
                onClick={() =>
                  props.handleLockUser(row.id, row.email, row.isActive)
                }
                style={{
                  cursor: "pointer",
                  marginRight: "20px",
                }}
              />
            ) : (
              <LockIcon
                onClick={() =>
                  props.handleLockUser(row.id, row.email, row.isActive)
                }
                style={{
                  cursor: "pointer",
                  marginRight: "16px",
                }}
              />
            )}
            <BinIcon
              style={{ cursor: "pointer" }}
              onClick={() => props.handleDeleteUser(row.id, row.email)}
            />
          </IconsContainer>
        </MainTableRow3>
      </MainTableRow>
      <MainTableRow>
        <MainTableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            borderBottom: "none",
            paddingLeft: "0px",
            paddingRight: "0px",
          }}
          colSpan={4}
        >
          <MainCollapse in={isCollapsed(row)} timeout="auto" unmountOnExit>
            <MainTable>
              <MainTableBody>
                {row.workers === null
                  ? null
                  : row.workers.map((worker, index) => (
                      <MainTableRow2 key={index}>
                        <MainTableCell style={{ paddingLeft: "50px" }}>
                          <UserContainer>
                            {row.workers === null ? null : isCollapsed(row) ? (
                              <ArrowUpIcon2
                                style={{ marginRight: "24px", opacity: 0 }}
                              />
                            ) : (
                              <ArrowDownIcon
                                style={{ marginRight: "24px", opacity: 0 }}
                              />
                            )}
                            <UserMainContainer>
                              <NameContainer>
                                <UserName>
                                  {worker.firstName} {worker.lastName}
                                </UserName>
                              </NameContainer>
                              <EmailText>{worker.email}</EmailText>
                            </UserMainContainer>
                          </UserContainer>
                        </MainTableCell>
                        <MainTableCell>
                          {getUserCellContent(worker.roles[0], 1)}
                        </MainTableCell>
                        <MainTableCell>
                          {getUserCellContent(worker.emailConfirmed, 2)}
                        </MainTableCell>
                        <MainTableRow3>
                          <IconsContainer>
                            <Edit2Icon
                              style={{ marginRight: "16px", cursor: "pointer" }}
                              onClick={() =>
                                props.handleOpenUserDialog(worker.id)
                              }
                            />
                            {worker.isActive ? (
                              <UnlockIcon
                                onClick={() =>
                                  props.handleLockUser(
                                    worker.id,
                                    worker.email,
                                    worker.isActive
                                  )
                                }
                                style={{
                                  cursor: "pointer",
                                  marginRight: "30px",
                                }}
                              />
                            ) : (
                              <LockIcon
                                onClick={() =>
                                  props.handleLockUser(
                                    worker.id,
                                    worker.email,
                                    worker.isActive
                                  )
                                }
                                style={{
                                  cursor: "pointer",
                                  marginRight: "30px",
                                }}
                              />
                            )}

                            <BinIcon
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                props.handleDeleteUser(worker.id, worker.email)
                              }
                            />
                          </IconsContainer>
                        </MainTableRow3>
                      </MainTableRow2>
                    ))}
              </MainTableBody>
            </MainTable>
          </MainCollapse>
        </MainTableCell>
      </MainTableRow>
    </>
  );
};

TableRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
    emailConfirmed: PropTypes.bool,
    email: PropTypes.string,
    isActive: PropTypes.bool,
    agencyName: PropTypes.string,
    workers: PropTypes.arrayOf(
      PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        roles: PropTypes.arrayOf(PropTypes.string),
        emailConfirmed: PropTypes.bool,
      })
    ),
  }),
  handleLockUser: PropTypes.func,
  handleDeleteUser: PropTypes.func,
  open: PropTypes.string,
  setOpen: PropTypes.func,
  searchValue: PropTypes.string,
  handleOpenUserDialog: PropTypes.func,
};
