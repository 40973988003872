import { css } from "@emotion/react";
import styled from "styled-components";
import selectedTheme from "themes";

export const Label = styled.div`position: relative;
line-height: 20px;
font-weight: 500;
`;
export const Input1 = styled.textarea`
border: 1px solid ${selectedTheme.colors.gray[300]};
background-color: ${props => props?.disabled ? selectedTheme.colors.gray[100] : selectedTheme.colors.base.white};
align-self: stretch;
flex: 1;
border-radius: 8px;
box-shadow: ${selectedTheme.effects.shadow.xs.boxShadow};
overflow: hidden;
display: flex;
flex-direction: row;
padding: 12px 14px;
align-items: flex-start;
justify-content: flex-start;
  transition: all 0.2s;
  //text
  font-family: ${selectedTheme.type.textMd.regular.fontFamily};
  font-size: ${selectedTheme.type.textMd.regular.fontSize};
  font-weight: ${selectedTheme.type.textMd.regular.fontWeight};
  font-style: ${selectedTheme.type.textMd.regular.fontStyle};
  line-height: ${selectedTheme.type.textMd.regular.lineHeight};
  color: ${selectedTheme.colors.gray[700]};

  ${(props) =>
    props?.disabled &&
    css`
      background-color: ${selectedTheme.colors.gray[300]};
    `}
  &::placeholder {
    color: ${selectedTheme.colors.gray[500]};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
    box-shadow: ${(props) =>
      props?.error && typeof props?.error.error !== "undefined"
        ? selectedTheme.effects.shadow.xsFocused_4pxError100.boxShadow
        : selectedTheme.effects.shadow.xsFocused_4pxPrimary100.boxShadow};
    border: 1px solid
      ${(props) =>
        props?.error && typeof props?.error.error !== "undefined"
          ? selectedTheme.colors.error[300]
          : selectedTheme.colors.primary[300]};
  }
`;
export const InputWithLabel1 = styled.div`align-self: stretch;
flex: 1;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
gap: 6px;
`;
export const HintText1 = styled.div`align-self: stretch;
position: relative;
line-height: 20px;
color:${selectedTheme.colors.gray[600]};
`;
export const TextareaInputField1Root = styled.div`width: 100%;
position: relative;
height: 128px;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
gap: var(--gap-7xs);
text-align: left;
font-size: var(--text-sm-regular-size);
color: var(--gray-700);
font-family: var(--text-sm-regular);
`;