import React from 'react';
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import {registerFormikConstants} from "../../../../initialValues/registerInitialValues";
import TextInputField from 'components/shared/InputFields/TextField/TextField';

const RegisterCompanyAddressField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
      <TextInputField
        id={registerFormikConstants.companyAddress}
        name={registerFormikConstants.companyAddress}
        placeholder={t("register.companyAddressPlaceholder")}
        label={t("register.companyAddressLabel")}
        value={formik.values.companyAddress}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched?.[registerFormikConstants.companyAddress] && formik.errors?.[registerFormikConstants.companyAddress]}
        helperText={i18n.register && i18n.register?.companyAddressHintText && i18n.register?.companyAddressHintText !== "" && i18n.t("register.companyAddressHintText")}
        apiError={props?.apiError}
      />
  );
};

RegisterCompanyAddressField.propTypes = {
  formik: PropTypes.object,
  apiError: PropTypes.string,
};

export default RegisterCompanyAddressField;