import React from "react";
import { ReactComponent as BinIconSvg } from "../../../assets/Icons/bin.svg";

const BinIcon = (props) => {
  return <BinIconSvg {...props} />;
};

BinIcon.propTypes = {};

export default BinIcon;
