import React from "react";
import { ReactComponent as DeleteIconSvg } from "../../../assets/Icons/delete.svg";

const DeleteIcon = (props) => {
  return <DeleteIconSvg {...props} />;
};

DeleteIcon.propTypes = {};

export default DeleteIcon;
