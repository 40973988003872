import UploadIcon2 from "components/Icon/Icons/UploadIcon2";
import React from "react";
import { PropTypes } from 'prop-types';
import { ErrorText, Form, Input, UploadContainer, UploadHelperText, UploadMainText, UploadText } from "components/shared/UploadInput/UploadInput.styled";

const RentUploadImages = (props) => {
    return (
        <UploadContainer>
        <UploadIcon2 onClick={props?.handleClick} style={{ cursor: "pointer" }} />
        <Form>
          <UploadMainText>
            <UploadText onClick={props?.handleClick}>
              Klikni da uploadujes slike{" "}
            </UploadText>
            ili prevuci slike
          </UploadMainText>
          <Input/>
        </Form>
        <UploadHelperText>JPEG, PNG (max. 30MB)</UploadHelperText>
        {props?.isError && <ErrorText>Fajl nije uspesno uploadovan!</ErrorText>}
      </UploadContainer>
    )};

    RentUploadImages.propTypes = {
        ref:PropTypes.any,
        isError:PropTypes.any,
        handleFileChange:PropTypes.func,
        handleClick:PropTypes.func,
    }

    export default RentUploadImages;