import React from "react";
import { ReactComponent as MailIconSvg } from "../../../assets/Icons/mail-01-blue.svg";

const MailIconBlue = (props) => {
  return <MailIconSvg {...props} />;
};

MailIconBlue.propTypes = {};

export default MailIconBlue;
