import React, { useState } from "react";
import {
  BuildingBillsOverviewComponentContainer,
  ButtonsContainer,
  FilterContainer,
  TopContainer,
  ContentContainer,
} from "./BuildingBillsOverviewComponent.styled";
import {
  //PrimaryButton,
  SecondaryButton,
} from "components/shared/Buttons/Buttons.styled";
//import FilterIcon from "components/Icon/Icons/FilterIcon";
import SearchInputField from "components/shared/SearhInputField/SearchInputField";
import BillCard from "components/shared/BillCard/BillCard";
import PropTypes from "prop-types";
import { getMonth } from "../../util/dateHelpers";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "components/Icon/Icons/AddIcon";
import { useMediaQuery } from "@mui/material";

const BuildingBillsOverviewComponent = ({ bills }) => {
  const matches = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchValue, setSearchValue] = useState("");
  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  // apply filtering based on month and year of bill
  const applyFiltering = () => {
    return bills.filter(
      (bill) =>
        getMonth(bill.month)
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        bill.year.toString().toLowerCase().includes(searchValue.toLowerCase())
    );
  };

  return (
    <BuildingBillsOverviewComponentContainer>
      <TopContainer>
        <FilterContainer>
          {/* <PrimaryButton>
            <FilterIcon />
            {matches ? "" : "Filter"}
          </PrimaryButton> */}
          <SearchInputField
            style={{
              width: matches && "100%",
              marginLeft: !matches && "16px",
              marginRight: "16px",
            }}
            searchValue={searchValue}
            handleInputChange={handleInputChange}
          />
        </FilterContainer>
        <ButtonsContainer>
          <SecondaryButton
            style={{ flex: matches && 1, marginBottom: matches && "16px" }}
            onClick={() => navigate(`/buildings/${id}/add-debit`)}
            // style={{ border: "none", boxShadow: "none", marginRight: "10px" }}
          >
            <AddIcon />
            Novo zaduženje
          </SecondaryButton>
          {/* <PrimaryButton>
            <AddDebitIcon />
            Novo zaduženje kao prethodnog meseca
          </PrimaryButton> */}
        </ButtonsContainer>
      </TopContainer>
      <ContentContainer>
        {applyFiltering()?.map((bill, index) => (
          <BillCard bill={bill} key={index} />
        ))}
      </ContentContainer>
    </BuildingBillsOverviewComponentContainer>
  );
};

BuildingBillsOverviewComponent.propTypes = {
  bills: PropTypes.array,
};

export default BuildingBillsOverviewComponent;
