import React, { useEffect, useState } from "react";
import {
  Table,
  TableHeader,
  TableRow,
  ImportTenantsContainer,
  ImportTenantsMainText,
  ImportTenantsSubContainer,
  ImportTenantsText,
  TopContainer,
  TableRow2,
  TableCell,
  InformationsSubContainer,
  InformationsContainer,
  InformationText,
  TableCell2,
  DataContainer,
  DataSubContainer,
  DataText,
  DataInput,
  BackText,
  BackContainer,
  ErrorMessage,
  TBody,
} from "./ImportTenantsSecondStep.styled";
import PropTypes from "prop-types";
import ErrorIcon from "components/Icon/Icons/ErrorIcon";
import CloseIcon from "components/Icon/Icons/CloseIcon";
import ImportRightArrowIcon from "components/Icon/Icons/ImportRightArrowIcon";
import selectedTheme from "../../themes";
import {
  PrimaryButton,
  SecondaryButton,
} from "components/shared/Buttons/Buttons.styled";
import LeftArrowIcon from "components/Icon/Icons/LeftArrowIcon";
import { makeErrorToastMessage } from "../../util/toastMessage";
import { useCreateApartmentMutation } from "store/apartmentsApiSlice";

const ImportTenantsSecondStep = ({
  open,
  handleClose,
  apartments,
  returnToStepOne,
}) => {
  const [inputs, setInputs] = useState([]); // [{numOfApartment:123, objectCode:123},{}...]
  const [apr, setApr] = useState(apartments);
  const [createApartment] = useCreateApartmentMutation();
  const [isNumberOfApartmentUnique, setIsNumberOfApartmentUnique] = useState(
    // [false,true] - isNumberOfApartmentUNique[0] for apartment 1 and so on
    []
  );
  const [isObjectCodeUnique, setIsObjectCodeUnique] = useState([]);
  const [removedItems, setRemovedItems] = useState([]);
  useEffect(() => {
    setInputs(formatDatForInputs());
    setApr(apartments);
    setIsNumberOfApartmentUnique(getAllTrueValues());
    setIsObjectCodeUnique(getAllTrueValues());
  }, [apartments, apr, removedItems]);

  const handleInputChange = (e, index, isNumberOfApartment) => {
    let k = [...inputs];
    if (isNumberOfApartment) {
      k[index].numOfApartment = e.target.value;
    } else {
      k[index].objectCode = e.target.value;
    }
    setInputs(k);
  };

  const createApartmentHandler = (apartment, key) => {
    let s = { ...apartment.apartment };
    if (!apartment.isNumberOfApartmentValid) {
      s.numOfApartment = inputs[key].numOfApartment;
    }
    if (!apartment.isObjectCodeValid) {
      s.objectCode = inputs[key].objectCode;
    }
    createApartment(s)
      .unwrap()
      .then((res) => {
        if (
          res.isNumberOfApartmentValid === true &&
          res.isObjectCodeValid === true
        ) {
          const keyToRemove = "key";
          const valueToRemove = key;

          let newArray = apr.filter(
            (obj) => obj[keyToRemove] !== valueToRemove
          );

          newArray = newArray.filter((t) => !removedItems.includes(t.key));

          if (newArray.length === 0) {
            handleClose();
            setApr([]);
            setRemovedItems([]);
            return;
          }
          const m = [...removedItems];
          m.push(key);
          setRemovedItems(m);
          setApr(newArray);

          const gh = [...isNumberOfApartmentUnique];
          gh[key] = true;
          setIsNumberOfApartmentUnique(gh);

          const gh1 = [...isObjectCodeUnique];
          gh1[key] = true;
          setIsObjectCodeUnique(gh1);

          return;
        }
        if (res.isNumberOfApartmentValid === false) {
          const sm = [...isNumberOfApartmentUnique];
          sm[key] = false;
          setIsNumberOfApartmentUnique(sm);
        } else {
          const sm = [...isNumberOfApartmentUnique];
          sm[key] = true;
          setIsNumberOfApartmentUnique(sm);
        }

        if (res.isObjectCodeValid === false) {
          const sm1 = [...isObjectCodeUnique];
          sm1[key] = false;
          setIsObjectCodeUnique(sm1);
        } else {
          const sm1 = [...isObjectCodeUnique];
          sm1[key] = true;
          setIsObjectCodeUnique(sm1);
        }
      })
      .catch(() => makeErrorToastMessage("Stan nije uspešno kreiran!"));
  };

  const isButtonDisabled = (apartment, index) => {
    if (apartments.length === 0 || inputs.length === 0) return true;

    if (
      (apartment.apartment.numOfApartment === inputs[index].numOfApartment &&
        apartment.isNumberOfApartmentValid === false) ||
      (apartment.apartment.objectCode === inputs[index].objectCode &&
        apartment.isObjectCodeValid === false)
    )
      return true;

    return false;
  };

  const formatDatForInputs = () => {
    const t = Array(apartments.length);
    for (let i = 0; i < apartments.length; i++) {
      t[i] = { numOfApartment: 0, objectCode: 0 };
      if (apartments[i].isNumberOfApartmentValid === false) {
        t[i].numOfApartment = apartments[i].apartment.numOfApartment;
      }
      if (!apartments[i].isObjectCodeValid === false) {
        t[i].objectCode = apartments[i].apartment.objectCode;
      }
    }
    return t;
  };

  const getAllTrueValues = () => {
    const s = Array(apartments.length);
    for (let i = 0; i < apartments.length; i++) {
      s[i] = true;
    }
    return s;
  };

  return (
    <ImportTenantsContainer
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "922px",
          },
        },
      }}
    >
      <ImportTenantsSubContainer>
        <TopContainer>
          <ErrorIcon />
          <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
        </TopContainer>
        <ImportTenantsMainText>Greške pri importovanju</ImportTenantsMainText>
        <ImportTenantsText>
          Molimo vas da promenite sporne podatke
        </ImportTenantsText>
        <Table>
          <TBody>
            <TableRow>
              <TableHeader style={{ borderTopLeftRadius: "12px" }}>
                Stan
              </TableHeader>
              <TableHeader style={{ borderTopRightRadius: "12px" }}>
                Sporne Stavke
              </TableHeader>
            </TableRow>
            {apr
              .filter((k) => !removedItems.includes(k.key))
              .map((apartment) => (
                <TableRow2 key={apartment.key}>
                  <TableCell
                    style={{
                      borderRight: `1px solid ${selectedTheme.colors.gray[200]}`,
                      borderBottom: `1px solid ${selectedTheme.colors.gray[200]}`,
                    }}
                  >
                    <InformationsContainer>
                      <InformationsSubContainer>
                        <InformationText
                          style={{
                            color: selectedTheme.colors.gray[900],
                            fontWeight:
                              selectedTheme.type.textSm.medium.fontWeight,
                          }}
                        >
                          Broj Stana
                        </InformationText>
                        <InformationText
                          style={{
                            color: selectedTheme.colors.gray[900],
                            fontWeight:
                              selectedTheme.type.textSm.medium.fontWeight,
                          }}
                        >
                          Šifra objekta
                        </InformationText>
                        <InformationText>Ime vlasnika</InformationText>
                        <InformationText>Broj stanara</InformationText>
                      </InformationsSubContainer>
                      <ImportRightArrowIcon />
                      <InformationsSubContainer>
                        <InformationText
                          style={{
                            color:
                              apartment.isNumberOfApartmentValid === false
                                ? selectedTheme.colors.error[700]
                                : selectedTheme.colors.gray[700],
                            fontWeight:
                              apartment.isNumberOfApartmentValid === false
                                ? selectedTheme.type.textSm.medium.fontWeight
                                : selectedTheme.type.textSm.regular.fontWeight,
                          }}
                        >
                          Stan br {apartment.apartment.numOfApartment}
                        </InformationText>
                        <InformationText
                          style={{
                            color:
                              apartment.isObjectCodeValid === false
                                ? selectedTheme.colors.error[700]
                                : selectedTheme.colors.gray[700],
                            fontWeight:
                              apartment.isObjectCodeValid === false
                                ? selectedTheme.type.textSm.medium.fontWeight
                                : selectedTheme.type.textSm.regular.fontWeight,
                          }}
                        >
                          {apartment.apartment.objectCode === null
                            ? "/"
                            : apartment.apartment.objectCode}
                        </InformationText>
                        <InformationText>
                          {apartment.apartment.tenant.firstName}{" "}
                          {apartment.apartment.tenant.lastName}
                        </InformationText>
                        <InformationText>
                          {apartment.apartment.numOfTenants}
                        </InformationText>
                      </InformationsSubContainer>
                    </InformationsContainer>
                  </TableCell>
                  <TableCell2
                    style={{
                      borderBottom: `1px solid ${selectedTheme.colors.gray[200]}`,
                    }}
                  >
                    <DataContainer>
                      {apartment.isNumberOfApartmentValid === false ? (
                        <DataSubContainer>
                          <DataText>Broj Stana</DataText>
                          <DataInput
                            style={{
                              width:
                                apartment.isNumberOfApartmentValid === false &&
                                apartment.isObjectCodeValid === false
                                  ? "135px"
                                  : "339px",
                            }}
                            value={
                              inputs.length === 0
                                ? 0
                                : inputs[apartment.key].numOfApartment
                            }
                            onChange={(e) =>
                              handleInputChange(e, apartment.key, true)
                            }
                          />
                          {isNumberOfApartmentUnique[apartment.key] ===
                          false ? (
                            <ErrorMessage>
                              Broj stana mora imati jedinstvenu vrednost!
                            </ErrorMessage>
                          ) : null}
                        </DataSubContainer>
                      ) : null}
                      {apartment.isObjectCodeValid === false ? (
                        <DataSubContainer style={{ marginLeft: "16px" }}>
                          <DataText>Šifra objekta</DataText>
                          <DataInput
                            style={{
                              width:
                                apartment.isNumberOfApartmentValid === false &&
                                apartment.isObjectCodeValid === false
                                  ? "188px"
                                  : "339px",
                            }}
                            value={
                              inputs.length === 0
                                ? 0
                                : inputs[apartment.key].objectCode
                            }
                            onChange={(e) =>
                              handleInputChange(e, apartment.key, false)
                            }
                          />
                          {isObjectCodeUnique[apartment.key] === false ? (
                            <ErrorMessage>
                              šifra objekta mora imati jedinstvenu vrednost!
                            </ErrorMessage>
                          ) : null}
                        </DataSubContainer>
                      ) : (
                        false
                      )}

                      <SecondaryButton
                        style={{
                          marginLeft: "48px",
                          color: selectedTheme.colors.base.white,
                          paddingLeft: "32px",
                          paddingRight: "32px",
                        }}
                        backgroundcolor={
                          isButtonDisabled(apartment, apartment.key) === true
                            ? selectedTheme.colors.primary[200]
                            : selectedTheme.colors.primary[600]
                        }
                        disabled={isButtonDisabled(apartment, apartment.key)}
                        onClick={() =>
                          createApartmentHandler(apartment, apartment.key)
                        }
                      >
                        Promeni
                      </SecondaryButton>
                    </DataContainer>
                  </TableCell2>
                </TableRow2>
              ))}
          </TBody>
        </Table>
        <BackContainer>
          <LeftArrowIcon
            style={{ cursor: "pointer" }}
            onClick={returnToStepOne}
          />
          <BackText>Nazad</BackText>
        </BackContainer>
        <PrimaryButton
          style={{ width: "100%", marginTop: "42px" }}
          onClick={handleClose}
        >
          Odustani
        </PrimaryButton>
      </ImportTenantsSubContainer>
    </ImportTenantsContainer>
  );
};

ImportTenantsSecondStep.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  apartments: PropTypes.array,
  returnToStepOne: PropTypes.func,
};

export default ImportTenantsSecondStep;
