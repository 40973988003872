import FilterIcon from "components/Icon/Icons/FilterIcon";
import { PrimaryButton } from "components/shared/Buttons/Buttons.styled";
import SearchInputField from "components/shared/SearhInputField/SearchInputField";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import selectedTheme from "../../themes";
import AlertDialog from "components/shared/Dialogs/AlertDialog";
import DeleteIcon from "components/Icon/Icons/DeleteIcon";
import {
  useDeleteUserMutation,
  useLockUserMutation,
  useUpdateUserDataQuery,
} from "store/settingsApiSlice";
import {
  makeErrorToastMessage,
  makeToastMessage,
} from "../../util/toastMessage";
import {
  UserManagamentSubContainer,
  UserManagementContainer,
} from "./UserManagement.styled";
import UsersTable from "components/UsersTable/UsersTable";
import selectedThemes from "../../themes";
import AddIcon from "components/Icon/Icons/AddIcon";
import AddUserDialog from "components/AddUserDialog/AddUserDialog";
import { useMediaQuery } from '@mui/material';

const UserManagementComponent = ({ users, roles }) => {
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false); // for deleting user
  const [open2, setOpen2] = useState(false); // for locking user
  const [openAddUser, setOpenAddUser] = useState(false);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [deleteUser] = useDeleteUserMutation();
  const [userId, setUserId] = useState(null);
  const [email, setEmail] = useState(null);
  const [lockUser] = useLockUserMutation();
  const [isLocked, setIsLocked] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const { data, isLoading } = useUpdateUserDataQuery(selectedUser);
  const matches = useMediaQuery("(min-width:768px)");
  console.log(matches)

  useEffect(() => {}, [selectedUser]);

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const setUser = (id, email) => {
    setEmail(email);
    setUserId(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (userId, email) => {
    setUser(userId, email);
    setOpen(true);
  };

  const handleDeleteUser = () => {
    deleteUser({ id: userId })
      .unwrap()
      .then(() => {
        handleClose();
        setUserId(null);
        makeToastMessage("Uspešno ste obrisali korisnika");
      })
      .catch(() => makeErrorToastMessage("Korisnik nije uspešno obrisan"));
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleOpen2 = (userId, email, isActive) => {
    setIsLocked(!isActive);
    setUser(userId, email);
    setOpen2(true);
  };

  // we can either lock or unlock user
  const handleLockUser = () => {
    lockUser({ userId: userId, isLockingUser: !isLocked })
      .unwrap()
      .then(() => {
        handleClose2();
        setUserId(null);
        makeToastMessage(
          `Uspešno ste ${isLocked ? "otključali" : "zaključali"} korisnika`
        );
      })
      .catch(() =>
        makeErrorToastMessage(
          `Korisnik nije uspešno ${isLocked ? "otključan" : "zaključan"}`
        )
      );
  };

  const handleCloseAddUserDialog = () => {
    setOpenAddUser(false);
  };

  const handleOpenAddUserDialog = () => {
    setOpenAddUser(true);
  };

  const filterUsers = () => {
    if (users.length === 0) return [];
    // for administrator view (there is no option to collapse row)
    if (users.length === 0) return [];
    if (users[0].workers === null) {
      return users.filter((user) =>
        (user.firstName + " " + user.lastName).includes(searchValue)
      );
    }

    // superAdministrator view
    const filteredArray = users
      .map((user) => {
        // filter workers based on first and last name
        const filteredWorkers = user.workers.filter((worker) =>
          (worker.firstName + " " + worker.lastName).includes(searchValue)
        );

        // there are no workers for administrator after filtering so don't show his workers
        if (filteredWorkers.length === 0) {
          return { ...user, workers: [] };
        }

        return { ...user, workers: filteredWorkers };
      })
      .filter(
        (k) =>
          k.workers.length > 0 ||
          (k.firstName + " " + k.lastName).includes(searchValue)
      );

    return filteredArray;
  };

  const handleOpenUserEditDialog = (userId) => {
    setOpenEditUser(true);
    setSelectedUser(userId);
  };

  const handleCloseEditUserDialog = () => {
    setOpenEditUser(false);
    setSelectedUser(null);
  };

  return isLoading ? (
    <p>Loading...</p>
  ) : (
    <>
      <AlertDialog
        open={open}
        handleClose={handleClose}
        title="Želite da izbrišete korisnika?"
        content={`Da li ste sigurni da želite da izbrišete korisnika ${email} ? Ova funkcija je iverzebilna.`}
        handleSubmit={handleDeleteUser}
        Icon={<DeleteIcon />}
        actionName="IZBRIŠI"
      />
      <AlertDialog
        open={open2}
        handleClose={handleClose2}
        title={`Želite da ${isLocked ? "otključate" : "zaključate"} korisnika?`}
        content={`Da li ste sigurni da želite da ${
          isLocked ? "otključate" : "zaključate"
        } korisnika ${email} ?`}
        handleSubmit={handleLockUser}
        actionName={`${isLocked ? "OTKLJUČAJ" : "ZAKLJUČAJ"}`}
      />
      <AddUserDialog
        open={openAddUser}
        handleClose={handleCloseAddUserDialog}
        roles={roles}
      />
      {selectedUser !== null ? (
        <AddUserDialog
          open={openEditUser}
          handleClose={handleCloseEditUserDialog}
          roles={roles}
          editUser={true}
          user={data}
          userId={selectedUser}
        />
      ) : null}

      <UserManagementContainer>
        <UserManagamentSubContainer>
          <div style={{ display: "flex", alignItems: "center", gap:"16px" }}>
            <PrimaryButton>
              <FilterIcon />
              Filter
            </PrimaryButton>
            <SearchInputField
              style={!matches ? { width:"100%" } : {}}
              searchValue={searchValue}
              handleInputChange={handleInputChange}
            />
          </div>
          <PrimaryButton
            onClick={handleOpenAddUserDialog}
            style={{
              backgroundColor: selectedThemes.colors.primary[600],
              color: selectedThemes.colors.base.white,
              width: !matches ? "100%" : "fit-content"
            }}
          >
            <AddIcon />
            Dodaj Korisnika
          </PrimaryButton>
        </UserManagamentSubContainer>
        <UsersTable
          style={{
            overflow: matches ? "auto" : "scroll",
            width: matches ? "100%" : "auto",
            marginTop: "24px",
            border: `1px solid ${selectedTheme.colors.gray[200]}`,
            boxShadow: `${selectedTheme.effects.shadow.sm.boxShadow}`,
          }}
          handleDeleteUser={handleOpen}
          handleLockUser={handleOpen2}
          rows={filterUsers()}
          searchValue={searchValue}
          handleOpenUserDialog={handleOpenUserEditDialog}
        />
      </UserManagementContainer>
    </>
  );
};

UserManagementComponent.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
  roles: PropTypes.arrayOf(PropTypes.object),
};

export default UserManagementComponent;
