import React, { useState, Fragment, useEffect } from "react";
import {
  BottomContainer,
  BottomRowItem,
  EmailContainer,
  HeaderContainer,
  HeaderTitle,
  NavbarContainer,
  NavbarItem,
  NavbarItemContainer,
  ProfileContainer,
  ProfileSubTitle,
  ProfileTitle,
  TopContainer,
  StickyContainer,
  MenuDialogContainer,
  MenuDialog,
  MultiplyContainer,
  IconContainer,
  ContentContainer,
} from "./Navbar.styled";
import MainIcon from "components/Icon/Icons/MainIcon";
import HomeIcon from "components/Icon/Icons/HomeIcon";
import BuildingsIcon from "components/Icon/Icons/BuildingsIcon";
import FinancesIcon from "components/Icon/Icons/FinancesIcon";
//import BillsIcon from "components/Icon/Icons/BillsIcon";
//import StatisticIcon from "components/Icon/Icons/StatisticIcon";
import SettingsIcon from "components/Icon/Icons/SettingsIcon";
import LeftArrowsIcon from "components/Icon/Icons/LeftArrowsIcon";
import ProfileIcon from "components/Icon/Icons/ProfileIcon";
import RightArrowIcon from "components/Icon/Icons/RightArrow";
import MenuIcon from "components/Icon/Icons/MenuIcon";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOut } from "store/authSlice";
import Line from "../Line/Line";
import XCloseIcon from "./../../Icon/Icons/XCloseIcon";
import { useMediaQuery } from "@mui/material";
import selectedTheme from "themes";
import { useProfileQuery } from "store/homeApiSlice";

const Navbar = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [mobileOpened, setMobileOpened] = useState(false);
  const matches = useMediaQuery("(max-width:768px)");


  // eslint-disable-next-line no-unused-vars
  const { data: userEmail, isLoading } = useProfileQuery({ staleTime: 5000 });

  useEffect(() => {
    !matches && setMobileOpened(false);
  },[])

  return (
    <>
    {mobileOpened && (
      <MenuDialog open={mobileOpened} onClose={() => setMobileOpened(false)}>
      <MenuDialogContainer>
        <ContentContainer>
        <TopContainer style={{ alignItems: collapsed && "center" }}>
            <HeaderContainer style={{ marginLeft: !collapsed && "16px" }}>
              <MainIcon />
              <HeaderTitle>Upravnica</HeaderTitle>
            </HeaderContainer>
           
                <NavbarItemContainer
                  style={{
                    marginTop: "24px",
                    justifyContent: collapsed && "center",
                  }}
                  onClick={() => {navigate("/"); setMobileOpened(false)}}
                >
                  <HomeIcon stroke={selectedTheme.colors.primary[100]} />
                  {!collapsed && <NavbarItem>Početna</NavbarItem>}
                </NavbarItemContainer>
                <NavbarItemContainer
                  style={{ justifyContent: collapsed && "center" }}
                  onClick={() => {navigate("/buildings");setMobileOpened(false)}}
                >
                  <BuildingsIcon stroke={selectedTheme.colors.primary[100]} style={{ width: 24, height: 24 }} />
                  {!collapsed && <NavbarItem>Zgrade</NavbarItem>}
                </NavbarItemContainer>
                <NavbarItemContainer
                  style={{ justifyContent: collapsed && "center" }}
                  onClick={() => {navigate("/finances"); setMobileOpened(false)}}
                >
                  <FinancesIcon stroke={selectedTheme.colors.primary[100]} style={{ width: 24, height: 24 }} />
                  {!collapsed && <NavbarItem>Finansije</NavbarItem>}
                </NavbarItemContainer>
                {/* <NavbarItemContainer
                  style={{ justifyContent: collapsed && "center" }}
                >
                  <BillsIcon style={{ width: 24, height: 24 }} />
                  {!collapsed && <NavbarItem>E-Računi</NavbarItem>}
                </NavbarItemContainer>
                <NavbarItemContainer
                  style={{ justifyContent: collapsed && "center" }}
                >
                  <StatisticIcon style={{ width: 24, height: 24 }} />
                  <NavbarItem>Statistika</NavbarItem>
                </NavbarItemContainer> */}
          </TopContainer>
            <BottomContainer>
            
             
                <NavbarItemContainer style={{ width: "100%" }}>
                  <ProfileContainer onClick={() => {navigate("/settings");setMobileOpened(false)}}>
                    <SettingsIcon stroke={selectedTheme.colors.primary[100]}/>
                    <NavbarItem>Podešavanja</NavbarItem>
                  </ProfileContainer>
                  </NavbarItemContainer>
                <Line style={{ marginTop: 20, marginBottom: 12 }} />
              <BottomRowItem>
                <NavbarItemContainer>
                  <ProfileContainer>
                    <ProfileIcon stroke={selectedTheme.colors.primary[100]} />
                      <EmailContainer>
                        <ProfileTitle>Mila Cvetkovic</ProfileTitle>
                        <ProfileSubTitle>
                          mila.cvetkovic@gmail.com
                        </ProfileSubTitle>
                      </EmailContainer>
                  </ProfileContainer>
                </NavbarItemContainer>
                <NavbarItemContainer>
                    <RightArrowIcon
                      onClick={() => {
                        {dispatch(logOut());setMobileOpened(false)}
                      }}
                    />
                  </NavbarItemContainer>
                </BottomRowItem>
                </BottomContainer>
                
        </ContentContainer>
      
          
      <MultiplyContainer>
        <IconContainer
          onClick={() => setMobileOpened(false)}
          style={{ cursor: "pointer" }}
        >
          <XCloseIcon />
        </IconContainer>
      </MultiplyContainer>

      </MenuDialogContainer>
      
    
    </MenuDialog>
    )}
      

      <NavbarContainer style={{ minWidth: collapsed === false ? 312 : 70 }}>
        <StickyContainer>
          <TopContainer style={{ alignItems: collapsed && "center" }}>
            <HeaderContainer style={{ marginLeft: !collapsed && "16px" }}>
              <MainIcon />
              {!matches && !collapsed && <HeaderTitle>Upravnica</HeaderTitle>}
            </HeaderContainer>
            {!matches && (
              <Fragment>
                <NavbarItemContainer
                  style={{
                    marginTop: "24px",
                    justifyContent: collapsed && "center",
                  }}
                  onClick={() => navigate("/")}
                >
                  <HomeIcon stroke={selectedTheme.colors.primary[100]} />
                  {!collapsed && <NavbarItem>Početna</NavbarItem>}
                </NavbarItemContainer>
                <NavbarItemContainer
                  style={{ justifyContent: collapsed && "center" }}
                  onClick={() => navigate("/buildings")}
                >
                  <BuildingsIcon stroke={selectedTheme.colors.primary[100]} style={{ width: 24, height: 24 }} />
                  {!collapsed && <NavbarItem>Zgrade</NavbarItem>}
                </NavbarItemContainer>
                <NavbarItemContainer
                  onClick={() => navigate("/finances")}
                  style={{ justifyContent: collapsed && "center" }}
                >
                  <FinancesIcon stroke={selectedTheme.colors.primary[100]} style={{ width: 24, height: 24 }} />
                  {!collapsed && <NavbarItem>Finansije</NavbarItem>}
                </NavbarItemContainer>
                {/* <NavbarItemContainer
                  style={{ justifyContent: collapsed && "center" }}
                >
                  <BillsIcon style={{ width: 24, height: 24 }} />
                  {!collapsed && <NavbarItem>E-Računi</NavbarItem>}
                </NavbarItemContainer>
                <NavbarItemContainer
                  style={{ justifyContent: collapsed && "center" }}
                >
                  <StatisticIcon style={{ width: 24, height: 24 }} />
                  {!collapsed && <NavbarItem>Statistika</NavbarItem>}
                </NavbarItemContainer> */}
              </Fragment>
            )}
          </TopContainer>
          {!matches ? (
            <BottomContainer>
              <BottomRowItem>
                <NavbarItemContainer style={{ width: "100%" }}>
                  <ProfileContainer onClick={() => navigate("/settings")}>
                    <SettingsIcon stroke={selectedTheme.colors.primary[100]} />
                    {!collapsed && <NavbarItem>Podešavanja</NavbarItem>}
                  </ProfileContainer>
                </NavbarItemContainer>
                {!collapsed && (
                  <NavbarItemContainer>
                    <LeftArrowsIcon onClick={() => setCollapsed(!collapsed)} />
                  </NavbarItemContainer>
                )}
              </BottomRowItem>

              {collapsed && (
                <NavbarItemContainer
                  onClick={() => setCollapsed(!collapsed)}
                  style={{
                    justifyContent: collapsed ? "center" : "space-between",
                  }}
                >
                  <LeftArrowsIcon
                    style={{
                      transform: "rotate(180deg)",
                      cursor: "pointer",
                    }}
                  />
                </NavbarItemContainer>
              )}
              {collapsed === false && (
                <Line style={{ marginTop: 20, marginBottom: 12, border: `1px solid ${selectedTheme.colors.gray[600]}`, }} />
              )}
              <BottomRowItem>
                <NavbarItemContainer>
                  <ProfileContainer>
                    <ProfileIcon stroke={selectedTheme.colors.primary[100]} />
                    {!collapsed && (
                      <EmailContainer>
                        {!isLoading && userEmail && 
                          <ProfileSubTitle>
                          {userEmail?.email}
                        </ProfileSubTitle>
                        }
                      </EmailContainer>
                    )}
                  </ProfileContainer>
                </NavbarItemContainer>

                {!collapsed && (
                  <NavbarItemContainer>
                    <RightArrowIcon
                      onClick={() => {
                        dispatch(logOut());
                      }}
                    />
                  </NavbarItemContainer>
                )}
              </BottomRowItem>
              {collapsed && (
                <NavbarItemContainer
                  style={{
                    justifyContent: collapsed ? "center" : "space-between",
                  }}
                >
                  <RightArrowIcon
                    onClick={() => {
                      dispatch(logOut());
                    }}
                    style={{ border: "none", padding: "none" }}
                  />
                </NavbarItemContainer>
              )}
            </BottomContainer>
          ) : (
            <BottomContainer>
              <MenuIcon
                onClick={() => {
                  setMobileOpened(true);
                }}
                style={{ border: "none", padding: "none" }}
              />
            </BottomContainer>
          )}
        </StickyContainer>
      </NavbarContainer>
    </>
  );
};

export default Navbar;
