import React from "react";
import PropTypes from "prop-types";
import BasicSelect from "components/shared/InputFields/SelectField/BasicSelect";
import { addApartmentInitialConstants } from 'initialValues/addApartmentInitialValues';

const DeliveryCityField = (props) => {
  const formik = props?.formik;

  return (
    <BasicSelect
      id={addApartmentInitialConstants.deliveryCityId}
      name={addApartmentInitialConstants.deliveryCityId}
      label="Grad dostave"
      value={formik.values.deliveryCityId}
      //onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched?.[addApartmentInitialConstants.deliveryCityId] && formik.errors?.[addApartmentInitialConstants.deliveryCityId]}
      apiError={props?.apiError}
      changeFormikValue={formik.setFieldValue}
      class={props?.class}
      options={props?.options}
      defaultValue={props?.defaultValue}
    />
  );
};

DeliveryCityField.propTypes = {
  formik: PropTypes.object,
  apiError: PropTypes.any,
  class: PropTypes.string,
  options: PropTypes.array,
  defaultValue: PropTypes.any,
};

export default DeliveryCityField;
