import React from 'react'
//import PropTypes from 'prop-types'
import { LoadingModalContainer } from './LoadingModal.styled';
import LoaderIcon from 'components/Icon/Icons/LoaderIcon';

const LoadingModal = () => {
  return (
    <LoadingModalContainer>
        <LoaderIcon/>
    </LoadingModalContainer>
  )
}

LoadingModal.propTypes = {}

export default LoadingModal