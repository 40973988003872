import { Typography, Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const AlertDialogContainer = styled(Box)``;

export const TopContainer = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  width: 100%;
  height: 44px;
  margin-top: 32px;
`;

export const Title = styled(Typography)`
  font-family: ${selectedTheme.type.textLg.semibold.fontFamily};
  font-size: ${selectedTheme.type.textLg.semibold.fontSize};
  font-weight: ${selectedTheme.type.textLg.semibold.fontWeight};
  font-style: ${selectedTheme.type.textLg.semibold.fontStyle};
  line-height: ${selectedTheme.type.textLg.semibold.lineHeight};
  color: ${selectedTheme.colors.gray[900]};
  margin-top: 16px;
`;

export const Content = styled(Typography)`
  font-family: ${selectedTheme.type.textLg.regular.fontFamily};
  font-size: 14px;
  font-weight: ${selectedTheme.type.textLg.regular.fontWeight};
  font-style: ${selectedTheme.type.textLg.regular.fontStyle};
  line-height: ${selectedTheme.type.textLg.regular.lineHeight};
  color: ${selectedTheme.colors.gray[600]};
`;

export const ActionButton = styled(Box)`
  font-family: ${selectedTheme.type.textMd.semibold.fontFamily};
  font-size: ${selectedTheme.type.textMd.semibold.fontSize};
  font-weight: ${selectedTheme.type.textMd.semibold.fontWeight};
  font-style: ${selectedTheme.type.textMd.semibold.fontStyle};
  line-height: ${selectedTheme.type.textMd.semibold.lineHeight};
  color: ${selectedTheme.colors.error[700]};
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Span = styled("span")`
  cursor: pointer;
`;
