import BuildingsIcon from "components/Icon/Icons/BuildingsIcon";
import DownloadIcon from "components/Icon/Icons/DownloadIcon";
import RightArrowNavigationIcon from "components/Icon/Icons/RightArrowNavigationIcon";
import {
  PrimaryButton,
  SecondaryButton,
} from "components/shared/Buttons/Buttons.styled";
import Line from "components/shared/Line/Line";
import React, { useEffect, useState } from "react";
import selectedTheme from "../../themes";
import Navbar from "components/shared/Navbar/Navbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  AddDebitPageContainer,
  AddDebitPageSubContainer,
  AddItemButtonContainer,
  BottomContainer,
  BuildingMonthlyItemsContainer,
  ButtonsContainer,
  EmptyContainer,
  Input,
  InputContainer,
  InputLabel,
  ItemsTitle,
  MiddleContainer,
  NavigationContainer,
  NavigationPreviousText,
  NavigationText,
  Title,
  TopContainer,
} from "./AddDebitPage.styled";
import AddDebitIcon from "components/Icon/Icons/AddDebitIcon";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useDeleteBuildingMonthlyItemMutation } from "store/buildingMonthlyItems";
import { useParams, useNavigate } from "react-router-dom";
import BuildingMonthlyItem from "components/BuildingMonthlyItem/BuildingMonthlyItem";
import { useCreateDebitMutation } from "store/debitApiSlice";
import { useBuildingBillsMutation } from "store/exportApiSlice";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import { useDebitDetailsQuery } from "store/debitApiSlice";
import BackLink from "components/shared/BackLink/BackLink";
import { useTranslation } from "react-i18next";
import { useDispatch} from 'react-redux';
import { startLoading, stopLoading} from '../../store/loadingSlice';

const AddDebitPage = () => {
  const matches = useMediaQuery("(max-width:768px)");
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data, isLoading } = useDebitDetailsQuery(id);

  const [deleteMonthlyItem] = useDeleteBuildingMonthlyItemMutation();
  const [createDebit] = useCreateDebitMutation();
  const [getBuildingBills] = useBuildingBillsMutation();

  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [isListDisplayed, setIsListDisplayed] = useState([]); // is list for selecting new apartments displayed or not [true,false,...]
  const [selectedApartments, setSelectedApartments] = useState([]); // [[10058,10057],[10058]] - every item  can have multiple selected apartments
  const [includeApartments, setIncludeApartments] = useState([]); // [true,false,true, ....]
  const [names, setNames] = useState([]); // every item will represent an input of monthlyItems we get from backend
  const [amounts, setAmounts] = useState([]);
  const [selectedBillingTypes, setSelectedBillingTypes] = useState([]);

  const [managamentCost, setManagamentCost] = useState(0);
  const [fundCost, setFundCost] = useState(0);
  const [investmentCost, setInvestmentCost] = useState(0);

  // new created buildingMonthlyItems
  const [newMonthlyItems, setNewMonthlyItems] = useState([]);
  const [isListDisplayed2, setIsListDisplayed2] = useState([]); // is list for selecting new apartments displayed or not [true,false,...]
  const [selectedApartments2, setSelectedApartments2] = useState([[]]); // [[10058,10057],[10058]] - every item  can have multiple selected apartments
  const [includeApartments2, setIncludeApartments2] = useState([]); // [true,false,true, ....]
  const [names2, setNames2] = useState([]); // every item will represent an input of monthlyItems we get from backend
  const [amounts2, setAmounts2] = useState([]);
  const [selectedBillingTypes2, setSelectedBillingTypes2] = useState([]);

  useEffect(() => {
    setIsListDisplayed(initializeDisplayList());
    setNames(formatInput("name"));
    setAmounts(formatInput("amount"));
    setIncludeApartments(formatInput("includeApartments"));
    setSelectedBillingTypes(formatBillingTypes());
    setSelectedApartments(formatApartments());
    setManagamentCost(isLoading ? 0 : data.costOfManagement);
    setFundCost(isLoading ? 0 : data.currentFund);
    setInvestmentCost(isLoading ? 0 : data.investment);
    formatBillingTypes2();
    // setSelectedBillingTypes2(formatBillingTypes2());
  }, [data]);

  const formatInput = (key) => {
    if (isLoading === true) return [];
    const arr = [];
    data.monthlyItems.forEach((monthlyItem) => {
      arr.push(monthlyItem[key]);
    });
    return arr;
  };

  const formatBillingTypes = () => {
    if (isLoading === true) return [];
    const arr = [];
    data.monthlyItems.forEach((k) => {
      arr.push(k.billingTypeId);
    });
    return arr;
  };

  const formatApartments = () => {
    if (isLoading === true) return [];
    const arr = [];
    data.monthlyItems.forEach((monthlyItem) => {
      arr.push(monthlyItem.apartments.split(" "));
    });
    return arr;
  };

  const handleChangeBillingType = (event, index) => {
    const arr = [...selectedBillingTypes];
    arr[index] = event.target.value;
    setSelectedBillingTypes(arr);
  };

  const handleSelectApartment = (selectedItem, index) => {
    const s = [...selectedApartments];
    const newArray = [...selectedApartments[index]];
    const i = newArray.indexOf(selectedItem.toString());
    if (i !== -1) {
      // item is already selected so remove item
      newArray.splice(i, 1);
    } else {
      newArray.push(selectedItem.toString());
    }
    s[index] = newArray;
    console.log(s);
    setSelectedApartments(s);
  };

  const handleUnselectApartment = (item, index) => {
    const s = [...selectedApartments];
    const newArray = [...selectedApartments[index]];
    const i = newArray.indexOf(item.toString());
    newArray.splice(i, 1);
    s[index] = newArray;
    setSelectedApartments(s);
  };

  const handleChangeIncludeApartments = (index) => {
    const arr = [...includeApartments];
    arr[index] = !includeApartments[index];
    setIncludeApartments(arr);
  };

  const handleChangeName = (e, index) => {
    const arr = [...names];
    arr[index] = e.target.value;
    setNames(arr);
  };

  const handleChangeAmount = (e, index) => {
    const arr = [...amounts];
    arr[index] = e.target.value;
    setAmounts(arr);
  };

  const handleDeleteMonthlyItem = (monthlyItemId) => {
    deleteMonthlyItem(monthlyItemId);
  };

  const initializeDisplayList = () => {
    if (isLoading === true) return [];
    const t = [];
    data.monthlyItems.forEach(() => {
      t.push(false);
    });
    return t;
  };

  const handleChangeDisplayList = (value, index) => {
    const k = [...isListDisplayed];
    k[index] = value;
    setIsListDisplayed(k);
  };

  const addNewMonthlyItem = () => {
    const k = {
      name: "",
      billingTypeId: 1,
      amount: 0,
      numOfApartments: [],
      includeApartments: false,
      apartments: "",
    };

    const arr = [...includeApartments2];
    arr.push(false);
    setIncludeApartments2(arr);

    const s = [...newMonthlyItems];
    s.push(k);
    setNewMonthlyItems(s);
  };

  const formatBillingTypes2 = () => {
    if (isLoading === true) return [];
    const s = [...selectedBillingTypes2];
    s.push(data.billingTypes[0].id);
    setSelectedBillingTypes2(s);
  };

  const handleChangeBillingType2 = (event, index) => {
    const arr = [...selectedBillingTypes2];
    arr[index] = event.target.value;
    setSelectedBillingTypes2(arr);
  };

  const handleSelectApartment2 = (selectedItem, index) => {
    const s = [...selectedApartments2];
    if (s.length <= index) {
      while (s.length <= index + 1) {
        s.push([]);
      }
    }
    const newArray = [...s[index]];
    const i = newArray.indexOf(selectedItem);
    if (i !== -1) {
      // item is already selected so remove item
      newArray.splice(i, 1);
    } else {
      newArray.push(selectedItem);
    }
    s[index] = newArray;
    setSelectedApartments2(s);
  };

  const handleUnselectApartment2 = (item, index) => {
    const s = [...selectedApartments2];
    const newArray = [...selectedApartments2[index]];
    const i = newArray.indexOf(item);
    newArray.splice(i, 1);
    s[index] = newArray;
    setSelectedApartments2(s);
  };

  const handleChangeIncludeApartments2 = (index) => {
    const arr = [...includeApartments2];
    arr[index] = !includeApartments2[index];
    setIncludeApartments2(arr);
  };

  const handleChangeName2 = (e, index) => {
    const arr = [...names2];
    arr[index] = e.target.value;
    setNames2(arr);
  };

  const handleChangeAmount2 = (e, index) => {
    const arr = [...amounts2];
    arr[index] = e.target.value;
    setAmounts2(arr);
  };

  const handleDeleteMonthlyItem2 = (index) => {
    const k = [...newMonthlyItems];
    k.splice(index, 1);
    setNewMonthlyItems(k);
    deleteItem(index);
  };

  const deleteItem = (ind) => {
    setNames2(names2.filter((item, index) => index !== ind));
    setIsListDisplayed2(
      isListDisplayed2.filter((item, index) => index !== ind)
    );
    setSelectedApartments2(
      selectedApartments2.filter((item, index) => index !== ind)
    );
    setIncludeApartments2(
      includeApartments2.filter((item, index) => index !== ind)
    );
    setAmounts2(amounts2.filter((item, index) => index !== ind));
    setSelectedBillingTypes2(
      selectedBillingTypes2.filter((item, index) => index !== ind)
    );
  };

  const handleChangeDisplayList2 = (value, index) => {
    const k = [...isListDisplayed2];
    k[index] = value;
    setIsListDisplayed2(k);
  };

  const handleCreateDebit = () => {
    dispatch(startLoading());
    const debit = {
      buildingId: parseInt(id),
      month: selectedDate.toDate().getMonth() + 1,
      year: selectedDate.toDate().getFullYear(),
      management: managamentCost,
      fund: fundCost,
      investment: investmentCost,
      buildingMonthlyItems: [],
    };
    for (let i = 0; i < names2.length; i++) {
      debit.buildingMonthlyItems.push({
        id: -1,
        name: names2[i],
        buildingId: parseInt(id),
        billingTypeId: selectedBillingTypes2[i],
        amount: parseFloat(amounts2[i]),
        numOfApartments:
          selectedApartments2[i] !== undefined
            ? selectedApartments2[i].filter((k) => k !== "")
            : [],
        includeApartments: includeApartments2[i],
      });
    }

    for (let i = 0; i < data.monthlyItems.length; i++) {
      debit.buildingMonthlyItems.push({
        id: data.monthlyItems[i].id,
        name: names[i],
        buildingId: parseInt(id),
        billingTypeId: selectedBillingTypes[i],
        amount: parseFloat(amounts[i]),
        numOfApartments:
          selectedApartments[i] !== undefined
            ? selectedApartments[i].filter((k) => k !== "")
            : [],
        includeApartments: includeApartments[i],
      });
    }
    createDebit(debit)
      .unwrap()
      .then(() => {
        makeToastMessage("Uspešno ste kreirali zaduženje");
        navigate(`/buildings/${id}`);
        dispatch(stopLoading());
      })
      .catch(() => {
        makeErrorToastMessage("Niste uspešno kreirali zaduženje")
        dispatch(stopLoading());
      });
  };

  const handleDownloadBuildingBills = () => {
    getBuildingBills({
      buildingId: id,
      month: selectedDate.toDate().getMonth() + 1,
      year: selectedDate.toDate().getFullYear(),
    });
  };

  const handleCloseAllDropdowns = () => {
    const listOne = [...isListDisplayed];
    for (let i = 0; i < listOne.length; i++) {
      listOne[i] = false;
    }
    setIsListDisplayed(listOne);

    const listTwo = [...isListDisplayed2];
    for (let i = 0; i < listTwo.length; i++) {
      listTwo[i] = false;
    }
    setIsListDisplayed2(listTwo);
  };

  return (isLoading) ? (
    <p>Loading</p>
  ) : (
    <AddDebitPageContainer onClick={handleCloseAllDropdowns}>
      <Navbar />
      <AddDebitPageSubContainer>
        {matches && (
          <BackLink
            style={{ marginTop: 32, marginLeft: 16 }}
            to={`/buildings/${id}`}
          >
            {t("forgotPassword.backLink")}
          </BackLink>
        )}
        {!matches && (
          <>
            <NavigationContainer>
              <BuildingsIcon
                onClick={() => navigate("/buildings")}
                style={{ cursor: "pointer" }}
              />
              <RightArrowNavigationIcon style={{ marginLeft: "20px" }} />
              <NavigationPreviousText
                onClick={() => navigate(`/buildings/${id}`)}
                style={{ cursor: "pointer" }}
              >
                Zgrada #{id}
              </NavigationPreviousText>
              <RightArrowNavigationIcon style={{ marginLeft: "20px" }} />
              <NavigationText>Novo zaduženje</NavigationText>
            </NavigationContainer>
          </>
        )}
        <TopContainer>
          <Title>Novo Zaduženje</Title>
          <ButtonsContainer>
            <PrimaryButton
              style={{ marginLeft: "12px" }}
              onClick={() => navigate(`/buildings/${id}`)}
            >
              Odustani
            </PrimaryButton>
          </ButtonsContainer>
        </TopContainer>
        {!matches && <Line />}
        <ItemsTitle>Stavke</ItemsTitle>

        <BuildingMonthlyItemsContainer>
          {data.monthlyItems.map((monthlyItem, index) => (
            <BuildingMonthlyItem
              amounts={amounts}
              handleChangeAmount={handleChangeAmount}
              handleChangeBillingType={handleChangeBillingType}
              handleChangeDisplayList={handleChangeDisplayList}
              handleChangeName={handleChangeName}
              handleDeleteMonthlyItem={handleDeleteMonthlyItem}
              handleSelectApartment={handleSelectApartment}
              handleUnselectApartment={handleUnselectApartment}
              index={index}
              isListDisplayed={isListDisplayed}
              key={index}
              monthlyItem={monthlyItem}
              names={names}
              selectedApartments={selectedApartments}
              selectedBillingTypes={selectedBillingTypes}
              billingTypes={data.billingTypes}
              handleChangeIncludeApartments={handleChangeIncludeApartments}
              includeApartments={includeApartments}
              apartments={[...data.apartmentNumbers].sort(
                (a, b) => parseInt(a, 10) - parseInt(b, 10)
              )}
              formatBillingTypes={formatBillingTypes2}
            />
          ))}
          {newMonthlyItems.map((monthlyItem, index) => (
            <BuildingMonthlyItem
              amounts={amounts2}
              handleChangeAmount={handleChangeAmount2}
              handleChangeBillingType={handleChangeBillingType2}
              handleChangeDisplayList={handleChangeDisplayList2}
              handleChangeName={handleChangeName2}
              handleDeleteMonthlyItem={handleDeleteMonthlyItem2}
              handleSelectApartment={handleSelectApartment2}
              handleUnselectApartment={handleUnselectApartment2}
              index={index}
              isListDisplayed={isListDisplayed2}
              key={index}
              monthlyItem={monthlyItem}
              names={names2}
              selectedApartments={selectedApartments2}
              selectedBillingTypes={selectedBillingTypes2}
              billingTypes={data.billingTypes}
              handleChangeIncludeApartments={handleChangeIncludeApartments2}
              includeApartments={includeApartments2}
              apartments={[...data.apartmentNumbers].sort(
                (a, b) => parseInt(a, 10) - parseInt(b, 10)
              )}
              isNewItem={true}
              numberOfOldItems={data.monthlyItems.length}
              formatBillingTypes={formatBillingTypes2}
            />
          ))}
        </BuildingMonthlyItemsContainer>

        <AddItemButtonContainer>
          <EmptyContainer />
          <EmptyContainer />
          <PrimaryButton onClick={addNewMonthlyItem}>
            <AddDebitIcon />
            Nova Stavka
          </PrimaryButton>
        </AddItemButtonContainer>
        {matches && <Line style={{ marginTop: "24px" }} />}
        <MiddleContainer>
          <InputContainer style={{ width: matches ? "100%" : "33%" }}>
            <InputLabel>Upravljanje</InputLabel>
            <Input
              value={managamentCost}
              onChange={(e) => setManagamentCost(e.target.value)}
              type="number"
            />
          </InputContainer>
          <InputContainer
            style={{
              width: matches ? "100%" : "33%",
              marginLeft: matches ? "0px" : "12px",
              marginTop: matches && "16px",
            }}
          >
            <InputLabel>Tekuće održavanje</InputLabel>
            <Input
              value={fundCost}
              onChange={(e) => setFundCost(e.target.value)}
              type="number"
            />
          </InputContainer>
          <InputContainer
            style={{
              width: matches ? "100%" : "33%",
              marginLeft: matches ? "0px" : "12px",
              marginTop: matches && "16px",
            }}
          >
            <InputLabel>Investiciono održavanje</InputLabel>
            <Input
              value={investmentCost}
              onChange={(e) => setInvestmentCost(e.target.value)}
              type="number"
            />
          </InputContainer>
        </MiddleContainer>
        {!matches && <Line style={{ marginTop: "24px" }} />}
        <BottomContainer>
          {matches && (
            <InputLabel style={{ width: "100%", padding: "0 16px" }}>
              Izaberi mesec
            </InputLabel>
          )}
          <DatePicker
            label=""
            value={selectedDate}
            views={["month", "year"]}
            onChange={(newValue) => setSelectedDate(newValue)}
            sx={{
              width: matches ? "100%" : "167px",
              backgroundColor: selectedTheme.colors.base.white,
              borderRadius: "8px",
              marginBottom: matches && "32px",
              padding: matches && "0 16px",
            }}
          />
          {matches && <Line style={{ marginTop: 0, marginBottom: "24px" }} />}
          <ButtonsContainer
            style={{ width: matches && "100%", padding: matches && "0 16px" }}
          >
            <PrimaryButton
              style={{ flex: matches && 1 }}
              onClick={handleDownloadBuildingBills}
            >
              <DownloadIcon />
              Preuzmi račune
            </PrimaryButton>
            <SecondaryButton
              style={{
                marginLeft: "12px",
                flex: matches && 1,
              }}
              onClick={handleCreateDebit}
            >
              Zaduži
            </SecondaryButton>
          </ButtonsContainer>
        </BottomContainer>
      </AddDebitPageSubContainer>
    </AddDebitPageContainer>
  );
};

export default AddDebitPage;
