import { Box } from "@mui/material";
import styled from "styled-components";
//import selectedTheme from "../../themes";

export const ApartmentsOverviewContainer = styled(Box)`
  margin-top: 60px;
  @media (max-width: 768px) {
    margin-top: 24px;
  }
`;

export const ApartmentsOverviewSubContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 0 16px;
  }
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  @media (max-width: 768px) {
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 16px;
  }
  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

export const FilterContainer = styled(Box)`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: row-reverse;
    margin-left: 16px;
    margin-right: 16px;
    width: 100%;
  }
`;
