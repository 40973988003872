import React from 'react'
//import PropTypes from 'prop-types'
import { LoadingScreenContainer, LoadingScreenSubcontainer } from './LoadingScreen.styled';
import LoadingModal from './LoadingModal/LoadingModal';

const LoadingScreen = () => {
  return (
    <LoadingScreenContainer>
        <LoadingScreenSubcontainer>
            <LoadingModal/>
        </LoadingScreenSubcontainer>
    </LoadingScreenContainer>
  )
}

LoadingScreen.propTypes = 
{

}

export default LoadingScreen