import React from "react";
import { PropTypes } from "prop-types";
import {
  BackLinkContainer,
  BackLinkIconContainer,
  BackLinkText,
} from "./BackLink.styled";
import { ReactComponent as BackArrow } from "../../../assets/Icons/arrow-left.svg";
import { useNavigate } from 'react-router-dom';




const BackLink = (props) => {
  const navigate = useNavigate();

  const handleBackButton = (link) => {
    navigate(link);
  };
  return (
    <BackLinkContainer style={props.style} onClick={(props?.to && props.to != "") ? ()=>handleBackButton(props?.to) : props?.onClick}>
      <BackLinkIconContainer>
        <BackArrow />
      </BackLinkIconContainer>
      <BackLinkText>{props?.children}</BackLinkText>
    </BackLinkContainer>
  );
};

BackLink.propTypes = {
  to: PropTypes.string,
  onClick:PropTypes.function,
  children:PropTypes.node,
  style: PropTypes.object,
};

export default BackLink;
