import React from "react";
import { ReactComponent as EditIconSvg } from "../../../assets/Icons/edit.svg";

const EditIcon = (props) => {
  return <EditIconSvg {...props} />;
};

EditIcon.propTypes = {};

export default EditIcon;
