import React from "react";
import { ReactComponent as DownloadIconSvg } from "../../../assets/Icons/download.svg";

const DownloadIcon = (props) => {
  return <DownloadIconSvg {...props} />;
};

DownloadIcon.propTypes = {};

export default DownloadIcon;
