import * as Yup from "yup";
import { addBuildingInitialConstants } from "initialValues/addBuildingInitialValues";

export default Yup.object().shape({
  [addBuildingInitialConstants.address]: Yup.string().required(
    "Adresa zgrade je obavezno polje!"
  ),
  [addBuildingInitialConstants.currentAccount]: Yup.string().required(
    "Tekući racun je obavezno polje!"
  ),
  [addBuildingInitialConstants.pib]: Yup.string()
    .required("Unesite PIB zgrade")
    .length(9, "Dužina PIB-a mora biti tačno 9 cifara"),
  [addBuildingInitialConstants.identificationNumber]: Yup.string()
    .required("Unesite matični broj zgrade")
    .length(8, "Dužina matičnog broja mora biti tačno 8 cifara"),
  [addBuildingInitialConstants.costOfManagement]: Yup.string().required(
    "Cena upravljanja je obavezno polje!"
  ),
  [addBuildingInitialConstants.currentFund]: Yup.string().required(
    "Tekući fond je obavezno polje!"
  ),
  [addBuildingInitialConstants.investment]: Yup.string().required(
    "Investicioni fond je obavezno polje!"
  ),
});
