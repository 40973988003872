import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const CustomLine = styled(Box)`
  width: 100%;
  height: 1px;
  background-color: ${selectedTheme.colors.gray[200]};
  border: 1px solid ${selectedTheme.colors.gray[200]};
  margin-top: 20px;
`;
