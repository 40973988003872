import { apiSlice } from "store/apiSlice";

export const debitApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["DebitDetails"],
  endpoints: (builder) => ({
    debitDetails: builder.query({
      query: (buildingId) => ({
        url: `/debit/${buildingId}`,
      }),
      providesTags: ["DebitDetails"],
    }),
    createDebit: builder.mutation({
      query: (data) => ({
        url: "/debit",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["BuildingDetails", "DebitDetails"],
    }),
  }),
});

export const { useDebitDetailsQuery, useCreateDebitMutation } = debitApiSlice;
