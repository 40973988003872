import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const BuildingsOverviewContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid ${selectedTheme.colors.gray[200]};
  box-shadow: ${selectedTheme.effects.shadow.sm.boxShadow};
  flex: 1;
  height:100%;
   background-color: ${selectedTheme.colors.base.white};
`;
