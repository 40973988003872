export const forgotPasswordFormikConstants = {
  email: "email",
  newPassword:"newPassword",
  confirmPassword:"confirmPassword",

}
  export default {
    [forgotPasswordFormikConstants.email]: "",
    [forgotPasswordFormikConstants.newPassword]: "",
    [forgotPasswordFormikConstants.confirmPassword]: "",
  }
  