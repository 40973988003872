import { Box } from "@mui/system";
import styled from "styled-components";

export const ApartmentPageContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height:100vh;
  height: 100%;
  @media (max-width: 768px) {
    padding: 0;
    flex-direction: column;
    padding-top: 48px;
  }
`;
export const PageContainer = styled(Box)`
  display: flex;
  gap: 24px;
  width:100%;
  padding:32px;
  @media (max-width: 768px) {
    padding: 0;
    margin: 0;
  }
`;

export const HeaderContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 768px) {
    padding: 0px 16px;
    flex-direction: column;
    align-items: flex-start;
  }
`;


export const MainSection = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;
