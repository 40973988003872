import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import {registerFormikConstants} from "../../../../initialValues/registerInitialValues";
import TextInputField from "components/shared/InputFields/TextField/TextField";

const RegisterEmailField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;

  return (
      <TextInputField
        id={registerFormikConstants.email}
        name={registerFormikConstants.email}
        placeholder={t("register.emailPlaceholder")}
        label={t("register.emailLabel")}
        value={formik.values.email}
        onChange={formik.handleChange}
        type="email"
        onBlur={formik.handleBlur}
        error={formik.touched?.[registerFormikConstants.email] && formik.errors?.[registerFormikConstants.email]}
        helperText={i18n.register && i18n.register?.emailHintText && i18n.register?.emailHintText !== "" && i18n.t("register.emailHintText")}
        apiError={props.apiError}
      />
  );
};

RegisterEmailField.propTypes = {
  formik: PropTypes.object,
  apiError:PropTypes.any,
};

export default RegisterEmailField;
