import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const UploadContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${selectedTheme.colors.gray[200]};
  border-radius: 12px;
  background-color: ${selectedTheme.colors.base.white};
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const UploadMainText = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.regular.fontFamily};
  font-size: ${selectedTheme.type.textSm.regular.fontSize};
  font-style: ${selectedTheme.type.textSm.regular.fontStyle};
  font-weight: ${selectedTheme.type.textSm.regular.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
`;

export const UploadText = styled("span")`
  font-family: ${selectedTheme.type.textSm.semibold.fontFamily};
  font-size: ${selectedTheme.type.textSm.semibold.fontSize};
  font-style: ${selectedTheme.type.textSm.semibold.fontStyle};
  font-weight: ${selectedTheme.type.textSm.semibold.fontWeight};
  color: ${selectedTheme.colors.primary[700]};
  cursor: pointer;
  &:hover {
    color: ${selectedTheme.colors.primary[800]};
  }
`;

export const UploadHelperText = styled(Typography)`
  font-family: ${selectedTheme.type.textXs.regular.fontFamily};
  font-size: ${selectedTheme.type.textXs.regular.fontSize};
  font-style: ${selectedTheme.type.textXs.regular.fontStyle};
  font-weight: ${selectedTheme.type.textXs.regular.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
`;

export const ErrorText = styled(Typography)`
  font-family: ${selectedTheme.type.textXs.regular.fontFamily};
  font-size: 16px;
  font-style: ${selectedTheme.type.textXs.regular.fontStyle};
  font-weight: ${selectedTheme.type.textXs.regular.fontWeight};
  color: ${selectedTheme.colors.error[600]};
`;

export const Form = styled("form")``;

export const Input = styled("input")`
  display: none;
`;
