import React from "react";
import PropTypes from "prop-types";
import TextInputField from "components/shared/InputFields/TextField/TextField";
import { addApartmentInitialConstants } from "initialValues/addApartmentInitialValues";

const EmailField = (props) => {
  const formik = props?.formik;
  
  return (
    <TextInputField
      id={addApartmentInitialConstants.email}
      name={addApartmentInitialConstants.email}
      placeholder="Email"
      label="Email vlasnika"
      value={formik.values.email}
      onChange={formik.handleChange}
      type="email"
      onBlur={formik.handleBlur}
      error={
        formik.touched?.[addApartmentInitialConstants.email] &&
        formik.errors?.[addApartmentInitialConstants.email]
      }
      apiError={props.apiError}
      style={props?.style}
    />
  );
};

EmailField.propTypes = {
  formik: PropTypes.object,
  apiError: PropTypes.any,
  style: PropTypes.object,
};

export default EmailField;
