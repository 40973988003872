import { Box } from "@mui/material";
import styled from "styled-components";

export const AllFinancesContainer = styled(Box)``;

export const CardsContainer = styled(Box)`
  display: flex;
  margin-top: 40px;
  @media (max-width: 768px) {
    padding: 0 16px;
    flex-direction: column;
    margin-top: 32px;
  }
`;

export const AllFinancesSubcontainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 0 16px;
  }
`;

export const FilterContainer = styled(Box)`
  display: flex;
  margin-top: 32px;
  @media (max-width: 768px) {
    flex-direction: row-reverse;
    margin-left: 16px;
    margin-right: 16px;
  }
`;
