import React from "react";
import TotalBuildingsComponent from "components/TotalBuildingsComponent/TotalBuildingsComponent";
import HeaderComponent from "components/shared/Header/HomePage/HeaderComponent";
import ProfitComponent from "components/ProfitComponent/ProfitComponent";
import BuildingsOverviewComponent from "components/BuildingsOverviewComponent/BuildingsOverviewComponent";
import BiggestDebtorsOverviewComponent from "components/BiggestDebtorsOverviewComponent/BiggestDebtorsOverviewComponent";
import Navbar from "components/shared/Navbar/Navbar";
import {
  BottomContainer,
  ContentContainer,
  HomePageContainer,
  HomePageSubContainer,
  TopContainer,
} from "./HomePage.styled";

const HomePage = () => {
  return (
    <HomePageContainer>
      <HomePageSubContainer>
        <Navbar />
        <ContentContainer>
          <HeaderComponent />
          <TopContainer>
            <TotalBuildingsComponent />
            <ProfitComponent />
          </TopContainer>
          <BottomContainer>
            <BuildingsOverviewComponent />
            <BiggestDebtorsOverviewComponent />
          </BottomContainer>
        </ContentContainer>
      </HomePageSubContainer>
    </HomePageContainer>
  );
};

export default HomePage;
