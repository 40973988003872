import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "themes";

export const FinanceOverviewContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 34px;
`;

export const EncapsulatingContainer = styled(Box)`
    display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 768px) {
    padding: 0 16px;
  }
`

export const FilterContainer = styled(Box)`
    display: flex;
    align-items: center;
    margin-top: 32px;
    gap: 12px;
`;

export const FinanceOverviewTitle = styled(Typography)`
font-family: ${selectedTheme.type.textMd.semibold.fontFamily};
font-size: ${selectedTheme.type.textMd.semibold.fontSize};
font-style: ${selectedTheme.type.textMd.semibold.fontStyle};
font-weight: ${selectedTheme.type.textMd.semibold.fontWeight};
color: ${selectedTheme.colors.gray[600]};
`;

export const FinanceOverviewPrice = styled(Typography)`
font-family: ${selectedTheme.type.displayMd.semibold.fontFamily};
font-size: ${selectedTheme.type.displayMd.semibold.fontSize};
font-style: ${selectedTheme.type.displayMd.semibold.fontStyle};
font-weight: ${selectedTheme.type.displayMd.semibold.fontWeight};
color: ${selectedTheme.colors.gray[900]};
`;
