import * as React from "react";
import PropTypes from "prop-types";
import {
  MainTable,
  MainTableCell,
  MainTableHead,
  MainTableRow,
  PrimaryTableContainer,
  MainTableBody,
  Pagination,
  PaginationButtonsContainer,
} from "./UsersTable.styled";
import { WidgetFooter } from "components/shared/Widgets/Widgets.styled";
import { PrimaryButton } from "components/shared/Buttons/Buttons.styled";
import selectedTheme from "../../themes";
import { TableRow } from "./TableRow";
import { useMediaQuery } from "@mui/material";
import { ReactComponent as BackArrow } from "../../assets/Icons/arrow-left.svg";
import { ReactComponent as NextArrow } from "../../assets/Icons/arrow-right.svg";

export default function UsersTable({
  style,
  handleLockUser,
  handleDeleteUser,
  rows,
  searchValue,
  handleOpenUserDialog,
}) {
  const matches = useMediaQuery("(max-width:768px)");
  const [open, setOpen] = React.useState("0");
  const [currentPage, setCurrentPage] = React.useState(0);
  const pageSize = 8;
  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage + 1 < Math.ceil(rows.length / pageSize)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;
  const currentPageData = rows.slice(startIndex, endIndex);

  return (
    <PrimaryTableContainer style={style}>
      <MainTable aria-label="collapsible table">
        <MainTableHead>
          <MainTableRow>
            <MainTableCell>Korisnik</MainTableCell>
            <MainTableCell>Uloga</MainTableCell>
            <MainTableCell>Potvrđen</MainTableCell>
            <MainTableCell />
          </MainTableRow>
        </MainTableHead>
        <MainTableBody>
          {currentPageData.map((row, index) => (
            <TableRow
              key={index}
              row={row}
              open={open}
              setOpen={setOpen}
              handleDeleteUser={handleDeleteUser}
              handleLockUser={handleLockUser}
              searchValue={searchValue}
              handleOpenUserDialog={handleOpenUserDialog}
            />
          ))}
        </MainTableBody>
      </MainTable>
      <WidgetFooter
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          fontFamily: selectedTheme.type.textSm.regular.fontFamily,
          fontSize: selectedTheme.type.textSm.medium.fontSize,
          fontStyle: selectedTheme.type.textSm.medium.fontStyle,
          fontWeight: selectedTheme.type.textSm.medium.fontWeight,
          borderTop: "none",
        }}
      >
        {!matches && (
          <>
            <Pagination>
              Strana {currentPage + 1} od{" "}
              {rows.length === 0 ? 1 : Math.ceil(rows.length / pageSize)}
            </Pagination>
            <PaginationButtonsContainer>
              <PrimaryButton
                onClick={handlePreviousPage}
                style={{ marginRight: 28 }}
              >
                Prethodna
              </PrimaryButton>
              <PrimaryButton onClick={handleNextPage}>Sledeća</PrimaryButton>
            </PaginationButtonsContainer>
          </>
        )}
        {matches && (
          <>
            <PrimaryButton
              style={{ padding: "8px" }}
              onClick={handlePreviousPage}
            >
              <BackArrow />
            </PrimaryButton>
            <Pagination>
              Strana {currentPage + 1} od{" "}
              {rows.length === 0 ? 1 : Math.ceil(rows.length / pageSize)}
            </Pagination>
            <PrimaryButton style={{ padding: "8px" }} onClick={handleNextPage}>
              <NextArrow />
            </PrimaryButton>
          </>
        )}
      </WidgetFooter>
    </PrimaryTableContainer>
  );
}

UsersTable.propTypes = {
  style: PropTypes.object,
  handleLockUser: PropTypes.func,
  handleDeleteUser: PropTypes.func,
  rows: PropTypes.arrayOf(PropTypes.object),
  searchValue: PropTypes.string,
  handleOpenUserDialog: PropTypes.func,
};
