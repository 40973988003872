import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as ImportIconSvg } from "../../../assets/Icons/import.svg";

const ImportIcon = (props) => {
  return <ImportIconSvg {...props} />;
};

ImportIcon.propTypes = {};

export default ImportIcon;
