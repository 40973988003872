import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const BuildingDetailsPageContainer = styled(Box)`
  display: flex;
  min-height:100vh;
  height: 100%;
   background-color: ${selectedTheme.colors.base.white};
   @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 48px;
  }
`;
export const HeaderContainer = styled(Box)`
  padding: 32px;
  width: 100%;
  @media (max-width: 768px) {
    padding: 0;
    margin-top: 32px;
  }
`;

export const TopHeaderContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    margin: 0 16px;
  }
`;

export const TopHeaderTextContainer = styled(Box)`
    display:flex;
    flex-direction:column;
`

export const TopHeaderTitle = styled(Typography)`
  font-family: ${selectedTheme.type.displaySm.semibold.fontFamily};
  font-size: ${selectedTheme.type.displaySm.semibold.fontSize};
  font-style: ${selectedTheme.type.displaySm.semibold.fontStyle};
  font-weight: ${selectedTheme.type.displaySm.semibold.fontWeight};
  color: ${selectedTheme.colors.gray[900]};
`;

export const TopHeaderSubTitle = styled(Typography)`
  font-family: ${selectedTheme.type.displayMd.regular.fontFamily};
  font-size: ${selectedTheme.type.displayMd.regular.fontSize};
  font-style: ${selectedTheme.type.displayMd.regular.fontStyle};
  font-weight: ${selectedTheme.type.displayMd.regular.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
  font-size:16px;
`;

export const TopHeaderButtonsContainer = styled(Box)`
  display: flex;
`;

export const TopHeaderContentContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  padding-right: 140px;
  @media (max-width: 768px) {
    padding-right: 0px;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 16px;
  }
`;

export const ContentItemContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const ContentHeader = styled(Box)`
  font-family: ${selectedTheme.type.displaySm.medium.fontFamily};
  font-size: ${selectedTheme.type.displaySm.medium.fontSize};
  font-style: ${selectedTheme.type.displaySm.medium.fontStyle};
  font-weight: ${selectedTheme.type.displaySm.medium.fontWeight};
  color: ${selectedTheme.colors.gray[500]};
  font-size: 14px;
`;

export const ContentSubHeader = styled(Box)`
  font-family: ${selectedTheme.type.displayMd.medium.fontFamily};
  font-size: ${selectedTheme.type.displayMd.medium.fontSize};
  font-style: ${selectedTheme.type.displayMd.medium.fontStyle};
  font-weight: ${selectedTheme.type.displayMd.medium.fontWeight};
  color: ${selectedTheme.colors.gray[500]};
  font-size: 16px;
  margin-top: 8px;
`;

export const AccountNumberContainer = styled(Box)`
  display: flex;
  margin-top: 8px;
  align-items: center;
`;

export const AccountNumberTitle = styled(Typography)`
  font-family: ${selectedTheme.type.displayMd.semibold.fontFamily};
  font-size: ${selectedTheme.type.displayMd.semibold.fontSize};
  font-style: ${selectedTheme.type.displayMd.semibold.fontStyle};
  font-weight: ${selectedTheme.type.displayMd.semibold.fontWeight};
  color: ${selectedTheme.colors.primary[700]};
  font-size: 16px;
`;
