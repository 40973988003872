import { apiSlice } from "store/apiSlice";

export const settingsApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["AgencyDetails"],
  endpoints: (builder) => ({
    agencyDetails: builder.query({
      query: () => ({
        url: "/settings",
      }),
      providesTags: ["AgencyDetails"],
    }),
    updateAgencySettings: builder.mutation({
      query: ({ formData }) => ({
        url: "/settings",
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["AgencyDetails"],
    }),
    deleteUser: builder.mutation({
      query: ({ id }) => ({
        url: `/users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["AgencyDetails"],
    }),
    lockUser: builder.mutation({
      query: (data) => ({
        url: "/users/lockUser",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["AgencyDetails"],
    }),
    createUser: builder.mutation({
      query: (data) => ({
        url: "/users/addNewUser",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["AgencyDetails"],
    }),
    updateUserData: builder.query({
      query: (userId) => ({
        url: `/users/update-data/${userId}`,
      }),
    }),
    updateUser: builder.mutation({
      query: ({ formData, id }) => ({
        url: `/users/${id}`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["AgencyDetails"],
    }),
  }),
});

export const {
  useAgencyDetailsQuery,
  useUpdateAgencySettingsMutation,
  useDeleteUserMutation,
  useLockUserMutation,
  useCreateUserMutation,
  useUpdateUserDataQuery,
  useUpdateUserMutation,
} = settingsApiSlice;
