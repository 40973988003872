import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const BuildingBillsOverviewComponentContainer = styled(Box)`
  padding-top: 60px;
  background-color: ${(selectedTheme.colors.base.white)};
  @media (max-width: 768px) {
    padding-top: 24px;
  }
`;

export const TopContainer = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 0 16px;
  }
  
`;

export const FilterContainer = styled(Box)`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: row-reverse;
    margin-right: 16px;
    width: 100%;
  }
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;
