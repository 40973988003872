import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as RefreshIconSvg } from "../../../assets/Icons/refresh-cw-05.svg";

const RefreshIcon = (props) => {
  return <RefreshIconSvg {...props} />;
};

RefreshIcon.propTypes = {};

export default RefreshIcon;
