import ThreeDotsIcon from "components/Icon/Icons/ThreeDotsIcon";
import React from "react";
import {
  Card,
  TopContainer,
  Text,
  PercentageContainer,
  PercentageText,
  ContentContainer,
  ContentText,
} from "./TotalBuildingsCard.styled";
import ArrowUpIcon from "components/Icon/Icons/ArrowUp";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";

const TotalTenantsCard = ({ numberOfTenants }) => {
  const matches = useMediaQuery("(max-width:768px)");
  return (
    <Card
      style={{
        marginLeft: matches ? "0px" : "24px",
        marginTop: matches && "24px",
      }}
    >
      <TopContainer>
        <Text>Ukupno Ukućana</Text>
        <ThreeDotsIcon />
      </TopContainer>
      <ContentContainer>
        <ContentText>{numberOfTenants}</ContentText>
        <PercentageContainer>
          <ArrowUpIcon />
          <PercentageText>15%</PercentageText>
        </PercentageContainer>
      </ContentContainer>
    </Card>
  );
};

TotalTenantsCard.propTypes = {
  numberOfTenants: PropTypes.number,
};

export default TotalTenantsCard;
