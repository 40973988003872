import { Box } from "@mui/system";
import styled from "styled-components";

export const RegisterContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  background: white;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`;