import ArrowDownIcon from "components/Icon/Icons/ArrowDownIcon";
import BinIcon from "components/Icon/Icons/BinIcon";
import BinRedIcon from "components/Icon/Icons/BinRedIcon";
import ChipCloseIcon from "components/Icon/Icons/ChipCloseIcon";
import React, { useEffect } from "react";
import {
  MainContainer,
  MainSubContainer,
  TopMainContainer,
  NumberOfItem,
  InputContainer,
  InputLabel,
  Input,
  SelectInput,
  SelectItem,
  BottomMainContainer,
  MultipleSelectInput,
  SelectItemsListContainer,
  SelectedItemsContainer,
  SelectedItemContainer,
  ExcludeApartmentsContainer,
  ExcludeApartmentsTextContainer,
  ExludeApartmentsTitle,
  ExcludeApartmentsSubTitle,
  InputSubLabel,
  SelectItemsListSubContainer,
  ListItem,
  MultipleSelectContainer,
} from "./BuildingMonthlyItem.styled";
import PropTypes from "prop-types";
import selectedTheme from "../../themes";
import { SwitchButton } from "components/SwitchButton/SwitchButton";
import ArrowUpIcon2 from "components/Icon/Icons/ArrowUpIcon2";
import CheckIconBlue from "components/Icon/Icons/CheckIconBlue";
import { useMediaQuery } from "@mui/material";

const BuildingMonthlyItem = ({
  index,
  names,
  handleChangeName,
  selectedBillingTypes,
  handleChangeBillingType,
  amounts,
  handleChangeAmount,
  handleDeleteMonthlyItem,
  selectedApartments,
  handleUnselectApartment,
  handleChangeDisplayList,
  isListDisplayed,
  handleSelectApartment,
  monthlyItem,
  billingTypes,
  handleChangeIncludeApartments,
  includeApartments,
  apartments,
  isNewItem = false,
  numberOfOldItems,
  formatBillingTypes,
}) => {
  const matches = useMediaQuery("(max-width:768px)");
  useEffect(() => {
    formatBillingTypes();
  }, []);

  const isApartmentChecked = (item, index) => {
    const t = [...selectedApartments];
    if (t[index] === undefined) return false;
    return t[index].includes(item.toString()) === true;
  };

  return (
    <MainContainer>
      <MainSubContainer>
        <TopMainContainer>
          <NumberOfItem>
            {isNewItem ? numberOfOldItems + (index + 1) : index + 1}.
          </NumberOfItem>
          <InputContainer
            style={{
              width: matches ? "100%" : "53%",
              marginTop: matches && "12px",
            }}
          >
            <InputLabel>Ime stavke</InputLabel>
            <Input
              value={names[index]}
              onChange={(e) => handleChangeName(e, index)}
            />
          </InputContainer>
          <InputContainer
            style={{
              marginLeft: matches ? "0px" : "16px",
              width: matches ? "100%" : "22%",
              marginTop: matches && "16px",
            }}
          >
            <InputLabel>Tip Naplate</InputLabel>
            <SelectInput
              value={parseInt(selectedBillingTypes[index])}
              onChange={(e) => handleChangeBillingType(e, index)}
            >
              {billingTypes.map((billingType, index) => (
                <SelectItem value={billingType.id} key={index}>
                  {billingType.name}
                </SelectItem>
              ))}
            </SelectInput>
          </InputContainer>
          <InputContainer
            style={{
              marginLeft: matches ? "0px" : "16px",
              width: matches ? "100%" : "25%",
              marginTop: matches && "16px",
            }}
          >
            <InputLabel>Iznos</InputLabel>
            <Input
              type="number"
              value={amounts[index]}
              defaultValue={0}
              onChange={(e) => handleChangeAmount(e, index)}
            />
          </InputContainer>
          <BinRedIcon
            onClick={() =>
              handleDeleteMonthlyItem(isNewItem ? index : monthlyItem.id)
            }
            style={{
              marginLeft: matches ? "0px" : "55px",
              width: "5%",
              cursor: "pointer",
              position: matches && "absolute",
              right: matches && 30,
            }}
          />
        </TopMainContainer>
        <BottomMainContainer>
          <NumberOfItem style={{ width: "5%" }}></NumberOfItem>
          <InputContainer style={{ width: matches ? "100%" : "53%" }}>
            <InputLabel>
              Izaberi stanove <InputSubLabel>(prazno za sve)</InputSubLabel>
            </InputLabel>
            <MultipleSelectContainer>
              <MultipleSelectInput
                onClick={(e) => {
                  e.stopPropagation();
                  handleChangeDisplayList(!isListDisplayed[index], index);
                }}
              >
                <SelectedItemsContainer>
                  {selectedApartments[index] === undefined
                    ? null
                    : selectedApartments[index].map(
                        (selectedApartment, currentIndex) =>
                          selectedApartment !== "" && (
                            <SelectedItemContainer key={currentIndex}>
                              {selectedApartment}
                              <ChipCloseIcon
                                style={{
                                  marginLeft: "8px",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  handleUnselectApartment(
                                    selectedApartment,
                                    index
                                  );
                                  e.stopPropagation();
                                }}
                              />
                            </SelectedItemContainer>
                          )
                      )}
                </SelectedItemsContainer>
                {isListDisplayed[index] ? (
                  <ArrowUpIcon2
                    style={{ cursor: "pointer" }}
                    onClick={() => handleChangeDisplayList(false, index)}
                  />
                ) : (
                  <ArrowDownIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => handleChangeDisplayList(true, index)}
                  />
                )}
              </MultipleSelectInput>
              {isListDisplayed[index] ? (
                <SelectItemsListContainer>
                  <SelectItemsListSubContainer style={{ padding: "5px" }}>
                    {apartments.map((listItem, currentIndex) => (
                      <ListItem
                        key={currentIndex}
                        style={{
                          backgroundColor: isApartmentChecked(listItem, index)
                            ? selectedTheme.colors.gray[50]
                            : selectedTheme.colors.base.white,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSelectApartment(listItem, index);
                        }}
                      >
                        {listItem}
                        {isApartmentChecked(listItem, index) ? (
                          <CheckIconBlue />
                        ) : null}
                      </ListItem>
                    ))}
                  </SelectItemsListSubContainer>
                </SelectItemsListContainer>
              ) : null}
            </MultipleSelectContainer>
          </InputContainer>
          <ExcludeApartmentsContainer>
            <SwitchButton
              checked={includeApartments[index]}
              onChange={() => handleChangeIncludeApartments(index)}
            />
            <ExcludeApartmentsTextContainer>
              <ExludeApartmentsTitle>
                Izuzmi izabrane stanove
              </ExludeApartmentsTitle>
              <ExcludeApartmentsSubTitle>
                Sve sem izabranih stanova ce uci u kriterijum za naplatu
              </ExcludeApartmentsSubTitle>
            </ExcludeApartmentsTextContainer>
          </ExcludeApartmentsContainer>
          {!matches && (
            <BinIcon
              style={{
                marginLeft: "55px",
                width: "5%",
                visibility: "hidden",
              }}
            />
          )}
        </BottomMainContainer>
      </MainSubContainer>
    </MainContainer>
  );
};

BuildingMonthlyItem.propTypes = {
  index: PropTypes.number,
  names: PropTypes.arrayOf(PropTypes.string),
  handleChangeName: PropTypes.func,
  selectedBillingTypes: PropTypes.arrayOf(PropTypes.number),
  handleChangeBillingType: PropTypes.func,
  amounts: PropTypes.arrayOf(PropTypes.number),
  handleChangeAmount: PropTypes.func,
  handleDeleteMonthlyItem: PropTypes.func,
  selectedApartments: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  handleUnselectApartment: PropTypes.func,
  handleChangeDisplayList: PropTypes.func,
  isListDisplayed: PropTypes.arrayOf(PropTypes.bool),
  handleSelectApartment: PropTypes.func,
  monthlyItem: PropTypes.object,
  billingTypes: PropTypes.arrayOf(PropTypes.object),
  handleChangeIncludeApartments: PropTypes.func,
  includeApartments: PropTypes.arrayOf(PropTypes.bool),
  apartments: PropTypes.arrayOf(PropTypes.number),
  isNewItem: PropTypes.bool,
  numberOfOldItems: PropTypes.number,
  formatBillingTypes: PropTypes.func,
};

export default BuildingMonthlyItem;
