import React from "react";
import PropTypes from "prop-types";
import { settingsInitialConstants } from "initialValues/settingsInitialValues";
import TextInputField from "components/shared/InputFields/TextField/TextField";
import { charactersToExlude } from "constants/helpers";

const IdentificationNumberField = (props) => {
  const formik = props?.formik;

  const preventEnteringNonNumericCharacters = (event) => {
    // input will accept only numbers
    if (charactersToExlude.includes(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <TextInputField
      id={settingsInitialConstants.identificationNumber}
      name={settingsInitialConstants.identificationNumber}
      placeholder="Unesite Matični broj vaše firme"
      label="Matični broj"
      type="number"
      value={formik.values.identificationNumber}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
        error={formik.touched?.[settingsInitialConstants.identificationNumber] && formik.errors?.[settingsInitialConstants.identificationNumber]}
      apiError={props.apiError}
      style={props?.style}
      onKeyDown={preventEnteringNonNumericCharacters}
    />
  );
};

IdentificationNumberField.propTypes = {
  formik: PropTypes.object,
  apiError: PropTypes.any,
  style: PropTypes.object,
};

export default IdentificationNumberField;
