import React from "react";
import { CustomLine } from "./Line.styled";
import PropTypes from "prop-types";

const Line = ({ style }) => {
  return <CustomLine style={style}></CustomLine>;
};

Line.propTypes = {
  style: PropTypes.object,
};

export default Line;
