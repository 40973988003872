import { apiSlice } from "store/apiSlice";

export const buildingsApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Buildings", "BuildingDetails"],
  endpoints: (builder) => ({
    buildingsInformations: builder.query({
      query: () => ({
        url: "/buildings",
      }),
      providesTags: ["Buildings"],
    }),
    deleteBuilding: builder.mutation({
      query: (id) => ({
        url: `/buildings?id=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Buildings", "BuildingsHome"],
    }),
    buildingDetailsPageInformations: builder.query({
      query: (id) => ({
        url: `/buildings/${id}`,
        method: "GET",
      }),
      providesTags: ["BuildingDetails"],
    }),
    importTenants: builder.mutation({
      query: (formData) => ({
        url: "/imports",
        method: "POST",
        body: formData,
        prepareHeaders: (headers) => {
          headers.set("Content-Type", "multipart/form-data");
          return headers;
        },
      }),
      invalidatesTags: ["BuildingDetails"],
    }),
    createBuilding: builder.mutation({
      query: (formData) => ({
        url: "/buildings",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Buildings", "BuildingsHome"],
    }),
    buildingUpdateData: builder.query({
      query: (id) => ({
        url: `/buildings/update-data/${id}`,
        method: "GET",
      }),
      providesTags: ["BuildingUpdateData"],
    }),
    updateBuilding: builder.mutation({
      query: ({ formData, id }) => ({
        url: `/buildings/${id}`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["Buildings", "BuildingUpdateData"],
    }),
    apartmentIds: builder.query({
      query: (id) => ({
        url: `/buildings/apartmentIds?buildingId=${id}`,
      }),
    }),
  }),
});

export const {
  useBuildingsInformationsQuery,
  useDeleteBuildingMutation,
  useBuildingDetailsPageInformationsQuery,
  useImportTenantsMutation,
  useCreateBuildingMutation,
  useBuildingUpdateDataQuery,
  useUpdateBuildingMutation,
  useApartmentIdsQuery,
} = buildingsApiSlice;
