import { apiSlice } from "store/apiSlice";
import { makeErrorToastMessage } from "../util/toastMessage";

export const exportApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    allBills: builder.mutation({
      query: ({ month, year }) => ({
        url: `/export?month=${month}&year=${year}`,
        method: "POST",
        responseHandler: async (response) => {
          const blob = await response.blob();
          if (blob.size === 1) {
            makeErrorToastMessage("Ne postoje racuni za tekuci mesec");
            return;
          }
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download =
            "Racuni zgrade" +
            " " +
            month.toString() +
            "." +
            year.toString() +
            ".pdf";
          link.click();
          window.URL.revokeObjectURL(url);
        },
        cache: "no-cache",
      }),
    }),
    fileForImportingTenants: builder.mutation({
      query: () => ({
        url: `/export/tenantsImportFile`,
        method: "POST",
        responseHandler: async (response) => {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "ImportStanara.xlsx";
          link.click();
          window.URL.revokeObjectURL(url);
        },
        cache: "no-cache",
      }),
    }),
    buildingBills: builder.mutation({
      query: ({ buildingId, month, year }) => ({
        url: `/export/buildingBills?buildingId=${buildingId}&month=${month}&year=${year}`,
        method: "POST",
        responseHandler: async (response) => {
          const blob = await response.blob();
          if (blob.size === 1) {
            makeErrorToastMessage("Ne postoje racuni za tekuci mesec");
            return;
          }
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download =
            "Racuni zgrade" +
            " " +
            buildingId +
            " " +
            month.toString() +
            "." +
            year.toString() +
            ".pdf";
          link.click();
          window.URL.revokeObjectURL(url);
        },
        cache: "no-cache",
      }),
    }),
    billDetails: builder.mutation({
      query: ({ id, month, year }) => ({
        url: `/export/billDetails?month=${month}&year=${year}&buildingId=${id}`,
        method: "POST",
        responseHandler: async (response) => {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "BillDetails.xlsx";
          link.click();
          window.URL.revokeObjectURL(url);
        },
        cache: "no-cache",
      }),
    }),
    apartmentPayments: builder.mutation({
      query: (apartmentId) => ({
        url: `/export/payments?id=${apartmentId}`,
        method: "POST",
        responseHandler: async (response) => {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "Payments.xlsx";
          link.click();
          window.URL.revokeObjectURL(url);
        },
        cache: "no-cache",
      }),
    }),
  }),
});

export const {
  useAllBillsMutation,
  useFileForImportingTenantsMutation,
  useBuildingBillsMutation,
  useBillDetailsMutation,
  useApartmentPaymentsMutation
} = exportApiSlice;
