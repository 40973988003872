import {
  PrimaryButton,
  SecondaryButton,
} from "components/shared/Buttons/Buttons.styled";
import Line from "components/shared/Line/Line";
import React, { useRef, useState } from "react";
import {
  BottomContainer,
  ButtonsContainer,
  DefaultAgencyLogo,
  DefaultAgencyLogoContainer,
  EmptyContainer,
  GeneralSettingsComponentContainer,
  InputContainer,
  InputsContainer,
  LogoContainer,
  LogoSubContainer,
  LogoText,
  MainContainer,
  MainText,
  PercentageContainer,
} from "./GeneralSettingsComponent.styled";
import PibField from "components/SettingsInputs/PibField";
import { useFormik } from "formik";
import settingsValidation from "validations/settingsValidation";
import IdentificationNumberField from "components/SettingsInputs/IdentificationNumberField";
import PercentageField from "components/SettingsInputs/PercentageField";
import MinimalAmountField from "components/SettingsInputs/MinimalAmountField";
import selectedTheme from "../../themes";
import PropTypes from "prop-types";
import { useUpdateAgencySettingsMutation } from "store/settingsApiSlice";
import {
  makeErrorToastMessage,
  makeToastMessage,
} from "../../util/toastMessage";
import { compareObjects } from "util/helpers/objectHelpers";
import Buildings2Icon from "components/Icon/Icons/Buildings2Icon";
import UploadInput from "components/shared/UploadInput/UploadInput";
import { useMediaQuery } from "@mui/material";

const GeneralSettingsComponent = ({ agencySettings }) => {
  const [updateAgencySettings, { isLoading: isUpdatingSettings }] =
    useUpdateAgencySettingsMutation();
  const matches = useMediaQuery("(min-width:768px)");
  const [file, setFile] = useState(null);

  const hiddenFileInput = useRef(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleUpdateAgencySettings = () => {
    // user uploaded a new logo
    let data = {
      identificationNumber: formik.values.identificationNumber.toString(),
      minimumAmount: formik.values.minimumAmount,
      percentage: formik.values.percentage,
      pib: formik.values.pib.toString(),
    };
    if (file !== null) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        updateAgencySettings({
          formData: { ...data, logo: reader.result },
        })
        .unwrap()
        .then((res) => {
          setFile(null);
          makeToastMessage(res.data ? res.data : "Uspesno");
        })
        .catch((err) => {
          console.log(err);
          makeErrorToastMessage(err.data ? err.data : "Neuspesno")
        });
      };
    } else {
      updateAgencySettings({
        formData: { ...data },
      })
        .unwrap()
        .then((res) => {
          setFile(null);
          makeToastMessage(res.data ? res.data : "Uspesno");
        })
        .catch((err) => {
          console.log(err);
          makeErrorToastMessage(err.data ? err.data : "Neuspesno")
        });
    }
  };

  const formik = useFormik({
    initialValues: agencySettings,
    validationSchema: settingsValidation,
    onSubmit: handleUpdateAgencySettings,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const resetForm = () => {
    setFile(null);
    formik.resetForm();
  };

  return isUpdatingSettings ? (
    <p>Loading...</p>
  ) : (
    <GeneralSettingsComponentContainer
      component="form"
      onSubmit={formik.handleSubmit}
    >
      <MainContainer>
        <MainText>Detalji kompanije</MainText>
        {!matches && <Line />}
        <InputsContainer>
          <LogoContainer>
            <LogoText>Logo kompanije</LogoText>
            <LogoSubContainer>
              {agencySettings.logo === null ? (
                <DefaultAgencyLogoContainer>
                  <Buildings2Icon />
                </DefaultAgencyLogoContainer>
              ) : (
                <DefaultAgencyLogo src={agencySettings.logo} alt="logo" />
              )}

              <UploadInput
                style={
                  matches
                    ? {
                        marginLeft: "32px",
                      }
                    : {}
                }
                mainText="Klikni da uploaduješ fajl"
                helperText="PNG, JPG or GIF (max 800x400px)"
                handleClick={handleClick}
                handleFileChange={handleFileChange}
                hiddenFileInput={hiddenFileInput}
                file={file}
                accept=".jpg, .jpeg, .png, .gif"
              />
            </LogoSubContainer>
          </LogoContainer>
          <PibField
            formik={formik}
            style={matches ? { width: "512px" } : { width: "100%" }}
          />
          <InputContainer>
            <IdentificationNumberField
              formik={formik}
              style={matches ? { width: "512px" } : { width: "100%" }}
            />
          </InputContainer>
          <InputContainer>
            <PercentageContainer>
              <PercentageField
                formik={formik}
                style={matches ? { width: "512px" } : { width: "100%" }}
              />
            </PercentageContainer>
          </InputContainer>
          <InputContainer>
            <MinimalAmountField
              formik={formik}
              style={matches ? { width: "512px" } : { width: "100%" }}
            />
          </InputContainer>
        </InputsContainer>
        <EmptyContainer />
      </MainContainer>
      <Line />
      <BottomContainer>
        <EmptyContainer />
        <ButtonsContainer>
          <PrimaryButton style={{ marginRight: "12px" }} onClick={resetForm}>
            Resetuj
          </PrimaryButton>
          <SecondaryButton
            style={{
              color: selectedTheme.colors.base.white,
            }}
            onClick={handleUpdateAgencySettings}
            disabled={
              compareObjects(formik.values, agencySettings) && file === null
            }
            backgroundcolor={
              compareObjects(formik.values, agencySettings) && file === null
                ? selectedTheme.colors.primary[200]
                : selectedTheme.colors.primary[600]
            }
          >
            Sačuvaj podešavanja
          </SecondaryButton>
        </ButtonsContainer>
      </BottomContainer>
    </GeneralSettingsComponentContainer>
  );
};

GeneralSettingsComponent.propTypes = {
  agencySettings: PropTypes.object,
};

export default GeneralSettingsComponent;
