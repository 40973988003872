import { Button } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const PrimaryButton = styled(Button)`
  background-color: ${selectedTheme.colors.base.white};
  border: 1px solid ${selectedTheme.colors.gray[300]};
  box-shadow: ${selectedTheme.effects.shadow.xs.boxShadow};
  border-radius: 8px;
  padding: 10px 16px;
  gap: 8px;
  font-family: ${selectedTheme.type.textSm.semibold.fontFamily};
  font-size: ${selectedTheme.type.textSm.semibold.fontSize};
  font-weight: ${selectedTheme.type.textSm.semibold.fontWeight};
  font-style: ${selectedTheme.type.textSm.semibold.fontStyle};
  color: ${selectedTheme.colors.gray[700]};
  text-transform: none;
  white-space: nowrap;
  min-width: unset;
  &:hover {
    background-color: ${selectedTheme.colors.gray[50]};
    color: ${selectedTheme.colors.gray[800]};
  }
  &:focus {
    box-shadow: ${selectedTheme.effects.shadow.xsFocused_4pxGray100.boxShadow};
  }
`;

export const SecondaryButton = styled(Button)`
  background-color: ${({ backgroundcolor }) =>
    backgroundcolor ? backgroundcolor : selectedTheme.colors.primary[600]};
  box-shadow: ${selectedTheme.effects.shadow.xs.boxShadow};
  border-radius: 8px;
  padding: 10px 16px;
  gap: 8px;
  font-family: ${selectedTheme.type.textSm.semibold.fontFamily};
  font-size: ${selectedTheme.type.textSm.semibold.fontSize};
  font-weight: ${selectedTheme.type.textSm.semibold.fontWeight};
  font-style: ${selectedTheme.type.textSm.semibold.fontStyle};
  color: ${selectedTheme.colors.base.white};
  text-transform: none;
  white-space: nowrap;
  min-width: unset;
  &:hover {
    background-color: ${({ hoverBackground }) =>
      hoverBackground ? hoverBackground : selectedTheme.colors.primary[700]};
    color: ${selectedTheme.colors.base.white};
  }
  &:focus {
    box-shadow: ${selectedTheme.effects.shadow.xsFocused_4pxPrimary100};
  }
`;
