import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const SettingsPageContainer = styled(Box)`
  display: flex;
  min-height:100vh;
  height: 100%;
  background-color: ${selectedTheme.colors.gray[25]};
  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 48px;
  }
`;
export const ContentContainer = styled(Box)`
  width: 100%;
  padding: 32px;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const Title = styled(Typography)`
  font-family: ${selectedTheme.type.displaySm.semibold.fontFamily};
  font-style: ${selectedTheme.type.displaySm.semibold.fontStyle};
  font-weight: ${selectedTheme.type.displaySm.semibold.fontWeight};
  color: ${selectedTheme.colors.gray[900]};
  font-size: 30px;
  @media (max-width: 768px) {
    padding: 24px 16px 0px 16px;
  }
`;
