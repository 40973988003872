import { Box } from "@mui/material";
import styled from "styled-components";

export const ReceiptContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  margin-top: 32px;
`;


export const TopContainer = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 768px) {
    padding: 0 16px;
    flex-direction: column-reverse;
    gap: 16px;
  }
`;

export const FilterContainer = styled(Box)`
  display: flex;
  @media (max-width: 768px) {
    gap: 16px;
    flex-direction: row-reverse;
    align-items: center;
  }
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  gap: 12px;
`;