import React from "react";
import { ReactComponent as ArrowDownIconSvg } from "../../../assets/Icons/arrow-down.svg";

const ArrowDownIcon = (props) => {
  return <ArrowDownIconSvg {...props} />;
};

ArrowDownIcon.propTypes = {};

export default ArrowDownIcon;
