import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import {registerFormikConstants} from "../../../../initialValues/registerInitialValues";
import TextInputField from "components/shared/InputFields/TextField/TextField";
import { charactersToExlude } from "constants/helpers";

const RegisterPhoneField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;

  const preventEnteringNonNumericCharacters = (event) => {
    // input will accept only numbers
    if (charactersToExlude.includes(event.key)) {
      event.preventDefault();
    }
  };

  return (
      <TextInputField
        id={registerFormikConstants.phoneNumber}
        name={registerFormikConstants.phoneNumber}
        placeholder={t("register.phoneNumberPlaceholder")}
        label={t("register.phoneNumberLabel")}
        value={formik.values.phoneNumber}
        onChange={formik.handleChange}
        type="text"
        phone
        onBlur={formik.handleBlur}
        error={formik.touched?.[registerFormikConstants.phoneNumber] && formik.errors?.[registerFormikConstants.phoneNumber]}
        helperText={i18n.register && i18n.register?.phoneNumberHintText && i18n.register?.phoneNumberHintText !== "" && i18n.t("register.phoneNumberHintText")}
        apiError={props.apiError}
        onKeyDown={preventEnteringNonNumericCharacters}
      />
  );
};

RegisterPhoneField.propTypes = {
  formik: PropTypes.object,
  apiError:PropTypes.any,
};

export default RegisterPhoneField;
