import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as FileIconSvg } from "../../../assets/Icons/file.svg";

const FileIcon = (props) => {
  return <FileIconSvg {...props} />;
};

FileIcon.propTypes = {};

export default FileIcon;
