import React from "react";
import PropTypes from "prop-types";
import { settingsInitialConstants } from "initialValues/settingsInitialValues";
import TextInputField from "components/shared/InputFields/TextField/TextField";
import { charactersToExlude } from "constants/helpers";

const MinimalAmountField = (props) => {
  const formik = props?.formik;

  const preventEnteringNonNumericCharacters = (event) => {
    // input will accept only numbers
    if (charactersToExlude.includes(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <TextInputField
      id={settingsInitialConstants.minimumAmount}
      name={settingsInitialConstants.minimumAmount}
      placeholder="Unesite Minimalni iznos provizije vaše firme"
      label="Minimalni iznos provizije"
      type="number"
      value={formik.values.minimumAmount}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={
        formik.touched?.[settingsInitialConstants.minimumAmount] &&
        formik.errors?.[settingsInitialConstants.minimumAmount]
      }
      apiError={props.apiError}
      style={props?.style}
      onKeyDown={preventEnteringNonNumericCharacters}
    />
  );
};

MinimalAmountField.propTypes = {
  formik: PropTypes.object,
  apiError: PropTypes.any,
  style: PropTypes.object,
};

export default MinimalAmountField;
