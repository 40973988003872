import React from "react";
import { Route, Routes } from "react-router-dom";

import LoginPage from "./pages/LoginPage/LoginPage";
import RegisterPage from "pages/RegisterPage/RegisterPage";
import HomePage from "pages/HomePage/HomePage";
// import RequireAuth from "components/RequireAuth/RequireAuth";
import AuthCallback from "pages/AuthCallbackPage/AuthCallbackPage";
import ProfilePage from "pages/ProfilePage/ProfilePage";
import Error from "pages/ErrorPage/ErrorPage";
import BuildingsPage from "pages/BuildingsPage/BuildingsPage";
import ForgotPasswordPage from "pages/ForgotPasswordPage/ForgotPasswordPage";
import ResetPasswordPage from "pages/ResetPasswordPage/ResetPassword";
import RequireAuth from "components/RequireAuth/RequireAuth";
import BuildingDetailsPage from "pages/BuidlingDetailsPage/BuildingDetailsPage";
import ApartmentPage from 'pages/ApartmentPage/ApartmentPage';
import AddBuildingPage from "pages/AddBuildingPage/AddBuildingPage";
import AddDebitPage from "pages/AddDebitPage/AddDebitPage";
import SettingsPage from "pages/SettingsPage/SettingsPage";
import AddEditApartmentPage from "pages/AddEditApartmentPage/AddEditApartmentPage";
import BillDetailsPage from "pages/BillDetailsPage/BillDetailsPage";
import FinancesPage from "pages/FinancesPage/FinancesPage";

const AppRoutes = () => (
  <Routes>
    <Route path="login" element={<LoginPage />} errorElement={<Error />} />
    <Route
      exact
      path="register"
      element={<RegisterPage />}
      errorElement={<Error />}
    />
    <Route
      path="forgot-password"
      element={<ForgotPasswordPage />}
      errorElement={<Error />}
    />
    <Route
      path="reset-password"
      element={<ResetPasswordPage />}
      errorElement={<Error />}
    />
    <Route path="api/auth/:provider/callback" element={<AuthCallback />} />
    <Route element={<RequireAuth />} errorElement={<Error />}>
      <Route path="/" element={<HomePage />} />
      <Route path="/buildings" element={<BuildingsPage />} />
      <Route path="/buildings/:id" element={<BuildingDetailsPage />} />
      <Route path="/buildings/:id/bills/:billId" element={<BillDetailsPage/>}/>
      <Route path="/buildings/:id/add-debit" element={<AddDebitPage />} />
      <Route path="/buildings/:id/add-apartment" element={<AddEditApartmentPage />} />
      <Route path="/buildings/:id/edit-apartment/:id" element={<AddEditApartmentPage />} />
      <Route path="/add-building" element={<AddBuildingPage />} />
      <Route path="/add-building/:id" element={<AddBuildingPage />} />
      <Route path="/settings" element={<SettingsPage />} />
      <Route path="/finances" element={<FinancesPage />} />
      <Route path="profile" element={<ProfilePage />} />
      <Route path="/buildings/:id/apartments/:id" element={<ApartmentPage />}/>

    </Route>
  </Routes>
);

export default AppRoutes;
