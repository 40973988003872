import { Grid, Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const LoginLayoutContainer = styled(Box)`
  display: flex;
  position: relative;
  min-height: 100vh;
  min-width: 100%;
  height: 100%;
  flex-direction: row;
  //margin-top: -25px;
`;

export const AbsoluteContainer = styled(Box)``;

export const BottomLeftGraphic = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
`;
export const TopRightGraphic = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
`;

export const MockupImageContainer = styled(Box)`
  z-index: 100;
  max-height: 682px;
  height: 682px;
  width: 1024px;
  position: absolute;
  left: 96px;
  top:15%;
`;

export const MockupImage = styled.img`
  width: 100%;
`;

export const Content = styled(Grid)`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  justify-content: space-between;
  flex-grow: 1;
`;

export const HalfPanel = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  min-height: 100vh;
  height: 100%;
  background: ${(props) =>
    props?.gray ? `${selectedTheme.colors.gray[100]}` : "unset"};
  overflow: ${(props)=> props?.overflowHidden ? "hidden" : "auto"};
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const FullHeightContainer = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
