import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as LockIconSvg } from "../../../assets/Icons/lock.svg";

const LockIcon = (props) => {
  return <LockIconSvg {...props} />;
};

LockIcon.propTypes = {};

export default LockIcon;
