import React from "react";
import PropTypes from "prop-types";
import { settingsInitialConstants } from "initialValues/settingsInitialValues";
import TextInputField from "components/shared/InputFields/TextField/TextField";
import { charactersToExlude } from "constants/helpers";
import PercentageIcon from './../Icon/Icons/PercentageIcon';

const PercentageField = (props) => {
  const formik = props?.formik;

  const preventEnteringNonNumericCharacters = (event) => {
    // input will accept only numbers
    if (charactersToExlude.includes(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <TextInputField
      id={settingsInitialConstants.percentage}
      name={settingsInitialConstants.percentage}
      placeholder="Unesite Procenat provizije vaše firme"
      label="Procenat provizije"
      type="number"
      value={formik.values.percentage}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
        error={formik.touched?.[settingsInitialConstants.percentage] && formik.errors?.[settingsInitialConstants.percentage]}
      apiError={props.apiError}
      style={props?.style}
      onKeyDown={preventEnteringNonNumericCharacters}
      leadingIcon={<PercentageIcon
        style={{
          zIndex: 1000,
        }}
      />}
    />
  );
};

PercentageField.propTypes = {
  formik: PropTypes.object,
  apiError: PropTypes.any,
  style: PropTypes.object,
};

export default PercentageField;
