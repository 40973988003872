import React from "react";
import PropTypes from "prop-types";
//import { InputRightElement } from "./TextField.styled";
import AlertCircle from "../../../Icon/Icons/AlertCircle";

import {
  HintText,
  InputContainer,
  InputLabel,
  ErrorMessage,
  RightInputElementsContainer,
  InputRightElement,
  InputRightElementError,
  InputField,
  LeftInputElementContainer,
} from "../InputFields.styled";

const TextField = (props) => {
  const onChange = (event) => {
    if (!props?.disabledField) {
      var regExp = /[a-zA-Z]/g;
      //if PROP number OR tel is set, set only numbers
      if (regExp.test(event.target.value) && (props?.type === "number" || props?.type ==="tel")) {
        event.target.value = event.target.value.slice(0, -1);
      }
      //if decimal PROP is set, mask for 2 decimal places
      if (props?.type === "number"&& event.target.value.includes(".")&& event.target.value.split('.')[1]?.length > 2) {
        event.target.value = event.target.value.slice(0, -1);
      }
      // input mask for CurrentAccount field
      if (props?.handleCurrentAccountField !== undefined) {
        event.target.value = props?.handleCurrentAccountField(event);
      }
      props?.onChange(event);
    }
  };

  return (
    <InputContainer
      className={props.className}
      onClick={props?.onClick}
      disabled={props?.disabledField}
      $isErrorShown={props?.error}
      style={props?.style}
    >
      {props?.label && (
        <InputLabel
          $required={props?.requiredField && !props?.value}
          className={props?.labelClass}
        >
          {props?.label}
        </InputLabel>
      )}
      {props?.leadingIcon && (
        <LeftInputElementContainer>
          {props?.leadingIcon}
        </LeftInputElementContainer>
      )}

      <InputField
        variant="standard"
        disabled={props?.disabledField}
        $isErrorShown={props?.error}
        InputProps={{ disableUnderline: true }}
        type={props?.type}
        pattern={props?.pattern}
        id={props?.id}
        name={props?.name}
        onChange={onChange}
        onBlur={props?.onBlur}
        placeholder={props?.placeholder}
        value={props?.value}
        {...props?.inputProps}
        error={props?.error}
        maxLength={props?.maxLength}
        style={props?.style}
        ref={props?.ref}
        onKeyDown={props?.onKeyDown}
        leadingIcon={props?.leadingIcon}
      />
      <RightInputElementsContainer>
        {props?.error && (
          <InputRightElementError>
            <AlertCircle />
          </InputRightElementError>
        )}
        {props?.icon && !props?.error && (
          <InputRightElement>{props?.icon}</InputRightElement>
        )}
      </RightInputElementsContainer>
      {/* {props?.date && !props.error && <CalendarAdornment />} */}

      {/* show hint text if it exists and not empty in i18n and no formik errors */}
      {props?.helperText && <HintText>{props?.helperText}</HintText>}
      {props?.error && (
        <ErrorMessage>
          {typeof props?.error !== "object" &&
            props?.error !== null &&
            props?.error}
        </ErrorMessage>
      )}
    </InputContainer>
  );
};

TextField.displayName = "TextField";

TextField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.object,
  helperText: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  date: PropTypes.bool,
  disabledField: PropTypes.bool,
  value: PropTypes.any,
  name: PropTypes.any,
  number: PropTypes.bool,
  requiredField: PropTypes.bool,
  inputProps: PropTypes.any,
  placeholder: PropTypes.string,
  icon: PropTypes.node,
  fullWidth: PropTypes.bool,
  isSmall: PropTypes.bool,
  style: PropTypes.object,
  labelClass: PropTypes.string,
  ref: PropTypes.object,
  handleCurrentAccountField: PropTypes.func,
  maxLength: PropTypes.number,
  onKeyDown: PropTypes.func,
  leadingIcon: PropTypes.node,
  pattern:PropTypes.string,
};

export default TextField;
