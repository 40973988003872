import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import {registerFormikConstants} from "../../../../initialValues/registerInitialValues";
import TextInputField from "components/shared/InputFields/TextField/TextField";
import { charactersToExlude } from "constants/helpers";

const RegisterPibField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;

  const preventEnteringNonNumericCharacters = (event) => {
    // input will accept only numbers
    if (charactersToExlude.includes(event.key)) {
      event.preventDefault();
    }
  };

  return (
      <TextInputField
        id={registerFormikConstants.pib}
        name={registerFormikConstants.pib}
        placeholder={t("register.pibPlaceholder")}
        label={t("register.pibLabel")}
        value={formik.values.pib}
        onChange={formik.handleChange}
        type="number"
        onBlur={formik.handleBlur}
        error={formik.touched?.[registerFormikConstants.pib] && formik.errors?.[registerFormikConstants.pib]}
        helperText={i18n.register && i18n.register?.pibHintText && i18n.register?.pibHintText !== "" && i18n.t("register.pibHintText")}
        apiError={props.apiError}
        onKeyDown={preventEnteringNonNumericCharacters}
      />
  );
};

RegisterPibField.propTypes = {
  formik: PropTypes.object,
  apiError:PropTypes.any,
};

export default RegisterPibField;
