import { apiSlice } from "store/apiSlice";

export const apartmentsApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Apartments", "ApartmentDetails", "UpdateApartment"],
  endpoints: (builder) => ({
    apartmentDetails: builder.query({
      query: (id) => ({
        url: `/apartments/${id}`,
        method: "GET",
      }),
      providesTags: ["ApartmentDetails"],
    }),
    revertApartmentDetails: builder.mutation({
      query: (data) => ({
        url: "/apartments/revertChange",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ApartmentDetails", "BuildingDetails"],
    }),
    createApartment: builder.mutation({
      query: (data) => ({
        url: "/apartments/import",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["BuildingDetails"],
    }),
    manuallyCreateApartment: builder.mutation({
      query: (formData) => {
        var bodyFormData = new FormData();

        if (formData.Images !== undefined) {
          for (let i = 0; i < formData.Images.length; i++) {
            bodyFormData.append(
              "Images[" + i + "].File",
              formData.Images[i].file
            );
            bodyFormData.append("Images[" + i + "].ImageId", "");
          }
        }

        bodyFormData.append("BuildingId", formData.BuildingId);
        bodyFormData.append("CurrentState", formData.CurrentState);
        bodyFormData.append("DefaultRentPrice", formData.DefaultRentPrice);
        bodyFormData.append("ElectronicDelivery", formData.ElectronicDelivery);
        if (formData.Email !== null) {
          bodyFormData.append("Email", formData.Email);
        }
        bodyFormData.append("FirstName", formData.FirstName);
        bodyFormData.append("LastName", formData.LastName);
        bodyFormData.append("NumOfApartment", formData.NumOfApartment);
        bodyFormData.append("NumOfTenants", formData.NumOfTenants);
        bodyFormData.append("PhoneNumber", formData.PhoneNumber);
        bodyFormData.append("IsAvaliableForRent", formData.isAvailableForRent);
        if (bodyFormData.Images !== undefined) {
          for (let i = 0; i < formData.Images.length; i++) {
            bodyFormData.append(
              "Images[" + i + "].File",
              formData.Images[i].file
            );
            bodyFormData.append("Images[" + i + "].ImageId", "");
          }
        }

        if (formData.RentalInformations !== null) {
          bodyFormData.append(
            "RentalInformations",
            formData.RentalInformations
          );
        }
        bodyFormData.append("SquareFootage", formData.SquareFootage);
        bodyFormData.append("DeliveryAddress", formData.DeliveryAddress);
        bodyFormData.append("CityOfDeliveryId", formData.DeliveryCityId);
        bodyFormData.append("DeliveryZipCode", formData.ZipCode);
        return {
          url: "/apartments",
          method: "POST",
          body: bodyFormData,
          prepareHeaders: (headers) => {
            headers.set("Content-Type", "multipart/form-data");
            return headers;
          },
        };
      },
      invalidatesTags: ["BuildingDetails"],
    }),
    updateApartmentData: builder.query({
      query: (id) => ({
        url: `/apartments/update-data/${id}`,
        method: "GET",
      }),
      providesTags: ["UpdateApartment", "ApartmentDetails"],
    }),
    updateApartment: builder.mutation({
      query: ({ formData }) => {
        var bodyFormData = new FormData();
        bodyFormData.append("Id", formData.apartmentId);
        bodyFormData.append("CurrentState", formData.currentState);
        bodyFormData.append("DefaultRentPrice", formData.defaultRentPrice);
        bodyFormData.append("ElectronicDelivery", formData.electronicDelivery);
        if (formData.email !== null) {
          bodyFormData.append("Email", formData.email);
        }
        bodyFormData.append("FirstName", formData.firstName);
        bodyFormData.append("LastName", formData.lastName);
        bodyFormData.append("NumOfApartment", formData.numOfApartment);
        bodyFormData.append("NumOfTenants", formData.numOfTenants);
        bodyFormData.append("PhoneNumber", formData.phoneNumber);
        bodyFormData.append("IsAvailableForRent", formData.isAvailableForRent);
        for (let i = 0; i < formData.UploadedImages.length; i++) {
          bodyFormData.append(
            "UploadImages[" + i + "].File",
            formData.UploadedImages[i].File
          );
          bodyFormData.append(
            "UploadImages[" + i + "].ImageId",
            formData.UploadedImages[i].ImageId
          );
        }
        if (formData.rentalInformations !== null) {
          bodyFormData.append(
            "RentalInformations",
            formData.rentalInformations
          );
        }
        bodyFormData.append("SquareFootage", formData.squareFootage);
        bodyFormData.append("DeliveryAddress", formData.deliveryAddress);
        bodyFormData.append("CityOfDeliveryId", formData.deliveryCityId);
        bodyFormData.append("DeliveryZipCode", formData.zipCode);
        return {
          url: "/apartments/update",
          method: "POST",
          body: bodyFormData,
          prepareHeaders: (headers) => {
            headers.set("Content-Type", "multipart/form-data");
            return headers;
          },
        };
      },
      invalidatesTags: [
        "UpdateApartment",
        "BuildingDetails",
        "ApartmentDetails",
        "DebitDetails"
      ],
    }),
  }),
});

export const {
  useApartmentDetailsQuery,
  useRevertApartmentDetailsMutation,
  useCreateApartmentMutation,
  useManuallyCreateApartmentMutation,
  useUpdateApartmentDataQuery,
  useUpdateApartmentMutation,
} = apartmentsApiSlice;
