import React from "react";
import {
  ButtonsContainer,
  HeaderContainer,
  Text,
} from "./HeaderComponent.styled";
import NewBuildingIcon from "components/Icon/Icons/NewBuildingIcon";
import ExportAllBillsIcon from "components/Icon/Icons/ExportAllBillsIcon";
import { PrimaryButton } from "../../Buttons/Buttons.styled";
import { useNavigate } from "react-router";
import { useAllBillsMutation } from "store/exportApiSlice";
import { useMediaQuery } from "@mui/material";

export default function HeaderComponent() {
  const matches = useMediaQuery("(max-width:400px)");
  const navigate = useNavigate();
  const [getBills] = useAllBillsMutation();
  const currentDate = new Date();

  const downloadBills = () => {
    getBills({
      month: currentDate.getMonth() + 1,
      year: currentDate.getFullYear(),
    });
  };

  return (
    <HeaderContainer>
      <Text>Dobrodošla Mila!</Text>
      <ButtonsContainer>
        <PrimaryButton
          style={{
            marginRight: 12,
            marginBottom: matches && 8,
            flex: 1,
            width: "100%",
          }}
          onClick={() => navigate("add-building")}
        >
          <NewBuildingIcon />
          Nova Zgrada
        </PrimaryButton>
        <PrimaryButton style={{ flex: 1 }} onClick={() => downloadBills()}>
          <ExportAllBillsIcon />
          Export sve Račune
        </PrimaryButton>
      </ButtonsContainer>
    </HeaderContainer>
  );
}
