import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as AlertCircle } from "../../../assets/Icons/alert-circle.svg";

const WarningIcon = (props) => {
  return <AlertCircle {...props} />;
};

WarningIcon.propTypes = {};

export default WarningIcon;
