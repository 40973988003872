import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";


export const BiggestDebtorsOverviewContainer = styled(Box)`
  border-radius: 12px;
  border: 1px solid ${selectedTheme.colors.gray[200]};
  box-shadow: ${selectedTheme.effects.shadow.sm.boxShadow};
  width: 100%;
  flex-basis: 50%;
  margin-left:32px;
  height:100%;
  background-color: ${selectedTheme.colors.base.white};
  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 32px;
  }
`;