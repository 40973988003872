import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import BreadCrumbArrow from "components/Icon/Icons/BreadCrumbArrow";
import { Link } from "react-router-dom";

export const BreadCrumbsContainer = styled(Box)`
  font-family: ${selectedTheme.type.textSm.medium.fontFamily};
  font-size: ${selectedTheme.type.textSm.medium.fontSize};
  font-weight: ${selectedTheme.type.textSm.medium.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
  display: flex;
  gap: 6px;
`;
export const BreadCrumbItem = styled(Box)`
  font-family: ${selectedTheme.type.textSm.medium.fontFamily};
  font-size: ${selectedTheme.type.textSm.medium.fontSize};
  font-weight: ${selectedTheme.type.textSm.medium.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
  padding: 8px 4px;
`;
export const BreadCrumbItemLink = styled(Link)`
  font-family: ${selectedTheme.type.textSm.medium.fontFamily};
  font-size: ${selectedTheme.type.textSm.medium.fontSize};
  font-weight: ${selectedTheme.type.textSm.medium.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
  text-decoration: none;
`;
export const RouteSingleItem = styled(Typography)`
  display: inline;
  cursor: pointer;
  font-family: "Inter" !important;
`;

export const HeaderIcon = styled(Box)`
  width: 24px;
  height: 24px;
`;

export const HeaderItem = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-right: 10px;
`;

export const HeaderArrow = styled(BreadCrumbArrow)``;
