import React from "react";
import PropTypes from "prop-types";
import { CostFieldContainer } from "../CostField/CostField.styled";

const PrefixInputField = ({Field}) => {
  return (
    <>
      <CostFieldContainer>EU</CostFieldContainer>
      {Field}
    </>
  );
};

PrefixInputField.propTypes = {
  Field: PropTypes.any,
};

export default PrefixInputField;
