
import styled  from 'styled-components';
import { Box } from '@mui/material';


export const LoadingModalContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 12px;
    background-color: white;
    color: black;
    padding: 32px;
    height: 100px;
    width: 100px;
`