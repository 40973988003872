import React from "react";
import PropTypes from "prop-types";
import { addApartmentInitialConstants } from "initialValues/addApartmentInitialValues";
import CustomTextArea from "components/shared/InputFields/CustomTextArea/CustomTextArea";

const RentDetailsField = (props) => {
  const formik = props?.formik;

  return (
      <CustomTextArea
        id={addApartmentInitialConstants.rentalInformations}
        name={addApartmentInitialConstants.rentalInformations}
        placeholder="Rent Details"
        label="Detalji rentiranja"
        value={formik.values.rentalInformations}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched?.[addApartmentInitialConstants.rentalInformations] && formik.errors?.[addApartmentInitialConstants.rentalInformations]}
        apiError={props.apiError}
        style={props?.style}
        disabledField={!formik.values.isAvailableForRent}
      />
  );
};

RentDetailsField.propTypes = {
  formik: PropTypes.object,
  apiError:PropTypes.any,
  style: PropTypes.object,
};

export default RentDetailsField;
