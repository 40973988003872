import React from "react";
import { mix } from "polished";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import PropTypes from "prop-types";
import { TotalBuildingChartContainer } from "./TotalBuildingsChart.styled";
import selectedTheme from "themes";

ChartJS.register(ArcElement, Tooltip, Legend);

const TotalBuildingsChart = ({ numberOfApartments }) => {
  const baseColor = selectedTheme.colors.primary[600];
  const numShades = numberOfApartments.length;
  const colors = generateShadesOfBlue(baseColor, numShades);

  const data = {
    labels: numberOfApartments.map((num) => num.toString()).slice(0, 5),
    datasets: [
      {
        label: "# of Apartments",
        data: numberOfApartments,
        backgroundColor: colors,
        borderWidth: 0.5,
        pointStyle: "circle",
        pointRadius: 5, // Set the radius of the circle
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: "right",
        labels: {
          usePointStyle: true, // Use point style instead of rectangles
        },
        title: {
          display: true,
          text: "Stanovi", // Set the text to display above the labels
        },
      },
    },
  };

  function generateShadesOfBlue(baseColor, numShades) {
    const colors = [];
    const step = 1 / (numShades - 1);

    let currentColor = baseColor;

    for (let i = 0; i < numShades; i++) {
      colors.push(currentColor);
      currentColor = mix(step * i, "#FFFFFF", currentColor);
    }
    return colors;
  }

  return (
    <TotalBuildingChartContainer>
      <Doughnut data={data} options={options} />
    </TotalBuildingChartContainer>
  );
};

TotalBuildingsChart.propTypes = {
  numberOfApartments: PropTypes.arrayOf(PropTypes.number),
};
export default TotalBuildingsChart;
