import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import {registerFormikConstants} from "../../../../initialValues/registerInitialValues";
import TextInputField from "components/shared/InputFields/TextField/TextField";
import { charactersToExlude } from "constants/helpers";

const RegisterIdNumberField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;

  const preventEnteringNonNumericCharacters = (event) => {
    // input will accept only numbers
    if (charactersToExlude.includes(event.key)) {
      event.preventDefault();
    }
  };

  return (
      <TextInputField
        id={registerFormikConstants.identificationNumber}
        name={registerFormikConstants.identificationNumber}
        placeholder={t("register.identificationNumberPlaceholder")}
        label={t("register.identificationNumberLabel")}
        value={formik.values.identificationNumber}
        onChange={formik.handleChange}
        type="number"
        // requiredField={
        //   newClientValidationSchema.fields.companyName.exclusiveTests.required
        // }
        onBlur={formik.handleBlur}
        error={formik.touched?.[registerFormikConstants.identificationNumber] && formik.errors?.[registerFormikConstants.identificationNumber]}
        helperText={i18n.register && i18n.register?.identificationNumberHintText && i18n.register?.identificationNumberHintText !== "" && i18n.t("register.identificationNumberHintText")}
        apiError={props.apiError}
        onKeyDown={preventEnteringNonNumericCharacters}
      />
  );
};

RegisterIdNumberField.propTypes = {
  formik: PropTypes.object,
  apiError:PropTypes.any,
};

export default RegisterIdNumberField;
