import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  FormHeader,
  FormTitle,
  FormSubtitle,
  CheckboxContainer,
  InputLabel,
  LoginButton,
  LoginFormContainer,
  Column,
  CenteredRow,
  PreText,
  RegisterLink,
  Row,
  ForgotPasswordLink,
} from "./LoginForm.styled";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import loginInitialValues, {
  loginFormikConstants,
} from "../../../initialValues/loginInitialValues";
import loginValidationSchema from "../../../validations/loginValidation";
import { useLoginMutation } from "store/authApiSlice";
import { makeErrorToastMessage } from "util/toastMessage";
import { useNavigate } from "react-router";
import LoginPasswordField from "./LoginFields/LoginPasswordField";
import LoginEmailField from "./LoginFields/LoginEmailField";
import { useDispatch } from "react-redux";
import { setCredetnials } from "store/authSlice";
import useMediaQuery from "@mui/material/useMediaQuery";

const LoginForm = () => {
  const { t } = useTranslation();
  const [triedToLogin, setTriedToLogin] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [login, { isLoading }] = useLoginMutation();
  const navigate = useNavigate();

  const matches = useMediaQuery("(max-width:400px)");

  const [apiError, setApiError] = useState("");

  const dispatch = useDispatch();
  //const [user,setUser] = useState('');
  //const [password,setPassword] = useState('');
  //const [errorMsg, setErrorMsg] = useState('');


    const handleSubmit = async () => {
      const { email: Email, password: Password } = formik.values;

      try {
        const userData = await login({
          Email,
          Password,
        }).unwrap();
        dispatch(setCredetnials(userData));
        //makeToastMessage("Login successfuly.");
        setApiError("");
        navigate("/", { replace: true });
      } catch (err) {
        makeErrorToastMessage("Email ili Lozinka nisu tačni");
        setApiError(err.error);
      }
    };

  const formik = useFormik({
    initialValues: loginInitialValues,
    validationSchema: loginValidationSchema,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
  });

  const handleChangePassword = (event) => {
    formik?.handleChange(event);
    if (triedToLogin) setTriedToLogin(false);
  };

  return (
    <LoginFormContainer component="form" onSubmit={formik.handleSubmit}>
      <FormHeader>
        <FormTitle>{t("login.formTitle")}</FormTitle>
        <FormSubtitle>{t("login.formSubtitle")}</FormSubtitle>
      </FormHeader>
      {/* Input Column */}
      <Column gap={20} w={100}>
        {/* Email */}
        <LoginEmailField formik={formik} />

        {/* Password */}
        <LoginPasswordField
          formik={formik}
          onChange={handleChangePassword}
          apiError={apiError}
        />
        <Row w={100} spaceBetween>
          {/* Remember me checkbox - not working */}
          <CheckboxContainer>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  checked={formik.values.rememberMe}
                  id={loginFormikConstants.rememberMe}
                  onChange={formik.handleChange}
                />
              }
              label={<InputLabel>{t("login.rememberMeLabel")}</InputLabel>}
            />
          </CheckboxContainer>

          <ForgotPasswordLink
            style={{ width: matches && "100%", textAlign: matches && "center" }}
            to="/forgot-password"
          >
            {t("login.forgetPasswordLink")}
          </ForgotPasswordLink>
        </Row>
        <LoginButton component="button" type="submit">
          {t("login.loginButton")}
        </LoginButton>
      </Column>
      <CenteredRow gap={4}>
        <PreText>{t("login.registerPreText")}</PreText>
        <RegisterLink to="/register">{t("login.registerLink")}</RegisterLink>
      </CenteredRow>
    </LoginFormContainer>
  );
};

LoginForm.propTypes = {
  children: PropTypes.node,
};

export default LoginForm;
