import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";


export const ProfitContainer = styled(Box)`
  border-radius: 12px;
  border: 1px solid ${selectedTheme.colors.gray[200]};
  box-shadow: ${selectedTheme.effects.shadow.sm.boxShadow};
  flex-basis: 65%;
  margin-left:24px;
  background-color: ${selectedTheme.colors.base.white};
  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 24px;
  }
`;