import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const BuildingsPageContainer = styled(Box)`
  background-color: ${selectedTheme.colors.gray[25]};
  height: 100%;
`;

export const BuildingsPageSubContainer = styled(Box)`
  display: flex;
  min-height: 100vh;
  height: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 48px;
  }
`;

export const ContentContainer = styled(Box)`
  padding: 32px;
  width: 100%;
  @media (max-width: 768px) {
    padding: 0;
  }
`;
