import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  ImportTenantsContainer,
  ImportTenantsSubContainer,
} from "./ImportPayments.styled";
import ImportPaymentsFirstStep from "./ImportPaymentsFirstStep";
import ImportPaymentsSecondStep from "./ImportPaymentsSecondStep";

const ImportPayments = ({
  open,
  handleClose,
  handleOpen,
  isLoading,
}) => {
  const [firstStep, setFirstStep] = useState(true);
  const [payments,setPayments] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [stateSnapshot,setSnapshot] = useState(null);


  const handleCloseDialog = () => {
    handleClose();
    setFirstStep(true);
  };

  const handleOpenDialog = () => {
    handleOpen();
    setFirstStep(true);
  }

  return isLoading ? (
    <p>Uploading...</p>
  ) : (
    <ImportTenantsContainer
      open={open}
      onClose={handleCloseDialog}
      onOpen={handleOpenDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "max-content",
          },
        },
      }}
    >
      <ImportTenantsSubContainer>
        {firstStep ? (
          <ImportPaymentsFirstStep open={open}
          handleClose={handleCloseDialog}
          firstStep={firstStep}
          setPayments={setPayments}
          setSnapshot={setSnapshot}
          setFirstStep={setFirstStep} />
        ): (
          <ImportPaymentsSecondStep open={open}
          handleClose={handleCloseDialog}
          firstStep={firstStep}
          payments={payments}
          stateSnapshot={stateSnapshot}
          setSnapshot={setSnapshot}
          setPayments={setPayments}
          setFirstStep={setFirstStep} />
        )}
      </ImportTenantsSubContainer>
    </ImportTenantsContainer>
  );
};

ImportPayments.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOpen: PropTypes.func,
  importTenants: PropTypes.func,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};

export default ImportPayments;
