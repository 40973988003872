import { apiSlice } from "store/apiSlice";

export const debtPaymentsApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["DebtsPayment"],
  endpoints: (builder) => ({
    importPayments: builder.mutation({
      query: (formData) => ({
        url: "/imports/payments",
        method: "POST",
        body: formData,
        prepareHeaders: (headers) => {
          headers.set("Content-Type", "multipart/form-data");
          return headers;
        },
      }),
      invalidatesTags: ["ImportPayments"],
    }),
    importPayment: builder.mutation({
      query: (formData) => ({
        url: "/imports/single-payment",
        method: "POST",
        body: formData,
        prepareHeaders: (headers) => {
          headers.set("Content-Type", "multipart/form-data");
          return headers;
        },
      }),
      invalidatesTags: ["ImportSinglePayment"],
    }),
    deleteImportPayment: builder.mutation({
      query: (formData) => ({
        url: "/imports/delete",
        method: "POST",
        body: formData,
        prepareHeaders: (headers) => {
          headers.set("Content-Type", "multipart/form-data");
          return headers;
        },
      }),
      invalidatesTags: ["DeleteImportPayment"],
    }),
    payDebt: builder.mutation({
      query: ({ formData, id }) => ({
        url: `/buildings/${id}`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["DebtsPayment"],
    }),
  }),
});

export const { usePayDebtMutation, useImportPaymentsMutation, useImportPaymentMutation, useDeleteImportPaymentMutation } = debtPaymentsApiSlice;
