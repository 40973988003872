import * as Yup from "yup";
import { settingsInitialConstants } from "initialValues/settingsInitialValues";

export default Yup.object().shape({
  [settingsInitialConstants.pib]: Yup.string()
    .required("PIB je obavezno polje!")
    .length(9, "Dužina PIB-a mora biti tačno 9 cifara"),
  [settingsInitialConstants.identificationNumber]: Yup.string()
    .required("Matični broj je obavezno polje!")
    .length(8, "Dužina matičnog broja mora biti tačno 8 cifara"),
  [settingsInitialConstants.percentage]: Yup.string().required(
    "Procenat je obavezno polje!"
  ),
  [settingsInitialConstants.minimumAmount]: Yup.string().required(
    "Minimalni iznos je obavezno polje!"
  ),
});
