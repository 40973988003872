import BuildingNavigationIcon from "components/Icon/Icons/BuildingNavigationIcon";
import RightArrowNavigationIcon from "components/Icon/Icons/RightArrowNavigationIcon";
import React from "react";
import {
  AddBuildingPageContainer,
  ButtonsContainer,
  ContentSubContainer,
  Header,
  InformationContainer,
  NavigationContainer,
  NavigationItem,
  TopContainer,
  ContentContainer,
  InformationMainText,
  InformationSubText,
  Table,
  InputContainer,
  MainContainer,
  FundContainer,
  AddressContainer,
} from "./AddBuildingPage.styled";
import {
  PrimaryButton,
  SecondaryButton,
} from "components/shared/Buttons/Buttons.styled";
import ThreeDotsHorizontal from "components/Icon/Icons/ThreeDotsHorizontalIcon";
import selectedTheme from "../../themes";
import AddressField from "components/AddBuildingsInputs/AddressField";
import { useFormik } from "formik";
import addBuildingInitialValues from "initialValues/addBuildingInitialValues";
import addBuildingValidation from "validations/addBuildingValidation";
import CurrentAccountField from "components/AddBuildingsInputs/CurrentAccoutField";
import Navbar from "components/shared/Navbar/Navbar";
import PibField from "components/AddBuildingsInputs/PibField";
import IdentificationNumberField from "components/AddBuildingsInputs/IdentificationNumberField";
import ManagamentCostField from "components/AddBuildingsInputs/ManagamentCostField";
import CostField from "components/shared/InputFields/CostField/CostField";
import CurrentFund from "components/AddBuildingsInputs/CurrentFund";
import InvestmentFundField from "components/AddBuildingsInputs/InvestmentFundField";
import Line from "components/shared/Line/Line";
import CityField from "components/AddBuildingsInputs/CityField";
import { useNavigate, useParams } from "react-router-dom";
import { useAllCitiesQuery } from "store/citiesApiSlice";
import {
  useBuildingUpdateDataQuery,
  useCreateBuildingMutation,
  useUpdateBuildingMutation,
} from "store/buildingsApiSlice";
import {
  makeErrorToastMessage,
  makeToastMessage,
} from "../../util/toastMessage";
import { compareObjects } from "util/helpers/objectHelpers";
import { useMediaQuery } from '@mui/material';
import { BackContainer } from './../../components/shared/Modals/ConfirmActionModal/ConfirmActionModal.styled';
import LeftArrowIcon from './../../components/Icon/Icons/LeftArrowIcon';
import {BackText} from './../../components/ImportTenantsSecondStep/ImportTenantsSecondStep.styled';

// This page will be responsible for creating (id is undefined) and updating Building (id is not undefined)
const AddBuildingPage = () => {
  const matches = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: cities, isLoading: isLoadingCities } = useAllCitiesQuery();
  const [createBuilding, { isLoading: isCreatingBuilding }] =
    useCreateBuildingMutation();
  const [updateBuilding, { isLoading: isUpdatingBuilding }] =
    useUpdateBuildingMutation();

  const { data: building, isLoading: isLoadingBuilding } =
    id === undefined
      ? { data: null, isLoading: false }
      : useBuildingUpdateDataQuery(id);

  const handleSubmit = () => {
    id === undefined
      ? createBuilding({
          ...formik.values,
          currentAccount: formik.values.currentAccount
            .split("-")
            .join("")
            .replace(/\s/g, ""),
          pib: formik.values.pib.toString(),
          identificationNumber: formik.values.identificationNumber.toString(),
        })
          .unwrap()
          .then(() => {
            makeToastMessage("Uspešno ste kreirali zgradu");
            navigate("/buildings");
          })
          .catch(() => makeErrorToastMessage("Zgrada nije uspešno kreirana"))
      : updateBuilding({
          formData: {
            ...formik.values,
            currentAccount: formik.values.currentAccount.toString(),
            pib: formik.values.pib.toString(),
            identificationNumber: formik.values.identificationNumber.toString(),
          },
          id,
        })
          .unwrap()
          .then(() => {
            makeToastMessage("Uspešno ste izmenili zgradu");
            navigate("/buildings");
          })
          .catch(() => makeErrorToastMessage("Zgrada nije uspešno izmenjena"));
  };

  const formik = useFormik({
    initialValues:
      id === undefined
        ? addBuildingInitialValues
        : isLoadingBuilding
        ? addBuildingInitialValues
        : building,
    validationSchema: addBuildingValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const tranformDataForSelectInput = () => {
    return cities.map((obj) => {
      return {
        value: obj.id,
        label: obj.name,
      };
    });
  };

  return isLoadingCities ||
    isCreatingBuilding ||
    isLoadingBuilding ||
    isUpdatingBuilding ? (
    <p>Loading...</p>
  ) : (
    <AddBuildingPageContainer onSubmit={formik.handleSubmit} component="form">
      <Navbar />
      <MainContainer>
      {matches ? (
          <BackContainer>
          <LeftArrowIcon
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/buildings`)}
          />
          <BackText>Nazad</BackText>
        </BackContainer>
        ) : (
        <NavigationContainer>
          <BuildingNavigationIcon
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/buildings")}
          />
          <RightArrowNavigationIcon style={{ marginLeft: "20px" }} />
          <NavigationItem>
            {id === undefined ? "Nova zgrada" : "Izmeni zgradu"}
          </NavigationItem>
        </NavigationContainer>
        )}
        <TopContainer>
          <Header>{id === undefined ? "Nova zgrada" : "Izmeni zgradu"}</Header>
          {!matches && (
            <ButtonsContainer>
            <PrimaryButton style={{ marginRight: "12px", height: "45px" }}>
              <ThreeDotsHorizontal />
            </PrimaryButton>
            <PrimaryButton
              style={{ marginRight: "12px" }}
              onClick={() => navigate("/buildings")}
            >
              Odustani
            </PrimaryButton>
            <SecondaryButton
              style={{ color: selectedTheme.colors.base.white }}
              backgroundcolor={
                id !== undefined && compareObjects(building, formik.values)
                  ? selectedTheme.colors.primary[200]
                  : selectedTheme.colors.primary[600]
              }
              type="submit"
              disabled={
                id !== undefined && compareObjects(building, formik.values)
              }
            >
              {id === undefined ? "Potvrdi" : "Sacuvaj"}
            </SecondaryButton>
          </ButtonsContainer>
          )}
          
        </TopContainer>
        {!matches && (
          <Line />
        )}
        <ContentContainer>
          <ContentSubContainer>
            <InformationContainer>
              <InformationMainText>Osnovne informacije</InformationMainText>
              <InformationSubText>
                Najosnovnije informacije o zgradi
              </InformationSubText>
            </InformationContainer>
            <Table>
              <AddressContainer>
                <InputContainer style={{ flex: "auto" }}>
                  <AddressField formik={formik} style={{ width: !matches ? "458px" : "100%", flex:"auto" }} />
                </InputContainer>
                <InputContainer style={{ marginLeft: !matches ? "24px" : "0", flex: "auto" }}>
                  <CityField
                  style={{flex: 'auto', width: "100%" }}
                    formik={formik}
                    class="city-field"
                    options={tranformDataForSelectInput()}
                    defaultValue={
                      id === undefined
                        ? null
                        : tranformDataForSelectInput().filter(
                            (k) => k.value === building.cityId
                          )[0]
                    }
                  />
                </InputContainer>
              </AddressContainer>
              <InputContainer style={{ flex: "auto" }}>
                <CurrentAccountField
                  formik={formik}
                  style={{ width:  !matches ? "704px" : "100%", flex:"auto" }}

                />
              </InputContainer>
              <InputContainer style={{  flex: "auto" }}>
                <PibField style={{ width:  !matches ? "704px" : "100%", flex:"auto" }} formik={formik} />
              </InputContainer>
              <InputContainer style={{  flex: "auto" }}>
                <IdentificationNumberField
                  style={{ width:  !matches ? "704px" : "100%", flex:"auto" }}
                  formik={formik}
                />
              </InputContainer>
            </Table>
          </ContentSubContainer>
          <ContentSubContainer style={{ marginTop: "20px" }}>
            <InformationContainer>
              <InformationMainText>Finansije</InformationMainText>
              <InformationSubText>Opis Finansija</InformationSubText>
            </InformationContainer>
            <Table>
              <InputContainer style={{ flex: "auto" }}>
                <CostField
                  Field={
                    <ManagamentCostField
                      formik={formik}
                      labelClass="cost-field"
                      style={{
                        width: !matches ? "646px" : "100%",
                        borderBottomLeftRadius: "0px",
                        borderTopLeftRadius: "0px",
                        flex:"auto"
                      }}
                    />
                  }
                />
              </InputContainer>
              <FundContainer>
                <InputContainer style={{ flex: "auto" }}>
                  <CostField
                    Field={
                      <CurrentFund
                        formik={formik}
                        labelClass="cost-field"
                        style={{
                          width: !matches ? "282px" : "100%",
                          borderBottomLeftRadius: "0px",
                          borderTopLeftRadius: "0px",
                          flex:"auto"
                        }}
                      />
                    }
                  />
                </InputContainer>
                <InputContainer style={{ marginLeft: !matches ? "24px" : "0", flex: "auto" }}>
                  <CostField
                    Field={
                      <InvestmentFundField
                        formik={formik}
                        labelClass="cost-field"
                        style={{
                          width: !matches ? "282px" : "100%",
                          borderBottomLeftRadius: "0px",
                          borderTopLeftRadius: "0px",
                          flex:"auto"
                        }}
                      />
                    }
                  />
                </InputContainer>
              </FundContainer>
            </Table>
          </ContentSubContainer>
        </ContentContainer>
        <Line />
        <TopContainer style={{ justifyContent: !matches ? "flex-end" : "center" }}>
        <ButtonsContainer>
            <PrimaryButton
              style={{ marginRight: !matches ? "12px" : "0", width: !matches ? "auto" : "100%" }}
              onClick={() => navigate("/buildings")}
            >
              Odustani
            </PrimaryButton>
            <SecondaryButton
              style={{height: "45px", width: !matches ? "auto" : "100%"}}
              backgroundcolor={
                id !== undefined && compareObjects(building, formik.values)
                  ? selectedTheme.colors.primary[200]
                  : selectedTheme.colors.primary[600]
              }
              type="submit"
              disabled={
                id !== undefined && compareObjects(building, formik.values)
              }
            >
              {id === undefined ? "Potvrdi" : "Sacuvaj"}
            </SecondaryButton>
          </ButtonsContainer>
        </TopContainer>
      </MainContainer>
    </AddBuildingPageContainer>
  );
};

export default AddBuildingPage;
