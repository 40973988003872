import ApartmentsOverviewComponent from "components/ApartmentsOverviewComponent/ApartmentsOverviewComponent";
import Navbar from "components/shared/Navbar/Navbar";
import TabsComponent from "components/shared/Tab/TabsComponent";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  AccountNumberContainer,
  AccountNumberTitle,
  BuildingDetailsPageContainer,
  ContentHeader,
  ContentItemContainer,
  ContentSubHeader,
  HeaderContainer,
  TopHeaderButtonsContainer,
  TopHeaderContainer,
  TopHeaderContentContainer,
  TopHeaderSubTitle,
  TopHeaderTextContainer,
  TopHeaderTitle,
} from "./BuildingDetailsPage.styled";
import { PrimaryButton } from "components/shared/Buttons/Buttons.styled";
import EditIcon from "components/Icon/Icons/EditIcon";
import ThreeDotsHorizontal from "components/Icon/Icons/ThreeDotsHorizontalIcon";
import AccountNumberIcon from "components/Icon/Icons/AccountNumberIcon";
import { useBuildingDetailsPageInformationsQuery } from "store/buildingsApiSlice";
import BuildingBillsOverviewComponent from "components/BuildingBillsOverviewComponent/BuildingBillsOverviewComponent";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import BackLink from "components/shared/BackLink/BackLink";
import { useTranslation } from "react-i18next";

const BuildingsDetailsPage = () => {
  const matches = useMediaQuery("(max-width:768px)");
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useBuildingDetailsPageInformationsQuery(id);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleDialogToggle = (event, index) => {
    event.stopPropagation();
    if (selectedOption === index) {
      setSelectedOption(null);
    } else {
      setSelectedOption(index);
    }
  };

  const tabs = [
    {
      label: "Stanovi",
      component: (
        <ApartmentsOverviewComponent
          selectedOption={selectedOption}
          handleDialogToggle={handleDialogToggle}
          apartments={data === undefined ? [] : data.building.apartments}
        />
      ),
    },
    {
      label: "Računi",
      component: (
        <BuildingBillsOverviewComponent
          bills={data === undefined ? [] : data.bills}
        />
      ),
    },
  ];

  const calculateNumberOfTenants = () => {
    return data && data?.building.apartments.reduce(
      (sum, item) => sum + item.numOfTenants,
      0
    );
  };

  return isLoading ? (
    <p>Loading...</p>
  ) : (
    <BuildingDetailsPageContainer onClick={() => setSelectedOption(null)}>
      <Navbar />
      <HeaderContainer>
        {matches && (
          <BackLink
            style={{ marginLeft: "16px", marginBottom: "20px" }}
            to={"/buildings"}
          >
            {t("forgotPassword.backLink")}
          </BackLink>
        )}
        <TopHeaderContainer>
          <TopHeaderTextContainer>
            <TopHeaderTitle>{data.building.address}</TopHeaderTitle>
            <TopHeaderSubTitle>{data.building.info}</TopHeaderSubTitle>
          </TopHeaderTextContainer>
          <TopHeaderButtonsContainer>
            <PrimaryButton style={{ marginRight: 12 }}>
              <ThreeDotsHorizontal />
            </PrimaryButton>
            <PrimaryButton onClick={() => navigate(`/add-building/${id}`)}>
              <EditIcon />
              {matches ? "" : "Izmeni Zgradu"}
            </PrimaryButton>
          </TopHeaderButtonsContainer>
        </TopHeaderContainer>

        <TopHeaderContentContainer>
          <ContentItemContainer>
            <ContentHeader>Grad</ContentHeader>
            <ContentSubHeader>{data.building.city}</ContentSubHeader>
          </ContentItemContainer>
          <ContentItemContainer>
            <ContentHeader>Broj Stanara</ContentHeader>
            <ContentSubHeader>{calculateNumberOfTenants()}</ContentSubHeader>
          </ContentItemContainer>
          <ContentItemContainer>
            <ContentHeader>Broj Stanova</ContentHeader>
            <ContentSubHeader>{data.building.numOfApartments}</ContentSubHeader>
          </ContentItemContainer>
          <ContentItemContainer>
            <ContentHeader>Br. Računa</ContentHeader>
            <AccountNumberContainer>
              <AccountNumberTitle>
                {data.building.currentAccount}
              </AccountNumberTitle>
              <AccountNumberIcon style={{ marginLeft: "13px" }} />
            </AccountNumberContainer>
          </ContentItemContainer>
        </TopHeaderContentContainer>

        <TabsComponent tabs={tabs} />
      </HeaderContainer>
    </BuildingDetailsPageContainer>
  );
};

export default BuildingsDetailsPage;
