import React from "react";
import { ReactComponent as CloseIconSvg } from "../../../assets/Icons/close.svg";

const CloseIcon = (props) => {
  return <CloseIconSvg {...props} />;
};

CloseIcon.propTypes = {};

export default CloseIcon;
