import styled from 'styled-components';
import selectedTheme from 'themes';

export const RentImagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

export const ImagesWrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    height: auto;
`

export const PrimaryImageWrapper = styled.div`
    width: 100%;
    height: auto;
    min-height: 294px;
    position: relative;
`

export const StarIcon = styled.div`position: relative;
width: 12px;
height: 12px;
overflow: hidden;
flex-shrink: 0;
`;
export const BadgeText = styled.div`position: relative;
line-height: 18px;
font-weight: 500;
`;
export const BadgeRoot = styled.div`position: relative;
border-radius: 16px;
border: 2px solid ${selectedTheme.colors.primary[600]};
box-sizing: border-box;
width: max-content;
display: flex;
flex-direction: row;
padding: 12px 8px 12px 8px;
align-items: center;
justify-content: flex-start;
gap: 4px;
text-align: center;
font-size:${selectedTheme.type.textXs.medium.fontSize};
background-color: ${selectedTheme.colors.base.white};
color: ${selectedTheme.colors.primary[600]};
font-family: ${selectedTheme.type.textXs.medium.fontFamily};
position: absolute;
left: 20px;
top: 20px;
`;


export const BlurButtonsWrapper = styled.div`
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 60px;

`

export const BlurIconWrapper = styled.button`position: relative;
padding: 10px;
height: auto;
width: auto;
display: flex;
cursor: pointer;
border: 1px solid ${selectedTheme.colors.base.white};
border-radius: 8px;
box-shadow: ${selectedTheme.effects.shadow.xs.boxShadow};
mix-blend-mode: multiply;
overflow: hidden;
flex-shrink: 0;
background-color: ${props => props.danger ? "red" : selectedTheme.colors.primary[600] };
`;
export const BlurButtonWrapper = styled.div`position: relative;
border-radius: 8px;
background-color: rgba(255, 255, 255, 0.3);
box-shadow: var(--shadow-xs);
border: 1px solid rgba(208, 213, 221, 0.5);
box-sizing: border-box;
width: 100%;
overflow: hidden;
flex-shrink: 0;
display: flex;
flex-direction: row;
padding: 10px;
align-items: center;
justify-content: center;
`;

export const ImagesGrid = styled.div`
display: grid; 
  grid-template-columns: 1fr 1fr; 
  gap: 20px 20px; 
  grid-template-areas: 
    ". . ."
    ". . ."; 
`
export const PrimaryImage = styled.img`
    background: ${props => `url("${props?.src}")`};
    max-width:100%;

`

export const GridItem = styled.div`
    position: relative;
`
export const GridImage = styled.img`
    background: ${props => `url("${props?.src}")`};
    max-width:100%;
`

export const RentUploadImagesWrapper = styled.div`
    color: ${props => props?.isDraging ? "red" : null}

`
