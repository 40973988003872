import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as AddIconSvg } from "../../../assets/Icons/add.svg";

const AddIcon = (props) => {
  return <AddIconSvg {...props} />;
};

AddIcon.propTypes = {};

export default AddIcon;
