import { addApartmentInitialConstants } from "initialValues/addApartmentInitialValues";
import * as Yup from "yup";

export default Yup.object().shape({
  [addApartmentInitialConstants.firstName]: Yup.string().required(
    "Ime je obavezno polje"
  ),
  [addApartmentInitialConstants.lastName]: Yup.string().required(
    "Prezime je obavezno polje"
  ),
  [addApartmentInitialConstants.squareFootage]: Yup.string().required(
    "Kvadratura je obavezno polje"
  ),
  [addApartmentInitialConstants.numOfApartment]: Yup.string().required(
    "Broj stana je obavezno polje"
  ),
  [addApartmentInitialConstants.numOfTenants]: Yup.string().required(
    "Broj stanara je obavezno polje"
  ),
  [addApartmentInitialConstants.currentState]: Yup.string().required(
    "Trenutno stanje je obavezno polje"
  ),
  [addApartmentInitialConstants.email]: Yup.string().notRequired().matches(
    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
    "Uneli ste neispravan email"
  ),
});
