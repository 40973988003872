import { primaryThemeColors } from "./primaryThemeColors";
import primaryThemeFonts from "./primaryThemeFonts";
import { primaryTypography } from "./primaryTypography";
import primaryEffectStyles from "./primaryEffectStyles";

const primary = {
  colors: primaryThemeColors,
  fonts: primaryThemeFonts,
  type: primaryTypography,
  effects: primaryEffectStyles,
};

export default primary;
