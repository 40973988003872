import * as Yup from "yup";
import i18n from "i18next";
import { registerFormikConstants } from "initialValues/registerInitialValues";

export default Yup.object().shape({
  [registerFormikConstants.companyName]: Yup.string().required(
    i18n.t("register.companyNameErrorRequired")
  ),
  [registerFormikConstants.companyAddress]: Yup.string().required(
    i18n.t("register.companyAddressErrorRequired")
  ),
  // [registerFormikConstants.companyCity]: Yup.string()
  // .required(i18n.t("register.companyCityErrorRequired")),
  [registerFormikConstants.email]: Yup.string()
    .required(i18n.t("register.emailErrorRequired"))
    .email(i18n.t("register.emailErrorInvalid")),
  [registerFormikConstants.password]: Yup.string()
    .required(i18n.t("register.passwordErrorRequired"))
    .min(7, i18n.t("register.passwordErrorMinLength")),
  [registerFormikConstants.pib]: Yup.string()
    .required(i18n.t("register.pibErrorRequired"))
    .length(9, i18n.t("register.pibErrorValidLength")),
  [registerFormikConstants.identificationNumber]: Yup.string()
    .required(i18n.t("register.identificationNumberErrorRequired"))
    .length(8, i18n.t("register.identificationNumberErrorValidLength")),
  [registerFormikConstants.phoneNumber]: Yup.string()
    .required(i18n.t("register.phoneNumberErrorRequired")),
});
