import React from "react";
import PropTypes from "prop-types";
import { CostFieldContainer } from "./CostField.styled";

const CostField = ({ Field }) => {
  return (
    <>
      <CostFieldContainer>RSD</CostFieldContainer>
      {Field}
    </>
  );
};

CostField.propTypes = {
  Field: PropTypes.any,
};

export default CostField;
