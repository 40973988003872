export const registerFormikConstants = {
  companyName: "companyName",
  email: "email",
  password: "password",
  confirmPassword: "confirmPassword",
  pib: "pib",
  identificationNumber: "identificationNumber",
  companyAddress: "companyAddress",
  cityId: "cityId",
  phoneNumber: "phoneNumber",
};

export default {
  [registerFormikConstants.companyName]: "",
  [registerFormikConstants.email]: "",
  [registerFormikConstants.password]: "",
  [registerFormikConstants.confirmPassword]: "",
  [registerFormikConstants.pib]: "",
  [registerFormikConstants.identificationNumber]: "",
  [registerFormikConstants.companyAddress]: "",
  [registerFormikConstants.cityId]: 1,
  [registerFormikConstants.phoneNumber]: "",
};
