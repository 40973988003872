import React, { useState } from "react";
import {
  FilterContainer,
  HistoryOverviewContainer,
} from "./HistoryOverviewComponent.styled";
//import { PrimaryButton } from "components/shared/Buttons/Buttons.styled";
//import FilterIcon from "components/Icon/Icons/FilterIcon";
import SearchInputField from "components/shared/SearhInputField/SearchInputField";
import ApartmentHistoryTable from "components/ApartmentHistoryTable/ApartmentHistoryTable";
import selectedTheme from "themes";
import PropTypes from "prop-types";
import { useRevertApartmentDetailsMutation } from "store/apartmentsApiSlice";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import AlertDialog from "components/shared/Dialogs/AlertDialog";
import DeleteIcon from "components/Icon/Icons/DeleteIcon";

const HistoryOverviewComponent = ({ history }) => {
  const [open, setOpen] = useState(false);
  const [revertRowItem, setRevertRowItem] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [revertApartmentDetails] = useRevertApartmentDetailsMutation();
  
  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleClose = () => {
    setRevertRowItem({});
    setOpen(false);
  };

  const handleOpen = (event, row) => {
    event.stopPropagation();
    setRevertRowItem(row);
    setOpen(true);
  };

  const handleRevert = async () => {
    try {
      await revertApartmentDetails(revertRowItem).unwrap();
      makeToastMessage("success");
      setOpen(false);
      setRevertRowItem({})
    } catch (err) {
      makeErrorToastMessage(err.error);
      setOpen(false);
    }
  };

  const applyFiltering = () => {
    return history?.filter((item) => {
      // Apply filtering on all fields that you want to search against.
      return (
        // item.previousValue.toLowerCase().includes(searchValue.toLowerCase()) ||
        // item.currentValue.includes(searchValue) ||
        item.propName.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.date.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
  };

  return history == null ? (
    <p>Loading...</p>
  ) : (
    <>
      <AlertDialog
        open={open}
        handleClose={handleClose}
        handleSubmit={handleRevert}
        title="Da li ste sigurni da želite vratiti predhodnu vrednost?"
        content="Da li ste sigurni da želite vratiti predhodnu vrednost? Ova funkcija je iverzebilna"
        actionName="VRATI"
        Icon={<DeleteIcon />}
      />
      <HistoryOverviewContainer>
        <FilterContainer>
          {/* <PrimaryButton>
            <FilterIcon />
            Filter
          </PrimaryButton> */}
          <SearchInputField
            searchValue={searchValue}
            handleInputChange={handleInputChange}
          />
        </FilterContainer>
        <ApartmentHistoryTable
          style={{
            borderRadius: "4px",
            border: `1px solid ${selectedTheme.colors.gray[200]}`,
            boxShadow: `${selectedTheme.effects.shadow.sm.boxShadow}`,
          }}
          rows={applyFiltering()}
          handleOpen={handleOpen}
        />
      </HistoryOverviewContainer>
    </>
  );
};

HistoryOverviewComponent.propTypes = {
  history: PropTypes.array,
};

export default HistoryOverviewComponent;
