import { Box, Typography} from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const NavbarContainer = styled(Box)`
  border: 1px solid ${selectedTheme.colors.gray[800]};
  box-shadow: ${selectedTheme.effects.shadow.sm.boxShadow};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${selectedTheme.colors.gray[800]};
  transition: all 0.5s;
  min-height: 100%;
  position: sticky;
  overflow: hidden;
  @media (max-width: 768px) {
    min-height: unset;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }
`;

export const StickyContainer = styled(Box)`
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 32px;
  padding-bottom: 32px;
  @media (max-width: 768px) {
    position: static;
    flex-direction: row;
    height: auto;
    align-items: center;
    padding: 16px;
  }
`

export const HeaderContainer = styled(Box)`
  display: flex;
`;

export const TopContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const HeaderTitle = styled(Typography)`
  font-family: ${selectedTheme.type.textXl.semibold.fontFamily};
  font-size: ${selectedTheme.type.textXl.semibold.fontSize};
  font-weight: ${selectedTheme.type.textXl.semibold.fontWeight};
  font-style: ${selectedTheme.type.textXl.semibold.fontStyle};
  margin-left: 11px;
  color: ${selectedTheme.colors.primary[25]}
`;

export const NavbarItemContainer = styled(Box)`
  display: flex;
  padding: 8px 12px;
  align-items: center;
  margin-bottom: 4px;
  cursor: pointer;
  border-radius: 6px;
  &:hover {
    background-color: ${selectedTheme.colors.gray[700]};
  }

`;

export const NavbarItem = styled(Typography)`
  font-family: ${selectedTheme.type.textMd.semibold.fontFamily};
  font-size: ${selectedTheme.type.textMd.semibold.fontSize};
  font-weight: ${selectedTheme.type.textMd.semibold.fontWeight};
  font-style: ${selectedTheme.type.textMd.semibold.fontStyle};
  margin-left: 14px;
  color: ${selectedTheme.colors.primary[100]};
  &:hover {
    color: ${selectedTheme.colors.primary[200]};
  }
`;

export const BottomContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const BottomRowItem = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ProfileTitle = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.semibold.fontFamily};
  font-size: ${selectedTheme.type.textSm.semibold.fontSize};
  font-weight: ${selectedTheme.type.textSm.semibold.fontWeight};
  font-style: ${selectedTheme.type.textSm.semibold.fontStyle};
  color: ${selectedTheme.colors.primary[100]};
`;

export const ProfileSubTitle = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.semibold.fontFamily};
  font-size: ${selectedTheme.type.textSm.semibold.fontSize};
  font-weight: ${selectedTheme.type.textSm.regular.fontWeight};
  font-style: ${selectedTheme.type.textSm.semibold.fontStyle};
  color: ${selectedTheme.colors.primary[200]};
`;

export const ProfileContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

export const EmailContainer = styled(Box)`
  margin-left: 12px;
`;

export const MenuDialogContainer = styled(Box)`
  height: 100%;
  display: flex;
  justify-content: space-between;
  //background-color: ${selectedTheme.colors.base.white};
  
`
export const MultiplyContainer = styled(Box)`
  padding: 16px 8px 16px 16px;
  background-color: #344054;
  opacity: 0.8;
`

export const IconContainer = styled(Box)`

`

export const ContentContainer = styled(Box)`
padding: 32px 16px;
width: 100%;
display: flex;
justify-content: space-between;
flex-direction: column;
background-color: ${selectedTheme.colors.base.white};

`

export const MenuDialog = styled(Box)`
  display: block;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;

  z-index: 1000;
`
