import { Box, Typography } from "@mui/material";
import selectedTheme from "../../themes";
import styled from "styled-components";

export const ResetPasswordFormContainer = styled(Box)`
    display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 32px;
  gap: 32px;
  width: fit-content;
  flex-grow: 1;
`

export const ResetPasswordContentContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`

export const ResetPasswordFormWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-grow: 1;
`

export const HeaderWithIconContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;
`

export const HeaderIconContainer=styled(Box)`
    height: 56px;
    width: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${selectedTheme.colors.primary[100]};
    border: 10px solid ${selectedTheme.colors.primary[50]};
`

export const FormHeader = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;
  gap: 12px;
  width: 100%;
`;

export const FormTitle = styled(Typography)`
  //template
  font-family: ${selectedTheme.type.displayMd.semibold.fontFamily};
  font-size: ${selectedTheme.type.displayMd.semibold.fontSize};
  font-weight: ${selectedTheme.type.displayMd.semibold.fontWeight};
  font-style: ${selectedTheme.type.displayMd.semibold.fontStyle};
  line-height: ${selectedTheme.type.displayMd.semibold.lineHeight};
  letter-spacing: ${selectedTheme.type.displayMd.semibold.letterSpacing};
  //
  color: ${selectedTheme.colors.gray[900]};
`;
export const FormSubtitle = styled(Typography)`
  //template
  font-family: ${selectedTheme.type.textSm.regular.fontFamily};
  font-size: ${selectedTheme.type.textSm.regular.fontSize};
  font-weight: ${selectedTheme.type.textSm.regular.fontWeight};
  font-style: ${selectedTheme.type.textSm.regular.fontStyle};
  line-height: ${selectedTheme.type.textSm.regular.lineHeight};
  //
  color: ${selectedTheme.colors.gray[600]};
  text-align: center;
`;

export const FormSubtitleBoldText = styled(Typography)`
    //template
    font-family: ${selectedTheme.type.textSm.medium.fontFamily};
  font-size: ${selectedTheme.type.textSm.medium.fontSize};
  font-weight: ${selectedTheme.type.textSm.medium.fontWeight};
  font-style: ${selectedTheme.type.textSm.medium.fontStyle};
  line-height: ${selectedTheme.type.textSm.medium.lineHeight};
  //
  color: ${selectedTheme.colors.gray[600]};
`


export const ResetEmailLinkContent = styled(Box)`
    
`

export const LinkContainer = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`