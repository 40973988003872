import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as TrashIconSvg } from "../../../assets/Icons/trash-03.svg";

const TrashIcon = (props) => {
  return <TrashIconSvg {...props} />;
};

TrashIcon.propTypes = {};

export default TrashIcon;
