import React, { useState } from 'react'
import { ResetPasswordContentContainer, ResetPasswordFormContainer, ResetPasswordFormWrapper, FormSubtitleBoldText, LinkContainer} from './ResetPasswordContent.styled'
import AuthHeader from 'components/shared/Auth/AuthHeader/AuthHeader'
import { CenteredRow, Column, FormHeader, FormSubtitle, FormTitle, HeaderIconContainer, HeaderIconContainerGreen, HeaderWithIconContainer, LoginButton, RegisterLink } from 'components/Register/RegisterForm/RegisterForm.styled'
import { useFormik } from 'formik'
import forgotPasswordValidation from 'validations/forgotPasswordValidation'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Key } from "../../assets/Icons/key-01.svg";
import { ReactComponent as Tick } from "../../assets/Icons/check-circle.svg";
//import BackLink from 'components/shared/BackLink/BackLink'
import AuthFooter from 'components/shared/Auth/AuthFooter/AuthFooter'
import NewPasswordField from './ResetPasswordFields/NewPasswordField'
import ConfirmPasswordField from './ResetPasswordFields/ConfirmPasswordField'
import BackLink from 'components/shared/BackLink/BackLink'
import { useNavigate } from 'react-router-dom'


const ResetPasswordContent = () => {

    const { t } = useTranslation();
    const [validPassword, setValidPassword] = useState(false);
    const navigate = useNavigate();
  
    const handleSubmit = () => {
      setValidPassword(true);
    };

    const forwardToLogin = () => {
      navigate('/login');
    }
  
    const formik = useFormik({
      initialValues: forgotPasswordValidation,
      validationSchema: forgotPasswordValidation,
      onSubmit: handleSubmit,
      validateOnBlur: true,
      enableReinitialize: true,
    });
    
  return (
    <ResetPasswordContentContainer>
         <AuthHeader />
      {!validPassword ? (
        <ResetPasswordFormWrapper>
          <ResetPasswordFormContainer
            component="form"
            onSubmit={formik.handleSubmit}
          >
            <HeaderWithIconContainer>
              <HeaderIconContainer>
                <Key />
              </HeaderIconContainer>
              <FormHeader>
                <FormTitle>{t("resetPassword.formTitle")}</FormTitle>
                <FormSubtitle>{t("resetPassword.formSubtitle")}</FormSubtitle>
              </FormHeader>
            </HeaderWithIconContainer>
            <Column gap={24} w={100}>

              <NewPasswordField formik={formik} apiError={false} />
              <ConfirmPasswordField formik={formik} apiError={false} />
              <LoginButton component="button" type="submit" onClick={() => handleSubmit()}>
                {t("resetPassword.resetButton")}
              </LoginButton>
            </Column>
            <LinkContainer>
              <BackLink to={"/login"}>{t("resetPassword.backLink")}</BackLink>
            </LinkContainer>
          </ResetPasswordFormContainer>
        </ResetPasswordFormWrapper>
      ) : (
        <ResetPasswordFormWrapper>
          <ResetPasswordFormContainer>
            <HeaderWithIconContainer>
              <HeaderIconContainerGreen>
              <Tick />
              </HeaderIconContainerGreen>
              <FormHeader>
                <FormTitle>{t("resetPassword.confirm.title")}</FormTitle>
                <FormSubtitle>
                  {t("resetPassword.confirm.subtitle")}
                  &nbsp;
                  <FormSubtitleBoldText>
                    {formik.values.email}
                  </FormSubtitleBoldText>
                </FormSubtitle>
              </FormHeader>
            </HeaderWithIconContainer>
            <LoginButton component="button" type="button" onClick={() => forwardToLogin()}>
              {t("resetPassword.confirm.continueButton")}
            </LoginButton>

            <CenteredRow gap={4}>
        <RegisterLink to="">{t("resetPassword.confirm.loginButton")}</RegisterLink>
      </CenteredRow>
          </ResetPasswordFormContainer>
        </ResetPasswordFormWrapper>
      )}

      <AuthFooter />
    </ResetPasswordContentContainer>
   
  )
}


export default ResetPasswordContent