import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const BillDetailsPageContainer = styled(Box)`
  display: flex;
  background-color: ${selectedTheme.colors.gray[25]};
  width: 100%;
  min-height:100vh;
  height: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 48px;
  }
`;

export const BillDetailsPageSubContainer = styled(Box)`
  padding: 32px;
  width: 100%;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const NavigationContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

export const NavigationText = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.medium.fontFamily};
  font-size: ${selectedTheme.type.textSm.medium.fontSize};
  font-weight: ${selectedTheme.type.textSm.medium.fontWeight};
  font-style: ${selectedTheme.type.textSm.medium.fontStyle};
  line-height: ${selectedTheme.type.textSm.medium.lineHeight};
  color: ${selectedTheme.colors.gray[600]};
  margin-left: 8px;
  cursor: pointer;
`;

export const TitleText = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.semibold.fontFamily};
  font-size: 30px;
  font-weight: ${selectedTheme.type.textSm.semibold.fontWeight};
  font-style: ${selectedTheme.type.textSm.semibold.fontStyle};
  line-height: ${selectedTheme.type.textSm.semibold.lineHeight};
  color: ${selectedTheme.colors.gray[900]};
  margin-top: 20px;
  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const TopContainer = styled(Box)`
  display: flex;
  margin-top: 32px;
  justify-content: space-between;
  @media (max-width: 768px) {
    margin-top: 24px;
    flex-direction: column-reverse;
    padding: 0 16px;
    width: 100%;
  }
`;

export const FilterContainer = styled(Box)`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: row-reverse;
    width: 100%;
  }
`;
