export const settingsInitialConstants = {
  pib: "pib",
  identificationNumber: "identificationNumber",
  percentage: "percentage",
  minimumAmount: "minimumAmount",
};

export default {
  [settingsInitialConstants.pib]: "",
  [settingsInitialConstants.identificationNumber]: "",
  [settingsInitialConstants.percentage]: 0,
  [settingsInitialConstants.minimumAmount]: 0,
};
