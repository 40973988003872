import {
    Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
  } from "@mui/material";
  import styled from "styled-components";
  import selectedTheme from "../../themes";
  
  export const PrimaryTableContainer = styled(Box)`
    width: 100%;
    background-color: ${selectedTheme.colors.base.white};
    border-radius: 6px;
  `;
  
  export const MainTable = styled(Table)`
    width: 100%;
    border-radius: 6px;
  `;
  
  export const MainTableHead = styled(TableHead)`
    text-align: left;
    background-color: ${selectedTheme.colors.gray[50]};
  `;
  
  export const MainTableRow = styled(TableRow)`
    padding: 0px !important;
  `;
  
  export const MainTableRow3 = styled(TableCell)`
    font-family: ${selectedTheme.type.textXs.medium.fontFamily};
    font-size: 16px;
    font-style: ${selectedTheme.type.textXs.medium.fontStyle};
    font-weight: ${selectedTheme.type.textXs.medium.fontWeight};
    color: ${selectedTheme.colors.gray[600]};
    padding-left: 16px;
  `;
  
  export const MainTableCell = styled(TableCell)`
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-family: ${selectedTheme.type.textXs.medium.fontFamily};
    font-size: 16px;
    font-style: ${selectedTheme.type.textXs.medium.fontStyle};
    font-weight: ${selectedTheme.type.textXs.medium.fontWeight};
    color: ${selectedTheme.colors.gray[600]};
  `;
  
  export const IconsContainer = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
  `;
  
  export const MainTableBody = styled(TableBody)``;
  
  export const Pagination = styled(Typography)``;
  
  export const PaginationButtonsContainer = styled(Typography)``;
  
  export const HistoryChangeContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
  `;
  
  export const UserMainContainer = styled(Box)``;
  
  export const NameContainer = styled(Box)`
    display: flex;
  `;
  
  export const HistoryChangeFieldName = styled(Typography)`
    font-family: ${selectedTheme.type.textSm.medium.fontFamily};
    font-size: ${selectedTheme.type.textSm.medium.fontSize};
    font-style: ${selectedTheme.type.textSm.medium.fontStyle};
    font-weight: ${selectedTheme.type.textSm.medium.fontWeight};
    color: ${selectedTheme.colors.gray[900]};
  `;

  export const HistoryChangeValuesRow = styled(Box)`
    display: flex;
    flex-direction: row;
  `;

  export const ChangeValue = styled(Typography)`
    font-family: ${selectedTheme.type.textSm.regular.fontFamily};
    font-size: ${selectedTheme.type.textSm.regular.fontSize};
    font-style: ${selectedTheme.type.textSm.regular.fontStyle};
    font-weight: ${selectedTheme.type.textSm.regular.fontWeight};
    color: ${selectedTheme.colors.gray[600]};
  `;

  export const HistoryChangeDate = styled(Typography)`
    font-family: ${selectedTheme.type.textSm.regular.fontFamily};
    font-size: ${selectedTheme.type.textSm.regular.fontSize};
    font-style: ${selectedTheme.type.textSm.regular.fontStyle};
    font-weight: ${selectedTheme.type.textSm.regular.fontWeight};
    color: ${selectedTheme.colors.gray[900]};
  `;