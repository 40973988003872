import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { WidgetFooter } from "../../Widgets/Widgets.styled";
import { PrimaryButton } from "../../Buttons/Buttons.styled";
import {
  ButtonsContainer,
  DialogContainer,
  DialogContentContainer,
  DialogHeaderContainer,
  DialogMainText,
  DialogRowText,
  DialogSubContainer,
  DialogSubText,
  DialogTable,
  DialogTableContent,
  DialogTableHeader,
  DialogTableRow,
  DialogTopContainer,
  HeaderText,
  MainTable,
  MainTableBody,
  MainTableCell,
  MainTableHead,
  MainTableRow,
  MainTableRow2,
  MainTableRow3,
  Pagination,
  PaginationButtonsContainer,
  PrimaryTableContainer,
} from "./FinancesTable.styled";
// import BinIcon from "components/Icon/Icons/BinIcon";
// import EditIcon from "components/Icon/Icons/EditIcon";
// import ThreeDotsHorizontal from "components/Icon/Icons/ThreeDotsHorizontalIcon";
import { Checkbox, FormControlLabel } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import MailIcon from "components/Icon/Icons/MailIcon";
// import DownloadIcon from "components/Icon/Icons/DownloadIcon";
import CloseIcon from "components/Icon/Icons/CloseIcon";
import MailIconBlue from "components/Icon/Icons/MailIconBlue";
import { getMonth } from "util/dateHelpers";
import { useSendCheckedBillsMutation } from "store/billsApiSlice";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import ArrowDownIcon2 from "components/Icon/Icons/ArrowDownIcon2";
import { useMediaQuery } from "@mui/material";
import { ReactComponent as BackArrow } from "../../../../assets/Icons/arrow-left.svg";
import { ReactComponent as NextArrow } from "../../../../assets/Icons/arrow-right.svg";
import selectedTheme from './../../../../themes/index';

// data is an array of arrays[[1,"Sokobanja",4]...], columns an array of strings where each string represent column,
//first elements of columns array must to be id and first element of each array in data array need to be an id of element
//isThereImage is true when we want to represent image in our table (image need to be the second column, after ID)F
const FinancesTable = ({
  data,
  columns,
  pageSize,
  handleRowClick,
  style,
  isBuildingsTable = false,
  isBuildingDetailsTable = false,
  checkbox,
  includeNavigation = true,
}) => {
  const matches = useMediaQuery("(max-width:768px)");
  const [checked, setChecked] = useState([]);
  const [checkAll, setCheckAll] = useState(0);
  const [open, setOpen] = useState(false); // dialog for sending bills
  const [billsToShow, setBillsToShow] = useState([]);
  const [selectedBills, setSelectedBills] = useState([]);
  const [selectedApartment, setSelectedApartment] = useState(null);
  const [sendBills] = useSendCheckedBillsMutation();
  const [sortedDescending, setSortedDescending] = useState(true);

  // const handleOpen = (id) => {
  //   setSelectedApartment(id);
  //   const s = data.filter((k) => k[0] === id);
  //   setBillsToShow([...s[0][6]].sort((a, b) => b.month - a.month));
  //   const k = [];
  //   for (let i = 0; i < s[0][6].length; i++) {
  //     k.push(false);
  //   }
  //   setSelectedBills(k);
  //   setOpen(true);
  // };
  const handleClose = () => {
    setOpen(false);
    setBillsToShow([]);
    setSelectedBills([]);
    setSelectedApartment(null);
    setSortedDescending(true);
  };

  React.useEffect(() => {
    setChecked(Array(2).fill(false));
  }, []);

  React.useEffect(() => {
    if (checked.every((x) => x === true)) setCheckAll(0);
    else if (checked.some((x) => x === true)) setCheckAll(1);
    else setCheckAll(2);
  }, [checked]);

  // React.useEffect(() => {
  //   if (checkAll === 2) {
  //     setChecked(Array(2).fill(true))
  //   } else if (checkAll == 0) {
  //     setChecked(Array(2).fill(false))
  //   }
  // }, [checkAll]);

  const handleCheckAll = () => {
    let newArray = [];
    if (checkAll === 2) {
      newArray = Array(2).fill(true);
      //setCheckAll(0);
      setChecked(newArray);
    }
    if (checkAll === 1) {
      newArray = Array(2).fill(true);
      //setCheckAll(0);
      setChecked(newArray);
    }
    if (checkAll === 0) {
      newArray = Array(2).fill(false);
      //setCheckAll(2);
      setChecked(newArray);
    }
  };

  const handleCheckbox = (event, id) => {
    let newArray = [];
    newArray = selectedBills.map((item, index) => {
      if (index === id) {
        return !item;
      } else return item;
    });
    setChecked(newArray);
  };

  //const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage + 1 < Math.ceil(data.length / pageSize)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  // const navigateToEditBuildingPage = (e, buildingId) => {
  //   e.stopPropagation();
  //   navigate("/add-building/" + buildingId);
  // };

  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;
  const currentPageData = data.slice(startIndex, endIndex);


  const handleCheckBill = (event, index) => {
    let newArray = [...selectedBills];
    newArray[index] = !newArray[index];
    setSelectedBills(newArray);
  };

  const handleCheckAllBills = (event) => {
    let newArr = [...selectedBills];
    for (let i = 0; i < newArr.length; i++) {
      if (event.target.checked === true) {
        newArr[i] = true;
      } else {
        newArr[i] = false;
      }
    }
    setSelectedBills(newArr);
  };

  const handleSendBills = () => {
    const billsToSend = [];
    for (let i = 0; i < selectedBills.length; i++) {
      if (selectedBills[i] === true) {
        billsToSend.push(billsToShow[i].billId);
      }
    }
    const m = { apartmentId: selectedApartment, billIds: billsToSend };
    sendBills(m)
      .then(() => {
        makeToastMessage("Uspešno ste poslali zaduženja");
        handleClose();
      })
      .catch(() => makeErrorToastMessage("Niste uspešno poslali zaduženja!"));
  };

  const emptyFunction = () => {};

  const isButtonForSendingBillsDisabled = () => {
    return selectedBills.filter((k) => k === true).length === 0;
  };

  const handleSortBills = () => {
    const k = [...billsToShow];
    sortedDescending === true
      ? k.sort((a, b) => a.month - b.month)
      : k.sort((a, b) => b.month - a.month);
    setBillsToShow(k);
    sortedDescending === true
      ? setSortedDescending(false)
      : setSortedDescending(true);
  };

  return (
    <PrimaryTableContainer style={style}>
      {/* Dialog for sending bills */}
      <DialogContainer open={open} onClose={handleClose}>
        <DialogSubContainer>
          <DialogTopContainer>
            <MailIconBlue />
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
          </DialogTopContainer>
          <DialogHeaderContainer>
            <DialogMainText>Pošalji račune</DialogMainText>
            <DialogSubText>
              Molimo vas izaberite račune po mesecu koji želite da pošaljete
              upravniku zgrade.
            </DialogSubText>
          </DialogHeaderContainer>
          <DialogContentContainer>
            <DialogTable>
              <DialogTableHeader>
                <Checkbox
                  onClick={(event) => handleCheckAllBills(event)}
                  sx={{
                    color: selectedTheme.colors.primary[600],
                  }}
                />
                <HeaderText>Mesec</HeaderText>
                <ArrowDownIcon2
                  style={{
                    marginLeft: "4px",
                    cursor: "pointer",
                    transform:
                      sortedDescending === true
                        ? "rotate(0deg)"
                        : "rotate(180deg)",
                  }}
                  onClick={handleSortBills}
                />
              </DialogTableHeader>
              <DialogTableContent>
                {billsToShow.map((bill, index) => (
                  <DialogTableRow key={index}>
                    <Checkbox
                      sx={{
                        color: selectedTheme.colors.primary[600],
                      }}
                      onClick={(event) => handleCheckBill(event, index)}
                      checked={selectedBills[index]}
                    />
                    <DialogRowText>
                      {getMonth(bill.month)} {bill.year}
                    </DialogRowText>
                  </DialogTableRow>
                ))}
              </DialogTableContent>
            </DialogTable>
          </DialogContentContainer>
          <ButtonsContainer>
            <PrimaryButton style={{ width: "50%" }} onClick={handleClose}>
              Odustani
            </PrimaryButton>
            <PrimaryButton
              style={{
                width: "50%",
                marginLeft: "12px",
                backgroundColor:
                  isButtonForSendingBillsDisabled() === true
                    ? selectedTheme.colors.primary[200]
                    : selectedTheme.colors.primary[600],
                color: selectedTheme.colors.base.white,
              }}
              onClick={handleSendBills}
              disabled={isButtonForSendingBillsDisabled()}
            >
              Pošalji
            </PrimaryButton>
          </ButtonsContainer>
        </DialogSubContainer>
      </DialogContainer>

      <MainTable>
        <MainTableHead>
          <MainTableRow>
            {/* checkbox */}
            {checkbox ? (
              <MainTableCell>
                <FormControlLabel
                  label="Parent"
                  control={
                    <Checkbox
                      // indeterminate={
                      //   selected.length > 0 &&
                      //   selected.length < currentPageData.length
                      // }
                      checked={checkAll === 0}
                      indeterminate={checkAll === 1}
                      onClick={handleCheckAll}
                    />
                  }
                />
              </MainTableCell>
            ) : null}
            {columns
              .filter((k) => k !== "null") // because of table on BuildingDetailsPage
              .slice(1) // because we won't show an id
              .map((column, index) => (
                <MainTableCell key={index}>{column}</MainTableCell>
              ))}
            {isBuildingsTable ? <MainTableCell></MainTableCell> : null}
            {isBuildingDetailsTable ? <MainTableCell></MainTableCell> : null}
          </MainTableRow>
        </MainTableHead>
        <MainTableBody>
          {currentPageData.map((row, index) => {
            return (
              <MainTableRow2
                key={index}
                style={{
                  cursor: handleRowClick === undefined ? "auto" : "pointer",
                }}
              >
                {checkbox ? (
                  <MainTableRow3 key={index}>
                    <Checkbox
                      onClick={(event) => handleCheckbox(event, index)}
                      checked={checked[index]}
                    />
                  </MainTableRow3>
                ) : null}
                {row
                  .filter((k) => !Array.isArray(k)) // because of table on BuildingDetailsPage
                  .slice(1)
                  .map((cell, index) => (
                    <MainTableRow3
                      key={index}
                      onClick={() =>
                        handleRowClick === undefined
                          ? emptyFunction()
                          : handleRowClick(row[0])
                      }
                    >
                      {cell}
                    </MainTableRow3>
                  ))}

                {/* Icons */}

                  {/* <MainTableRow3>
                    <IconsContainer>
                      <BinIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) => handleDelete(event, row[0])}
                      />
                      <EditIcon
                        style={{ marginLeft: "28px", cursor: "pointer" }}
                        onClick={(e) => {
                          navigateToEditBuildingPage(e, row[0]);
                        }}
                      />
                    </IconsContainer>
                  </MainTableRow3> */}

                {/* {isBuildingDetailsTable ? (
                  <MainTableRow3 style={{ position: "relative" }}>
                    <IconsContainer>
                      {selectedOption === index && (
                        <OptionsContainer>
                          <Option onClick={() => handleOpen(row[0])}>
                            <MailIcon />
                            <OptionText>Pošalji račune</OptionText>
                          </Option>
                          <Option onClick={() => getApartmentPayments(row[0])}>
                            <DownloadIcon />
                            <OptionText>Preuzmi uplate</OptionText>
                          </Option>
                        </OptionsContainer>
                      )}
                      <TableIconActionContainer
                        onClick={(event) => handleDialogToggle(event, index)}
                      >
                        <ThreeDotsHorizontal />
                      </TableIconActionContainer>
                      <TableIconActionContainer
                        onClick={() => handleEditClick(row[0])}
                      >
                        <EditIcon />
                      </TableIconActionContainer>
                    </IconsContainer>
                  </MainTableRow3>
                ) : null} */}
              </MainTableRow2>
            );
          })}
        </MainTableBody>
      </MainTable>
      {includeNavigation ? (
        <WidgetFooter
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            fontFamily: selectedTheme.type.textSm.regular.fontFamily,
            fontSize: selectedTheme.type.textSm.medium.fontSize,
            fontStyle: selectedTheme.type.textSm.medium.fontStyle,
            fontWeight: selectedTheme.type.textSm.medium.fontWeight,
          }}
        >
          {!matches && (
            <>
              <Pagination>
                Strana {currentPage + 1} od{" "}
                {data.length === 0 ? 1 : Math.ceil(data.length / pageSize)}
              </Pagination>
              <PaginationButtonsContainer>
                <PrimaryButton
                  onClick={handlePreviousPage}
                  style={{ marginRight: 28 }}
                >
                  Prethodna
                </PrimaryButton>
                <PrimaryButton onClick={handleNextPage}>Sledeća</PrimaryButton>
              </PaginationButtonsContainer>
            </>
          )}
          {matches && (
            <>
              <PrimaryButton
                style={{ padding: "8px" }}
                onClick={handlePreviousPage}
              >
                <BackArrow />
              </PrimaryButton>
              <Pagination>
                Strana {currentPage + 1} od{" "}
                {data.length === 0 ? 1 : Math.ceil(data.length / pageSize)}
              </Pagination>
              <PrimaryButton
                style={{ padding: "8px" }}
                onClick={handleNextPage}
              >
                <NextArrow />
              </PrimaryButton>
            </>
          )}
        </WidgetFooter>
      ) : null}
    </PrimaryTableContainer>
  );
};

FinancesTable.propTypes = {
  checkbox: PropTypes.bool,
  data: PropTypes.array,
  columns: PropTypes.arrayOf(PropTypes.string),
  pageSize: PropTypes.number,
  handleRowClick: PropTypes.func,
  handleEditClick: PropTypes.func,
  style: PropTypes.object,
  isBuildingsTable: PropTypes.bool,
  isBuildingDetailsTable: PropTypes.bool,
  handleDelete: PropTypes.func,
  includeNavigation: PropTypes.bool,
  getApartmentPayments: PropTypes?.func,
  passedBuildingId: PropTypes.number,
  handleDialogToggle: PropTypes?.func,
  selectedOption: PropTypes?.any,
};

export default FinancesTable;
