import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const GeneralSettingsComponentContainer = styled(Box)`
  margin-top: 40px;
  padding-bottom: 40px;
  @media (max-width: 768px) {
    margin-top: 0;
    padding: 16px;
  }

`;

export const MainContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;

export const MainText = styled(Typography)`
  font-family: ${selectedTheme.type.displaySm.medium.fontFamily};
  font-size: 14px;
  font-style: ${selectedTheme.type.displaySm.medium.fontStyle};
  font-weight: ${selectedTheme.type.displaySm.medium.fontWeight};
  color: ${selectedTheme.colors.gray[700]};
  @media (max-width: 768px) {
    font-family: ${selectedTheme.type.textLg.semibold.fontFamily};
  font-size: ${selectedTheme.type.textLg.semibold.fontSize};
  font-style: ${selectedTheme.type.displaySm.medium.fontStyle};
  font-weight: ${selectedTheme.type.textLg.semibold.fontWeight};
  color: ${selectedTheme.colors.gray[900]};
  }
`;

export const InputsContainer = styled(Box)`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const InputContainer = styled(Box)`
  margin-top: 16px;
`;

export const PercentageContainer = styled(Box)`
  position: relative;
`;

export const EmptyContainer = styled(Box)``;

export const BottomContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
`;

export const LogoContainer = styled(Box)`
   @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

export const LogoText = styled(Typography)`
  font-family: ${selectedTheme.type.displaySm.medium.fontFamily};
  font-size: 14px;
  font-style: ${selectedTheme.type.displaySm.medium.fontStyle};
  font-weight: ${selectedTheme.type.displaySm.medium.fontWeight};
  color: ${selectedTheme.colors.gray[700]};
`;

export const LogoSubContainer = styled(Box)`
  display: flex;
  margin-top: 6px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

export const DefaultAgencyLogoContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 200px;
  background-color: ${selectedTheme.colors.gray[100]};
`;

export const DefaultAgencyLogo = styled("img")`
  width: 32px;
  height: 32px;
  border-radius: 5px;
`;
