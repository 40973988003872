import React from "react";
import PropTypes from "prop-types";
import {
  LoginContainer,
} from "./LoginContent.styled";
import LoginForm from "./LoginForm/LoginForm";
import AuthHeader from "components/shared/Auth/AuthHeader/AuthHeader";
import AuthFooter from "components/shared/Auth/AuthFooter/AuthFooter";

const LoginContent = () => {

  return (
    <LoginContainer>
      <AuthHeader />
      <LoginForm />
      <AuthFooter/>
    </LoginContainer>
  );
};

LoginContent.propTypes = {
  children: PropTypes.node,
};

export default LoginContent;
