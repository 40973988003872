import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as LoaderIconSvg } from "../../../assets/Icons/tube-spinner.svg";

const LoaderIcon = (props) => {
  return <LoaderIconSvg {...props} />;
};

LoaderIcon.propTypes = {};

export default LoaderIcon;
