import React from "react";
import { ReactComponent as BinRedIconSvg } from "../../../assets/Icons/binRed.svg";

const BinRedIcon = (props) => {
  return <BinRedIconSvg {...props} />;
};

BinRedIcon.propTypes = {};

export default BinRedIcon;
