import React from "react";
import {
  ImportTenantsContainer,
  ImportTenantsMainText,
  ImportTenantsSubContainer,
  ImportTenantsText,
  TopContainer,
} from "./ConfirmActionModal.styled";
import PropTypes from "prop-types";
import ErrorIcon from "components/Icon/Icons/ErrorIcon";
import CloseIcon from "components/Icon/Icons/CloseIcon";
import { PrimaryButton, SecondaryButton } from "components/shared/Buttons/Buttons.styled";
import { ButtonsContainer } from "components/ApartmentsOverviewComponent/ApartmentsOverviewComponent.styled";

const ConfirmActionModal = ({
  open,
  onClose,
  action
}) => {
  return (
    <ImportTenantsContainer
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "922px",
          },
        },
      }}
    >
      <ImportTenantsSubContainer>
        <TopContainer>
          <ErrorIcon />
          <CloseIcon style={{ cursor: "pointer" }} onClick={onClose} />
        </TopContainer>
        <ImportTenantsMainText>Potvrdite vasu odluku</ImportTenantsMainText>
        <ImportTenantsText>
          Da li ste sigurni da zelite da uradite ovu akciju
        </ImportTenantsText>
        <ButtonsContainer style={{gap:"12px"}}>
        <PrimaryButton
          style={{ width: "100%", marginTop: "42px" }}
          onClick={onClose}
        >
          Odustani
        </PrimaryButton>
        <SecondaryButton
          style={{ width: "100%", marginTop: "42px"}}
          onClick={action}
        >
          Prihvati
        </SecondaryButton>
        </ButtonsContainer>
      </ImportTenantsSubContainer>
    </ImportTenantsContainer>
  );
};

ConfirmActionModal.propTypes = {
  open:PropTypes.bool,
  onClose:PropTypes.func,
  action:PropTypes.func,
};

export default ConfirmActionModal;
