import React from "react";
import PropTypes from "prop-types";
import { IconContainer } from "./Icon.styled";

const Icon = (props) => {
  const containerProps = { ...props };
  delete containerProps.children;
  return <IconContainer {...containerProps}>{props.children}</IconContainer>;
};

Icon.propTypes = {
  children: PropTypes.node,
};

export default Icon;
