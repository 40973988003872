import * as React from "react";
import PropTypes from "prop-types";
import {
  MainTableCell,
  MainTableRow,
  MainTableRow3,
  IconsContainer,
  ChangeValue,
  HistoryChangeContainer,
  HistoryChangeFieldName,
  HistoryChangeValuesRow,
  HistoryChangeDate,
} from "./ApartmenHistoryTable.styled";
import ReverseIcon from "components/Icon/Icons/ReverseIcon";

export const TableRow = (props) => {
  const { row, handleOpen } = props;

  return (
    <>
      <MainTableRow>
        <MainTableCell style={{ padding: 0, width: "80%" }}>
          <HistoryChangeContainer>
            <HistoryChangeFieldName>{row.propName}</HistoryChangeFieldName>
            <HistoryChangeValuesRow>
              <ChangeValue>
                {row.previousValue} {"->"}
              </ChangeValue>
              <ChangeValue> {row.currentValue}</ChangeValue>
            </HistoryChangeValuesRow>
          </HistoryChangeContainer>
        </MainTableCell>
        <MainTableCell>
          <HistoryChangeDate>{row.date}</HistoryChangeDate>
        </MainTableCell>
        <MainTableRow3>
          <IconsContainer>
            <ReverseIcon
              style={{ cursor: "pointer" }}
              onClick={(event) => {
                handleOpen(event, {
                  historyId: row.id,
                  propertyName: row.propertyName,
                });
              }}
            />
          </IconsContainer>
        </MainTableRow3>
      </MainTableRow>
    </>
  );
};

TableRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
    propName: PropTypes.string,
    propertyName: PropTypes.string,
    previousValue: PropTypes.string,
    currentValue: PropTypes.string,
    date: PropTypes.string,
  }),
  handleOpen: PropTypes.func,
};
