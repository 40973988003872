import * as Yup from "yup";
import { addUserInitialConstants } from "initialValues/addUserInitialValues";

export default Yup.object().shape({
  [addUserInitialConstants.firstName]: Yup.string().required(
    "Ime korisnika je obavezno polje!"
  ),
  [addUserInitialConstants.lastName]: Yup.string().required(
    "Prezime korisnika je obavezno polje!"
  ),
  [addUserInitialConstants.email]: Yup.string()
    .required("Email korisnika je obavezno polje!")
    .email("Unesite validnu email adresu"),
  [addUserInitialConstants.password]: Yup.string()
    .required("Lozinka je obavezno polje!")
    .min(7, "Minimalna duzina lozinke je 7 karaktera"),
});
