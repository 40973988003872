import React from "react";
import PropTypes from "prop-types";
import BasicSelect from "components/shared/InputFields/SelectField/BasicSelect";
import financesInitialValues from 'initialValues/financesInitialValues';
import { financesFormikContants } from 'initialValues/financesInitialValues';

const BuildingIdSelect = (props) => {
  const formik = props?.formik;

  return (
    <BasicSelect
      id={financesFormikContants.buildingId}
      name={financesFormikContants.buildingId}
      label="Izaberi zgradu"
      value={formik.values.buildingId}
      onBlur={formik.handleBlur}
      error={formik.touched?.[financesInitialValues.buildingId] && formik.errors?.[financesInitialValues.buildingId]}
      apiError={props?.apiError}
      changeFormikValue={formik.setFieldValue}
      class={props?.class}
      options={props?.options}
      defaultValue={props?.defaultValue}
    />
  );
};

BuildingIdSelect.propTypes = {
  formik: PropTypes.object,
  apiError: PropTypes.any,
  class: PropTypes.string,
  options: PropTypes.array,
  defaultValue: PropTypes.any,
};

export default BuildingIdSelect;
