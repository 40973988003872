import styled from "styled-components";
import { Box, Typography, Link } from "@mui/material";
import selectedTheme from "../../../themes";

export const BackLinkContainer = styled(Link)`
  cursor: pointer;
  display: flex;
  gap: 8px;
  text-decoration: none;
  color: ${selectedTheme.colors.gray[600]};
  border: none;
  box-shadow: none;
  &:hover {
    color: ${selectedTheme.colors.gray[700]};
    background-color: none;
    box-shadow: none;
  }
  &:focus {
    outline: none;
    box-shadow: ${selectedTheme.effects.shadow.xsFocused_4pxPrimary100
      .boxShadow};
    border: 1px solid ${selectedTheme.colors.primary[300]};
    border-radius: 6px;
  }
`;
export const BackLinkIconContainer = styled(Box)`
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BackLinkText = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.semibold.fontFamily};
  font-size: ${selectedTheme.type.textSm.semibold.fontSize};
  font-weight: ${selectedTheme.type.textSm.semibold.fontWeight};
  font-style: ${selectedTheme.type.textSm.semibold.fontStyle};
  line-height: ${selectedTheme.type.textSm.semibold.lineHeight};
  text-decoration: none;
  white-space: nowrap;
`;
