import * as React from "react";
import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";
import CloseIcon from "components/Icon/Icons/CloseIcon";
import {
  ActionButton,
  AlertDialogContainer,
  ButtonsContainer,
  Content,
  Span,
  Title,
  TopContainer,
} from "./AlertDialog.styled";
import { SecondaryButton } from "../Buttons/Buttons.styled";
const AlertDialog = ({
  open,
  handleClose,
  handleSubmit,
  title,
  content,
  Icon,
  actionName,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "400px", // Set your width here
          },
        },
      }}
    >
      <AlertDialogContainer style={{ padding: "24px" }}>
        <TopContainer>
          {Icon === undefined ? null : Icon}
          <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
        </TopContainer>
        <Title>{title}</Title>
        <Content>{content}</Content>
        <ButtonsContainer>
          <ActionButton>
            <Span onClick={handleSubmit}>{actionName}</Span>
          </ActionButton>
          <SecondaryButton
            onClick={handleClose}
            style={{
              marginLeft: "18px",
              width: "50%",
            }}
          >
            Odustani
          </SecondaryButton>
        </ButtonsContainer>
      </AlertDialogContainer>
    </Dialog>
  );
};

AlertDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string,
  Icon: PropTypes.node,
  actionName: PropTypes.string,
};

export default AlertDialog;
