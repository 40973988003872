import React from "react";
import PropTypes from "prop-types";
import { addBuildingInitialConstants } from "initialValues/addBuildingInitialValues";
import BasicSelect from "components/shared/InputFields/SelectField/BasicSelect";

const CityField = (props) => {
  const formik = props?.formik;

  return (
    <BasicSelect
      id={addBuildingInitialConstants.cityId}
      name={addBuildingInitialConstants.cityId}
      label="Grad"
      value={formik.values.cityId}
      //onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched?.[addBuildingInitialConstants.cityId] && formik.errors?.[addBuildingInitialConstants.cityId]}
      apiError={props?.apiError}
      changeFormikValue={formik.setFieldValue}
      class={props?.class}
      options={props?.options}
      defaultValue={props?.defaultValue}
    />
  );
};

CityField.propTypes = {
  formik: PropTypes.object,
  apiError: PropTypes.any,
  class: PropTypes.string,
  options: PropTypes.array,
  defaultValue: PropTypes.any,
};

export default CityField;
