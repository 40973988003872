import React from "react";
import {
  HeaderContainer,
  HeaderSubContainer,
  HeaderText,
} from "./HeaderComponent.styled";
import { SecondaryButton } from "components/shared/Buttons/Buttons.styled";
import PlusIcon from "components/Icon/Icons/PlusIcon";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

function HeaderComponent() {
  const matches = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate();
  return (
    <HeaderContainer>
      <HeaderText>Zgrade</HeaderText>
      <HeaderSubContainer>
        <SecondaryButton
          style={{width: matches && '100%'}}
          onClick={() => navigate("/add-building")}
        >
          <PlusIcon />
          Dodaj Zgradu
        </SecondaryButton>
      </HeaderSubContainer>
    </HeaderContainer>
  );
}

export default HeaderComponent;
