import { apiSlice } from "store/apiSlice";

export const buildingMonthlyItemsApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["BuildingMonthlyItems"],
  endpoints: (builder) => ({
    deleteBuildingMonthlyItem: builder.mutation({
      query: (monthlyItemId) => ({
        url: `/buildingMonthlyItems?id=${monthlyItemId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BuildingMonthlyItems", "DebitDetails"],
    }),
  }),
});

export const {
  useBuildingMonthlyItemsQuery,
  useDeleteBuildingMonthlyItemMutation,
} = buildingMonthlyItemsApiSlice;
