import React from "react";
import { ReactComponent as UnlockIconSvg } from "../../../assets/Icons/unlock.svg";

const UnlockIcon = (props) => {
  return <UnlockIconSvg {...props} />;
};

UnlockIcon.propTypes = {};

export default UnlockIcon;
