import React, { useState } from "react";
import { SearchInput } from "./SearchInputField.styled";
import PropTypes from "prop-types";
import SearchIcon from "components/Icon/Icons/SearchIcon";

const SearchInputField = ({ style, searchValue, handleInputChange }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  return (
    <SearchInput
      style={style}
      startAdornment={<SearchIcon />}
      placeholder="Pretraga"
      value={searchValue}
      onChange={(event) => handleInputChange(event)}
      onFocus={handleFocus}
      onBlur={handleBlur}
      isfocused={isFocused}
    ></SearchInput>
  );
};

SearchInputField.propTypes = {
  style: PropTypes.object,
  searchValue: PropTypes.string,
  handleInputChange: PropTypes.func,
};

export default SearchInputField;
