export default {
  app: {
    title: "Upravnica",
  },
  refresh: {
    title: "Are you active?",
    cta: "You were registered as not active, please confirm that you are active in the next minute, if you don't you will be logged out.",
  },
  common: {
    language: "Jezik",
    english: "Engleski",
    serbian: "Srpski",
    dataGridExample: "Primer Data Grid-a",
    close: "Close",
    trademark: "TM",
    search: "Pretraga",
    error: "Greška",
    continue: "Nastavite",
    labelUsername: "Korisničko ime",
    labelEmail: "E-mail",
    labelPassword: "Šifra",
    next: "Napred",
    nextPage: "Sledeća stranica",
    previousPage: "Predhodna stranica",
    back: "Nazad",
    goBack: "Idite nazad",
    ok: "U redu",
    done: "Gotovo",
    confirm: "Potvrdite",
    printDownload: "Print/Download",
    cancel: "Cancel",
    remove: "Remove",
    invite: "Invite",
    save: "Save",
    complete: "Complete",
    download: "Download",
    yes: "Yes",
    no: "No",
    to: "to",
    select: "Select...",
    none: "None",
    date: {
      range: "{{start}} to {{end}}",
    },
  },
  auth: {
    title: "Upravnica",
    watermark: "@Upravnica",
    supportEmail: "upravnica.support@mail.com",
  },
  pages: {
    settings: "Podešavanja",
    profile: "Profil",
    personal: "Personal",
    home: "Početna",
    buildings:"Zgrade",
    building:"Zgrada",
    apartment:"Stan",
  },
  register: {
    //form
    formTitle: "Registruj se",
    formSubtitle:
      "Nakon registracije poslaćemo Vam verifikacioni link na email",
    //inputs
    companyNameLabel: "Ime Firme",
    companyNamePlaceholder: "Unesite Ime Firme",
    companyNameHintText: "",
    emailLabel: "Email",
    emailPlaceholder: "Unesite email",
    emailHintText: "",
    passwordLabel: "Lozinka",
    passwordPlaceholder: "",
    passwordHintText: "",
    confirmPasswordLabel: "Potvrdi lozinku",
    confirmPasswordHintText: "",
    pibLabel: "PIB",
    pibPlaceholder: "Unesi PIB",
    pibHintText: "",
    identificationNumberLabel: "Matični Broj",
    identificationNumberPlaceholder: "Unesite matični broj firme",
    identificationNumberHintText: "",
    companyAddressLabel: "Adresa firme",
    companyAddressPlaceholder: "Unesite adresu firme",
    companyAddressHintText: "",
    companyCityLabel: "Grad firme",
    companyCityPlaceholder: "Unesite grad firme",
    companyCityHintText: "",
    phoneNumberLabel: "Broj telefona",
    phoneNumberPlaceholder: "Unesite broj telefona",
    phoneNumberHintText: "",
    //buttons
    registerButton: "Registruj se",
    loginPreText: "Već imate nalog?",
    loginLink: "Uloguj se",
    successTitle: "Hvala na registraciji!",
    successSubtitle: "Molimo proverite vaš email radi potvrde vašeg naloga!",
    openEmailButton: "Otvori email aplikaciju",
    continueButton: "Nastavi",
    //validation messages
    emailErrorRequired: "Email je obavezan!",
    emailErrorInvalid: "email format nije validan",
    companyNameErrorRequired: "Ime Kompanije je obavezno!",
    passwordErrorRequired: "Lozinka je obavezna",
    passwordErrorMinLength: "Lozinka nije dovoljno duga",
    confirmPasswordErrorRequired: "Molimo vas potvrdite vašu lozinku",
    confirmPasswordErrorNoMatch: "Lozinke se ne poklapaju",
    pibErrorRequired: "Pib je obavezan",
    pibErrorValidLength: "Dužina PIB-a mora biti tačno 9 cifara",
    identificationNumberErrorRequired: "Unesite matični broj",
    identificationNumberErrorValidLength: "Dužina matičnog broja mora biti tačno 8 cifara",
    companyAddressErrorRequired: "Adresa je obavezna",
    companyCityErrorRequired: "Grad firme je obavezan",
    phoneNumberErrorRequired: "Broj telefona je obavezan",
    //footer
    watermark: "© Upravnica",
    supportEmail: "support@mail.com",
  },
  login: {
    //display text
    logInTitle: "Upravnica",
    //form
    formTitle: "Uloguj se",
    formSubtitle: "Dobro došli! Molimo vas unesite vaše kredencijale.",
    //inputs
    emailLabel: "Email",
    emailPlaceholder: "Unesite email",
    emailHintText: "",
    passwordLabel: "Lozinka",
    passwordPlaceholder: "",
    passwordHintText: "",
    rememberMeLabel: "Zapamti me na 30 dana",
    forgetPasswordLink: "Zaboravili ste lozinku?",
    loginButton: "Uloguj se",
    registerPreText: "Nemate nalog?",
    registerLink: "Prijavi se",
    //validation messages
    emailErrorRequired: "Email je obavezan!",
    emailErrorInvalid: "email format nije validan",
    passwordErrorRequired: "Lozinka je obavezna",
    passwordErrorMinLength: "Lozinka nije dovoljno duga",
  },
  forgotPassword: {
    //form
    formTitle: "Registruj se",
    formSubtitle:
      "Nakon registracije poslacemo Vam verifikacioni link na email",
    emailLabel: "Email",
    emailPlaceholder: "Unesite email",
    emailHintText: "",
    emailErrorRequired: "Email je obavezan!",
    resetButton: "Resetuj lozinku",
    backLink: "Nazad",
    confirm: {
      title: "Proverite svoj email!",
      subtitle: "Link za resetovanje lozinke je poslat na",
      openMailButton: "Otvori email aplikaciju",
      sendAgainPreText: "Niste dobili email?",
      sendAgainButton: "Klikni da posaljes ponovo",
      backLink: "Nazad",
    },
  },
  resetPassword: {
    //form
    formTitle: "Registruj se",
    formSubtitle:
      "Nakon registracije poslaćemo Vam verifikacioni link na email",
    newPasswordLabel: "Nova Lozinka",
    newPasswordPlaceholder: "",
    newPasswordHintText: "",
    confirmPasswordLabel: "Potvrdi Lozinka",
    confirmPasswordPlaceholder: "",
    confirmPasswordHintText: "",
    //errors
    newPasswordErrorRequired: "Lozinka je obavezna",
    newPasswordErrorMinLength: "Lozinka nije dovoljno duga",
    condfirmPasswordErrorRequired: "Potvrda Lozinke je obavezna",
    confirmPasswordErrorNoMatch: "Lozinke se ne poklapaju",
    //
    resetButton: "Resetuj lozinku",
    backLink: "Nazad",
    confirm: {
      title: "Lozinka je resetovana!",
      subtitle: "Lozinka je uspešno resetovana. Klikni ispod da bi ste se ulogovali",
      continueButton: "Nastavi",
      loginButton: "Uloguj se",
    },
  },
  notFound: {
    text: "We're sorry but we couldn't find the page you were looking for.",
    goBack: "Go back to homepage",
  },
  errorPage: {
    text: "We're sorry, an internal server error came up. Please be patient or try again later.",
    goBack: "Go back to homepage",
    logout: "Logout",
  },

  apiErrors: {
    ClientIpAddressIsNullOrEmpty: "Client Ip address is null or empty",
    UsernameDoesNotExist: "Username does not exist",
    WrongCredentials: "Wrong credentials",
    SomethingWentWrong: "Something went wrong",
    WrongPasswordAccountIsLocked: "Wrong credentials, account is locked",
    AccountIsLocked: "Account is locked",
  },
};
