import React from "react";
import PropTypes from "prop-types";
import {
  Content,
  MockupImage,
  LoginLayoutContainer,
  HalfPanel,
  TopRightGraphic,
  BottomLeftGraphic,
  AbsoluteContainer,
  MockupImageContainer,
  FullHeightContainer,
} from "./LoginLayout.styled";
import { ReactComponent as LinesPattern } from "../../assets/LargeSVGS/SignUp_LinePattern.svg";
import useMediaQuery from "@mui/material/useMediaQuery";

const LoginLayout = (props) => {
  const matches = useMediaQuery("(min-width:768px)");
  return (
    <LoginLayoutContainer>
      <HalfPanel>
        <Content>{props?.content}</Content>
      </HalfPanel>
      {matches && (
        <HalfPanel gray overflowHidden>
          <FullHeightContainer>
            <MockupImageContainer>
              <MockupImage src={props?.image} />
            </MockupImageContainer>
          </FullHeightContainer>
          <AbsoluteContainer>
            <TopRightGraphic>
              <LinesPattern />
            </TopRightGraphic>
            <BottomLeftGraphic>
              <LinesPattern />
            </BottomLeftGraphic>
          </AbsoluteContainer>
        </HalfPanel>
      )}
    </LoginLayoutContainer>
  );
};

LoginLayout.propTypes = {
  image: PropTypes.node,
  content: PropTypes.node,
};

export default LoginLayout;
