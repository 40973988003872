export const months = [
  "Januar",
  "Februar",
  "Mart",
  "April",
  "Maj",
  "Jun",
  "Jul",
  "Avgust",
  "Septembar",
  "Oktobar",
  "Novembar",
  "Decembar",
];

export const imageFormats = {
  chatHeader: "chatHeader",
  original: "original",
  chatCard: "chatCard",
  review: "review",
};

// characters to exlude from PIB,Identicifation number and so on (fields only accept numbers)
export const charactersToExlude = ["+", "-", "e", ",", "E"];
