import React from "react";
import PropTypes from "prop-types";
import TextInputField from "components/shared/InputFields/TextField/TextField";
import { addApartmentInitialConstants } from "initialValues/addApartmentInitialValues";

const TenantNameField = (props) => {
  const formik = props?.formik;

  return (
    <TextInputField
      id={addApartmentInitialConstants.firstName}
      name={addApartmentInitialConstants.firstName}
      placeholder="Unesi ime vlasnika"
      label="Ime vlasnika"
      value={formik.values.firstName}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched?.[addApartmentInitialConstants.firstName] && formik.errors?.[addApartmentInitialConstants.firstName]}
      apiError={props.apiError}
      style={props?.style}
    />
  );
};

TenantNameField.propTypes = {
  formik: PropTypes.object,
  apiError: PropTypes.any,
  style: PropTypes.object,
};

export default TenantNameField;
