import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as File2IconSvg } from "../../../assets/Icons/file2.svg";

const File2Icon = (props) => {
  return <File2IconSvg {...props} />;
};

File2Icon.propTypes = {};

export default File2Icon;
