import React from "react";
import PropTypes from "prop-types";
import { addBuildingInitialConstants } from "initialValues/addBuildingInitialValues";
import TextInputField from "components/shared/InputFields/TextField/TextField";

const CurrentAccountField = (props) => {
  const formik = props?.formik;

  const handleCurrentAccountField = (event) => {
    const inputValue = event.target.value;
    const cleanedValue = inputValue.replace(/[^\d]/g, ""); // Remove non-digit characters
    const truncatedValue = cleanedValue.slice(0, 22);

    let formatted = "";
    const groups = truncatedValue.match(/^(\d{1,3})(\d{1,13})?(\d{1,2})?$/);

    if (groups) {
      formatted = groups
        .slice(1, 4)
        .filter((group) => !!group)
        .join(" - ");
    }

    return formatted;
  };

  return (
    <TextInputField
      id={addBuildingInitialConstants.currentAccount}
      name={addBuildingInitialConstants.currentAccount}
      placeholder="Unesite tekući račun"
      label="Tekuci račun"
      value={formik.values.currentAccount}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      maxLength={24}
      error={
        formik.touched?.[addBuildingInitialConstants.currentAccount] &&
        formik.errors?.[addBuildingInitialConstants.currentAccount]
      }
      handleCurrentAccountField={handleCurrentAccountField}
      apiError={props.apiError}
      style={props?.style}
    />
  );
};

CurrentAccountField.propTypes = {
  formik: PropTypes.object,
  apiError: PropTypes.any,
  style: PropTypes.object,
};

export default CurrentAccountField;
