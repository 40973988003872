import { Box } from '@mui/material';
import styled  from 'styled-components';

export const LoadingScreenContainer = styled(Box)`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    user-select: none;
    background-color: black;
    opacity:20%;
    background-blend-mode: multiply;
    z-index: 1000;
`

export const LoadingScreenSubcontainer = styled(Box)`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
