import React, {  useState } from "react";
import PropTypes from "prop-types";
import { addApartmentInitialConstants } from "initialValues/addApartmentInitialValues";
import { ExcludeApartmentsContainer, ExcludeApartmentsSubTitle, ExcludeApartmentsTextContainer, ExludeApartmentsTitle } from "components/BuildingMonthlyItem/BuildingMonthlyItem.styled";
import { SwitchButton } from "components/SwitchButton/SwitchButton";

const EmailSwitchField = (props) => {
  const formik = props?.formik;
  const [sendToEmail, setSendToEmail] = useState(props.defaultValue);

  const handleValueChange = () => {
    formik.setFieldValue(
      addApartmentInitialConstants.electronicDelivery,
      !sendToEmail
    );
    setSendToEmail(!sendToEmail);
  };

  return (
    <ExcludeApartmentsContainer>
      <SwitchButton
        checked={sendToEmail}
        id={addApartmentInitialConstants.electronicDelivery}
        name={addApartmentInitialConstants.electronicDelivery}
        onChange={() => handleValueChange()}
      />
      <ExcludeApartmentsTextContainer>
        <ExludeApartmentsTitle>Racuni stizu na Email </ExludeApartmentsTitle>
        <ExcludeApartmentsSubTitle>
          {/* Neki dodatni opis */}
        </ExcludeApartmentsSubTitle>
      </ExcludeApartmentsTextContainer>
    </ExcludeApartmentsContainer>
  );
};

EmailSwitchField.propTypes = {
  formik: PropTypes.object,
  apiError: PropTypes.any,
  changeFormikValue: PropTypes.func,
  defaultValue: PropTypes.bool,
};

export default EmailSwitchField;
