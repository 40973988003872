import React from "react";
import PropTypes from "prop-types";
import { AuthFooterContainer, Watermark, SupportEmailContainer, SupportEmailIconContainer, SupportEmail} from "./AuthFooter.styled";
import { useTranslation } from "react-i18next";
import mail01 from "../../../../assets/Icons/mail-01.svg"

const AuthFooter = () => {
  const { t } = useTranslation();
  return (
    <AuthFooterContainer>
      <Watermark>{t("auth.watermark")}</Watermark>
      <SupportEmailContainer>
        <SupportEmailIconContainer src={mail01}></SupportEmailIconContainer>
        <SupportEmail>
        {t("auth.supportEmail")}
        </SupportEmail>
        </SupportEmailContainer>
    </AuthFooterContainer>
  );
};

AuthFooter.propTypes = {
  children: PropTypes.node,
};

export default AuthFooter;
