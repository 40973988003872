export function authScopeGetHelper(key) {
  return JSON.parse(localStorage.getItem(key));
}

export function authScopeStringGetHelper(key) {
  return localStorage.getItem(key);
}

export function authScopeSetHelper(key, value) {
  localStorage.setItem(key, value);
}

export function authScopeRemoveHelper(key) {
  localStorage.removeItem(key);
}

export function authScopeClearHelper() {
  localStorage.clear();
}
