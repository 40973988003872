import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import {registerFormikConstants} from "../../../../initialValues/registerInitialValues";
import PasswordField from "components/shared/InputFields/PasswordField/PasswordField";

const RegisterPasswordField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;

  return (
      <PasswordField
        id={registerFormikConstants.password}
        name={registerFormikConstants.password}
        placeholder={t("register.passwordPlaceholder")}
        label={t("register.passwordLabel")}
        value={formik.values.password}
        onChange={props?.onChange}
        type="password"
        onBlur={formik.handleBlur}
        error={formik.touched?.[registerFormikConstants.password] && formik.errors?.[registerFormikConstants.password]}
        helperText={i18n.register && i18n.register?.passwordHintText && i18n.register?.passwordHintText !== "" && i18n.t("register.passwordHintText")}
        loginAttempt={props?.loginAttempt}
        apiError={props?.apiError}
      />
  )
};

RegisterPasswordField.propTypes = {
  formik: PropTypes.object,
  onChange: PropTypes.func,
  loginAttempt:PropTypes.bool,
  apiError:PropTypes.object,
};

export default RegisterPasswordField;
