import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  FilterContainer,
  FinanceOverviewContainer,
  FinanceOverviewPrice,
  FinanceOverviewTitle,
  EncapsulatingContainer,
} from "./FinanceOverviewComponent.styled";
import TotalDebtChart from "components/Charts/TotalDept/TotalDeptChart";
import PrimaryTable from "components/shared/Tables/PrimaryTable";
import selectedTheme from "themes";
//import { PrimaryButton } from "components/shared/Buttons/Buttons.styled";
//import FilterIcon from "components/Icon/Icons/FilterIcon";
import SearchInputField from "components/shared/SearhInputField/SearchInputField";
import { formatNumber } from "util/helpers/numberHelpers";

const FinanceOverviewComponent = ({ finance }) => {
  const [searchValue, setSearchValue] = useState("");
  const columns = ["ID", "Uplate", "Datum", "Iznos"];
  const pageSize = 8;

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const payments = finance.payments.map(
    ({ id, referenceNumber, dateOfPayment, paymentAmount }) => [
      id,
      "Uplata #" + referenceNumber,
      dateOfPayment,
      formatNumber(paymentAmount) + "rsd",
    ]
  );

  const applyFiltering = () => {
    // apply filtering on all fields
    return payments.filter((d) =>
      d.some((field) =>
        field.toString().toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  };

  return (
    <FinanceOverviewContainer>
      <EncapsulatingContainer>
      <FinanceOverviewTitle>Trenutni Dug</FinanceOverviewTitle>
      <FinanceOverviewPrice>
        {finance.currentState < 0
          ? formatNumber(Math.abs(finance.currentState))
          : formatNumber(-Math.abs(finance.currentState))}
        din
      </FinanceOverviewPrice>
      <TotalDebtChart debts={finance.debtsByMonths} />
      <FilterContainer>
        {/* <PrimaryButton>
          <FilterIcon />
          Filter
        </PrimaryButton> */}
        <SearchInputField
          searchValue={searchValue}
          handleInputChange={handleInputChange}
        />
      </FilterContainer>
      </EncapsulatingContainer>
      
      <PrimaryTable
        style={{
          marginTop: "24px",
          border: `1px solid ${selectedTheme.colors.gray[200]}`,
          boxShadow: `${selectedTheme.effects.shadow.sm.boxShadow}`,
        }}
        columns={columns}
        pageSize={pageSize}
        data={applyFiltering()}
      />
    </FinanceOverviewContainer>
  );
};

FinanceOverviewComponent.propTypes = {
  finance: PropTypes.shape({
    currentState: PropTypes.number,
    debtsByMonths: PropTypes.arrayOf(PropTypes.number),
    payments: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default FinanceOverviewComponent;
