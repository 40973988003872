import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  
  ButtonsContainer,
  FilterContainer,
  ReceiptContainer,
  TopContainer,
} from "./ReceiptTabContent.styled";
import { PrimaryButton } from "components/shared/Buttons/Buttons.styled";
//import FilterIcon from "components/Icon/Icons/FilterIcon";
import SearchInputField from "components/shared/SearhInputField/SearchInputField";

import DownloadCloudIcon from "components/Icon/Icons/DownloadCloudIcon";

import UploadCloudIcon from "components/Icon/Icons/UploadCloudIcon";
import { getMonth } from "util/dateHelpers";
import EnhancedTable from "components/shared/Tables/CheckedTable/CheckedTable";
import { useSendCheckedBillsMutation } from "store/billsApiSlice";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import { useMediaQuery } from '@mui/material';

const columns = [
  {
    id: "month",
    numeric: false,
    disablePadding: true,
    label: "Mesec",
  },
  { id: "amount", numeric: true, disablePadding: false, label: "Iznos" },
  { id: "status", numeric: true, disablePadding: false, label: "Status" },
  { id: "actions", numeric: false, disablePadding: false, label: "" },
  { id: "commands", numeric: false, disablePadding: false, label: "" },
];


const ReceiptTabContent = ({
  debts,
  //dialogOpen,
  setDialogOpen,
  handleExport,
  buildingId,
}) => {
  const [checkedElements, setCheckedElements] = useState([]);
  const pageSize = 10;
  
  const matches = useMediaQuery("(max-width:768px)");

  const [sendCheckedBills] = useSendCheckedBillsMutation();

  const [searchValue, setSearchValue] = useState("");
  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  // apply filtering based on month and year of bill
  
  // eslint-disable-next-line no-unused-vars
  const applyFiltering = () => {
    return debts.filter(
      (receipt) =>
        getMonth(receipt.month)
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        receipt.year
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
    );
  };

  
  const handleDownloadCheckedElements = () => {
    handleExport(checkedElements);
  };

  const handleDownloadSingleElement = (element) => {
    let elementArray = [];
    elementArray.push(element);
    handleExport(elementArray);
  };

  
  const handleSendCheckedElements = async (billArray) => {
    try {
      await sendCheckedBills({
        apartmentId: buildingId,
        billIds: billArray,
      }).unwrap();
      makeToastMessage("success");
    } catch (err) {
      makeErrorToastMessage(err.error);
    }
  };

  const handleSendSingleElement = async (element) => {
    let billArray = [];
    billArray.push(element);
    try {
      await sendCheckedBills({
        apartmentId: buildingId,
        billIds: billArray,
      }).unwrap();
      makeToastMessage("success");
    } catch (err) {
      makeErrorToastMessage(err.error);
    }
  };

  return debts == null ? (
    <p>Loading...</p>
  ) : (
    <ReceiptContainer>
      <TopContainer>
        <FilterContainer>
          {/* <PrimaryButton>
            <FilterIcon />
            Filter
          </PrimaryButton> */}
          <SearchInputField
            searchValue={searchValue}
            handleInputChange={handleInputChange}
          />
        </FilterContainer>
        <ButtonsContainer>
          <PrimaryButton
            onClick={() => handleDownloadCheckedElements(checkedElements)}
            
            style={{width: matches ? "100%" : "auto"}}
            
            
          >
            <DownloadCloudIcon />
            Preuzmi {!matches && 'Označene'}
          </PrimaryButton>
          <PrimaryButton style={{width: matches ? "100%" : "auto"}}
            onClick={() => handleSendCheckedElements(checkedElements)}
          >
            <UploadCloudIcon />
            Posalji {!matches && 'Označene'}
          </PrimaryButton>
        </ButtonsContainer>
      </TopContainer>
      <EnhancedTable
        setCheckedElements={setCheckedElements}
        downloadElement={handleDownloadSingleElement}
        sendElement={handleSendSingleElement}
        data={debts}
        pageSize={pageSize}
        columns={columns}
        // originaly for opening the drawer
        handleRowClick={() => {}}
        //for dialog
        setDialogOpen={setDialogOpen}
      />
    </ReceiptContainer>
  );
};

ReceiptTabContent.propTypes = {
  debts: PropTypes.array,
  dialogOpen: PropTypes.bool,
  setDialogOpen: PropTypes.func,
  handleExport: PropTypes.func,
  buildingId: PropTypes.string,
};

export default ReceiptTabContent;
