import Navbar from "components/shared/Navbar/Navbar";
import React, { useEffect, useState } from "react";
import {
  ApartmentPageContainer,
  HeaderContainer,
  MainSection,
  PageContainer,
} from "./ApartmentPage.styled";
import BreadCrumbs from "components/shared/BreadCrumbs/BreadCrumbs";
import ApartmentsHeaderComponent from "components/shared/Header/ApartmentsPage/ApartmentsHeaderComponent";
import TabsComponent from "components/shared/Tab/TabsComponent";
import OverviewTabsContent from "components/Apartments/OverviewTabContent/OverviewTabsContent";
import { useParams } from "react-router-dom";
import { useApartmentDetailsQuery } from "../../store/apartmentsApiSlice";
import ReceiptTabContent from "components/Apartments/ReceiptTabContent/ReceiptTabContent";
import ConfirmActionModal from "components/shared/Modals/ConfirmActionModal/ConfirmActionModal";
import { useCheckedBillsMutation } from "store/billsApiSlice";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import HistoryOverviewComponent from "components/HistoryTabContent/HistoryOverviewComponent";
import FinanceOverviewComponent from "components/FinanceTabContent/FinanceOverviewComponent";
import { formatDate } from "util/dateHelpers";
//import CustomDrawer from "components/shared/Drawer/CustomDrawer";
import RentTabContent from "./../../components/Apartments/RentTabContent/RentTabContent";
import { BackContainer } from './../../components/shared/Modals/ConfirmActionModal/ConfirmActionModal.styled';
import LeftArrowIcon from './../../components/Icon/Icons/LeftArrowIcon';
import {BackText} from '../../components/ImportTenantsSecondStep/ImportTenantsSecondStep.styled'
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ApartmentPage = () => {
  // eslint-disable-next-line no-unused-vars
  //const [drawer, setDrawer] = useState({open: false, content:{}});
  const matches = useMediaQuery("(min-width:768px)");
  const navigate = useNavigate();


  const { id } = useParams();
  const { data, isLoading } = useApartmentDetailsQuery(id);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [buildingId, setBuildingId] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [checkedBills] = useCheckedBillsMutation();

  // eslint-disable-next-line no-unused-vars
  const handleExport = async (billArray) => {
    await checkedBills({
      id,
      billArray,
    })
      .unwrap()
      .then(() => {
        makeToastMessage("Uspesno ste preuzeli racun");
      })
      .catch((err) => makeErrorToastMessage(err.error));
  };

  function getSubstringBetween(str, startSubstr, endSubstr) {
    const startIndex = str.indexOf(startSubstr);
    if (startIndex === -1) return ""; // Start substring not found

    const endIndex = str.indexOf(endSubstr, startIndex + startSubstr.length);
    if (endIndex === -1) return ""; // End substring not found

    return str.slice(startIndex + startSubstr.length, endIndex);
  }

  const extractBuildingId = () => {
    const url = location.href;
    setBuildingId(getSubstringBetween(url, "buildings/", "/apartments"));
  };

  function mapPropertyName(propertyName) {
    const propertyMap = {
      SquareFootage: "Kvadratura",
      NumOfApartment: "Broj stana",
      ObjectCode: "Šifra objekta",
      CurrentState: "Tekući balans",
      DefaultRentPrice: "Cena izdavanja",
      IsAvailableForRent: "Stan moguć za izdavanje",
      RentalInformations: "Detalji rentiranja",
      FirstName: "Ime vlasnika",
      LastName: "Prezime vlasnika",
      PhoneNumber: "Broj telefona vlasnika",
      Email: "Email vlasnika",
      ElectronicDelivery: "Računi stižu na Email",
      DeliveryAddress: "Adresa za dostavu",
      CityOfDelivery: "Grad za dostavu",
      NumOfTenants: "Broj stanara",

      // Add more property name mappings as needed
    };

    return propertyMap[propertyName] || propertyName;
  }

  function getDisplayValue(previousValue) {
    if (previousValue === "True") {
      return "Da";
    } else if (previousValue === "False") {
      return "Ne";
    }
    // Add more conditions here if needed for other cases
    // For example, if you want to handle some default case
    // when the value is not 'True' or 'False'.
    return previousValue;
  }

  function formatHistoryData(data) {
    return data.map((item) => {
      const newPropertyName = mapPropertyName(item.propertyName);
      let additionalUnit = "";

      if (item.propertyName === "SquareFootage") {
        additionalUnit = "m2";
      } else if (item.propertyName === "DefaultRentPrice") {
        additionalUnit = "eur";
      } else if (item.propertyName === "CurrentState") {
        additionalUnit = "din";
      } else {
        additionalUnit = "";
      }

      const formattedPreviousValue = getDisplayValue(item.previousValue);
      const formattedCurrentValue = getDisplayValue(item.currentValue);

      return {
        ...item,
        propName: newPropertyName,
        previousValue: formattedPreviousValue + additionalUnit,
        currentValue: formattedCurrentValue + additionalUnit,
        date: formatDate(item.date, "dd.MM.yyyy"),
      };
    });
  }

  const tabs = [
    {
      label: "Pregled",
      component: (
        <OverviewTabsContent
          apartment={data === undefined ? null : data.apartment}
          finance={data === undefined ? null : data.finance}
        />
      ),
    },
    {
      label: "Racuni",
      component: (
        <ReceiptTabContent
          debts={data === undefined ? null : data.debits}
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          handleExport={handleExport}
          buildingId={id}
          //apartment={data === undefined ? null : data.apartment}
        />
      ),
    },
    {
      label: "Finansije",
      component: (
        <FinanceOverviewComponent
          finance={data === undefined ? null : data.finance}
        />
      ),
    },
    {
      label: "Istorija",
      component: (
        <HistoryOverviewComponent
          history={
            data?.apartmentHistory
              ? formatHistoryData(data.apartmentHistory)
              : null
          }
        />
      ),
    },
    {
      label: "Rent",
      component: (
        <RentTabContent
          apartment={data === undefined ? null : data.apartment}
          buildingId={id}
        />
      ),
    },
  ];

  useEffect(() => {
    extractBuildingId();
  }, []);

  return isLoading ? (
    <p>Loading...</p>
  ) : (
    <ApartmentPageContainer>
      <Navbar />
      <PageContainer>
        <MainSection>
          <HeaderContainer>
            {!matches ? (
               <BackContainer>
               <LeftArrowIcon
                 style={{ cursor: "pointer" }}
                 onClick={() => {navigate(`/buildings/${buildingId}`)}}
               />
               <BackText>Nazad</BackText>
             </BackContainer>
            ) : (
              <BreadCrumbs />
            )}
            <ApartmentsHeaderComponent
              apartmentId={id}
              buildingId={buildingId}
            />
          </HeaderContainer>
          <TabsComponent tabs={tabs} />
        </MainSection>
        {/* <CustomDrawer drawer={drawer} setDrawer={setDrawer} /> */}
        <ConfirmActionModal
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          action={() => {}}
        />
      </PageContainer>
    </ApartmentPageContainer>
  );
};

export default ApartmentPage;
