import { Box } from "@mui/material";
import styled from "styled-components";

export const HistoryOverviewContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  margin-top: 34px;
`;

export const FilterContainer = styled(Box)`
    display: flex;
    align-items: center;
    gap: 12px;
    @media (max-width: 768px) {
    padding: 0 16px;
  }
`