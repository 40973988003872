import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  AllBuildingsOverviewContainer,
  CardsContainer,
  FilterContainer,
} from "./AllBuildingsOverviewComponent.styled";
import TotalTenantsCard from "components/BuildingsCards/TotalTenantsCard";
import TotalBuildingsCard from "components/BuildingsCards/TotalBuildingsCard";
//import { PrimaryButton } from "components/shared/Buttons/Buttons.styled";
//import FilterIcon from "components/Icon/Icons/FilterIcon";
import SearchInputField from "components/shared/SearhInputField/SearchInputField";
import PrimaryTable from "components/shared/Tables/PrimaryTable";
import AlertDialog from "components/shared/Dialogs/AlertDialog";
import { useDeleteBuildingMutation } from "store/buildingsApiSlice";
import { useNavigate } from "react-router";
import selectedTheme from "../../themes";
import DeleteIcon from "components/Icon/Icons/DeleteIcon";
import useMediaQuery from "@mui/material/useMediaQuery";

const AllBuildingsOverviewComponent = ({
  buildings,
  numberOfBuildings,
  numberOfTenants,
}) => {
  const navigate = useNavigate();
  const matches = useMediaQuery("(max-width:768px)");
  const [deleteBuilding] = useDeleteBuildingMutation();
  const [buildingToDeleteId, setBuildingToDeleteId] = useState(null);

  // input for searching by address
  const [searchValue, setSearchValue] = useState("");
  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  // dialog for deleting building
  const [open, setOpen] = React.useState(false);
  const handleOpen = (event, buildingId) => {
    event.stopPropagation();
    setBuildingToDeleteId(buildingId);
    setOpen(true);
  };
  const handleClose = () => {
    setBuildingToDeleteId(null);
    setOpen(false);
  };
  const handleDeleteBuilding = () => {
    deleteBuilding(buildingToDeleteId);
    setOpen(false);
  };

  // setup for table
  const columns = ["ID", "Adresa", "", "Br.stanova", "Napomena zgrade"];
  buildings = buildings.map(
    ({ id, numberOfApartments, isThereApartmentForRent, address, info }) => [
      id,
      address,
      isThereApartmentForRent,
      numberOfApartments,
      info,
    ]
  );
  const pageSize = 8;
  const handleRowClick = (id) => {
    navigate(`/buildings/${id}`);
  };

  const applyFiltering = () => {
    // apply filtering on building address
    return buildings.filter((d) =>
      d[1].toLowerCase().includes(searchValue.toLowerCase())
    );
  };

  return (
    <>
      <AlertDialog
        open={open}
        handleClose={handleClose}
        handleSubmit={handleDeleteBuilding}
        title="Da li ste sigurni da želite obrisati zgradu?"
        content="Da li ste sigurni da želite da izbrišete zgradu? Ova funkcija je iverzebilna"
        actionName="IZBRIŠI"
        Icon={<DeleteIcon />}
      />
      <AllBuildingsOverviewContainer>
        <CardsContainer>
          <TotalBuildingsCard numberOfBuildings={numberOfBuildings} />
          <TotalTenantsCard numberOfTenants={numberOfTenants} />
        </CardsContainer>
        <FilterContainer>
          {/* <PrimaryButton>
            <FilterIcon />
            {matches ? "" : "Filter"}
          </PrimaryButton> */}
          <SearchInputField
            style={{
              width: matches && "100%",
              marginRight: matches && "16px",
              marginLeft: !matches && "16px",
            }}
            searchValue={searchValue}
            handleInputChange={handleInputChange}
          />
        </FilterContainer>
        <PrimaryTable
          data={applyFiltering()}
          columns={columns}
          pageSize={pageSize}
          handleRowClick={handleRowClick}
          style={{
            marginTop: "24px",
            border: `1px solid ${selectedTheme.colors.gray[200]}`,
            boxShadow: `${selectedTheme.effects.shadow.sm.boxShadow}`,
          }}
          isBuildingsTable={true}
          handleDelete={handleOpen}
        />
      </AllBuildingsOverviewContainer>
    </>
  );
};

AllBuildingsOverviewComponent.propTypes = {
  buildings: PropTypes.array,
  numberOfBuildings: PropTypes.number,
  numberOfTenants: PropTypes.number,
};

export default AllBuildingsOverviewComponent;
