import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from 'store/apiSlice';

const authSlice = createSlice({
  name: "auth",
  initialState: { user: undefined, token: undefined },
  reducers: {
    setCredetnials: (state, action) => {
      const { user, token, refreshToken } = action.payload;
      state.user = user;
      state.token = { token, refreshToken };
    },
    logOut: (state) => {
      state.user = undefined;
      state.token = undefined;
      apiSlice.util.invalidateTags(['UserData','Buildings'])
      localStorage.clear();
      window.location.reload(false);
    },
  },
});

export const { setCredetnials, logOut } = authSlice.actions;
export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
