import { apiSlice } from "store/apiSlice";
import { makeErrorToastMessage } from "util/toastMessage";

export const billApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["BillDetails"],
  endpoints: (builder) => ({
    billDetail: builder.query({
      query: (id) => ({
        url: `/bills?billId=${id}`,
      }),
      providesTags: ["BillDetails"],
    }),
    checkedBills: builder.mutation({
      query: ({ id, billArray }) => ({
        url: "/export/apartmentBills",
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: {
          apartmentId: id,
          debitIds: billArray,
        },
        responseHandler: async (response) => {
          const blob = await response.blob();
          if (blob.size === 1) {
            makeErrorToastMessage("Ne postoje racuni za tekuci mesec");
            return;
          }
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          const contentDisposition = response.headers.get("content-disposition");
          const matches = /filename="([^"]+)"/.exec(contentDisposition);
          link.download = matches && matches[1];
          link.click();
          window.URL.revokeObjectURL(url);
        },
        cache: "no-cache",
      }),
    }),
    sendCheckedBills: builder.mutation({
      query: (data) => ({
        url: `/bills/checkedBills`,
        method: "POST",
        body: data,
        responseHandler: async (response) => {
          console.log(response);
        },
      }),
    }),
  }),
});

export const {
  useBillDetailQuery,
  useCheckedBillsMutation,
  useSendCheckedBillsMutation,
} = billApiSlice;
