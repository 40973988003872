import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as AccountNumberIconSvg } from "../../../assets/Icons/accountNumber.svg";

const AccountNumberIcon = (props) => {
  return <AccountNumberIconSvg {...props} />;
};

AccountNumberIcon.propTypes = {};

export default AccountNumberIcon;
