import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const HomePageContainer = styled(Box)`
  background-color: ${selectedTheme.colors.gray[25]};
`;

export const HomePageSubContainer = styled(Box)`
  display: flex;
  min-height: 100vh;
  height: 100%;
  overflow: hidden;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ContentContainer = styled(Box)`
  margin: 32px;
  width: 100%;
  display:flex;
  flex-direction:column;
  @media (max-width: 768px) {
    width: 100%;
    margin: 0px;
    padding-top: 48px;
  }
`;

export const TopContainer = styled(Box)`
  display: flex;
  margin-top: 32px;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 0px;
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const BottomContainer = styled(Box)`
  display: flex;
  margin-top: 32px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
