import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const AddDebitPageContainer = styled(Box)`
  display: flex;
  min-height:100vh;
  height: 100%;
  background-color: ${selectedTheme.colors.gray[25]};
  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 48px;
  }
`;

export const AddDebitPageSubContainer = styled(Box)`
  padding-top: 36px;
  padding-bottom: 32px;
  padding-right: 32px;
  padding-left: 32px;
  width: 100%;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const NavigationContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

export const NavigationPreviousText = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.medium.fontFamily};
  font-size: ${selectedTheme.type.textSm.medium.fontSize};
  font-style: ${selectedTheme.type.textSm.medium.fontStyle};
  font-weight: ${selectedTheme.type.textSm.medium.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
  margin-left: 20px;
`;

export const NavigationText = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.medium.fontFamily};
  font-size: ${selectedTheme.type.textSm.medium.fontSize};
  font-style: ${selectedTheme.type.textSm.medium.fontStyle};
  font-weight: ${selectedTheme.type.textSm.medium.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
  margin-left: 20px;
`;

export const TopContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  @media (max-width: 768px) {
    margin-top: 20px;
    padding: 0 16px;
  }
`;

export const Title = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.semibold.fontFamily};
  font-size: 30px;
  font-style: ${selectedTheme.type.textSm.semibold.fontStyle};
  font-weight: ${selectedTheme.type.textSm.semibold.fontWeight};
  color: ${selectedTheme.colors.gray[900]};
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

export const ItemsTitle = styled(Typography)`
  font-family: ${selectedTheme.type.textLg.semibold.fontFamily};
  font-size: ${selectedTheme.type.textLg.semibold.fontSize};
  font-style: ${selectedTheme.type.textLg.semibold.fontStyle};
  font-weight: ${selectedTheme.type.textLg.semibold.fontWeight};
  color: ${selectedTheme.colors.gray[900]};
  margin-top: 32px;
  @media (max-width: 768px) {
    margin-top: 24px;
    padding: 0 16px;
  }
`;

export const BuildingMonthlyItemsContainer = styled(Box)`
  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;
export const InputContainer = styled(Box)``;

export const InputLabel = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.medium.fontFamily};
  font-size: ${selectedTheme.type.textSm.medium.fontSize};
  font-style: ${selectedTheme.type.textSm.medium.fontStyle};
  font-weight: ${selectedTheme.type.textSm.medium.fontWeight};
  color: ${selectedTheme.colors.gray[700]};
`;

export const Input = styled("input")`
  border: 1px solid ${selectedTheme.colors.gray[300]};
  box-shadow: ${selectedTheme.effects.shadow.xs.boxShadow};
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 12px;
  font-family: ${selectedTheme.type.textMd.regular.fontFamily};
  font-size: ${selectedTheme.type.textMd.regular.fontSize};
  font-style: ${selectedTheme.type.textMd.regular.fontStyle};
  font-weight: ${selectedTheme.type.textMd.regular.fontWeight};
  color: ${selectedTheme.colors.gray[900]};
  width: 100%;
`;

export const AddItemButtonContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding: 0 16px;
`;

export const EmptyContainer = styled(Box)``;

export const MiddleContainer = styled(Box)`
  display: flex;
  margin-top: 24px;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 32px;
    padding: 0 16px;
  }
`;

export const BottomContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 32px;
  }
`;
