import React from "react";
import { ReactComponent as EditUserIconSvg } from "../../../assets/Icons/editUser.svg";

const EditUserIcon = (props) => {
  return <EditUserIconSvg {...props} />;
};

EditUserIcon.propTypes = {};

export default EditUserIcon;
