import React from "react";
import { ReactComponent as BuildingNavigationIconSvg } from "../../../assets/Icons/buildingNavigation.svg";

const BuildingNavigationIcon = (props) => {
  return <BuildingNavigationIconSvg {...props} />;
};

BuildingNavigationIcon.propTypes = {};

export default BuildingNavigationIcon;
