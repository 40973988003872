import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import {forgotPasswordFormikConstants} from "../../../initialValues/forgotPasswordInitialValues";
import PasswordField from "components/shared/InputFields/PasswordField/PasswordField";

const NewPasswordField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;

  return (
      <PasswordField
      id={forgotPasswordFormikConstants.newPassword}
      name={forgotPasswordFormikConstants.newPassword}
      placeholder={t("resetPassword.newPasswordPlaceholder")}
      label={t("resetPassword.newPasswordLabel")}
      value={formik.values.newPassword}
      onChange={props?.onChange}
      type="password"
      onBlur={formik.handleBlur}
        error={formik.touched?.[forgotPasswordFormikConstants.newPassword] && formik.errors?.[forgotPasswordFormikConstants.newPassword]}
      helperText={i18n.forgotPassword && i18n.newPassword?.newPasswordHintText && i18n.forgotPassword?.newPasswordHintText !== "" && i18n.t("forgotPassword.newPasswordHintText")}
      loginAttempt={props?.loginAttempt}
      apiError={props?.apiError}
      />
  )
};

NewPasswordField.propTypes = {
  formik: PropTypes.object,
  onChange: PropTypes.func,
  loginAttempt:PropTypes.bool,
  apiError:PropTypes.object,
};

export default NewPasswordField;
