import styled from "styled-components";
import { Box } from "@mui/material";

export const CostFieldContainer = styled(Box)`
  border: 1px solid #d0d5dd;
  width: 58px;
  height: 46px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  margin-top: 26px;
  border-right: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;
