import styled from "styled-components";
import Select from "react-select";
import selectedTheme from "themes";

export const StyledSelect = styled(Select)`
  .Select__control {
    padding: 10px 14px;
    background: ${selectedTheme.colors.base.white};
    width: 100%;
    border-radius: 8px;
    border: 1px solid ${selectedTheme.colors.gray[300]};
    border-color: ${selectedTheme.colors.gray[300]};
    box-shadow: ${selectedTheme.effects.shadow.xs.boxShadow};
    transition: all 0.2s;
    //text
    font-family: ${selectedTheme.type.textMd.regular.fontFamily};
    font-size: ${selectedTheme.type.textMd.regular.fontSize};
    font-weight: ${selectedTheme.type.textMd.regular.fontWeight};
    font-style: ${selectedTheme.type.textMd.regular.fontStyle};
    line-height: ${selectedTheme.type.textMd.regular.lineHeight};
    color: ${selectedTheme.colors.gray[700]};
    cursor: pointer;
  }

  .Select__control:hover {
    border-color: ${(props) =>
      props?.error && typeof props?.error.error !== "undefined"
        ? selectedTheme.colors.error[300]
        : selectedTheme.colors.gray[300]};
  }

  .Select__control:focus {
    outline: none;
    box-shadow: none;
    border-color: ${(props) =>
      props?.error && typeof props?.error.error !== "undefined"
        ? selectedTheme.colors.error[300]
        : selectedTheme.colors.primary[300]};
  }

  .Select__control--is-focused {
    outline: none;
    box-shadow: ${(props) =>
      props?.error && typeof props?.error.error !== "undefined"
        ? selectedTheme.effects.shadow.xsFocused_4pxError100.boxShadow
        : selectedTheme.effects.shadow.xsFocused_4pxPrimary100
            .boxShadow} !important;
    border: 1px solid
      ${(props) =>
        props?.error && typeof props?.error.error !== "undefined"
          ? selectedTheme.colors.error[300]
          : selectedTheme.colors.primary[300]} !important;
  }

  .Select__placeholder {
    margin: 0;
    color: ${selectedTheme.colors.gray[500]};
  }

  .Select__value-container,
  .Select__value-container--is-multi {
    margin: 0;
    padding: 0;
  }

  .Select__input-container {
    margin: 0;
    padding: 0;
  }

  .Select__control--is-focused {
    box-shadow: 0 0 0 1px black;
    outline: none;
  }

  /* [class$="-indicatorContainer"]{
    padding: 0px;
  } */

  .Select__indicator {
    padding: 0px !important;
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__menu {
    color: #3c3d3e;
  }

  .Select__multi-value {
    border-radius: 6px;
  }
`;
