import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import {forgotPasswordFormikConstants} from "../../../initialValues/forgotPasswordInitialValues";
import TextInputField from "components/shared/InputFields/TextField/TextField";

const ForgotPasswordEmailField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;

  return (
      <TextInputField
        id={forgotPasswordFormikConstants.email}
        name={forgotPasswordFormikConstants.email}
        placeholder={t("forgotPassword.emailPlaceholder")}
        label={t("forgotPassword.emailLabel")}
        value={formik.values.email}
        onChange={formik.handleChange}
        type="email"
        onBlur={formik.handleBlur}
        error={formik.touched?.[forgotPasswordFormikConstants.email] && formik.errors?.[forgotPasswordFormikConstants.email]}
        helperText={i18n.forgotPassword && i18n.forgotPassword?.emailHintText && i18n.forgotPassword?.emailHintText !== "" && i18n.t("forgotPassword.emailHintText")}
        apiError={props.apiError}
      />
  );
};

ForgotPasswordEmailField.propTypes = {
  formik: PropTypes.object,
  apiError:PropTypes.any,
};

export default ForgotPasswordEmailField;