import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";

export const AuthFooterContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  padding: 32px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Watermark = styled(Typography)`
  //template
  font-family: ${selectedTheme.type.textSm.regular.fontFamily};
  font-size: ${selectedTheme.type.textSm.regular.fontSize};
  font-weight: ${selectedTheme.type.textSm.regular.fontWeight};
  font-style: ${selectedTheme.type.textSm.regular.fontStyle};
  line-height: ${selectedTheme.type.textSm.regular.lineHeight};
  //
  color:${selectedTheme.colors.gray[600]};
`;
export const SupportEmailContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: nowrap;
  align-items: center;
`;
export const SupportEmail = styled(Typography)`
  //template
  font-family: ${selectedTheme.type.textSm.regular.fontFamily};
  font-size: ${selectedTheme.type.textSm.regular.fontSize};
  font-weight: ${selectedTheme.type.textSm.regular.fontWeight};
  font-style: ${selectedTheme.type.textSm.regular.fontStyle};
  line-height: ${selectedTheme.type.textSm.regular.lineHeight};
  //
  color:${selectedTheme.colors.gray[600]};
`;
export const SupportEmailIconContainer = styled.img``;
