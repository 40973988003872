import BuildingNavigationIcon from "components/Icon/Icons/BuildingNavigationIcon";
import RightArrowNavigationIcon from "components/Icon/Icons/RightArrowNavigationIcon";
import React, { useEffect, useState } from "react";
import {
  AddEditApartmentPageContainer,
  ButtonsContainer,
  ContentSubContainer,
  Header,
  InformationContainer,
  NavigationContainer,
  NavigationItem,
  TopContainer,
  ContentContainer,
  InformationMainText,
  InformationSubText,
  Table,
  InputContainer,
  MainContainer,
  FundContainer,
  AddressContainer,
} from "./AddEditApartmentPage.styled";
import { PrimaryButton } from "components/shared/Buttons/Buttons.styled";
import ThreeDotsHorizontal from "components/Icon/Icons/ThreeDotsHorizontalIcon";
import { useFormik } from "formik";
import Navbar from "components/shared/Navbar/Navbar";
import Line from "components/shared/Line/Line";
import { useNavigate, useParams } from "react-router-dom";
import {
  useManuallyCreateApartmentMutation,
  useUpdateApartmentDataQuery,
} from "store/apartmentsApiSlice";
import TenantNameField from "components/AddApartmentInputs/TenantNameField";
import TenantLastNameField from "components/AddApartmentInputs/TenantLastNameField";
import addApartmentInitialValues from "initialValues/addApartmentInitialValues";
import PhoneNumberField from "components/AddApartmentInputs/PhoneNumberField";
import EmailSwitchField from "components/AddApartmentInputs/EmailSwitchField";
import ApartmentNumberField from "components/AddApartmentInputs/ApartmentNumberField";
import TenantNumberField from "components/AddApartmentInputs/TenantNumberField";
import CostField from "components/shared/InputFields/CostField/CostField";
import CurrentFund from "components/AddApartmentInputs/CurrentFund";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import EmailField from "components/AddApartmentInputs/EmailField";
import addApartmentValidation from "validations/addApartmentValidation";
import RentSquareFootField from "components/AddApartmentInputs/RentSquareFootField";
import RentSwitchField from "components/AddApartmentInputs/RentSwitchField";
import RentPriceField from "components/AddApartmentInputs/RentPriceField";
import PrefixInputField from "components/shared/InputFields/PrefixField/PrefixInputField";
import RentDetailsField from "components/AddApartmentInputs/RentDetails";
import RentImagesComponent from "components/RentImagesComponent/RentImagesComponent";
import { useUpdateApartmentMutation } from "store/apartmentsApiSlice";
import { BackContainer } from "./../../components/shared/Modals/ConfirmActionModal/ConfirmActionModal.styled";
import LeftArrowIcon from "./../../components/Icon/Icons/LeftArrowIcon";
import { BackText } from "./../../components/ImportTenantsSecondStep/ImportTenantsSecondStep.styled";
import { useMediaQuery } from "@mui/material";
import selectedTheme from "./../../themes/index";
import DeliveryAddressField from "./../../components/AddApartmentInputs/DeliveryAddressField";
import DeliveryCityField from "./../../components/AddApartmentInputs/DeliveryCityField";
import ZipCodeField from "./../../components/AddApartmentInputs/ZipCodeField";
import { useAllCitiesQuery } from "store/citiesApiSlice";

// This page will be responsible for creating (id is undefined) and updating Building (id is not undefined)
const AddEditApartmentPage = () => {
  const matches = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate();
  const { id } = useParams();
  const apartmentId = parseInt(location.href.split("/")[6]);
  const [buildingId, setBuildingId] = useState(null);
  const [editData, setEditData] = useState({});
  const [images, setImages] = useState([]);
  const { data: cities } = useAllCitiesQuery();
  // if  isNaN(apartmentId) return null that means we are on page for creating an apartment
  const { data: initialEditData, isLoading: isLoadingApartment } = isNaN(
    apartmentId
  )
    ? { data: null, isLoading: false }
    : useUpdateApartmentDataQuery(id);

  // eslint-disable-next-line no-unused-vars
  const [manuallyCreateApartment, { isLoading: isLoadingBuilding }] =
    useManuallyCreateApartmentMutation();

  const [updateApartment, { isLoading: isUpdatingApartment }] =
    useUpdateApartmentMutation();

  function getSubstringBetween(str, startSubstr, endSubstr) {
    const startIndex = str.indexOf(startSubstr);
    if (startIndex === -1) return ""; // Start substring not found

    const endIndex = str.indexOf(endSubstr, startIndex + startSubstr.length);
    if (endIndex === -1) return ""; // End substring not found

    return str.slice(startIndex + startSubstr.length, endIndex);
  }

  //console.log(initialEditData);

  const extractBuildingId = () => {
    const url = location.href;
    const validEditNumber = getSubstringBetween(
      url,
      "buildings/",
      "/edit-apartment"
    );
    if (validEditNumber !== "") setBuildingId(validEditNumber);
    else setBuildingId(id);
  };

  //console.log(initialEditData.c);

  const handleSubmit = async () => {
    //extract formik values

    let {
      email,
      firstName,
      lastName,
      numOfApartment,
      numOfTenants,
      defaultRentPrice,
      electronicDelivery,
      currentState,
      squareFootage,
      rentalInformations,
      isAvailableForRent,
      phoneNumber,
      zipCode,
      deliveryAddress,
      deliveryCityId,
    } = formik.values;

    const DataObject = {
      Email: email,
      FirstName: firstName,
      LastName: lastName,
      PhoneNumber: phoneNumber === null ? null : phoneNumber.toString(),
      NumOfApartment: numOfApartment.toString(),
      NumOfTenants: numOfTenants,
      ElectronicDelivery: electronicDelivery,
      CurrentState: currentState,
      BuildingId: buildingId,
      isAvailableForRent: isAvailableForRent,
      SquareFootage: squareFootage,
      DefaultRentPrice: !isAvailableForRent ? 0 : defaultRentPrice,
      RentalInformations: !isAvailableForRent ? null : rentalInformations,
      DeliveryCityId: deliveryCityId,
      DeliveryAddress: deliveryAddress,
      DeliveryZipCode: zipCode,
      Images: images,
    };
    if (email !== undefined && email !== "") {
      DataObject.Email = email;
    } else {
      DataObject.Email = null;
    }

    const returnImages = () => {
      const Images = [];
      if (images === undefined) return [];
      for (let i = 0; i < images.length; i++) {
        if (images[i].imageId !== undefined) {
          Images.push({
            File: null,
            ImageId: images[i].imageId,
          });
        } else {
          Images.push({
            File: images[i].file,
            ImageId: "",
          });
        }
      }
      return Images;
    };

    isNaN(apartmentId) === true
      ? manuallyCreateApartment(DataObject)
          .unwrap()
          .then(() => {
            makeToastMessage("Uspešno ste kreirali stan");
            navigate(`/buildings/${buildingId}`);
          })
          .catch(() => {
            makeErrorToastMessage("Niste uspešno kreirali stan");
          })
      : updateApartment({
          formData: {
            ...formik.values,
            numOfApartment: formik.values.numOfApartment.toString(),
            buildingId: buildingId,
            isAvailableForRent: formik.values.isAvailableForRent,
            zipCode: formik.values.zipCode,
            UploadedImages: returnImages(),
            apartmentId: apartmentId,
          },
        })
          .unwrap()
          .then(() => {
            makeToastMessage("Uspešno ste izmenili stan");
            navigate(`/buildings/${buildingId}/apartments/${apartmentId}`);
          })
          .catch(() => {
            //console.log(e);
            // makeErrorToastMessage(
            //   e.data.Errors[0] !== undefined
            //     ? e.data.Errors[0].Messages[0].Message
            //     : "Niste uspešno izmenili stan"
            // );
            makeErrorToastMessage("Niste uspešno izmenili stan");
          });
  };

  //map apartment attributes to initial data values for formik
  useEffect(() => {
    setEditData({
      firstName: initialEditData?.firstName,
      lastName: initialEditData?.lastName,
      email: initialEditData?.email,
      electronicDelivery: initialEditData?.electronicDelivery ? true : false,
      phoneNumber:
        initialEditData?.phoneNumber === null
          ? null
          : initialEditData?.phoneNumber.toString(),
      numOfApartment: initialEditData?.numOfApartment.toString(),
      numOfTenants: initialEditData?.numOfTenants,
      currentState: initialEditData?.currentState,
      isAvailableForRent: initialEditData?.isAvailableForRent ? true : false,
      squareFootage: initialEditData?.squareFootage,
      defaultRentPrice: initialEditData?.defaultRentPrice,
      rentalInformations: initialEditData?.rentalInformations,
      deliveryCityId: initialEditData?.cityOfDeliveryId,
      deliveryAddress: initialEditData?.deliveryAddress,
      zipCode: parseInt(initialEditData?.deliveryZipCode),
      images: initialEditData?.images,
    });
  }, [initialEditData]);

  useEffect(() => {
    setImages(editData.images);
  }, [editData]);

  const formik = useFormik({
    initialValues: isNaN(apartmentId) ? addApartmentInitialValues : editData,
    validationSchema: addApartmentValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    extractBuildingId();
  }, []);

  const tranformDataForSelectInput = () => {
    return cities.map((obj) => {
      return {
        value: obj.id,
        label: obj.name,
      };
    });
  };

  //console.log(initialEditData);

  return isLoadingBuilding || isLoadingApartment || isUpdatingApartment ? (
    <p>Loading...</p>
  ) : (
    <AddEditApartmentPageContainer
      onSubmit={formik.handleSubmit}
      component="form"
    >
      <Navbar />
      <MainContainer>
        {matches ? (
          <BackContainer>
            <LeftArrowIcon
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`/buildings/${buildingId}`)}
            />
            <BackText>Nazad</BackText>
          </BackContainer>
        ) : (
          <NavigationContainer>
            <BuildingNavigationIcon
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/buildings")}
            />
            <RightArrowNavigationIcon style={{ marginLeft: "20px" }} />
            <NavigationItem>{`Zgrada${buildingId}`}</NavigationItem>
            <RightArrowNavigationIcon style={{ marginLeft: "20px" }} />
            <NavigationItem>
              {id === buildingId ? "Novi stan" : "Izmeni stan"}
            </NavigationItem>
          </NavigationContainer>
        )}

        <TopContainer>
          <Header>{id === buildingId ? "Novi stan" : "Izmeni stan"}</Header>
          {!matches && (
            <ButtonsContainer>
              <PrimaryButton style={{ marginRight: "12px", height: "45px" }}>
                <ThreeDotsHorizontal />
              </PrimaryButton>
              <PrimaryButton
                style={{ marginRight: "12px" }}
                onClick={() => navigate(`/buildings/${buildingId}`)}
              >
                Odustani
              </PrimaryButton>
              <PrimaryButton type="submit">
                {id !== buildingId ? "Potvrdi" : "Sacuvaj"}
              </PrimaryButton>
            </ButtonsContainer>
          )}
        </TopContainer>
        {!matches && <Line />}
        <ContentContainer>
          <ContentSubContainer>
            <InformationContainer style={{ width: "280px" }}>
              <InformationMainText>Osnovne informacije</InformationMainText>
              <InformationSubText>
                Najosnovnije informacije o stanu
              </InformationSubText>
            </InformationContainer>
            <Table>
              <AddressContainer>
                <InputContainer>
                  <TenantNameField formik={formik} style={{ flex: "1 1 0%" }} />
                </InputContainer>
                <InputContainer style={{ marginLeft: !matches ? "24px" : "0" }}>
                  <TenantLastNameField
                    formik={formik}
                    style={{ flex: "1 1 0%" }}
                  />
                </InputContainer>
              </AddressContainer>
              <InputContainer style={{ marginTop: !matches ? "24px" : "0" }}>
                <PhoneNumberField formik={formik} style={{ flex: "1 1 0%" }} />
              </InputContainer>
              <InputContainer style={{ marginTop: !matches ? "24px" : "0" }}>
                <EmailField formik={formik} style={{ flex: "1 1 0%" }} />
              </InputContainer>
              <InputContainer style={{ marginTop: !matches ? "24px" : "0" }}>
                <EmailSwitchField
                  style={{ flex: "1 1 0%" }}
                  formik={formik}
                  defaultValue={
                    isNaN(apartmentId)
                      ? false
                      : initialEditData?.electronicDelivery
                  }
                />
              </InputContainer>
              <AddressContainer>
                <InputContainer>
                  <ApartmentNumberField
                    formik={formik}
                    style={{ flex: "1 1 0%" }}
                  />
                </InputContainer>
                <InputContainer style={{ marginLeft: !matches ? "24px" : "0" }}>
                  <TenantNumberField
                    formik={formik}
                    style={{ flex: "1 1 0%" }}
                  />
                </InputContainer>
              </AddressContainer>
              <InputContainer>
                <RentSquareFootField
                  formik={formik}
                  style={{ flex: "1 1 0%" }}
                />
              </InputContainer>
            </Table>
          </ContentSubContainer>
          <ContentSubContainer style={{ marginTop: !matches ? "24px" : "0" }}>
            <InformationContainer style={{ width: "280px" }}>
              <InformationMainText>Informacije o dostavi</InformationMainText>
              <InformationSubText>
                Ovde možete podesiti posebnu adresu na koju cete poslati račune
              </InformationSubText>
            </InformationContainer>
            <Table>
              <InputContainer>
                <DeliveryAddressField
                  formik={formik}
                  style={{ flex: "1 1 0%" }}
                />
              </InputContainer>
              <AddressContainer>
                <InputContainer>
                  {cities && (
                    <DeliveryCityField
                    formik={formik}
                    style={{ flex: "1 1 0%" }}
                    options={tranformDataForSelectInput()}
                    defaultValue={
                      id === undefined
                        ? 1
                        : tranformDataForSelectInput().filter(
                            (k) => k.value === initialEditData?.cityOfDeliveryId
                          )[0]
                    }
                  />
                  )}
                  
                </InputContainer>
                <InputContainer style={{ marginLeft: !matches ? "24px" : "0" }}>
                  <ZipCodeField formik={formik} style={{ flex: "1 1 0%" }} />
                </InputContainer>
              </AddressContainer>
            </Table>
          </ContentSubContainer>
          <ContentSubContainer style={{ marginTop: !matches ? "24px" : "0" }}>
            <InformationContainer style={{ width: "280px" }}>
              <InformationMainText>Finansije</InformationMainText>
              <InformationSubText>Opis Finansija</InformationSubText>
            </InformationContainer>
            <Table>
              <FundContainer>
                <InputContainer>
                  <CostField
                    Field={
                      <CurrentFund
                        formik={formik}
                        labelClass="cost-field"
                        style={{
                          flex: "1 1 0%",
                          width: "100%",
                          borderBottomLeftRadius: "0px",
                          borderTopLeftRadius: "0px",
                        }}
                      />
                    }
                  />
                </InputContainer>
              </FundContainer>
            </Table>
          </ContentSubContainer>
        </ContentContainer>
        {!matches && <Line />}
        <ContentContainer>
          <ContentSubContainer>
            <InformationContainer style={{ width: "280px" }}>
              <InformationMainText>Izdavanje stana</InformationMainText>
              <InformationSubText>Detalji o izdavanju stana</InformationSubText>
            </InformationContainer>
            <Table>
              <InputContainer style={{ marginTop: !matches ? "24px" : "0" }}>
                <RentSwitchField
                  style={{ flex: "1 1 0%" }}
                  formik={formik}
                  defaultValue={
                    isNaN(apartmentId)
                      ? false
                      : initialEditData?.isAvailableForRent
                  }
                />
              </InputContainer>
              <AddressContainer>
                <InputContainer>
                  <PrefixInputField
                    Field={
                      <RentPriceField
                        formik={formik}
                        labelClass=""
                        style={{
                          flex: "1 1 0%",
                          width: "100%",
                          borderBottomLeftRadius: "0px",
                          borderTopLeftRadius: "0px",
                        }}
                      />
                    }
                  />
                </InputContainer>
              </AddressContainer>
              <InputContainer style={{ marginTop: !matches ? "24px" : "0" }}>
                <RentDetailsField formik={formik} style={{ flex: "1 1 0%" }} />
              </InputContainer>
              <InputContainer
                style={{ marginTop: !matches ? "24px" : "0" }}
              ></InputContainer>
              <RentImagesComponent images={images} setImages={setImages} />
            </Table>
          </ContentSubContainer>
        </ContentContainer>
        <Line />
        <TopContainer
          style={{ justifyContent: !matches ? "flex-end" : "center" }}
        >
          <ButtonsContainer>
            <PrimaryButton
              style={{
                marginRight: !matches ? "12px" : "0",
                width: !matches ? "auto" : "100%",
              }}
              onClick={() => navigate(`/buildings/${buildingId}`)}
            >
              Odustani
            </PrimaryButton>
            <PrimaryButton
              type="submit"
              style={{
                height: "45px",
                width: !matches ? "auto" : "100%",
                backgroundColor: selectedTheme.colors.primary[600],
                color: selectedTheme.colors.base.white,
              }}
            >
              {id !== buildingId ? "Potvrdi" : "Sacuvaj"}
            </PrimaryButton>
          </ButtonsContainer>
        </TopContainer>
      </MainContainer>
    </AddEditApartmentPageContainer>
  );
};

export default AddEditApartmentPage;
