import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  ImportTenantsMainText,
  TextContainer,
  ImportTenantsText,
  FileContainer,
  LeftFileContainer,
  FileDetailsContainer,
  FileName,
  FileSize,
  CheckIconContainer,
  RightFileContainer,
  ButtonsContainer,
  TopContainer,
  InputContainer,
} from "./ImportPayments.styled";
import { PrimaryButton, SecondaryButton } from "components/shared/Buttons/Buttons.styled";
import selectedTheme from "../../themes";
import { useParams } from "react-router-dom";
import BinIcon from "components/Icon/Icons/BinIcon";
import CheckIcon from "components/Icon/Icons/CheckIcon";
import File2Icon from "components/Icon/Icons/File2Icon";
import {
  makeErrorToastMessage,
  makeToastMessage,
} from "../../util/toastMessage";
import UploadInput from "components/shared/UploadInput/UploadInput";
import UploadIcon from "components/Icon/Icons/UploadIcon";
import CloseIcon from "components/Icon/Icons/CloseIcon";
import { useImportPaymentsMutation } from "store/debtPaymentsApiSlice";

const ImportPaymentsFirstStep = ({
  handleClose,
  // eslint-disable-next-line no-unused-vars
  setPayments,
  setSnapshot,
  isLoading,
  isError,
  setFirstStep,
}) => {
  const { id } = useParams();
  const [file, setFile] = useState(null);
  const [importPayments] = useImportPaymentsMutation();

  const hiddenFileInput = useRef(null);

  const handleFileChange = (e) => {
    const maxFileSize = 300 * 1048576; // 300 MB
    if (e.target.files[0].size > maxFileSize) {
      makeErrorToastMessage("Fajl ne sme biti veći od 300 MB!");
      hiddenFileInput.current.value = null;
      return;
    }
    setFile(e.target.files[0]);
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", file);
    formData.append("id", id);

    importPayments(formData)
      .unwrap()
      .then((res) => {
          setPayments(JSON.parse(JSON.stringify(res,null,2)));
          localStorage.setItem('payments', JSON.stringify(res,null,2));
          setSnapshot(JSON.parse(localStorage.getItem('payments')))
          setFirstStep(false);
          makeToastMessage("Uspešno ste izvršili dodavanje uplata");
        }
      )
      .catch(() => {
        makeErrorToastMessage("Nije uspešno izvršen upload uplata!");
      });
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const removeFile = () => {
    hiddenFileInput.current.value = null;
    setFile(null);
  };

  return isLoading ? (
    <p>Uploading...</p>
  ) : (
    <>
        <TopContainer>
          <UploadIcon />
          <CloseIcon
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </TopContainer>
        <TextContainer style={{border:"none"}}>
          <ImportTenantsMainText>Importuj uplate</ImportTenantsMainText>
          <ImportTenantsText>
            Moguće je izmeniti neuploadovane stanove u sledećem koraku
          </ImportTenantsText>
        </TextContainer>
        <InputContainer style={{width:"600px"}}>
        <UploadInput
          mainText="Klikni da uploaduješ stanare"
          helperText="EXCEL (max. 300MB)"
          handleClick={handleClick}
          handleFileChange={handleFileChange}
          hiddenFileInput={hiddenFileInput}
          file={file}
          accept=".xlsx"
          isError={isError}
          style={{ marginTop: "18px" }}
          changeNameAfterUpload={false}
        />
        {file === null ? null : (
          <FileContainer>
            <LeftFileContainer>
              <File2Icon />
              <FileDetailsContainer>
                <FileName>{file.name}</FileName>
                <FileSize>
                  {(file.size / 1048576).toString().slice(0, 4)} MB
                </FileSize>
              </FileDetailsContainer>
            </LeftFileContainer>
            <RightFileContainer>
              <BinIcon
                style={{ marginRight: "25px", cursor: "pointer" }}
                onClick={removeFile}
              />
              <CheckIconContainer>
                <CheckIcon />
              </CheckIconContainer>
            </RightFileContainer>
          </FileContainer>
        )}
        </InputContainer>
        
        

        <ButtonsContainer>
          <PrimaryButton style={{ width: "50%" }} onClick={handleClose}>
            Odustani
          </PrimaryButton>
          <SecondaryButton
            onClick={() => handleSubmit(event)}
            style={{
              width: "50%",
              marginLeft: "12px",
              color: selectedTheme.colors.base.white,
            }}
            backgroundcolor={file === null
              ? selectedTheme.colors.primary[200]
              : selectedTheme.colors.primary[600]}
            disabled={file === null ? true : false}
          >
            Importuj
          </SecondaryButton>
        </ButtonsContainer>
    </>
  );
};

ImportPaymentsFirstStep.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOpen: PropTypes.func,
  setPayments: PropTypes.func,
  setSnapshot: PropTypes.func,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  firstStep: PropTypes.bool,
  setFirstStep: PropTypes.func,
};

export default ImportPaymentsFirstStep;
