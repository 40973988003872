import React from "react";
import {
  BuildingsPageContainer,
  BuildingsPageSubContainer,
  ContentContainer,
} from "./BuildingsPage.styled";
import Navbar from "components/shared/Navbar/Navbar";
import HeaderComponent from "components/shared/Header/BuildingsPage/HeaderComponent";
import TabsComponent from "components/shared/Tab/TabsComponent";
import AllBuildingsOverviewComponent from "components/AllBuildingsOverviewComponent/AllBuildingsOverviewComponent";
import { useBuildingsInformationsQuery } from "store/buildingsApiSlice";

function BuildingsPage() {
  const { data, isLoading } = useBuildingsInformationsQuery();
  const tabs = [
    {
      label: "Pregled",
      component: (
        <AllBuildingsOverviewComponent
          buildings={data === undefined ? [] : data.buildings}
          numberOfBuildings={data === undefined ? 0 : data.numberOfBuildings}
          numberOfTenants={data === undefined ? 0 : data.numberOfTenants}
        />
      ),
    },
  ];
  return isLoading ? (
    <p>Loading</p>
  ) : (
    <BuildingsPageContainer>
      <BuildingsPageSubContainer>
        <Navbar />
        <ContentContainer>
          <HeaderComponent />
          <TabsComponent tabs={tabs} />
        </ContentContainer>
      </BuildingsPageSubContainer>
    </BuildingsPageContainer>
  );
}

export default BuildingsPage;
