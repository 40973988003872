import { useSelector } from "react-redux";
import jwt from "jwt-decode";

const getToken = () => {
  return useSelector((state) => state.auth.token).token;
};

export const getUserRole = () => {
  return jwt(getToken())[
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
  ];
};
