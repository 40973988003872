import { Dialog, Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const ImportTenantsContainer = styled(Dialog)``;

export const ImportTenantsSubContainer = styled(Box)`
  padding: 24px;
`;

export const TopContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TextContainer = styled(Box)`
  margin-top: 16px;
`;

export const ImportTenantsMainText = styled(Typography)`
  font-family: ${selectedTheme.type.textLg.semibold.fontFamily};
  font-size: ${selectedTheme.type.textLg.semibold.fontSize};
  font-style: ${selectedTheme.type.textLg.semibold.fontStyle};
  font-weight: ${selectedTheme.type.textLg.semibold.fontWeight};
  color: ${selectedTheme.colors.gray[900]};
`;

export const ImportTenantsText = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.regular.fontFamily};
  font-size: ${selectedTheme.type.textSm.regular.fontSize};
  font-style: ${selectedTheme.type.textSm.regular.fontStyle};
  font-weight: ${selectedTheme.type.textSm.regular.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
`;

export const DownloadContainer = styled(Box)`
  display: flex;
  align-items: center;
  border: 1px solid ${selectedTheme.colors.gray[200]};
  box-shadow: ${selectedTheme.effects.shadow.sm.boxShadow};
  padding: 20px;
  margin-top: 40px;
  border-radius: 12px;
  margin-left: 20px;
  margin-right: 20px;
`;

export const DownloadTextContainer = styled(Box)`
  margin-left: 24px;
`;

export const DownloadMainText = styled(Typography)`
  font-family: ${selectedTheme.type.textMd.semibold.fontFamily};
  font-size: ${selectedTheme.type.textMd.semibold.fontSize};
  font-style: ${selectedTheme.type.textMd.semibold.fontStyle};
  font-weight: ${selectedTheme.type.textMd.semibold.fontWeight};
  color: ${selectedTheme.colors.gray[700]};
`;

export const DownloadText = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.regular.fontFamily};
  font-size: ${selectedTheme.type.textSm.regular.fontSize};
  font-style: ${selectedTheme.type.textSm.regular.fontStyle};
  font-weight: ${selectedTheme.type.textSm.regular.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
`;

export const FileContainer = styled(Box)`
  display: flex;
  border-radius: 12px;
  border: 1px solid #155eef;
  justify-content: space-between;
  align-items: center;
  padding-left: 14px;
  padding-right: 28px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-top: 16px;
`;

export const LeftFileContainer = styled(Box)`
  display: flex;
  align-items: center;
`;
export const RightFileContainer = styled(Box)`
  display: flex;
`;

export const FileDetailsContainer = styled(Box)`
  margin-left: 14px;
`;

export const FileName = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.medium.fontFamily};
  font-size: ${selectedTheme.type.textSm.medium.fontSize};
  font-style: ${selectedTheme.type.textSm.medium.fontStyle};
  font-weight: ${selectedTheme.type.textSm.medium.fontWeight};
  color: ${selectedTheme.colors.gray[700]};
`;

export const FileSize = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.regular.fontFamily};
  font-size: ${selectedTheme.type.textSm.regular.fontSize};
  font-style: ${selectedTheme.type.textSm.regular.fontStyle};
  font-weight: ${selectedTheme.type.textSm.regular.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
`;

export const CheckIconContainer = styled(Box)`
  display: flex;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #155eef;
  justify-content: center;
  align-items: center;
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  margin-top: 50px;
`;
