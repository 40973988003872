import React from "react";
import PropTypes from "prop-types";
import { addUserInitialConstants } from "initialValues/addUserInitialValues";
import TextInputField from "components/shared/InputFields/TextField/TextField";

const PasswordField = (props) => {
  const formik = props?.formik;

  return (
    <TextInputField
      id={addUserInitialConstants.password}
      name={addUserInitialConstants.password}
      placeholder="Unesite zeljenu lozinku"
      label="Lozinka"
      value={formik.values.password}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched?.[addUserInitialConstants.password] && formik.errors?.[addUserInitialConstants.password]}
      apiError={props.apiError}
      style={props?.style}
      type="password"
    />
  );
};

PasswordField.propTypes = {
  formik: PropTypes.object,
  apiError: PropTypes.any,
  style: PropTypes.object,
};

export default PasswordField;
