import React from "react";
import { ReactComponent as CheckIconBlueSvg } from "../../../assets/Icons/checkIconBlue.svg";

const CheckIconBlue = (props) => {
  return <CheckIconBlueSvg {...props} />;
};

CheckIconBlue.propTypes = {};

export default CheckIconBlue;
