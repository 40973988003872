import { createSlice } from "@reduxjs/toolkit";
import generate from "util/randomData";
import { createSelector } from "reselect";

const randomDataSlice = createSlice({
  name: "randomData",
  initialState: {
    items: [],
    filteredItems: [],
    count: 0,
    page: 0,
    itemsPerPage: 12,
    filter: "",
    sort: "",
  },
  reducers: {
    loadRandomData: (state, action) => {
      const count = action.payload;
      const generatedItems = generate(count);

      state.items = generatedItems;
      state.filteredItems = generatedItems;
      state.count = generatedItems.length;
    },
    updatePage: (state, action) => {
      state.page = action.payload;
    },
    updateItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    updateFilter: (state, action) => {
      const filter = action.payload;
      const filteredItems = filter
        ? state.items.filter((item) =>
            item.name.toLowerCase().includes(filter.toLowerCase())
          )
        : state.items;

      state.filter = filter;
      state.filteredItems = filteredItems;
      state.count = filteredItems.length;
    },
    updateSort: (state, action) => {
      const sort = action.payload;
      const [field, direction] = sort.split("-");

      const sortDirection = direction === "asc" ? 1 : -1;

      const dataItems = state.filteredItems.length
        ? state.filteredItems
        : state.items;

      const sorted = [...dataItems].sort((a, b) => {
        if (a[field] > b[field]) {
          return sortDirection;
        }
        if (b[field] > a[field]) {
          return sortDirection * -1;
        }
        return 0;
      });

      state.sort = sort;
      state.filteredItems = sorted;
    },
  },
});

export const {
  loadRandomData,
  updatePage,
  updateItemsPerPage,
  updateFilter,
  updateSort,
} = randomDataSlice.actions;
export default randomDataSlice.reducer;

// Random data selectors

const randomDataSelector = (state) => state.randomData;
export const itemsSelector = createSelector(
  randomDataSelector,
  (state) => state.filteredItems
);

export const pageSelector = createSelector(
  randomDataSelector,
  (state) => state.page
);

export const itemsPerPageSelector = createSelector(
  randomDataSelector,
  (state) => state.itemsPerPage
);

export const countSelector = createSelector(
  randomDataSelector,
  (state) => state.count
);

export const filterSelector = createSelector(
  randomDataSelector,
  (state) => state.filter
);

export const sortSelector = createSelector(
  randomDataSelector,
  (state) => state.sort
);
