import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import {loginFormikConstants} from "../../../../initialValues/loginInitialValues";
import TextInputField from "components/shared/InputFields/TextField/TextField";

const LoginEmailField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;

  return (
      <TextInputField
        id={loginFormikConstants.email}
        name={loginFormikConstants.email}
        placeholder={t("login.emailPlaceholder")}
        label={t("login.emailLabel")}
        value={formik.values.email}
        onChange={formik.handleChange}
        type="email"
        onBlur={formik.handleBlur}
        error={formik.touched?.[loginFormikConstants.email] && formik.errors?.[loginFormikConstants.email]}
        helperText={i18n.login && i18n.login?.emailHintText && i18n.login?.emailHintText !== "" && i18n.t("login.emailHintText")}
        apiError={props.apiError}
      />
  );
};

LoginEmailField.propTypes = {
  formik: PropTypes.object,
  apiError:PropTypes.any,
};

export default LoginEmailField;
