import React from "react";
import PropTypes from "prop-types";
import {
  AllStatsContainer,
  CardsContainer,
  OverviewContainer,
  Stat,
  StatLink,
  StatTitle,
  StatsContainer,
  StatRow,
} from "./OverviewTabsContent.styled";

import { Row } from "components/Login/LoginForm/LoginForm.styled";
import AddressIcon from "components/Icon/Icons/AddressIcon";
import Nugget from "components/shared/Nugget/Nugget";
import FinantialWidgetComponent from './../FinantialWidgetComponent/FinantialWidgetComponent';

const OverviewTabsContent = ({ apartment, finance }) => {
  console.log(finance);
  return (
    <>
      <OverviewContainer>
        <AllStatsContainer>
          <StatsContainer>
            <StatTitle>Vlasnik</StatTitle>
            <Stat>
              {apartment.tenant.firstName} {apartment.tenant.lastName}
            </Stat>
          </StatsContainer>
          <StatsContainer>
            <StatTitle>Email</StatTitle>
            <Row gap={16}>
              <StatLink>
                {apartment.tenant.email === null
                  ? "/"
                  : apartment.tenant.email}
              </StatLink>
              {apartment.electronicDelivery && (
                <Nugget dot={true} state={"success"}>
                  E-dostava
                </Nugget>
              )}
            </Row>
          </StatsContainer>
          <StatsContainer>
            <StatTitle>Br spratova</StatTitle>
            <Stat>{apartment.numOfFloor}</Stat>
          </StatsContainer>
          <StatsContainer>
            <StatTitle>Adresa naplate</StatTitle>
            <StatRow gap={8}>
              <AddressIcon />
              <Stat>{apartment.address}, Beograd</Stat>
            </StatRow>
          </StatsContainer>
        </AllStatsContainer>
        <CardsContainer>
          <FinantialWidgetComponent finance ={finance}/>
        </CardsContainer>
      </OverviewContainer>
    </>
  );
};

OverviewTabsContent.propTypes = {
  apartment: PropTypes.object,
  finance: PropTypes.object,
};

export default OverviewTabsContent;
