import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as CheveronRight } from "../../../assets/Icons/chevron-right.svg";

const BreadCrumbArrow = (props) => {
  return <CheveronRight {...props} />;
};

BreadCrumbArrow.propTypes = {};

export default BreadCrumbArrow;
