import React, { useState } from "react";
import {
  ApartmentsOverviewContainer,
  ApartmentsOverviewSubContainer,
  ButtonsContainer,
  FilterContainer,
} from "./ApartmentsOverviewComponent.styled";
import {
  PrimaryButton,
  SecondaryButton,
} from "components/shared/Buttons/Buttons.styled";
import SearchInputField from "components/shared/SearhInputField/SearchInputField";
//import FilterIcon from "components/Icon/Icons/FilterIcon";
import ImportIcon from "components/Icon/Icons/ImportIcon";
import AddIcon from "components/Icon/Icons/AddIcon";
import PropTypes from "prop-types";
import PrimaryTable from "components/shared/Tables/PrimaryTable";
import selectedTheme from "../../themes";
import ImportTenantsFirstStep from "components/ImportTenatsFirstStepComponent/ImportTenantsFirstStep";
import ImportTenantsSecondStep from "components/ImportTenantsSecondStep/ImportTenantsSecondStep";
import { useImportTenantsMutation } from "store/buildingsApiSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useApartmentPaymentsMutation } from "store/exportApiSlice";
import { formatNumber } from "util/helpers/numberHelpers";
import useMediaQuery from "@mui/material/useMediaQuery";
import UploadCloudIcon from "components/Icon/Icons/UploadCloudIcon";
import ImportPayments from "components/ImportPayments/ImportPayments";

const ApartmentsOverviewComponent = ({
  apartments,
  selectedOption,
  handleDialogToggle,
}) => {
  const matches = useMediaQuery("(max-width:768px)");
  const matchesUnder400 = useMediaQuery("(max-width:400px)");
  const { id } = useParams();
  const navigate = useNavigate();
  const [openPaymentsDialog, setOpenPaymentsDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [importTenants, { isLoading, isError, data }] =
    useImportTenantsMutation(); // data = [{isNumberOfApartmentValid:true,isObjectCodeValid:false,apartment:{}},{}...]
  const [getApartmentPayments] = useApartmentPaymentsMutation();

  const [searchValue, setSearchValue] = useState("");
  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleOpenPaymentsDialog = () => {
    setOpenPaymentsDialog(true);
  }
  const handleClosePaymentsDialog = () => {
    setOpenPaymentsDialog(false);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const returnToStepOne = () => {
    setOpen2(false);
    setOpen(true);
  };

  const columns = [
    "ID",
    "Vlasnik",
    "",
    "Br. stana",
    "Br. Stanara",
    "Trenutno Stanje",
    "null",
  ];

  // we need to pass bills to PrimaryTable here
  apartments = apartments.map(
    ({
      id,
      isAvailableForRent,
      numOfApartment,
      numOfTenants,
      tenant,
      currentState,
      bills,
    }) => [
      id,
      tenant.firstName + " " + tenant.lastName,
      isAvailableForRent,
      numOfApartment,
      numOfTenants,
      formatNumber(currentState) + "rsd",
      bills,
    ]
  );
  const pageSize = 8;

  const handleEditClick = (rowId) => {
    const buildingId = id;
    const apartmentId = rowId;
    navigate(`/buildings/${buildingId}/edit-apartment/${apartmentId}`);
  };

  const handleRowClick = (row) => {
    const buildingId = id;
    const apartmentId = row;
    navigate(`/buildings/${buildingId}/apartments/${apartmentId}`);
  };

  // apply filtering nased on tenant name
  const applyFiltering = () => {
    return apartments.filter((d) =>
      d[1].toLowerCase().includes(searchValue.toLowerCase())
    );
  };

  // add unique key to every item
  const transformData = () => {
    if (open2 === false) return [];
    return data.map((apartment, index) => ({
      ...apartment,
      key: index,
    }));
  };

  return (
    <ApartmentsOverviewContainer>
      {/* import payments Dialog */}
      <ImportPayments
        open={openPaymentsDialog}
        handleClose={handleClosePaymentsDialog}
        handleOpen={handleOpenPaymentsDialog}
        importTenants={importTenants}
        isLoading={isLoading}
        isError={isError}
      />
      <ImportTenantsFirstStep
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen2}
        importTenants={importTenants}
        isLoading={isLoading}
        isError={isError}
      />
      <ImportTenantsSecondStep
        open={open2}
        handleOpen={handleOpen2}
        handleClose={handleClose2}
        apartments={data === undefined ? [] : transformData()}
        returnToStepOne={returnToStepOne}
      />
      <ApartmentsOverviewSubContainer>
        <FilterContainer>
          {/* <PrimaryButton>
            <FilterIcon />
            {matches ? "" : "Filter"}
          </PrimaryButton> */}
          <SearchInputField
            style={{
              width: matches && "100%",
              marginLeft: !matches && "16px",
              marginRight: "16px",
            }}
            searchValue={searchValue}
            handleInputChange={handleInputChange}
          />
        </FilterContainer>
        <ButtonsContainer>
        <PrimaryButton
            onClick={handleOpenPaymentsDialog}
            style={{
              marginRight: matches ? "12px" : "28px",
              flex: matches && 1,
              width: matchesUnder400 && "100%",
              marginBottom: matchesUnder400 && "8px",
            }}
          >
            <UploadCloudIcon />
            Importuj uplate
          </PrimaryButton>
          <PrimaryButton
            onClick={handleOpen}
            style={{
              marginRight: matches ? "12px" : "28px",
              flex: matches && 1,
              width: matchesUnder400 && "100%",
              marginBottom: matchesUnder400 && "8px",
            }}
          >
            <ImportIcon />
            Importuj Stanare
          </PrimaryButton>
          <SecondaryButton
            style={{ flex: matches && 1 }}
            onClick={() => navigate(`/buildings/${id}/add-apartment`)}
          >
            <AddIcon />
            Dodaj Stan
          </SecondaryButton>
        </ButtonsContainer>
      </ApartmentsOverviewSubContainer>
      <PrimaryTable
        passedBuildingId={id}
        data={applyFiltering()}
        columns={columns}
        pageSize={pageSize}
        handleRowClick={handleRowClick}
        handleEditClick={handleEditClick}
        style={{
          marginTop: "24px",
          border: `1px solid ${selectedTheme.colors.gray[200]}`,
          boxShadow: `${selectedTheme.effects.shadow.sm.boxShadow}`,
        }}
        isBuildingsTable={false}
        isBuildingDetailsTable={true}
        getApartmentPayments={getApartmentPayments}
        handleDialogToggle={handleDialogToggle}
        selectedOption={selectedOption}
      />
    </ApartmentsOverviewContainer>
  );
};

ApartmentsOverviewComponent.propTypes = {
  apartments: PropTypes.array,
  selectedOption: PropTypes.any,
  handleDialogToggle: PropTypes.func,
};

export default ApartmentsOverviewComponent;
