import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const TotalBuildingsWidgetContainer = styled(Box)`
  border-radius: 12px;
  border: 1px solid ${selectedTheme.colors.gray[200]};
  box-shadow: ${selectedTheme.effects.shadow.sm.boxShadow};
  flex-basis: 35%;
  background-color: ${selectedTheme.colors.base.white};
`;

export const NotificationText = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.medium.fontFamily};
  font-size: ${selectedTheme.type.textSm.medium.fontSize};
  font-style: ${selectedTheme.type.textSm.medium.fontStyle};
  font-weight: ${selectedTheme.type.textSm.medium.fontWeight};
  color: ${selectedTheme.colors.gray[900]};
  height: 300px;
  margin-left: 24px;
`;
