import React from "react";
import {
  BillCardContainer,
  BillCardDetailsContainer,
  BillCardSubContainer,
  CustomTable,
  TableHead,
  DetailsButton,
  HeadRow,
  MainText,
  TextContainer,
  ContentRow,
  TableDataCell,
  ProfitContainer,
} from "./BillCard.styled";
import PropTypes from "prop-types";
import { getMonth } from "../../../util/dateHelpers";
import selectedTheme from "../../../themes";
import { useNavigate, useParams } from "react-router-dom";
import { formatNumber } from "util/helpers/numberHelpers";
import { useMediaQuery } from "@mui/material";
import  ArrowUpIcon  from "components/Icon/Icons/ArrowUp";
import { PercentageText } from "./../../BuildingsCards/TotalBuildingsCard.styled";

const BillCard = ({ bill }) => {
  const matches = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <BillCardContainer>
      <BillCardSubContainer style={{ padding: !matches && "24px" }}>
        <BillCardDetailsContainer>
          <TextContainer>
            <MainText>
              {getMonth(bill.month)} {bill.year}
            </MainText>
            {bill.profitPerMonth && (
              <ProfitContainer>
                <ArrowUpIcon />
                <PercentageText style={{marginLeft:"0px"}}>{bill.profitPerMonth} RSD</PercentageText>
              </ProfitContainer>
            )}
          </TextContainer>
          {!matches && (
            <DetailsButton
              onClick={() =>
                navigate(
                  `/buildings/${id}/bills/${bill.id}?month=${bill.month}&year=${bill.year}`
                )
              }
            >
              Detaljnije
            </DetailsButton>
          )}
        </BillCardDetailsContainer>
        <CustomTable sx={{ borderRadius: 0 }}>
          <HeadRow>
            <TableHead
              style={{
                borderTopLeftRadius: !matches && "16px",
              }}
            >
              Ime stavke
            </TableHead>
            <TableHead>Tip Naplate</TableHead>
            <TableHead
              style={{
                borderTopRightRadius: !matches && "16px",
              }}
            >
              Iznos
            </TableHead>
          </HeadRow>
          {bill.monthlyItems.map((monthlyItem, index) => (
            <ContentRow key={index}>
              <TableDataCell
                style={{
                  color: selectedTheme.colors.gray[900],
                }}
              >
                {monthlyItem.name}
              </TableDataCell>
              <TableDataCell>{monthlyItem.billingType.name}</TableDataCell>
              <TableDataCell style={{ borderRadius: "16px" }}>
                {formatNumber(monthlyItem.amount)}rsd
              </TableDataCell>
            </ContentRow>
          ))}
        </CustomTable>
        {matches && (
          <DetailsButton
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: 20,
              marginBottom: 24,
              padding: 10,
            }}
            onClick={() =>
              navigate(
                `/buildings/${id}/bills/${bill.id}?month=${bill.month}&year=${bill.year}`
              )
            }
          >
            Detaljnije
          </DetailsButton>
        )}
      </BillCardSubContainer>
    </BillCardContainer>
  );
};

BillCard.propTypes = {
  bill: PropTypes.object,
};

export default BillCard;
