import React, { useState } from "react";
import PropTypes from "prop-types";
import selectedTheme from "./../../themes/index";
import {
  ImportTenantsMainText,
  TextContainer,
  ImportTenantsText,
  BackContainer,
  BackText,
  TopContainer,
  ButtonsContainer,
  Table,
  TBody,
  TableRow,
  TableHeader,
  TableRow2,
  DataContainer,
  DataSubContainer,
  DataInput,
  ErrorMessage,
  TableSubheader,
  TablesContainer,
  BodyContainer,
  SubheaderTextContainer,
  TableParagraph,
  TableSubheaderError,
  NarrowTableCell,
} from "./ImportPayments.styled";
import {
  PrimaryButton,
  SecondaryButton,
} from "components/shared/Buttons/Buttons.styled";
import LeftArrowIcon from "components/Icon/Icons/LeftArrowIcon";
import CloseIcon from "components/Icon/Icons/CloseIcon";
import ErrorIcon from "components/Icon/Icons/ErrorIcon";
import DeleteIcon from "components/Icon/Icons/DeleteIcon";
import {
  useDeleteImportPaymentMutation,
  useImportPaymentMutation,
} from "store/debtPaymentsApiSlice";
import {
  makeErrorToastMessage,
  makeToastMessage,
} from "../../util/toastMessage";
import { useParams } from "react-router-dom";

const ImportPaymentsSecondStep = ({
  //handleOpen,
  handleClose,
  payments,
  stateSnapshot,
  // eslint-disable-next-line no-unused-vars
  setPayments,
  setSnapshot,
  isLoading,
  //isError,
  setFirstStep,
}) => {
  //console.log(payments.incorrectPayments);

  const { id } = useParams();
  const [apiError, setApiError] = useState("");
  const [importPayment] = useImportPaymentMutation();

  const [deletePayment] = useDeleteImportPaymentMutation();
  //const { id } = useParams();


  // eslint-disable-next-line no-undef, no-unused-vars
  const handleDeletePayment = (payment) => {
    let newState = spliceCorrectState(payment);

    const formData = new FormData();
    formData.append("PaymentAmount", payment.paymentAmount);
    formData.append("DateOfPayment", payment.dateOfPayment);
    formData.append("ReferenceNumber", payment.referenceNumber);
    formData.append("id", id);

    deletePayment(formData)
      .unwrap()
      .then(() => {
        setPayments(newState);
        localStorage.setItem('payments', JSON.stringify(newState,null,2));
        setSnapshot(JSON.parse(localStorage.getItem('payments')))
        setApiError("");
        makeToastMessage("Uspešno ste izvršili dodavanje uplata");
      })
      .catch(() => {
        //setApiError("Racun pogresan");
        makeErrorToastMessage("Nije uspešno izvršen upload uplata!");
      });
  };

  const spliceCorrectState = (payment) => {
    const updatedState = {
      ...payments, // Copy the existing state
      correctPayments: [...payments.correctPayments], // Copy the 'incorrectPayments' array
    }
    // Find the index and remove the element if found
    const indexOfObject = updatedState.correctPayments.findIndex((object) => {
      return (
        object.referenceNumber === payment.referenceNumber &&
        object.paymentAmount === payment.paymentAmount &&
        object.dateOfPayment === payment.dateOfPayment
      );
    });

    if (indexOfObject !== -1) {
      updatedState.correctPayments.splice(indexOfObject, 1); // Remove the element
    }
    return updatedState;
  };

  const spliceIncorrectState = (payment) => {
    const updatedState = {
      ...payments, // Copy the existing state
      incorrectPayments: [...payments.incorrectPayments], // Copy the 'incorrectPayments' array
    }
    // Find the index and remove the element if found
    const indexOfObject = updatedState.incorrectPayments.findIndex((object) => {
      return (
        object.referenceNumber === payment.referenceNumber &&
        object.paymentAmount === payment.paymentAmount &&
        object.dateOfPayment === payment.dateOfPayment
      );
    });

    if (indexOfObject !== -1) {
      updatedState.incorrectPayments.splice(indexOfObject, 1); // Remove the element
    }
    return updatedState;
  };

  const handleImportPayment = (payment, newValue, e) => {
    e.preventDefault();
    //console.log(newValue);

    const formData = new FormData();
    formData.append("PaymentAmount", payment.paymentAmount);
    formData.append("DateOfPayment", payment.dateOfPayment);
    formData.append("ReferenceNumber", newValue);
    formData.append("id", id);

    // eslint-disable-next-line no-unused-vars
    let newState = spliceIncorrectState(payment);

    importPayment(formData)
      .unwrap()
      .then(() => {
        setPayments(newState);
        localStorage.setItem('payments', JSON.stringify(newState,null,2));
        setSnapshot(JSON.parse(localStorage.getItem('payments')))
        setApiError("");
        makeToastMessage("Uspešno ste izvršili dodavanje uplata");
      })
      .catch(() => {
        //setApiError("Racun pogresan");
        makeErrorToastMessage("Nije uspešno izvršen upload uplata!");
      });
  };

  const handleSetPayments = (e,index) => {
      const newValue = e.target.value;
      let updatedPayments = 
        payments.incorrectPayments;
      const newUpdatePayments = [...updatedPayments]
      newUpdatePayments[index].referenceNumber = newValue;
      setPayments({...payments, incorrectPayments: newUpdatePayments})

      };

  function formatDate(date) {
    const day = date.getDate().toString().padStart(2, "0"); // Ensure two-digit day
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Ensure two-digit month (months are zero-based)
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  return isLoading ? (
    <p>Uploading...</p>
  ) : (
    <>
      <TopContainer>
        <ErrorIcon />
        <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
      </TopContainer>
      <TextContainer>
        <ImportTenantsMainText>Greske pri ulati</ImportTenantsMainText>
        <ImportTenantsText>
          Moguće je izmeniti neuploadovane stanove u sledećem koraku
        </ImportTenantsText>
      </TextContainer>
      <BodyContainer>
        {/* First */}
        <TablesContainer>
          <SubheaderTextContainer>
            <TableSubheader>Uspesno importovane uplate</TableSubheader>
            <TableParagraph>
              Pregled i mogucnost brisanja uspesno importovanih uplata
            </TableParagraph>
          </SubheaderTextContainer>

          <Table>
            <TBody>
              <TableRow>
                <TableHeader style={{ borderTopLeftRadius: "12px" }}>
                  Datum
                </TableHeader>
                <TableHeader>Poziv na broj</TableHeader>
                <TableHeader>Iznos</TableHeader>
                <TableHeader
                  style={{ borderTopRightRadius: "12px" }}
                ></TableHeader>
              </TableRow>
              {payments != null &&
                payments.correctPayments.length > 0 &&
                payments.correctPayments.map((item, index) => {
                  const day = parseInt(item.dateOfPayment.substr(0, 2), 10);
                  const month =
                    parseInt(item.dateOfPayment.substr(2, 2), 10) - 1; // Months in JavaScript are 0-based, so we subtract 1
                  const year = parseInt(item.dateOfPayment.substr(4, 4), 10);

                  // Create a Date object
                  const dateObject = new Date(year, month, day);
                  return (
                    <TableRow2 key={index}>
                      <NarrowTableCell>
                        {formatDate(dateObject)}
                      </NarrowTableCell>
                      <NarrowTableCell>{item.referenceNumber}</NarrowTableCell>
                      <NarrowTableCell>{item.paymentAmount}</NarrowTableCell>
                      <NarrowTableCell
                        onClick={() => handleDeletePayment(item)}
                      >
                        <DeleteIcon />
                      </NarrowTableCell>
                    </TableRow2>
                  );
                })}
            </TBody>
          </Table>
        </TablesContainer>
        {/* Second */}
        <TablesContainer>
          <SubheaderTextContainer>
            <TableSubheaderError>
              Pogresno importovane uplate
            </TableSubheaderError>
            <TableParagraph>
              Molimo vas rucno promenite brojeve racuna.
            </TableParagraph>
          </SubheaderTextContainer>
          <Table>
            <TBody>
              <TableRow>
                <TableHeader style={{ borderTopLeftRadius: "12px" }}>
                  Datum
                </TableHeader>
                <TableHeader>Poziv na broj</TableHeader>
                <TableHeader>Iznos</TableHeader>
                <TableHeader
                  style={{ borderTopRightRadius: "12px" }}
                ></TableHeader>
              </TableRow>
              {payments != null &&
                payments.incorrectPayments.length > 0 &&
                payments.incorrectPayments.map((item, index) => {
                  const day = parseInt(item.dateOfPayment.substr(0, 2), 10);
                  const month =
                    parseInt(item.dateOfPayment.substr(2, 2), 10) - 1; // Months in JavaScript are 0-based, so we subtract 1
                  const year = parseInt(item.dateOfPayment.substr(4, 4), 10);


                  // Create a Date object
                  const dateObject = new Date(year, month, day);
                  return (
                    <TableRow2 key={index}>
                      <NarrowTableCell>
                        {formatDate(dateObject)}
                      </NarrowTableCell>
                      <NarrowTableCell>
                        <DataContainer>
                          <DataSubContainer>
                            <DataInput
                              value={
                                payments.incorrectPayments[index]
                                  .referenceNumber
                              }
                              onChange={(e) => handleSetPayments(e,index)}
                            />
                            <ErrorMessage>{apiError}</ErrorMessage>
                          </DataSubContainer>
                        </DataContainer>
                      </NarrowTableCell>
                      <NarrowTableCell>{item.paymentAmount}</NarrowTableCell>
                      <NarrowTableCell>
                        <SecondaryButton
                          style={{
                            marginLeft: "48px",
                            //   color: selectedTheme.colors.base.white,
                            paddingLeft: "32px",
                            paddingRight: "32px",
                          }}
                          backgroundcolor={
                            (stateSnapshot.incorrectPayments[index]?.referenceNumber ===
                              payments.incorrectPayments[index]
                                .referenceNumber) ===
                            true
                              ? selectedTheme.colors.primary[200]
                              : selectedTheme.colors.primary[600]
                          }
                          disabled={
                            stateSnapshot.incorrectPayments[index]?.referenceNumber ===
                            payments.incorrectPayments[index].referenceNumber
                              ? true
                              : false
                          }
                          onClick={(e) =>
                            handleImportPayment(
                              item,
                              payments.incorrectPayments[index].referenceNumber,
                              e
                            )
                          }
                        >
                          Promeni
                        </SecondaryButton>
                      </NarrowTableCell>
                    </TableRow2>
                  );
                })}
            </TBody>
          </Table>
        </TablesContainer>
      </BodyContainer>

      <BackContainer onClick={() => setFirstStep(true)}>
        <LeftArrowIcon style={{ cursor: "pointer" }} />
        <BackText>Nazad</BackText>
      </BackContainer>
      <ButtonsContainer style={{ marginTop: "0px" }}>
        <PrimaryButton style={{ width: "100%" }} onClick={handleClose}>
          Odustani
        </PrimaryButton>
      </ButtonsContainer>
    </>
  );
};

ImportPaymentsSecondStep.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOpen: PropTypes.func,
  setPayments: PropTypes.func,
  setSnapshot: PropTypes.func,
  payments: PropTypes.object,
  stateSnapshot: PropTypes.object,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  setFirstStep: PropTypes.func,
};

export default ImportPaymentsSecondStep;
