import React from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import {
  IconsContainer,
  Pagination,
  PaginationButtonsContainer,
} from "../PrimaryTable.styled";
import { WidgetFooter } from "components/shared/Widgets/Widgets.styled";
import { PrimaryButton } from "components/shared/Buttons/Buttons.styled";
import selectedTheme from "themes";
import Nugget from "components/shared/Nugget/Nugget";
import { Row } from "components/Login/LoginForm/LoginForm.styled";
import MailIcon from "components/Icon/Icons/MailIcon";
import DowloadCloudIcon from "components/Icon/Icons/DownloadCloudIcon";
import { formatNumber } from "util/helpers/numberHelpers";

function getMonthName(monthNumber) {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString("en-US", { month: "short" }).toUpperCase();
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableHead = ({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  columns,
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* How to get the checked prop below and send it to parent component */}
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontSize: "16px" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  columns: PropTypes.array,
};

const EnhancedTable = ({
  setCheckedElements,
  data,
  pageSize,
  columns,
  // eslint-disable-next-line no-unused-vars
  handleRowClick,
  setDialogOpen,
  downloadElement,
  sendElement,
}) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(pageSize);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    console.log(id);
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    console.log(newSelected);
    setSelected(newSelected);
    setCheckedElements(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(page + newPage);
  };

  //   const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(parseInt(event.target.value, 10));
  //     setPage(0);
  //   };

  //   const handleChangeDense = (event) => {
  //     setDense(event.target.checked);
  //   };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  React.useEffect(() => {
    setCheckedElements(selected);
  }, [selected]);

  return (
    <div>
      <Paper>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            // size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              columns={columns}
            />
            <TableBody>
              {stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell
                        padding="checkbox"
                        onClick={(event) => handleClick(event, row.id)}
                      >
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        style={{ fontSize: "16px" }}
                      >
                        {getMonthName(row.month)} {row.year}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: "16px" }}>
                        {formatNumber(row.totalCharges)}
                      </TableCell>

                      <TableCell
                        align="right"
                        style={{ alignItems: "end", fontSize: "16px" }}
                      >
                        {row.status === "Nije plaćeno" && (
                          <Nugget dot state={"error"} marginLeft="auto">
                            Neplaceno
                          </Nugget>
                        )}
                        {row.status === "Placeno" && (
                          <Nugget dot state={"success"} marginLeft="auto">
                            Placeno
                          </Nugget>
                        )}
                      </TableCell>
                      <TableCell>
                        <IconsContainer>
                          <DowloadCloudIcon
                            onClick={() => downloadElement(row.id)}
                            style={{ cursor: "pointer", marginRight: "30px" }}
                          />
                          <MailIcon
                            onClick={() => sendElement(row.id)}
                            style={{ cursor: "pointer" }}
                          />
                        </IconsContainer>
                      </TableCell>
                      <TableCell>
                        <Row
                          gap={12}
                          style={{ justifyContent: "end", padding: "0 24px" }}
                        >
                          <PrimaryButton onClick={() => setDialogOpen(true)}>
                            Podesi kao Placen
                          </PrimaryButton>
                        </Row>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <WidgetFooter
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            fontFamily: selectedTheme.type.textSm.regular.fontFamily,
            fontSize: selectedTheme.type.textSm.medium.fontSize,
            fontStyle: selectedTheme.type.textSm.medium.fontStyle,
            fontWeight: selectedTheme.type.textSm.medium.fontWeight,
          }}
        >
          <Pagination>Strana {page + 1}</Pagination>
          <PaginationButtonsContainer>
            <PrimaryButton
              onClick={() => handleChangePage(event, -1)}
              style={{ marginRight: 28 }}
            >
              Prethodna
            </PrimaryButton>
            <PrimaryButton onClick={() => handleChangePage(event, +1)}>
              Sledeca
            </PrimaryButton>
          </PaginationButtonsContainer>
        </WidgetFooter>
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </div>
  );
};

EnhancedTable.propTypes = {
  setCheckedElements: PropTypes.func,
  data: PropTypes.array,
  pageSize: PropTypes.number,
  columns: PropTypes.array,
  handleRowClick: PropTypes.func,
  setDialogOpen: PropTypes.func,
  downloadElement: PropTypes.func,
  sendElement: PropTypes.func,
};

export default EnhancedTable;
