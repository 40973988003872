import React from "react";
import { ReactComponent as ArrowDownIconSvg } from "../../../assets/Icons/arrowDown.svg";

const ArrowDownIcon2 = (props) => {
  return <ArrowDownIconSvg {...props} />;
};

ArrowDownIcon2.propTypes = {};

export default ArrowDownIcon2;
