import React from "react";
import PropTypes from "prop-types";
import TextInputField from "components/shared/InputFields/TextField/TextField";
import { charactersToExlude } from "constants/helpers";
import financesInitialValues, { financesFormikContants } from "initialValues/financesInitialValues";


const CurrentBalanceInput = (props) => {
  const formik = props?.formik;

  const preventEnteringNonNumericCharactersAndHave2DecimalPlaces = (event) => {
    // input will accept only numbers
    if (charactersToExlude.includes(event.key) && event.target.value.match(/^\d*\.?\d{0,2}$/)) {
      event.preventDefault();
    }
  };

  return (
    <TextInputField
      id={financesFormikContants.currentFinancesBalance}
      name={financesFormikContants.currentFinancesBalance}
      placeholder={0.00}
      label="Trenutno stanje na racunu"
      value={formik.values.currentFinancesBalance}
      onChange={formik.handleChange}
      labelClass={props?.labelClass}
      onBlur={formik.handleBlur}
      error={formik.touched?.[financesInitialValues.currentBalance] && formik.errors?.[financesInitialValues.currentBalance]}
      apiError={props.apiError}
      style={props?.style}
      onKeyDown={preventEnteringNonNumericCharactersAndHave2DecimalPlaces}
    />
  );
};

CurrentBalanceInput.propTypes = {
  formik: PropTypes.object,
  apiError: PropTypes.any,
  style: PropTypes.object,
  labelClass: PropTypes.string,
};

export default CurrentBalanceInput;
