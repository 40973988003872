import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import i18next from "i18next";
import AppRoutes from "./AppRoutes";
import { useTranslation } from "react-i18next";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { StyledEngineProvider } from "@mui/material";
import { authScopeStringGetHelper } from "util/authScopeHelpers";
import { LANGUAGE } from "constants/localStorage";
import LoadingScreen from "components/shared/LoadingScreen/LoadingScreen";
import { useSelector } from "react-redux";
import { selectLoading } from './store/loadingSlice';
// import { HOME_PAGE } from "constants/pages";
// import { useSelector } from "react-redux";
// import { selectCurrentToken } from "features/auth/authSlice";
import ClarityTracking from "components/ClarityTracking/ClarityTracking";

const App = () => {
  const { i18n } = useTranslation();
  // const navigate = useNavigate();
  const isLoading = useSelector(selectLoading);


  // const auth = useSelector(selectCurrentToken);

  useEffect(() => {
    const lang = authScopeStringGetHelper(LANGUAGE);

    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, []);

  // useEffect(() => {
  //   if (auth !== null) {
  //     navigate(HOME_PAGE, { replace: true });
  //   }
  // }, []);

  return (
    <>
      <Helmet>
        <title>{i18next.t("app.title")}</title>
      </Helmet>
      <ClarityTracking/>
      <StyledEngineProvider injectFirst>
        <ToastContainer bodyClassName="ToastBody" />
        {isLoading && <LoadingScreen/> }       
        <AppRoutes />
      </StyledEngineProvider>
    </>
  );
};

export default App;
