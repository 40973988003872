import React from "react";
import {
  HeaderContainer,
  HeaderText,
  HeaderTextContainer,
  SubHeaderText,
  ButtonRow,
} from "./ApartmentsHeaderComponent.styled";
import { PrimaryButton } from "components/shared/Buttons/Buttons.styled";
import EditIcon from "components/Icon/Icons/EditIcon";
import ThreeDotsHorizontal from "components/Icon/Icons/ThreeDotsHorizontalIcon";
import { PropTypes } from "prop-types";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from '@mui/material';

function ApartmentsHeaderComponent({ buildingId, apartmentId }) {
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:768px)");

  return (
    <HeaderContainer>
      <HeaderTextContainer>
        <HeaderText>Stan#123</HeaderText>
      {matches && (
        <SubHeaderText>
        Neki mali opis koji moze a i ne mora da se vidi i u glavnoj tabeli
        (npr: Ukucani Marko i Laza, mnogo fini ljudi)
      </SubHeaderText>
      )}
        
      </HeaderTextContainer>

      <ButtonRow>
        <PrimaryButton>
          <ThreeDotsHorizontal />
        </PrimaryButton>
        <PrimaryButton
          onClick={() => navigate(`/buildings/${buildingId}/edit-apartment/${apartmentId}`)}
        >
          <EditIcon />
          Izmeni stan
        </PrimaryButton>
      </ButtonRow>
    </HeaderContainer>
  );
}

ApartmentsHeaderComponent.propTypes = {
  buildingId: PropTypes.string,
  apartmentId:PropTypes.string,
};

export default ApartmentsHeaderComponent;
