import { Grid } from "@mui/material";
import styled from "styled-components";

export const RegisterLayoutContainer = styled(Grid)`
  display: flex;
  position: relative;
  min-height: 100vh;
  min-width: 100%;
  height: 100%;
  flex-direction: row;
  //margin-top: -25px;
`;


export const Content = styled(Grid)`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  justify-content: space-between;
`;
