import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import Icon from "../../Icon/Icon";

export const InputContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1 0%;
  gap: 6px;
  flex-wrap: wrap;
  padding: 0px;
  position: relative;
`;

export const InputLabel = styled(Typography)`
  //template
  font-family: ${selectedTheme.type.textSm.medium.fontFamily};
  font-size: ${selectedTheme.type.textSm.medium.fontSize};
  font-weight: ${selectedTheme.type.textSm.medium.fontWeight};
  font-style: ${selectedTheme.type.textSm.medium.fontStyle};
  line-height: ${selectedTheme.type.textSm.medium.lineHeight};
  //
  color: ${selectedTheme.colors.gray[700]};
`;
export const InputField = styled.input`
  padding: ${props => props?.leadingIcon ? ("10px 14px 10px 40px") : ("10px 14px")};
  //padding: 10px 14px;
  background-color: ${props => props?.disabled ? selectedTheme.colors.gray[100] : selectedTheme.colors.base.white};
  width: 100%;
  max-height: 48px;
  border-radius: 8px;
  border: 1px solid ${selectedTheme.colors.gray[300]};
  box-shadow: ${selectedTheme.effects.shadow.xs.boxShadow};
  transition: all 0.2s;
  //text
  font-family: ${selectedTheme.type.textMd.regular.fontFamily};
  font-size: ${selectedTheme.type.textMd.regular.fontSize};
  font-weight: ${selectedTheme.type.textMd.regular.fontWeight};
  font-style: ${selectedTheme.type.textMd.regular.fontStyle};
  line-height: ${selectedTheme.type.textMd.regular.lineHeight};
  color: ${selectedTheme.colors.gray[700]};
  
  &::placeholder {
    color: ${selectedTheme.colors.gray[500]};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
  &:focus {
    outline: none;
    box-shadow: ${(props) =>
      props?.error && typeof props?.error !== "undefined"
        ? selectedTheme.effects.shadow.xsFocused_4pxError100.boxShadow
        : selectedTheme.effects.shadow.xsFocused_4pxPrimary100.boxShadow};
    border: 1px solid
      ${(props) =>
        props?.error && typeof props?.error !== "undefined"
          ? selectedTheme.colors.error[300]
          : selectedTheme.colors.primary[300]};
  }
`;

export const HintText = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.regular.fontFamily};
  font-size: ${selectedTheme.type.textSm.regular.fontSize};
  font-weight: ${selectedTheme.type.textSm.regular.fontWeight};
  font-style: ${selectedTheme.type.textSm.regular.fontStyle};
  line-height: ${selectedTheme.type.textSm.regular.lineHeight};
  color: ${selectedTheme.colors.gray[600]};
`;

export const ErrorMessage = styled(Box)`
  font-family: ${selectedTheme.type.textSm.regular.fontFamily};
  font-size: ${selectedTheme.type.textSm.regular.fontSize};
  font-weight: ${selectedTheme.type.textSm.regular.fontWeight};
  font-style: ${selectedTheme.type.textSm.regular.fontStyle};
  line-height: ${selectedTheme.type.textSm.regular.lineHeight};
  color: ${selectedTheme.colors.error[500]};
`;

export const LeftInputElementContainer = styled(Box)`
  margin-top: auto;
  margin-bottom: auto;
  position: absolute;
  top: 37.5px;
  left: 14px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const RightInputElementsContainer = styled(Box)`
  margin-top: auto;
  margin-bottom: auto;
  position: absolute;
  top: 37.5px;
  right: 14px;
  height: 26px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 4px;
  align-items: center;
  justify-content: flex-end;
`;

export const InputRightElement = styled(Icon)`
  cursor: pointer;
  width: 26px;
  & svg {
    width: 26px;
    height: 26px;
  }
`;
export const InputRightElementError = styled(Icon)`
  width: 16px;
  & svg {
    width: 16px;
    height: 16px;
  }
`;
