import { Typography, Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";

export const HeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 32px 16px;
  }
`;

export const Text = styled(Typography)`
  font-family: ${selectedTheme.type.displaySm.semibold.fontFamily};
  font-size: ${selectedTheme.type.displaySm.semibold.fontSize};
  font-weight: ${selectedTheme.type.displaySm.semibold.fontWeight};
  font-style: ${selectedTheme.type.displaySm.semibold.fontStyle};
  line-height: ${selectedTheme.type.displaySm.semibold.lineHeight};
  color: ${selectedTheme.colors.gray[900]};
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  @media (max-width: 768px) {
    margin-top: 4px;
  }
  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

export const ButtonText = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.semibold.fontFamily};
  font-size: ${selectedTheme.type.textSm.semibold.fontSize};
  font-weight: ${selectedTheme.type.textSm.semibold.fontWeight};
  font-style: ${selectedTheme.type.textSm.semibold.fontStyle};
  color: ${selectedTheme.colors.gray[700]};
`;
