import * as React from "react";
import PropTypes from "prop-types";
import {
  MainTable,
  MainTableCell,
  MainTableHead,
  MainTableRow,
  PrimaryTableContainer,
  Pagination,
  PaginationButtonsContainer,
  MainTableBody,
} from "./ApartmenHistoryTable.styled";
import { WidgetFooter } from "components/shared/Widgets/Widgets.styled";
import { PrimaryButton } from "components/shared/Buttons/Buttons.styled";
import selectedTheme from "../../themes";
import { TableRow } from "./TableRow";

export default function ApartmentHistoryTable({ style, rows, handleOpen }) {
  //const [open, setOpen] = React.useState("0");
  const [currentPage, setCurrentPage] = React.useState(0);
  const pageSize = 8;
  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage + 1 < Math.ceil(rows.length / pageSize)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;
  const currentPageData = rows.slice(startIndex, endIndex);

  return (
    <PrimaryTableContainer style={style}>
      <MainTable>
        <MainTableHead>
          <MainTableRow>
            <MainTableCell>Izmene Stana</MainTableCell>
            <MainTableCell>Datum izmene</MainTableCell>
            <MainTableCell />
          </MainTableRow>
        </MainTableHead>
        <MainTableBody>
          {currentPageData.map((row, index) => (
            <TableRow key={index} row={row} handleOpen={handleOpen} />
          ))}
        </MainTableBody>
      </MainTable>
      <WidgetFooter
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          fontFamily: selectedTheme.type.textSm.regular.fontFamily,
          fontSize: selectedTheme.type.textSm.medium.fontSize,
          fontStyle: selectedTheme.type.textSm.medium.fontStyle,
          fontWeight: selectedTheme.type.textSm.medium.fontWeight,
          borderTop: "none",
        }}
      >
        <Pagination>
          Strana {currentPage + 1} od{" "}
          {rows.length === 0 ? 1 : Math.ceil(rows.length / pageSize)}
        </Pagination>
        <PaginationButtonsContainer>
          <PrimaryButton
            onClick={handlePreviousPage}
            style={{ marginRight: 28 }}
          >
            Prethodna
          </PrimaryButton>
          <PrimaryButton onClick={handleNextPage}>Sledeća</PrimaryButton>
        </PaginationButtonsContainer>
      </WidgetFooter>
    </PrimaryTableContainer>
  );
}

ApartmentHistoryTable.propTypes = {
  style: PropTypes.object,
  rows: PropTypes.arrayOf(PropTypes.object),
  handleOpen: PropTypes.func,
};
