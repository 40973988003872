import React from "react";
import { ReactComponent as UploadIcon2Svg } from "../../../assets/Icons/upload2.svg";

const UploadIcon2 = (props) => {
  return <UploadIcon2Svg {...props} />;
};

UploadIcon2.propTypes = {};

export default UploadIcon2;
