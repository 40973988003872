import React from "react";
import PropTypes from "prop-types";
import { LoginHeaderContainer, LoginTitle, Logo } from "./AuthHeader.styled";
import { useTranslation } from "react-i18next";
import logo from "../../../../assets/Images/SignUp_Logo.jpg";

const AuthHeader = () => {
  const { t } = useTranslation();
  return (
    <LoginHeaderContainer>
      <Logo src={logo} />
      <LoginTitle>{t("auth.title")}</LoginTitle>
    </LoginHeaderContainer>
  );
};

AuthHeader.propTypes = {
  children: PropTypes.node,
};

export default AuthHeader;
