import React from "react";
import {
  NotificationText,
  TotalBuildingsWidgetContainer,
} from "./TotalBuildingsComponent.styled";
import {
  WidgetContent,
  WidgetFooter,
  WidgetHeader,
  WidgetTitle,
} from "../shared/Widgets/Widgets.styled";
import TotalBuildingsChart from "components/Charts/TotalBuildings/TotalBuildingsChart";
import PropTypes from "prop-types";
import { PrimaryButton } from "components/shared/Buttons/Buttons.styled";
import ThreeDotsIcon from "components/Icon/Icons/ThreeDotsIcon";
import { useNavigate } from "react-router-dom";
import { useNumberOfApartmentsQuery } from "store/homeApiSlice";

const TotalBuildingsComponent = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useNumberOfApartmentsQuery();
  return isLoading ? (
    <p>Loading...</p>
  ) : (
    <TotalBuildingsWidgetContainer>
      <WidgetContent>
        <WidgetHeader>
          <WidgetTitle>Ukupno Zgrada</WidgetTitle>
          <ThreeDotsIcon />
        </WidgetHeader>
      </WidgetContent>
      {data.length === 0 ? (
        <NotificationText>Nemate ni jednu zgradu</NotificationText>
      ) : (
        <TotalBuildingsChart numberOfApartments={data} />
      )}

      <WidgetFooter>
        <PrimaryButton onClick={() => navigate("/buildings")}>
          Pregled Zgrada
        </PrimaryButton>
      </WidgetFooter>
    </TotalBuildingsWidgetContainer>
  );
};

TotalBuildingsComponent.propTypes = {
  numberOfApartments: PropTypes.arrayOf(PropTypes.number),
};
export default TotalBuildingsComponent;
