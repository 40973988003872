import React from "react";
import { ReactComponent as LeftArrowsIconSvg } from "../../../assets/Icons/leftArrows.svg";

const LeftArrowsIcon = (props) => {
  return <LeftArrowsIconSvg {...props} />;
};

LeftArrowsIcon.propTypes = {};

export default LeftArrowsIcon;
