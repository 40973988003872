import React from "react";
import { ProfitContainer } from "./ProfitComponent.styled";
import {
  WidgetContent,
  WidgetHeader,
  WidgetTitle,
  WidgetTitleContainer,
  WidgetTitleDetails,
  WidgetHeaderRightContainer,
  WidgetMegaText,
} from "components/shared/Widgets/Widgets.styled";
import ThreeDotsIcon from "components/Icon/Icons/ThreeDotsIcon";
import ProfitChart from "components/Charts/Profit/ProfitChart";
import { useProfitsQuery } from "store/homeApiSlice";
import { formatNumber } from "util/helpers/numberHelpers";

function ProfitComponent() {
  const currentDate = new Date();
  const { data, isLoading } = useProfitsQuery(currentDate.getFullYear());
  return isLoading ? (
    <p>Loading...</p>
  ) : (
    <ProfitContainer>
      <WidgetContent>
        <WidgetHeader>
          <WidgetTitleContainer>
            <WidgetTitle>Profit</WidgetTitle>
            <WidgetTitleDetails>
              Vaši profiti za zadati period
            </WidgetTitleDetails>
          </WidgetTitleContainer>
          <WidgetHeaderRightContainer>
            <WidgetMegaText>
              {formatNumber(
                data.reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                )
              )}{" "}
              din
            </WidgetMegaText>
            <ThreeDotsIcon />
          </WidgetHeaderRightContainer>
        </WidgetHeader>
      </WidgetContent>
      <ProfitChart profits={data} />
    </ProfitContainer>
  );
}

export default ProfitComponent;
