import React, { Fragment } from "react";
import RentUploadImages from "./RentUploadImages/RentUploadImages";
//import StarIcon from "./../Icon/Icons/StarIcon";
import RefreshIcon from "./../Icon/Icons/RefreshIcon";
import TrashIcon from "./../Icon/Icons/TrashIcon";
import ReactImageUploading from "react-images-uploading";
import { PropTypes } from 'prop-types';
import { BadgeRoot, BadgeText, BlurButtonsWrapper, BlurIconWrapper, GridImage, GridItem, ImagesGrid, PrimaryImage, PrimaryImageWrapper, RentImagesContainer, RentUploadImagesWrapper } from "./RentImagesComponent.styled";
import { UploadText } from "components/shared/UploadInput/UploadInput.styled";

const RentImagesComponent = ({images,setImages}) => {
  const maxNumber = 7;
  const onChange = (imageList) => {
    setImages(imageList);
  };

  //console.log(images)

  return (
    <RentImagesContainer>
      <ReactImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => {
          return (
            <Fragment>
              <RentUploadImagesWrapper
                isDragging={isDragging}
                onClick={onImageUpload}
                {...dragProps}
              >
                <RentUploadImages />
              </RentUploadImagesWrapper>
              {
                imageList.length > 0 && <UploadText onClick={onImageRemoveAll} style={{color:"red", textAlign:"right"}}>
                Izbrisi sve slike
              </UploadText>
              }
              {/* there are 2 objects of images which have different attributes */}
              {(imageList.filter((item, index) => index === 0)[0]?.data_url || imageList.filter((item, index) => index === 0)[0]?.imageLink) && (
                <PrimaryImageWrapper>
                  <PrimaryImage
                  //display primary image
                    src={
                      imageList.filter((item, index) => index === 0)[0]
                        ?.data_url !== undefined ? imageList.filter((item, index) => index === 0)[0]
                        ?.data_url : 
                        `${imageList.filter((item, index) => index === 0)[0]
                        ?.imageLink}/carousel`
                    }
                    
                  />
                  <BadgeRoot>
                    {/* <StarIcon /> */}
                    <BadgeText>Naslovna</BadgeText>
                  </BadgeRoot>
                  <BlurButtonsWrapper>
                    <BlurIconWrapper type="button" onClick={() => onImageUpdate(0)}>
                      <RefreshIcon />
                    </BlurIconWrapper>
                    <BlurIconWrapper type="button" danger onClick={() => onImageRemove(0)}>
                      <TrashIcon />
                    </BlurIconWrapper>
                  </BlurButtonsWrapper>
                </PrimaryImageWrapper>
              )}

              <ImagesGrid>
                {imageList.map(
                  (image, index) =>
                    index !== 0 && (
                      <GridItem key={index}>
                        <GridImage srcSet={image.data_url !== undefined ? image.data_url : `${image.imageLink}/carousel`} alt="" />
                        <BlurButtonsWrapper>
                          <BlurIconWrapper type="button" onClick={() => onImageUpdate(index)}>
                            <RefreshIcon />
                          </BlurIconWrapper>
                          <BlurIconWrapper type="button" danger onClick={() => onImageRemove(index)}>
                            <TrashIcon />
                          </BlurIconWrapper>
                        </BlurButtonsWrapper>
                      </GridItem>
                    )
                )}
              </ImagesGrid>
            </Fragment>
          );
        }}
      </ReactImageUploading>
    </RentImagesContainer>
  );
};

RentImagesComponent.propTypes = {
  images:PropTypes.array,
  setImages:PropTypes.func,
};

export default RentImagesComponent;
