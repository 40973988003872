import { apiSlice } from "store/apiSlice";

export const homeApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["BuildingsHome"],
  endpoints: (builder) => ({
    buildingsWithoutBills: builder.query({
      query: (month) => ({
        url: `/home/buildingsWithoutBills?month=${month}`,
      }),
      providesTags: ["BuildingsHome"],
    }),
    numberOfApartments: builder.query({
      query: () => ({
        url: "/home/numberOfApartments",
      }),
      providesTags: ["BuildingsHome"],
    }),
    profits: builder.query({
      query: (year) => ({
        url: `/home/profits?year=${year}`,
      }),
    }),
    biggestDebtors: builder.query({
      query: () => ({
        url: "/home/biggestDebtors",
      }),
    }),
    profile: builder.query({
      query: () => ({
        url: "/home/profile",
      }),
    }),
  }),
});

export const {
  useBuildingsWithoutBillsQuery,
  useBiggestDebtorsQuery,
  useNumberOfApartmentsQuery,
  useProfitsQuery,
  useProfileQuery,
} = homeApiSlice;
