import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";
import selectedTheme from "themes";

export const OverviewContainer = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 32px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 16px;
    margin-top: 24px;
  }
`;

export const AllStatsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const StatsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const StatTitle = styled(Typography)`
    font-family: ${selectedTheme.type.textSm.medium.fontFamily};
  font-size: ${selectedTheme.type.textSm.medium.fontSize};
  font-weight: ${selectedTheme.type.textSm.medium.fontWeight};
  font-style: ${selectedTheme.type.textSm.medium.fontStyle};
  line-height: ${selectedTheme.type.textSm.medium.lineHeight};
  color: ${selectedTheme.colors.gray[500]};
`

export const Stat = styled(Typography)`
    font-family: ${selectedTheme.type.textMd.medium.fontFamily};
  font-size: ${selectedTheme.type.textMd.medium.fontSize};
  font-weight: ${selectedTheme.type.textMd.medium.fontWeight};
  font-style: ${selectedTheme.type.textMd.medium.fontStyle};
  line-height: ${selectedTheme.type.textMd.medium.lineHeight};
  color: ${selectedTheme.colors.gray[700]};
`

export const StatLink = styled(Link)`
    font-family: ${selectedTheme.type.textMd.semibold.fontFamily};
  font-size: ${selectedTheme.type.textMd.semibold.fontSize};
  font-weight: ${selectedTheme.type.textMd.semibold.fontWeight};
  font-style: ${selectedTheme.type.textMd.semibold.fontStyle};
  line-height: ${selectedTheme.type.textMd.semibold.lineHeight};
  color: ${selectedTheme.colors.primary[700]};
  text-decoration: none;
`
export const StatRow = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: ${(props) =>
    props.spaceBetween ? "space-between" : "flex-start"};
  align-items: center;
  gap: ${(props) =>
    props.gap && typeof props.gap === "number" && !Number.isNaN(props.gap)
      ? `${props.gap}px`
      : "unset"};
  width: ${(props) =>
    props.w && typeof props.w === "number" && !Number.isNaN(props.w)
      ? `${props.w}%`
      : "auto"};

      @media (max-width: 400px) {
        width: 100%;
        align-items: flex-start;
      }
`;

export const CardsContainer = styled(Box)`
  display: flex;
  margin-top: 40px;
`;

