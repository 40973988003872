import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as MailIconSvg } from "../../../assets/Icons/mail-01.svg";

const MailIcon = (props) => {
  return <MailIconSvg {...props} />;
};

MailIcon.propTypes = {};

export default MailIcon;
