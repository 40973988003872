import * as React from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import selectedTheme from "../../../themes";
import PropTypes from "prop-types";
import { TabPanelContainer } from "./TabsComponent.styled";
import useMediaQuery from "@mui/material/useMediaQuery";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <TabPanelContainer
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </TabPanelContainer>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const StyledTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: selectedTheme.colors.gray[800],
  },
  "& .MuiTabs-indicatorSpan": {
    backgroundColor: selectedTheme.colors.gray[800],
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    // minWidth: 0,
    width: "fit-content",
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: selectedTheme.type.textSm.semibold.fontWeight,
    marginRight: 15,
    color: selectedTheme.colors.gray[500],
    fontFamily: selectedTheme.type.textSm.semibold.fontFamily,
    fontStyle: selectedTheme.type.textSm.semibold.fontStyle,
    fontSize: "14px",
    lineHeight: "20px",
    "&:hover": {
      color: "#1890ff",
      opacity: 1,
      borderBottom: "3px solid #1890ff",
    },
    "&.Mui-selected": {
      color: selectedTheme.colors.gray[800],
    },
    "&.Mui-focusVisible": {
      backgroundColor: selectedTheme.colors.gray[800],
    },
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: selectedTheme.colors.gray[800],
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: 40,
      width: "100%",
      color: selectedTheme.colors.gray[800],
    },
  })
);

const TabsComponent = ({ tabs }) => {
  const [value, setValue] = React.useState(0);
  const matches = useMediaQuery("(max-width:768px)");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", marginTop: "12px" }}>
      <Box>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="ant example"
          centered={matches}
        >
          {tabs.map((tab, index) => (
            <StyledTab label={tab.label} key={index} />
          ))}
        </StyledTabs>
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel value={value} index={index} key={index}>
          {tab.component}
        </TabPanel>
      ))}
    </Box>
  );
};

TabsComponent.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object),
};

export default TabsComponent;
