import React from 'react'
import PropTypes from 'prop-types'
import { DotCircle, NuggetContainer } from './Nugget.styled'

const Nugget = ({dot,state, filled, children, marginLeft}) => {
  return (
    <NuggetContainer state={state} filled={filled} marginLeft={marginLeft} >
        {dot && <DotCircle state={state}/>}
        {children}
    </NuggetContainer>
  )
}

Nugget.propTypes = {
    dot:PropTypes.bool,
    state:PropTypes.string,
    filled:PropTypes.bool,
    marginLeft:PropTypes.string,
    children:PropTypes.node,
}

export default Nugget