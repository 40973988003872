import React from "react";
import { ReactComponent as ErrorIconSvg } from "../../../assets/Icons/error.svg";

const ErrorIcon = (props) => {
  return <ErrorIconSvg {...props} />;
};

ErrorIcon.propTypes = {};

export default ErrorIcon;
