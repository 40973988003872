import React from "react";
import {
  ErrorText,
  Form,
  Input,
  UploadContainer,
  UploadHelperText,
  UploadMainText,
  UploadText,
} from "./UploadInput.styled";
import UploadIcon2 from "components/Icon/Icons/UploadIcon2";
import PropTypes from "prop-types";

const UploadInput = ({
  style,
  mainText,
  helperText,
  handleClick,
  hiddenFileInput,
  handleFileChange,
  file,
  accept,
  isError = false,
  changeNameAfterUpload = true,
}) => {
  return (
    <UploadContainer style={style}>
      <UploadIcon2 />
      {file !== null && changeNameAfterUpload === true ? (
        <p>{file.name}</p>
      ) : (
        <>
          {" "}
          <Form>
            <UploadMainText>
              <UploadText onClick={handleClick}>{mainText} </UploadText>
              ili prevuci fajl
            </UploadMainText>
            <Input
              type="file"
              ref={hiddenFileInput}
              onChange={handleFileChange}
              accept={accept}
            />
          </Form>
          <UploadHelperText>{helperText}</UploadHelperText>
        </>
      )}

      {isError && <ErrorText>Fajl nije uspešno uploadovan!</ErrorText>}
    </UploadContainer>
  );
};

UploadInput.propTypes = {
  style: PropTypes.object,
  mainText: PropTypes.string,
  helperText: PropTypes.string,
  handleClick: PropTypes.func,
  hiddenFileInput: PropTypes.object,
  handleFileChange: PropTypes.func,
  file: PropTypes.object,
  accept: PropTypes.string,
  isError: PropTypes.bool,
  changeNameAfterUpload: PropTypes.bool,
};

export default UploadInput;
