import * as React from "react";
import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";
import CloseIcon from "components/Icon/Icons/CloseIcon";
import UserIcon from "components/Icon/Icons/UserIcon";
import FirstNameField from "components/AddUserInputs/FirstNameField";
import { useFormik } from "formik";
import addUserValidation from "validations/addUserValidation";
import TenantLastNameField from "components/AddApartmentInputs/TenantLastNameField";
import EmailField from "components/AddApartmentInputs/EmailField";
import RoleField from "components/AddUserInputs/RoleField";
import { PrimaryButton } from "components/shared/Buttons/Buttons.styled";
import selectedTheme from "../../themes";
import {
  useCreateUserMutation,
  useUpdateUserMutation,
} from "store/settingsApiSlice";
import { makeToastMessage, makeErrorToastMessage } from "util/toastMessage";
import {
  AddUserDialogContainer,
  ButtonsContainer,
  InputContainer,
  MainContainer,
  Title,
  TopContainer,
} from "./AddUserDialog.styled";
import PasswordField from "components/AddUserInputs/PasswordField";
import { compareObjects } from "util/helpers/objectHelpers";
import EditUserIcon from "components/Icon/Icons/EditUserIcon";

// dialog will be used for editing and adding a new user
const AddUserDialog = ({
  open,
  handleClose,
  roles,
  editUser = false,
  user = {},
  userId = null,
}) => {
  const [createNewUser] = useCreateUserMutation();
  const [updateUser] = useUpdateUserMutation();
  const tranformDataForSelectInput = () => {
    return roles.map((obj) => {
      return {
        value: obj.name,
        label: obj.name,
      };
    });
  };

  const handleCloseDialog = () => {
    handleClose();
    formik.resetForm();
  };

  const handleCreateOrUpdateUser = () => {
    editUser === false
      ? createNewUser({ ...formik.values, role: formik.values.role })
          .unwrap()
          .then(() => {
            makeToastMessage("Uspešno ste kreirali korisnika");
            handleCloseDialog();
            formik.resetForm();
          })
          .catch(() => makeErrorToastMessage("Korisnik nije uspešno kreiran"))
      : updateUser({
          id: userId,
          formData: { ...formik.values, role: formik.values.role },
        })
          .unwrap()
          .then(() => {
            makeToastMessage("Uspešno ste izmenili korisnika");
            handleCloseDialog();
          })
          .catch(() => makeErrorToastMessage("Korisnik nije uspešno izmenjen"));
  };

  const formik = useFormik({
    initialValues:
      editUser === false
        ? {
            firstName: "",
            lastName: "",
            email: "",
            role: tranformDataForSelectInput()[0].value,
            password: "",
          }
        : { ...user, role: user.role },
    validationSchema: addUserValidation,
    onSubmit: handleCreateOrUpdateUser,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "653px",
          },
        },
      }}
      component="form"
      onSubmit={formik.handleSubmit}
    >
      <AddUserDialogContainer>
        <TopContainer>
          {editUser === true ? <EditUserIcon /> : <UserIcon />}
          <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
        </TopContainer>
        <Title>
          {editUser === false ? "Dodaj novog korisnika" : "Izmeni korisnika"}
        </Title>
        <MainContainer>
          <InputContainer style={{ marginTop: "32px" }}>
            <FirstNameField formik={formik} />
          </InputContainer>
          <InputContainer>
            <TenantLastNameField formik={formik} />
          </InputContainer>
          <InputContainer>
            <EmailField formik={formik} />
          </InputContainer>
          <InputContainer>
            <RoleField
              formik={formik}
              options={tranformDataForSelectInput()}
              defaultValue={
                editUser === true
                  ? { value: user.role, label: user.role }
                  : null
              }
            />
          </InputContainer>
          <InputContainer>
            <PasswordField formik={formik} />
          </InputContainer>
        </MainContainer>
        <ButtonsContainer>
          <PrimaryButton style={{ width: "50%" }} onClick={handleClose}>
            Odustani
          </PrimaryButton>
          <PrimaryButton
            style={{
              width: "50%",
              marginLeft: "18px",
              backgroundColor:
                editUser === true && compareObjects(user, formik.values)
                  ? selectedTheme.colors.primary[200]
                  : selectedTheme.colors.primary[600],
              color: selectedTheme.colors.base.white,
            }}
            disabled={editUser === true && compareObjects(user, formik.values)}
            onClick={handleCreateOrUpdateUser}
          >
            {editUser === false ? "Dodaj korisnika" : "Sacuvaj"}
          </PrimaryButton>
        </ButtonsContainer>
      </AddUserDialogContainer>
    </Dialog>
  );
};

AddUserDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string,
  Icon: PropTypes.func,
  actionName: PropTypes.string,
  roles: PropTypes.array,
  editUser: PropTypes.bool,
  user: PropTypes.object,
  userId: PropTypes.string,
};

export default AddUserDialog;
