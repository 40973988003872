import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { ReactComponent as Tick } from "../../../assets/Icons/check-circle.svg";
import { useEffect } from "react";
import RegisterCompanyNameField from "./RegisterFields/RegisterCompanyNameField";
import RegisterEmailField from "./RegisterFields/RegisterEmailField";
//import RegisterPasswordField from "./RegisterFields/RegisterPasswordField";
//import RegisterPibField from "./RegisterFields/RegisterPibField";
//import RegisterIdNumberField from "./RegisterFields/RegisterNumberField";
//import RegisterPhoneField from "./RegisterFields/RegisterPhoneField";
import {
  CenteredRow,
  Column,
  ColumnsAndButtonContainer,
  ConfirmationFormContainer,
  Devider,
  FormHeader,
  FormHeaderCentered,
  FormSubtitle,
  FormTitle,
  HeaderIconContainerGreen,
  HeaderWithIconContainer,
  LoginButton,
  PreText,
  RegisterFormContainer,
  RegisterLink,
  TwoColumnContainerWithDevider,
} from "./RegisterForm.styled";
import registerInitialValues from "initialValues/registerInitialValues";
import registerValidation from "validations/registerValidation";
import RegisterPasswordField from "./RegisterFields/RegisterPasswordField";
import RegisterPibField from "./RegisterFields/RegisterPibField";
import RegisterIdNumberField from "./RegisterFields/RegisterNumberField";
import RegisterPhoneField from "./RegisterFields/RegisterPhoneField";
import RegisterCompanyAddressField from "./RegisterFields/RegisterCompanyAddressField";
// import RegisterCompanyCityField from "./RegisterFields/RegisterCompanyCityField";
import { useRegisterMutation } from "store/registerApiSlice";

// eslint-disable-next-line no-unused-vars
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import { useAllCitiesQuery } from "store/citiesApiSlice";
import CityField from "components/AddBuildingsInputs/CityField";
//import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SecondaryButton } from "components/shared/Buttons/Buttons.styled";
import { useNavigate } from "react-router-dom";

const RegisterForm = () => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:768px)");
  const navigate = useNavigate();
  //const navigate = useNavigate();
  //const apiError = useSelector(selectLoginError);

  // eslint-disable-next-line no-unused-vars
  const [register, { isLoading }] = useRegisterMutation();
  const apiError = false;
  const [triedToLogin, setTriedToLogin] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [registrationValid, setRegistrationValid] = useState(false);
  const { data: cities, isLoading: isLoadingCities } = useAllCitiesQuery();

  const handleSubmit = async () => {
    //extract formik values
    let {
      companyName,
      email,
      password,
      pib,
      identificationNumber,
      companyAddress,
      phoneNumber,
      cityId,
    } = formik.values;

    //map values to api payloads
    const pIB = String(pib);
    const address = String(companyAddress);
    phoneNumber = String(phoneNumber);
    identificationNumber = String(identificationNumber);
    cityId = Number(cityId);


    try {
      await register({
        companyName,
        email,
        password,
        pIB,
        identificationNumber,
        address,
        phoneNumber,
        cityId,
      }).unwrap();
      makeToastMessage("User successfuly registered. Please login.");
      setRegistrationValid(true);
    } catch (err) {
      setRegistrationValid(false);
      makeErrorToastMessage(err.data.message);
    }


    
  };

  useEffect(() => {
    //dispatch(clearregisterErrors());
  }, []);

  const handleChangePassword = (event) => {
    formik?.handleChange(event);
    if (triedToLogin) setTriedToLogin(false);
  };

  const formik = useFormik({
    initialValues: registerInitialValues,
    validationSchema: registerValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const tranformDataForSelectInput = () => {
    return cities.map((obj) => {
      return {
        value: obj.id,
        label: obj.name,
      };
    });
  };

  return isLoadingCities ? (
    <p>Loading...</p>
  ) : (
    <>
      {!registrationValid ? (
        <RegisterFormContainer
          component="form"
          onSubmit={() => formik.handleSubmit()}
        >
          <FormHeader>
            <FormTitle>{t("register.formTitle")}</FormTitle>
            <FormSubtitle>{t("register.formSubtitle")}</FormSubtitle>
          </FormHeader>
          <ColumnsAndButtonContainer>
            <TwoColumnContainerWithDevider>
              {/* first column */}
              <Column gap={20} w={100}>
                {/* Company Name */}
                <RegisterCompanyNameField formik={formik} apiError={apiError} />

                {/* Email */}
                <RegisterEmailField formik={formik} />

                {/* Password */}
                <RegisterPasswordField
                  formik={formik}
                  onChange={handleChangePassword}
                  loginAttempt={triedToLogin}
                  apiError={false}
                />
              </Column>
              {matches && <Devider />}
              {/* second column */}
              <Column gap={20} w={100}>
                {/* PIB */}
                <RegisterPibField formik={formik} apiError={false} />
                {/* Identification Number */}
                <RegisterIdNumberField formik={formik} apiError={false} />
                {/* Comapny Address*/}
                {/*  */}
                <RegisterCompanyAddressField formik={formik} apiError={false} />
                {/*  */}
                {/* <RegisterCompanyCityField formik={formik} apiError={false} /> */}
                <CityField
                  formik={formik}
                  options={tranformDataForSelectInput()}
                  defaultValue={tranformDataForSelectInput()[0]}
                />
                {/*  */}
                {/*  */}

                {/* Phone Number */}
                <RegisterPhoneField formik={formik} apiError={false} />
              </Column>
            </TwoColumnContainerWithDevider>
            <LoginButton
              component="button"
              type="button"
              onClick={formik.handleSubmit}
            >
              {t("register.registerButton")}
            </LoginButton>
          </ColumnsAndButtonContainer>

          <CenteredRow gap={4}>
            <PreText>{t("register.loginPreText")}</PreText>
            <RegisterLink to="/login">{t("register.loginLink")}</RegisterLink>
          </CenteredRow>
        </RegisterFormContainer>
      ) : (
        <ConfirmationFormContainer>
          <HeaderWithIconContainer>
            <HeaderIconContainerGreen>
              <Tick />
            </HeaderIconContainerGreen>
            <FormHeaderCentered>
              <FormTitle>{t("register.successTitle")}</FormTitle>
              <FormSubtitle>{t("register.successSubtitle")}</FormSubtitle>
            </FormHeaderCentered>
          </HeaderWithIconContainer>
          <SecondaryButton
            sx={{width: '100%'}}
            onClick={() => navigate(`/login`)}
          >
            {t("register.loginLink")}
          </SecondaryButton>
        </ConfirmationFormContainer>
      )}
    </>
  );
};

RegisterForm.propTypes = {
  children: PropTypes.node,
};

export default RegisterForm;
