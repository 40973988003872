export const addApartmentInitialConstants = {
  firstName: "firstName",
  lastName: "lastName",
  phoneNumber: "phoneNumber",
  email: "email",
  electronicDelivery: "electronicDelivery",
  numOfApartment: "numOfApartment",
  numOfTenants: "numOfTenants",
  currentState: "currentState",
  isAvailableForRent: "isAvailableForRent",
  squareFootage: "squareFootage",
  defaultRentPrice: "defaultRentPrice",
  rentalInformations: "rentalInformations",
  primaryImage: "primaryImage",
  rentImages: "rentImages",
  //delivery
  deliveryAddress: "deliveryAddress",
  deliveryCityId: "deliveryCityId",
  zipCode:"zipCode",
};

export default {
  [addApartmentInitialConstants.firstName]: "",
  [addApartmentInitialConstants.lastName]: "",
  [addApartmentInitialConstants.email]: "",
  [addApartmentInitialConstants.electronicDelivery]: false,
  [addApartmentInitialConstants.phoneNumber]: "",
  [addApartmentInitialConstants.numOfApartment]: "",
  [addApartmentInitialConstants.numOfTenants]: "",
  [addApartmentInitialConstants.currentState]: 0,
  [addApartmentInitialConstants.isAvailableForRent]: false,
  [addApartmentInitialConstants.squareFootage]: "",
  [addApartmentInitialConstants.defaultRentPrice]: "",
  [addApartmentInitialConstants.rentalInformations]: "",
  [addApartmentInitialConstants.rentImages]: [],
  [addApartmentInitialConstants.deliveryAddress]: "",
  [addApartmentInitialConstants.deliveryCityId]: 1,
  [addApartmentInitialConstants.zipCode]:"",
};
