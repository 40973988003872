import React from "react";
import PropTypes from "prop-types";
import { addBuildingInitialConstants } from "initialValues/addBuildingInitialValues";
import TextInputField from "components/shared/InputFields/TextField/TextField";
import { charactersToExlude } from "constants/helpers";

const ManagamentCostField = (props) => {
  const formik = props?.formik;

  const preventEnteringNonNumericCharacters = (event) => {
    // input will accept only numbers
    if (charactersToExlude.includes(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <TextInputField
      id={addBuildingInitialConstants.costOfManagement}
      name={addBuildingInitialConstants.costOfManagement}
      placeholder=""
      label="Upravljanje"
      value={formik.values.costOfManagement}
      onChange={formik.handleChange}
      labelClass={props?.labelClass}
      type="number"
      onBlur={formik.handleBlur}
      error={formik.touched?.[addBuildingInitialConstants.costOfManagement] && formik.errors?.[addBuildingInitialConstants.costOfManagement]}
      apiError={props.apiError}
      style={props?.style}
      onKeyDown={preventEnteringNonNumericCharacters}
    />
  );
};

ManagamentCostField.propTypes = {
  formik: PropTypes.object,
  apiError: PropTypes.any,
  style: PropTypes.object,
  labelClass: PropTypes.string,
};

export default ManagamentCostField;
