import React, { useState } from "react";
import PropTypes from "prop-types";
import { addApartmentInitialConstants } from "initialValues/addApartmentInitialValues";
import { SwitchButton } from "components/SwitchButton/SwitchButton";
import {
  ExcludeApartmentsContainer,
  ExcludeApartmentsSubTitle,
  ExcludeApartmentsTextContainer,
  ExludeApartmentsTitle,
} from "components/BuildingMonthlyItem/BuildingMonthlyItem.styled";

const RentSwitchField = (props) => {
  const formik = props?.formik;

  const [rentEnabled, setRentEnabled] = useState(props.defaultValue);

  const handleValueChange = () => {
    formik.setFieldValue(
      addApartmentInitialConstants.isAvailableForRent,
      !rentEnabled
    );
    setRentEnabled(!rentEnabled);
  };

  return (
    <ExcludeApartmentsContainer>
      <SwitchButton
        checked={rentEnabled}
        id={addApartmentInitialConstants.isAvailableForRent}
        name={addApartmentInitialConstants.isAvailableForRent}
        onChange={() => handleValueChange()}
      />
      <ExcludeApartmentsTextContainer>
        <ExludeApartmentsTitle>Stan moguc za Izdavanje</ExludeApartmentsTitle>
        <ExcludeApartmentsSubTitle>Neki dodatni opis</ExcludeApartmentsSubTitle>
      </ExcludeApartmentsTextContainer>
    </ExcludeApartmentsContainer>
  );
};

RentSwitchField.propTypes = {
  formik: PropTypes.object,
  apiError: PropTypes.any,
  changeFormikValue: PropTypes.func,
  defaultValue: PropTypes.bool,
};

export default RentSwitchField;
