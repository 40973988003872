import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes/index";

export const Card = styled(Box)`
  border-radius: 12px;
  border: 1px solid ${selectedTheme.colors.gray[200]};
  box-shadow: ${selectedTheme.effects.shadow.sm.boxShadow};
  flex-basis: 33.3%;
  background-color: ${selectedTheme.colors.base.white};
  margin-left: 24px;
  padding: 24px;
`;

export const TopContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const Text = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.medium.fontFamily};
  font-size: ${selectedTheme.type.textSm.medium.fontSize};
  font-weight: ${selectedTheme.type.textSm.medium.fontWeight};
  font-style: ${selectedTheme.type.textSm.medium.fontStyle};
  color: ${selectedTheme.colors.gray[600]};
`;

export const ContentContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
`;

export const ContentText = styled(Typography)`
  font-family: ${selectedTheme.type.textMd.semibold.fontFamily};
  font-weight: ${selectedTheme.type.textMd.semibold.fontWeight};
  font-style: ${selectedTheme.type.textMd.semibold.fontStyle};
  color: ${selectedTheme.colors.gray[900]};
  font-size: 36px;
`;