import { Box } from "@mui/material";
import styled from "styled-components";

export const UserManagementContainer = styled(Box)`
  margin-top: 40px;
  padding-bottom: 48px;
  @media (max-width: 768px) {
    margin-top: 24px;
  }
`;

export const UserManagamentSubContainer = styled(Box)`
  display: flex;
  justify-content:space-between;
  align-items:center;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 0 16px;
    gap: 16px;
  }
`;
