import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import {
  HintText,
  InputContainer,
  InputLabel,
  ErrorMessage,
} from "../InputFields.styled";
import { StyledSelect } from "./SelectFields.styled";

const BasicSelect = (props) => {
  const [selectedOption, setSelectedOption] = useState(() => {
    if (props?.defaultValue !== null) return props?.defaultValue;
    else if (props?.preselectedOption) return props?.preselectedOption;
    else if (props.options === undefined) return null;
    else return props?.options[0];
  });

  const handleChange = (option) => {
    setSelectedOption(option);
    props?.changeFormikValue(props?.name, option?.value);
  };

  useEffect(() => {
    setSelectedOption(() => {
      if (props?.defaultValue !== null) return props?.defaultValue;
      else if (props?.preselectedOption) return props?.preselectedOption;
      else if (props.options === undefined) return null;
      else return props?.options[0];
    });
  }, [props?.preselectedOption]);

  return (
    <InputContainer
      className={props.className}
      onClick={props?.onClick}
      disabled={props?.disabledField}
      $isErrorShown={props?.error}
      style={{ alignItems: "stretch" }}
    >
      {props?.label && (
        <InputLabel $required={props?.requiredField && !props?.value}>
          {props?.label}
        </InputLabel>
      )}
      <StyledSelect
        classNamePrefix="Select"
        id={props?.id}
        name={props?.name}
        defaultValue={selectedOption}
        onChange={handleChange}
        options={props?.options}
        isDisabled={false}
        isLoading={false}
        isMulti={false}
        pageSize={3}
        isRtl={false}
        isSearchable={true}
        placeholder={props?.placeholder}
        value={selectedOption}
        error={props?.error}
        className={props?.class}
        //apiError={props?.apiError}
      />
      {/* show hint text if it exists and not empty in i18n and no formik errors */}
      {props?.helperText && <HintText>{props?.helperText}</HintText>}
      {props?.error && <ErrorMessage>{typeof props?.error !== 'object' && props?.error !== null && props?.error}</ErrorMessage>}
    </InputContainer>
  );
};

BasicSelect.displayName = "BasicSelect";

BasicSelect.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.object,
  helperText: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  date: PropTypes.bool,
  disabledField: PropTypes.bool,
  value: PropTypes.any,
  number: PropTypes.bool,
  requiredField: PropTypes.bool,
  inputProps: PropTypes.any,
  placeholder: PropTypes.string,
  icon: PropTypes.node,
  fullWidth: PropTypes.bool,
  isSmall: PropTypes.bool,
  changeFormikValue: PropTypes.func,
  class: PropTypes.string,
  options: PropTypes.array,
  preselectedOption: PropTypes.any,
  defaultValue: PropTypes.object,
};

export default BasicSelect;
