import { Box, Typography, Table, TableRow, TableCell } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const BillCardContainer = styled(Box)`
  display: flex;
  border: 1px solid ${selectedTheme.colors.gray[200]};
  box-shadow: ${selectedTheme.effects.shadow.sm.boxShadow};
  border-radius: 12px;
  margin-top: 24px;
  @media (max-width: 768px) {
    overflow-x: auto;
  }
`;

export const ProfitContainer = styled(Box)`
  display: flex;
  gap: 4px;
  align-items: center;
`

export const BillCardSubContainer = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const BillCardDetailsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 768px) {
    padding: 24px 24px 20px 24px;
    position: relative;
  }
`;

export const TextContainer = styled(Box)`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

export const MainText = styled(Typography)`
  font-family: ${selectedTheme.type.displayLg.semibold.fontFamily};
  font-size: 18px;
  font-weight: ${selectedTheme.type.displayLg.semibold.fontWeight};
  font-style: ${selectedTheme.type.displayLg.semibold.fontStyle};
  color: ${selectedTheme.colors.gray[900]};
`;

export const SubText = styled(Typography)`
  font-family: ${selectedTheme.type.displaySm.regular.fontFamily};
  font-size: 14px;
  font-weight: ${selectedTheme.type.displaySm.regular.fontWeight};
  font-style: ${selectedTheme.type.displaySm.regular.fontStyle};
  color: ${selectedTheme.colors.gray[600]};
`;

export const DetailsButton = styled(Typography)`
  font-family: ${selectedTheme.type.displaySm.semibold.fontFamily};
  font-size: 14px;
  font-weight: ${selectedTheme.type.displaySm.semibold.fontWeight};
  font-style: ${selectedTheme.type.displaySm.semibold.fontStyle};
  color: ${selectedTheme.colors.primary[700]};
  cursor: pointer;

  &:hover {
    color: ${selectedTheme.colors.primary[800]};
  }
`;

export const CustomTable = styled(Table)`
  border-collapse: collapse;
  width: 80%;
  border: 1px solid ${selectedTheme.colors.gray[200]};
  box-shadow: ${selectedTheme.effects.shadow.sm.boxShadow};
  border-radius: 16px;
  box-shadow: ${selectedTheme.effects.shadow.sm.boxShadow};
  border-style: hidden;
  @media (max-width: 768px) {
    border-radius: 0;
    width: 100%;
  }
`;

export const HeadRow = styled(TableRow)`
  height: 44px;
  background-color: ${selectedTheme.colors.gray[50]};
  text-align: left;
`;

export const TableHead = styled("th")`
  font-family: ${selectedTheme.type.displayXs.medium.fontFamily};
  font-size: 16px;
  font-weight: ${selectedTheme.type.displayXs.medium.fontWeight};
  font-style: ${selectedTheme.type.displayXs.medium.fontStyle};
  color: ${selectedTheme.colors.gray[600]};
  width: 33%;
  padding-left: 16px;
  @media (max-width: 768px) {
    padding-left: 24px;
  }
`;

export const ContentRow = styled(TableRow)`
  height: 64px;
  border-bottom: 1px solid ${selectedTheme.colors.gray[200]};
`;

export const TableDataCell = styled(TableCell)`
  font-family: ${selectedTheme.type.displaySm.regular.fontFamily};
  font-size: 16px;
  font-weight: ${selectedTheme.type.displaySm.regular.fontWeight};
  font-style: ${selectedTheme.type.displaySm.regular.fontStyle};
  color: ${selectedTheme.colors.gray[600]};
  width: 33%;
  paddingleft: 24px;
  @media (max-width: 768px) {
    padding-left: 24px;
  }
`;
