import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { ProfitContainer } from "./ProfitChart.styled";
import PropTypes from "prop-types";
import { months } from "constants/helpers";
import selectedTheme from "themes";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function ProfitChart({ profits }) {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const last12Months = [];

  for (let i = 0; i < 12; i++) {
    const monthIndex = (currentMonth + 1 + i) % 12;
    const month = months[monthIndex];
    last12Months.push(month);
  }

  const labels = last12Months;
  const data = {
    labels,
    datasets: [
      {
        label: "Profit " + currentDate.getFullYear(),
        data: profits,
        borderColor: selectedTheme.colors.primary[600],
        backgroundColor: "transparent",
        pointRadius: 0,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        align: "end",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Profit u dinarima", // Set the label for the y-axis
        },
      },
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Mesec 2023", // Set the label for the x-axis
        },
      },
    },
  };

  return (
    <ProfitContainer>
      <Line data={data} options={options} />
    </ProfitContainer>
  );
}

ProfitChart.propTypes = {
  profits: PropTypes.arrayOf(PropTypes.number),
};
export default ProfitChart;
