import React from "react";
import { ReactComponent as FilterIconSvg } from "../../../assets/Icons/filter.svg";

const FilterIcon = (props) => {
  return <FilterIconSvg {...props} />;
};

FilterIcon.propTypes = {};

export default FilterIcon;
