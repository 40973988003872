export const addBuildingInitialConstants = {
  address: "address",
  cityId: "cityId",
  currentAccount: "currentAccount",
  pib: "pib",
  identificationNumber: "identificationNumber",
  costOfManagement: "costOfManagement",
  currentFund: "currentFund",
  investment: "investment",
};

export default {
  [addBuildingInitialConstants.address]: "",
  [addBuildingInitialConstants.cityId]: 1,
  [addBuildingInitialConstants.currentAccount]: "",
  [addBuildingInitialConstants.pib]: "",
  [addBuildingInitialConstants.identificationNumber]: "",
  [addBuildingInitialConstants.costOfManagement]: 300,
  [addBuildingInitialConstants.currentFund]: 300,
  [addBuildingInitialConstants.investment]: 300,
};
