import React from "react";
import { BiggestDebtorsOverviewContainer } from "./BiggestDebtorsOverviewComponent.styled";
import {
  WidgetContent,
  WidgetHeader,
  WidgetHeaderRightContainer,
  WidgetTitle,
  WidgetTitleContainer,
  WidgetTitleDetails,
} from "components/shared/Widgets/Widgets.styled";
import { PrimaryButton } from "components/shared/Buttons/Buttons.styled";
import RightUpArrowIcon from "components/Icon/Icons/RightUpArrowIcon";
import PrimaryTable from "components/shared/Tables/PrimaryTable";
import { useBiggestDebtorsQuery } from "store/homeApiSlice";
import { formatNumber } from "util/helpers/numberHelpers";

const BiggestDebtorsOverviewComponent = () => {
  const { data, isLoading } = useBiggestDebtorsQuery();
  const columns = ["ID", "Stan", "Zgrada", "Dug"];
  const pageSize = 7;

  const getStructuredData = () =>
    data.map(({ id, tenantName, buildingAddress, debt }) => [
      id,
      tenantName,
      buildingAddress,
      formatNumber(debt),
    ]);

  const handleRowClick = (id) => {
    console.log(id);
  };
  return isLoading ? (
    <p>Loading...</p>
  ) : (
    <BiggestDebtorsOverviewContainer>
      <WidgetContent>
        <WidgetHeader>
          <WidgetTitleContainer>
            <WidgetTitle>Najveći Dužnici</WidgetTitle>
            <WidgetTitleDetails>
              Stanovi sa najvećim dugovanjima
            </WidgetTitleDetails>
          </WidgetTitleContainer>
          <WidgetHeaderRightContainer>
            <PrimaryButton>
              Vidi sve Dužnike
              <RightUpArrowIcon />
            </PrimaryButton>
          </WidgetHeaderRightContainer>
        </WidgetHeader>
      </WidgetContent>
      <PrimaryTable
        data={getStructuredData()}
        columns={columns}
        pageSize={pageSize}
        handleRowClick={handleRowClick}
      />
    </BiggestDebtorsOverviewContainer>
  );
};

export default BiggestDebtorsOverviewComponent;
