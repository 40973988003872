import { Box } from "@mui/material";
import styled from "styled-components";

export const RentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  margin-top: 32px;
  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const Text5 = styled.div`align-self: stretch;
position: relative;
line-height: 24px;
font-weight: 500;
`;
export const Star01Icon1 = styled.img`position: relative;
width: 12px;
height: 12px;
overflow: hidden;
flex-shrink: 0;
`;
export const Text6 = styled.div`position: relative;
line-height: 18px;
font-weight: 500;
`;
export const Badge = styled.div`position: absolute;
top: 13px;
left: 12px;
border-radius: 16px;
border: 1.5px solid #eff4ff;
display: flex;
flex-direction: row;
padding: 2px 8px 2px 6px;
align-items: center;
justify-content: flex-start;
gap: 4px;
`;
export const Image7 = styled.div`align-self: stretch;
flex: 1;
position: relative;
background-image: url("Image.png");
background-size: cover;
background-repeat: no-repeat;
background-position: top;
`;
export const Container5 = styled.div`
grid-area: 1 / 1 / 4 / 3;
`;
export const ImageIcon = styled.img`align-self: stretch;
flex: 1;
position: relative;
width: 100%;
overflow: hidden;
max-height: 100%;
height: 100%;
object-fit: cover;
`;
export const ImageParent = styled.div`align-self: stretch;
flex: 1;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
gap: 8px;
`;
export const Container6 = styled.div`
`;
export const ContainerParent = styled.div`
display: grid;
grid-template-columns: repeat(5, 1fr);
grid-template-rows: repeat(5, 1fr);
grid-column-gap: 16px;
grid-row-gap: 16px;
`;
export const Edit02Icon1 = styled.img`position: relative;
width: 20px;
height: 20px;
overflow: hidden;
flex-shrink: 0;
`;
export const Text7 = styled.div`position: relative;
line-height: 20px;
font-weight: 600;
`;
export const Button = styled.div`position: absolute;
top: 123px;
left: 68.5px;
border-radius: 8px;
background-color: #fff;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border: 1px solid #d0d5dd;
overflow: hidden;
display: flex;
flex-direction: row;
padding: 10px 16px;
align-items: center;
justify-content: center;
gap: 8px;
`;
export const FrameDiv = styled.div`align-self: stretch;
flex: 1;
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: flex-start;
gap: 8px;
text-align: left;
font-size: 14px;
color: #344054;
`;
export const FrameParent = styled.div`align-self: stretch;
height: 535px;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
gap: 8px;
text-align: center;
font-size: 12px;
color: #f5f8ff;
`;
export const ImAProduct = styled.p`margin-block-start: 0;
margin-block-end: 16px;
`;
export const ImPassionateAbout = styled.p`margin: 0;
`;
export const Paragraph = styled.div`align-self: stretch;
position: relative;
line-height: 24px;
color: #475467;
`;
export const TextAndParagraph1 = styled.div`align-self: stretch;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
gap: 8px;
`;
export const About = styled.div`flex: 1;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
`;
export const Heading = styled.div`position: relative;
line-height: 24px;
font-weight: 600;
`;
export const Balance = styled.div`position: relative;
letter-spacing: -0.02em;
line-height: 44px;
font-weight: 600;
`;
export const IconAndBalance1 = styled.div`display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
font-size: 36px;
color: #101828;
`;
export const HeadingAndBalance1 = styled.div`display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
gap: 16px;
@media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const Text9 = styled.div`position: relative;
line-height: 24px;
font-weight: 500;
`;
export const TextAndIcon1 = styled.div`display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
gap: 8px;
`;
export const DetailsWrap = styled.div`display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: flex-start;
`;
export const Details = styled.div`width: 448px;
display: flex;
flex-direction: column;
align-items: flex-end;
justify-content: flex-start;
gap: 24px;
color: #475467;
@media (max-width: 768px) {
  align-items: flex-start;
  }
`;
export const Content = styled.div`align-self: stretch;
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: flex-start;
gap: 64px;
@media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
export const DividerIcon = styled.img`align-self: stretch;
position: relative;
max-width: 100%;
overflow: hidden;
height: 1px;
flex-shrink: 0;
`;
export const Container4 = styled.div`align-self: stretch;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
gap: 24px;
`;
export const SectionRoot = styled.div`width: 100%;
position: relative;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
text-align: left;
font-size: 16px;
color: #101828;
font-family: Inter;
`;