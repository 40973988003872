import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  ButtonsContainer,
  CheckIconContainer,
  FileContainer,
  FileDetailsContainer,
  FileName,
  FileSize,
  ImportTenantsContainer,
  ImportTenantsMainText,
  ImportTenantsSubContainer,
  ImportTenantsText,
  InputContainer,
  LeftFileContainer,
  RightFileContainer,
  TextContainer,
  TopContainer,
  InputsGapContainer,
} from "./ImportFinances.styled";
import CurrentBalanceInput from "./ImportFinancesInput/CurrentBalanceInput";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import { useParams } from "react-router-dom";
import UploadIcon from "components/Icon/Icons/UploadIcon";
import CloseIcon from "components/Icon/Icons/CloseIcon";
import UploadInput from "components/shared/UploadInput/UploadInput";
import File2Icon from "components/Icon/Icons/File2Icon";
import BinIcon from "components/Icon/Icons/BinIcon";
import CheckIcon from "components/Icon/Icons/CheckIcon";
import {
  PrimaryButton,
  SecondaryButton,
} from "components/shared/Buttons/Buttons.styled";
import selectedTheme from "themes";
import { useFormik } from "formik";
import financesInitialValues from "initialValues/financesInitialValues";
import { useImportFinancesMutation } from "store/financesApiSlice";
import BuildingIdSelect from './ImportFinancesInput/BuildingIdSelect';

const ImportFinances = ({ open, handleClose, handleOpen, isLoading, buildings }) => {
  //const [payments, setPayments] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [stateSnapshot, setSnapshot] = useState(null);
  const [importFinances] = useImportFinancesMutation();

  const handleCloseDialog = () => {
    handleClose();
  };

  const handleOpenDialog = () => {
    handleOpen();
  };

  // eslint-disable-next-line no-unused-vars
  const { id } = useParams();
  const [file, setFile] = useState(null);

  const hiddenFileInput = useRef(null);

  const handleFileChange = (e) => {
    const maxFileSize = 300 * 1048576; // 300 MB
    if (e.target.files[0].size > maxFileSize) {
      makeErrorToastMessage("Fajl ne sme biti veći od 300 MB!");
      hiddenFileInput.current.value = null;
      return;
    }
    setFile(e.target.files[0]);
  };

  const handleSubmit = () => {
    //e.preventDefault();

    const formData = new FormData();
    formData.append("StartingAmount", formik.values.currentFinancesBalance);
    formData.append("File", file);
    formData.append("BuildingId", formik.values.buildingId)
    //need for buildingId
    //formData.append("id", id);

    importFinances(formData)
      .unwrap()
      .then(() => {
        makeToastMessage("Uspešno ste izvršili dodavanje finansija");
      })
      .catch(() => {
        makeErrorToastMessage("Nije uspešno izvršen upload finansija!");
      });
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const removeFile = () => {
    hiddenFileInput.current.value = null;
    setFile(null);
  };

  const tranformDataForSelectInput = () => {
    return buildings.map((obj) => {
      return {
        value: obj.id,
        label: obj.buildingAddress,
      };
    });
  };

  const formik = useFormik({
    initialValues: financesInitialValues,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return isLoading ? (
    <p>Uploading...</p>
  ) : (
    <ImportTenantsContainer
      open={open}
      onClose={handleCloseDialog}
      onOpen={handleOpenDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "min-content",
          },
        },
      }}
    >
      <ImportTenantsSubContainer
        onSubmit={formik.handleSubmit}
        component="form"
      >
        <TopContainer>
          <UploadIcon />
          <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
        </TopContainer>
        <TextContainer style={{ border: "none" }}>
          <ImportTenantsMainText>Izvezi finansije</ImportTenantsMainText>
          <ImportTenantsText>
            U sledecem prozoru mozete izvezati finansije o zgradama sa promenljivim pocetnim stanjem u excel fajl
          </ImportTenantsText>
        </TextContainer>
        <InputsGapContainer>
        <InputContainer>
          <BuildingIdSelect formik={formik}                   options={tranformDataForSelectInput()}
                  defaultValue={tranformDataForSelectInput()[0]} />
        </InputContainer>
        <InputContainer>
          <CurrentBalanceInput formik={formik} />
        </InputContainer>
        <InputContainer style={{ width: "600px" }}>
          <UploadInput
            mainText="Klikni da uploaduješ stanare"
            helperText="EXCEL (max. 300MB)"
            handleClick={handleClick}
            handleFileChange={handleFileChange}
            hiddenFileInput={hiddenFileInput}
            file={file}
            accept=".xlsx"
            //isError={isError}
            style={{ marginTop: "18px" }}
            changeNameAfterUpload={false}
          />
          {file === null ? null : (
            <FileContainer>
              <LeftFileContainer>
                <File2Icon />
                <FileDetailsContainer>
                  <FileName>{file.name}</FileName>
                  <FileSize>
                    {(file.size / 1048576).toString().slice(0, 4)} MB
                  </FileSize>
                </FileDetailsContainer>
              </LeftFileContainer>
              <RightFileContainer>
                <BinIcon
                  style={{ marginRight: "25px", cursor: "pointer" }}
                  onClick={removeFile}
                />
                <CheckIconContainer>
                  <CheckIcon />
                </CheckIconContainer>
              </RightFileContainer>
            </FileContainer>
          )}
        </InputContainer>
        </InputsGapContainer>


        <ButtonsContainer>
          <PrimaryButton style={{ width: "50%" }} onClick={handleClose}>
            Odustani
          </PrimaryButton>
          <SecondaryButton
            style={{
              width: "50%",
              marginLeft: "12px",
              color: selectedTheme.colors.base.white,
            }}
            backgroundcolor={
              file === null
                ? selectedTheme.colors.primary[200]
                : selectedTheme.colors.primary[600]
            }
            disabled={file === null ? true : false}
            type="submit"
          >
            Exportuj u Excel
          </SecondaryButton>
        </ButtonsContainer>
      </ImportTenantsSubContainer>
    </ImportTenantsContainer>
  );
};

ImportFinances.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOpen: PropTypes.func,
  importTenants: PropTypes.func,
isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  buildings: PropTypes.array,
};

export default ImportFinances;
