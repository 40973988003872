import React from "react";
import { ReactComponent as ProfileIconSvg } from "../../../assets/Icons/profile.svg";

const ProfileIcon = (props) => {
  return <ProfileIconSvg {...props} />;
};

ProfileIcon.propTypes = {};

export default ProfileIcon;
