import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";

export const HeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    margin: 32px 16px 0 16px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const HeaderSubContainer = styled(Box)`
  display: flex;
  @media (max-width: 768px) {
    width: 100%;
  }
  
`;

export const HeaderText = styled(Typography)`
  font-family: ${selectedTheme.type.displaySm.semibold.fontFamily};
  font-size: ${selectedTheme.type.displaySm.semibold.fontSize};
  font-weight: ${selectedTheme.type.displaySm.semibold.fontWeight};
  font-style: ${selectedTheme.type.displaySm.semibold.fontStyle};
  line-height: ${selectedTheme.type.displaySm.semibold.lineHeight};
  color: ${selectedTheme.colors.gray[900]};
  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
`;
