/* eslint-disable */
import React from "react";
import RegisterLayout from "layouts/RegisterLayout/RegisterLayout";
import RegisterContent from "components/Register/RegisterContent";

const RegisterPage = () => {

  return (
    <>
      <RegisterLayout content={<RegisterContent />} />
    </>

    
  );
};
export default RegisterPage;
