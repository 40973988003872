import React from "react";
import PropTypes from "prop-types";
import { addBuildingInitialConstants } from "initialValues/addBuildingInitialValues";
import TextInputField from "components/shared/InputFields/TextField/TextField";
import { charactersToExlude } from "constants/helpers";

const PibField = (props) => {
  const formik = props?.formik;

  const handleCurrentAccountField = (event) => {
    const inputValue = event.target.value;
    let formatted = "";
    formatted = inputValue.replace(/[^0-9]/g, "");
    return formatted;
  };

  const preventEnteringNonNumericCharacters = (event) => {
    // input will accept only numbers
    if (charactersToExlude.includes(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <TextInputField
      id={addBuildingInitialConstants.pib}
      name={addBuildingInitialConstants.pib}
      placeholder="Unesite PIB vaše firme"
      label="PIB"
      type="number"
      value={formik.values.pib}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={
        formik.touched?.[addBuildingInitialConstants.pib] &&
        formik.errors?.[addBuildingInitialConstants.pib]
      }
      apiError={props.apiError}
      style={props?.style}
      handleCurrentAccountField={handleCurrentAccountField}
      onKeyDown={preventEnteringNonNumericCharacters}
    />
  );
};

PibField.propTypes = {
  formik: PropTypes.object,
  apiError: PropTypes.any,
  style: PropTypes.object,
};

export default PibField;
