import React from "react";
import PropTypes from "prop-types";
import { addUserInitialConstants } from "initialValues/addUserInitialValues";
import TextInputField from "components/shared/InputFields/TextField/TextField";

const FirstNameField = (props) => {
  const formik = props?.formik;

  return (
    <TextInputField
      id={addUserInitialConstants.firstName}
      name={addUserInitialConstants.firstName}
      placeholder="Unesite ime korisnika"
      label="Ime Korisnika"
      value={formik.values.firstName}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched?.[addUserInitialConstants.firstName] && formik.errors?.[addUserInitialConstants.firstName]}
      apiError={props.apiError}
      style={props?.style}
    />
  );
};

FirstNameField.propTypes = {
  formik: PropTypes.object,
  apiError: PropTypes.any,
  style: PropTypes.object,
};

export default FirstNameField;
