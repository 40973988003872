import React from "react";
import { ReactComponent as Edit2IconSvg } from "../../../assets/Icons/edit2.svg";

const Edit2Icon = (props) => {
  return <Edit2IconSvg {...props} />;
};

Edit2Icon.propTypes = {};

export default Edit2Icon;
