import React from "react";
import PropTypes from "prop-types";
import { addBuildingInitialConstants } from "initialValues/addBuildingInitialValues";
import TextInputField from "components/shared/InputFields/TextField/TextField";

const AddressField = (props) => {
  const formik = props?.formik;

  return (
    <TextInputField
      id={addBuildingInitialConstants.address}
      name={addBuildingInitialConstants.address}
      placeholder="Unesi adresu"
      label="Adresa zgrade"
      value={formik.values.address}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched?.[addBuildingInitialConstants.address] && formik.errors?.[addBuildingInitialConstants.address]}
      apiError={props.apiError}
      style={props?.style}
    />
  );
};

AddressField.propTypes = {
  formik: PropTypes.object,
  apiError: PropTypes.any,
  style: PropTypes.object,
};

export default AddressField;
