import React from "react";
import PropTypes from "prop-types";
import TextInputField from "components/shared/InputFields/TextField/TextField";
import { addApartmentInitialConstants } from "initialValues/addApartmentInitialValues";

const TenantLastNameField = (props) => {
  const formik = props?.formik;

  return (
    <TextInputField
      id={addApartmentInitialConstants.lastName}
      name={addApartmentInitialConstants.lastName}
      placeholder="Unesi prezime vlasnika"
      label="Prezime vlasnika"
      value={formik.values.lastName}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched?.[addApartmentInitialConstants.lastName] && formik.errors?.[addApartmentInitialConstants.lastName]}
      apiError={props.apiError}
      style={props?.style}
      flex={props?.flex}
    />
  );
};

TenantLastNameField.propTypes = {
  formik: PropTypes.object,
  apiError: PropTypes.any,
  style: PropTypes.object,
  flex:PropTypes.bool,
};

export default TenantLastNameField;
