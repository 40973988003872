export const addUserInitialConstants = {
  firstName: "firstName",
  lastName: "lastName",
  email: "email",
  role: "role",
  password: "password",
};

export default {
  [addUserInitialConstants.firstName]: "",
  [addUserInitialConstants.lastName]: "",
  [addUserInitialConstants.email]: "",
  [addUserInitialConstants.role]: {},
  [addUserInitialConstants.password]: "",
};
