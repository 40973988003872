export const loginFormikConstants = {
  email: "email",
  password: "password",
  rememberMe: "rememberMe",

}

export default {
  [loginFormikConstants.email]: "",
  [loginFormikConstants.password]: "",
  [loginFormikConstants.rememberMe]: false,
}