import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Collapse,
} from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const PrimaryTableContainer = styled(Box)`
  width: 100%;
  background-color: ${selectedTheme.colors.base.white};
`;

export const MainTable = styled(Table)`
  width: 100%;
  border-collapse: colapse;
`;

export const MainTableHead = styled(TableHead)`
  text-align: left;
  height: 44px;
  background-color: ${selectedTheme.colors.gray[50]};
`;

export const MainTableRow = styled(TableRow)`
  padding: 0px !important;
`;

export const MainTableRow2 = styled(TableRow)``;

export const MainTableRow3 = styled(TableCell)`
  font-family: ${selectedTheme.type.textXs.medium.fontFamily};
  font-size: 16px;
  font-style: ${selectedTheme.type.textXs.medium.fontStyle};
  font-weight: ${selectedTheme.type.textXs.medium.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
  padding-left: 24px;
`;

export const MainTableCell = styled(TableCell)`
  padding-left: 24px;
  font-family: ${selectedTheme.type.textXs.medium.fontFamily};
  font-size: 16px;
  font-style: ${selectedTheme.type.textXs.medium.fontStyle};
  font-weight: ${selectedTheme.type.textXs.medium.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
`;

export const MainCollapse = styled(Collapse)``;

export const IconsContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const MainTableBody = styled(TableBody)``;

export const Pagination = styled(Typography)``;

export const PaginationButtonsContainer = styled(Typography)``;

export const RoleContainer = styled(Box)`
  display: flex;
  font-family: ${selectedTheme.type.textXs.medium.fontFamily};
  font-size: 16px;
  font-style: ${selectedTheme.type.textXs.medium.fontStyle};
  font-weight: ${selectedTheme.type.textXs.medium.fontWeight};
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
  width: fit-content;
  border-radius: 16px;
`;

export const UserContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

export const UserMainContainer = styled(Box)``;

export const EmailText = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.medium.fontFamily};
  font-size: 16px;
  font-style: ${selectedTheme.type.textSm.medium.fontStyle};
  font-weight: ${selectedTheme.type.textSm.medium.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
`;

export const NameContainer = styled(Box)`
  display: flex;
`;

export const UserName = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.medium.fontFamily};
  font-size: 16px;
  font-style: ${selectedTheme.type.textSm.medium.fontStyle};
  font-weight: ${selectedTheme.type.textSm.medium.fontWeight};
  color: ${selectedTheme.colors.gray[900]};
`;

export const AgencyName = styled(Box)`
  display: flex;
  font-family: ${selectedTheme.type.textXs.medium.fontFamily};
  font-size: ${selectedTheme.type.textXs.medium.fontSize};
  font-style: ${selectedTheme.type.textXs.medium.fontStyle};
  font-weight: ${selectedTheme.type.textXs.medium.fontWeight};
  color: ${selectedTheme.colors.primary[700]};
  background-color: ${selectedTheme.colors.primary[50]};
  margin-left: 10px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 16px;
`;
