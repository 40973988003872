import React from "react";
import { ReactComponent as ArrowUpIconSvg } from "../../../assets/Icons/arrow-up.svg";

const ArrowUpIcon = (props) => {
  return <ArrowUpIconSvg {...props} />;
};

ArrowUpIcon.propTypes = {};

export default ArrowUpIcon;
