import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Dialog,
} from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";

export const PrimaryTableContainer = styled(Box)`
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  background-color: ${selectedTheme.colors.base.white};
  @media (max-width: 768px) {
    overflow-x: auto;
  }
`;

export const TableIconActionContainer = styled(Box)`
  cursor: pointer;
  padding: 8px;
`

export const MainTable = styled(Table)`
  width: 100%;
  border-collapse: colapse;
`;

export const MainTableHead = styled(TableHead)`
  text-align: left;
  height: 44px;
  background-color: ${selectedTheme.colors.gray[50]};
`;

export const MainTableRow = styled(TableRow)`
  padding: 0px !important;
`;

export const MainTableCell = styled(TableCell)`
  padding-left: 24px;
  flex: 1;
  font-family: ${selectedTheme.type.textXs.medium.fontFamily};
  font-size: 16px;
  font-style: ${selectedTheme.type.textXs.medium.fontStyle};
  font-weight: ${selectedTheme.type.textXs.medium.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
  white-space: nowrap;
  @media (max-width: 768px) {
    white-space: nowrap;
  }
`;

export const MainTableRow2 = styled(TableRow)``;

export const MainTableRow3 = styled(TableCell)`
  font-family: ${selectedTheme.type.textXs.medium.fontFamily};
  font-size: 16px;
  font-style: ${selectedTheme.type.textXs.medium.fontStyle};
  font-weight: ${selectedTheme.type.textXs.medium.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
  padding-left: 24px;
  white-space: nowrap;
  @media (max-width: 768px) {
    white-space: nowrap;
  }
`;

export const Image = styled("img")`
  width: 64px;
`;

export const MainTableBody = styled(TableBody)``;

export const Pagination = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.medium.fontFamily};
  font-size: 16px;
  font-style: ${selectedTheme.type.textSm.medium.fontStyle};
  font-weight: ${selectedTheme.type.textSm.medium.fontWeight};
  color: ${selectedTheme.colors.gray[700]};
`;

export const PaginationButtonsContainer = styled(Typography)``;

export const RentContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

export const RentSubContainer = styled(Box)`
  display: flex;
  width: 50px;
  height: 26px;
  border-radius: 16px;
  background-color: ${selectedTheme.colors.primary[50]};
  mix-blend-mode: multiply;
  padding: 2px 8px;
  color: ${selectedTheme.colors.primary[700]};
  margin-left: 20px;
`;

export const IconsContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

export const OptionsContainer = styled(Box)`
  width: 240px;
  height: 84px;
  border: 1px solid ${selectedTheme.colors.gray[200]};
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 6px;
  padding-left: 6px;
  border-radius: 6px;
  position: absolute;
  top: 45px;
  right: 55px;
  background-color: ${selectedTheme.colors.base.white};
  z-index: 1000;
  box-shadow: ${selectedTheme.effects.shadow.md.boxShadow};
`;

export const Option = styled(Box)`
  display: flex;
  align-items: center;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 10px;
  padding-right: 10px;
`;

export const OptionText = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.medium.fontFamily};
  font-size: 14px;
  font-style: ${selectedTheme.type.textSm.medium.fontStyle};
  font-weight: ${selectedTheme.type.textSm.medium.fontWeight};
  color: ${selectedTheme.colors.gray[700]};
  padding-left: 8px;
`;

export const DialogContainer = styled(Dialog)``;

export const DialogSubContainer = styled(Box)`
  padding: 24px;
`;
export const DialogTopContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DialogHeaderContainer = styled(Box)`
  margin-top: 16px;
`;

export const DialogMainText = styled(Typography)`
  font-family: ${selectedTheme.type.textLg.semibold.fontFamily};
  font-size: ${selectedTheme.type.textLg.semibold.fontSize};
  font-style: ${selectedTheme.type.textLg.semibold.fontStyle};
  font-weight: ${selectedTheme.type.textLg.semibold.fontWeight};
  color: ${selectedTheme.colors.gray[900]};
`;
export const DialogSubText = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.regular.fontFamily};
  font-size: ${selectedTheme.type.textSm.regular.fontSize};
  font-style: ${selectedTheme.type.textSm.regular.fontStyle};
  font-weight: ${selectedTheme.type.textSm.regular.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
`;

export const DialogContentContainer = styled(Box)`
  margin-top: 32px;
`;

export const DialogTable = styled(Box)`
  width: 525px;
  max-height: 332px;
  box-shadow: ${selectedTheme.effects.shadow.sm.boxShadow};
  border: 1px solid ${selectedTheme.colors.gray[200]};
  border-radius: 12px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const DialogTableHeader = styled(Box)`
  width: 523px;
  height: 44px;
  background-color: ${selectedTheme.colors.gray[50]};
  display: flex;
  align-items: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom: 1px solid ${selectedTheme.colors.gray[200]};
  padding-left: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 24px;
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  margin-top: 32px;
`;

export const HeaderText = styled(Typography)`
  color: ${selectedTheme.colors.gray[600]};
  margin-left: 3px;
`;

export const DialogTableContent = styled(Box)``;

export const DialogTableRow = styled(Box)`
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${selectedTheme.colors.gray[200]};
`;

export const DialogRowText = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.medium.fontFamily};
  font-size: ${selectedTheme.type.textSm.medium.fontSize};
  font-style: ${selectedTheme.type.textSm.medium.fontStyle};
  font-weight: ${selectedTheme.type.textSm.medium.fontWeight};
  color: ${selectedTheme.colors.gray[900]};
`;
