import React from "react";
import { ReactComponent as LeftArrowIconSvg } from "../../../assets/Icons/leftArrow.svg";

const LeftArrowIcon = (props) => {
  return <LeftArrowIconSvg {...props} />;
};

LeftArrowIcon.propTypes = {};

export default LeftArrowIcon;
