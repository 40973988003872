import { Box, FormGroup, Typography } from "@mui/material";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import selectedTheme from "themes";
import Icon from "components/Icon/Icon";

export const LoginFormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 32px;
  gap: 32px;
  width: fit-content;
  @media (max-width: 768px) {
    width: 100%;
    padding: 16px;
  }
`;
export const FormHeader = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const FormTitle = styled(Typography)`
  //template
  font-family: ${selectedTheme.type.displayMd.semibold.fontFamily};
  font-size: ${selectedTheme.type.displayMd.semibold.fontSize};
  font-weight: ${selectedTheme.type.displayMd.semibold.fontWeight};
  font-style: ${selectedTheme.type.displayMd.semibold.fontStyle};
  line-height: ${selectedTheme.type.displayMd.semibold.lineHeight};
  letter-spacing: ${selectedTheme.type.displayMd.semibold.letterSpacing};
  //
  color: ${selectedTheme.colors.gray[900]};
`;
export const FormSubtitle = styled(Typography)`
  //template
  font-family: ${selectedTheme.type.textSm.regular.fontFamily};
  font-size: ${selectedTheme.type.textSm.regular.fontSize};
  font-weight: ${selectedTheme.type.textSm.regular.fontWeight};
  font-style: ${selectedTheme.type.textSm.regular.fontStyle};
  line-height: ${selectedTheme.type.textSm.regular.lineHeight};
  //
  color: ${selectedTheme.colors.gray[600]};
`;
export const InputContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  gap: 6px;
  flex-wrap: wrap;
  padding: 0px;
  position: relative;
`;
export const InputLabel = styled(Typography)`
  //template
  font-family: ${selectedTheme.type.textSm.medium.fontFamily};
  font-size: ${selectedTheme.type.textSm.medium.fontSize};
  font-weight: ${selectedTheme.type.textSm.medium.fontWeight};
  font-style: ${selectedTheme.type.textSm.medium.fontStyle};
  line-height: ${selectedTheme.type.textSm.medium.lineHeight};
  //
  color: ${selectedTheme.colors.gray[700]};
`;
export const InputField = styled.input`
  padding: 10px 14px;
  background: ${selectedTheme.colors.base.white};
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${selectedTheme.colors.gray[300]};
  box-shadow: ${selectedTheme.effects.shadow.xs.boxShadow};
  transition: all 0.2s;
  //text
  font-family: ${selectedTheme.type.textMd.regular.fontFamily};
  font-size: ${selectedTheme.type.textMd.regular.fontSize};
  font-weight: ${selectedTheme.type.textMd.regular.fontWeight};
  font-style: ${selectedTheme.type.textMd.regular.fontStyle};
  line-height: ${selectedTheme.type.textMd.regular.lineHeight};
  color: ${selectedTheme.colors.gray[700]};
  ${(props) =>
    props?.disabled &&
    css`
      /* background-color: ;
      color:  */
    `}
  &::placeholder {
    color: ${selectedTheme.colors.gray[500]};
  }
  &:focus {
    outline: none;
    box-shadow: ${(props) =>
      props?.error && typeof props?.error.error !== "undefined"
        ? selectedTheme.effects.shadow.xsFocused_4pxError100.boxShadow
        : selectedTheme.effects.shadow.xsFocused_4pxPrimary100.boxShadow};
    border: 1px solid
      ${(props) =>
        props?.error && typeof props?.error.error !== "undefined"
          ? selectedTheme.colors.error[300]
          : selectedTheme.colors.primary[300]};
  }
`;

export const HintText = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.regular.fontFamily};
  font-size: ${selectedTheme.type.textSm.regular.fontSize};
  font-weight: ${selectedTheme.type.textSm.regular.fontWeight};
  font-style: ${selectedTheme.type.textSm.regular.fontStyle};
  line-height: ${selectedTheme.type.textSm.regular.lineHeight};
  color: ${selectedTheme.colors.gray[600]};
`;

export const ErrorMessage = styled(Box)`
  font-family: ${selectedTheme.type.textSm.regular.fontFamily};
  font-size: ${selectedTheme.type.textSm.regular.fontSize};
  font-weight: ${selectedTheme.type.textSm.regular.fontWeight};
  font-style: ${selectedTheme.type.textSm.regular.fontStyle};
  line-height: ${selectedTheme.type.textSm.regular.lineHeight};
  color: ${selectedTheme.colors.error[500]};
`;
// export const InputRightImage = styled(Box)`
//   position: absolute;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   margin-top: auto;
//   margin-bottom: auto;
// `
export const CheckboxContainer = styled(FormGroup)`
  white-space: nowrap;
  & span {
    padding: 0 4px;
  }
  & label {
    margin-left: -5px;
  }
  //not working, try to style based on MaterialUI class in Span element
  & svg {
    //color: ${selectedTheme.colors.gray[300]};
  }
  @media (max-width: 400px) {
    margin-bottom: 24px;
  }
`;
export const LoginButton = styled(Box)`
  cursor: pointer;
  width: 100%;
  background: ${selectedTheme.colors.primary[600]};
  border: 1px solid ${selectedTheme.colors.primary[600]};
  color: ${selectedTheme.colors.base.white};
  font-family: ${selectedTheme.type.textMd.semibold.fontFamily};
  font-size: ${selectedTheme.type.textMd.semibold.fontSize};
  font-weight: ${selectedTheme.type.textMd.semibold.fontWeight};
  font-style: ${selectedTheme.type.textMd.semibold.fontStyle};
  line-height: ${selectedTheme.type.textMd.semibold.lineHeight};
  font-feature-settings: "salt" on;
  text-align: center;
  border-radius: 8px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${selectedTheme.effects.shadow.xs.boxShadow};
  &:hover {
    background: ${selectedTheme.colors.primary[700]};
    border: 1px solid ${selectedTheme.colors.primary[700]};
  }
  &:focus {
    outline: none;
    box-shadow: ${selectedTheme.effects.shadow.xsFocused_4pxPrimary100
      .boxShadow};
  }
`;

export const RightInputElementsContainer = styled(Box)`
  margin-top: auto;
  margin-bottom: auto;
  position: absolute;
  top: 37.5px;
  right: 14px;
  height: 26px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 4px;
  align-items: center;
  justify-content: flex-end;
`;

export const InputRightElement = styled(Icon)`
  cursor: pointer;
  width: 26px;
  & svg {
    width: 26px;
    height: 26px;
  }
`;
export const InputRightElementError = styled(Icon)`
  width: 16px;
  & svg {
    width: 16px;
    height: 16px;
  }
`;

export const Column = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${(props) =>
    props.w && typeof props.w === "number" && !Number.isNaN(props.w)
      ? `${props.w}%`
      : "auto"};
  gap: ${(props) =>
    props.gap && typeof props.gap === "number" && !Number.isNaN(props.gap)
      ? `${props.gap}px`
      : "unset"};
`;

export const CenteredRow = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${(props) =>
    props.gap && typeof props.gap === "number" && !Number.isNaN(props.gap)
      ? `${props.gap}px`
      : "unset"};
`;

export const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: ${(props) =>
    props.spaceBetween ? "space-between" : "flex-start"};
  align-items: center;
  gap: ${(props) =>
    props.gap && typeof props.gap === "number" && !Number.isNaN(props.gap)
      ? `${props.gap}px`
      : "unset"};
  width: ${(props) =>
    props.w && typeof props.w === "number" && !Number.isNaN(props.w)
      ? `${props.w}%`
      : "auto"};

      @media (max-width: 400px) {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
      }
`;
export const ForgotPasswordLink = styled(Link)`
  font-family: ${selectedTheme.type.textSm.semibold.fontFamily};
  font-size: ${selectedTheme.type.textSm.semibold.fontSize};
  font-weight: ${selectedTheme.type.textSm.semibold.fontWeight};
  font-style: ${selectedTheme.type.textSm.semibold.fontStyle};
  line-height: ${selectedTheme.type.textSm.semibold.lineHeight};
  color: ${selectedTheme.colors.primary[700]};
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    color: ${selectedTheme.colors.primary[800]};
  }
`;

export const PreText = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.regular.fontFamily};
  font-size: ${selectedTheme.type.textSm.regular.fontSize};
  font-weight: ${selectedTheme.type.textSm.regular.fontWeight};
  font-style: ${selectedTheme.type.textSm.regular.fontStyle};
  line-height: ${selectedTheme.type.textSm.regular.lineHeight};
  color: ${selectedTheme.colors.gray[600]};
`;

export const RegisterLink = styled(Link)`
  font-family: ${selectedTheme.type.textSm.semibold.fontFamily};
  font-size: ${selectedTheme.type.textSm.semibold.fontSize};
  font-weight: ${selectedTheme.type.textSm.semibold.fontWeight};
  font-style: ${selectedTheme.type.textSm.semibold.fontStyle};
  line-height: ${selectedTheme.type.textSm.semibold.lineHeight};
  color: ${selectedTheme.colors.primary[700]};
  text-decoration: none;

  &:hover {
    color: ${selectedTheme.colors.primary[800]};
  }
`;
