import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import {forgotPasswordFormikConstants} from "../../../initialValues/forgotPasswordInitialValues";
import PasswordField from "components/shared/InputFields/PasswordField/PasswordField";

const ConfirmPasswordField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;

  return (
      <PasswordField
        id={forgotPasswordFormikConstants.confirmPassword}
        name={forgotPasswordFormikConstants.confirmPassword}
        placeholder={t("resetPassword.confirmPasswordPlaceholder")}
        label={t("resetPassword.confirmPasswordLabel")}
        value={formik.values.confirmPassword}
        onChange={props?.onChange}
        type="password"
        onBlur={formik.handleBlur}
        error={formik.touched?.[forgotPasswordFormikConstants.confirmPassword] && formik.errors?.[forgotPasswordFormikConstants.confirmPassword]}
        helperText={i18n.forgotPassword && i18n.forgotPassword?.confirmPasswordHintText && i18n.forgotPassword?.confirmPasswordHintText !== "" && i18n.t("forgotPassword.confirmPasswordHintText")}
        loginAttempt={props?.loginAttempt}
        apiError={props?.apiError}
      />
  )
};

ConfirmPasswordField.propTypes = {
  formik: PropTypes.object,
  onChange: PropTypes.func,
  loginAttempt:PropTypes.bool,
  apiError:PropTypes.object,
};

export default ConfirmPasswordField;
