import React from "react";
import { ReactComponent as ThreeDotsHorizontalSvg } from "../../../assets/Icons/ThreeDotsHorizontal.svg";

const ThreeDotsHorizontal = (props) => {
  return <ThreeDotsHorizontalSvg {...props} />;
};

ThreeDotsHorizontal.propTypes = {};

export default ThreeDotsHorizontal;
