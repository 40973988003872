import { Autocomplete, TextField } from "@mui/material";
import React from "react";

const top100Films = [
  { label: "The Godfather", id: 1 },
  { label: "Pulp Fiction", id: 2 },
];

// or
// const top100Films = ["The Godfather", "Pulp Fiction"];

const ProfilePage = () => {
  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={top100Films}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Movie" />}
      />
    </>
  );
};

export default ProfilePage;
