import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "themes";

const handleColorByState = state => {
    switch (state) {
      case "error":
        return selectedTheme.colors.error[600];
      case "success":
        return selectedTheme.colors.success[600];
      default:
        return selectedTheme.colors.primary[600];
    }
  };

  const handleBackgroundByState = state => {
    switch (state) {
      case "error":
        return selectedTheme.colors.error[200];
      case "success":
        return selectedTheme.colors.success[200];
      default:
        return selectedTheme.colors.primary[200];
    }
  };

export const NuggetContainer = styled(Box)`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 6px;
  border-radius: 16px;
  font-family: ${selectedTheme.type.textXs.medium.fontFamily};
  font-size: ${selectedTheme.type.textXs.medium.fontSize};
  font-weight: ${selectedTheme.type.textXs.medium.fontWeight};
  font-style: ${selectedTheme.type.textXs.medium.fontStyle};
  line-height: ${selectedTheme.type.textXs.medium.lineHeight};
  //border: 1px solid ${selectedTheme.colors.success[300]};
  margin-left: ${props => props.marginLeft};

  color: ${(props) => handleColorByState(props?.state)};

  background-color: ${(props) => {
       handleBackgroundByState(props?.state)
  }};
  border: ${(props) => props.filled ? 'none' : `1.5px solid ${handleColorByState(props.state)}`};
  
`;

export const DotCircle = styled(Box)`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  //background-color: ${selectedTheme.colors.success[600]};
  background-color: ${(props) => handleColorByState(props.state)}
`;
