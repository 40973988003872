import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const WidgetFooter = styled(Box)`
  padding: 16px 24px;
  border-top: 1px solid ${selectedTheme.colors.gray[200]};
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
  @media (max-width: 768px) {
    padding: 12px 16px;
    position: sticky;
    left: 0;
    right: 0;
  }
`;

export const WidgetContent = styled(Box)`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const WidgetHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const WidgetTitle = styled(Box)`
  font-family: ${selectedTheme.type.textMd.semibold.fontFamily};
  font-size: ${selectedTheme.type.textMd.semibold.fontSize};
  font-weight: ${selectedTheme.type.textMd.semibold.fontWeight};
  font-style: ${selectedTheme.type.textMd.semibold.fontStyle};
  line-height: ${selectedTheme.type.textMd.semibold.lineHeight};
  color: ${selectedTheme.colors.gray[900]};
`;

export const WidgetTitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const WidgetTitleDetails = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.regular.fontFamily};
  font-size: ${selectedTheme.type.textSm.regular.fontSize};
  font-weight: ${selectedTheme.type.textSm.regular.fontWeight};
  font-style: ${selectedTheme.type.textSm.regular.fontStyle};
  line-height: ${selectedTheme.type.textSm.regular.lineHeight};
  color: ${selectedTheme.colors.gray[600]};
`;

export const WidgetMegaText = styled(Typography)`
  font-family: ${selectedTheme.type.displaySm.semibold.fontFamily};
  font-size: ${selectedTheme.type.displaySm.semibold.fontSize};
  font-weight: ${selectedTheme.type.displaySm.semibold.fontWeight};
  font-style: ${selectedTheme.type.displaySm.semibold.fontStyle};
  line-height: ${selectedTheme.type.displaySm.semibold.lineHeight};
  color: ${selectedTheme.colors.gray[900]};
  margin-right: 25px;
`;

export const WidgetHeaderRightContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;
