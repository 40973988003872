import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as AddDebitIconSvg } from "../../../assets/Icons/addDebit.svg";

const AddDebitIcon = (props) => {
  return <AddDebitIconSvg {...props} />;
};

AddDebitIcon.propTypes = {};

export default AddDebitIcon;
