import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const AddUserDialogContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

export const TopContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled(Typography)`
  font-family: ${selectedTheme.type.textLg.semibold.fontFamily};
  font-size: ${selectedTheme.type.textLg.semibold.fontSize};
  font-weight: ${selectedTheme.type.textLg.semibold.fontWeight};
  font-style: ${selectedTheme.type.textLg.semibold.fontStyle};
  line-height: ${selectedTheme.type.textLg.semibold.lineHeight};
  color: ${selectedTheme.colors.gray[900]};
  margin-top:16px;
`;

export const MainContainer = styled(Box)``;

export const InputContainer = styled(Box)`
  margin-top: 10px;
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 32px;
`;
