import React from "react";
import { ReactComponent as SearchIconSvg } from "../../../assets/Icons/seach.svg";

const SearchIcon = (props) => {
  return <SearchIconSvg {...props} />;
};

SearchIcon.propTypes = {};

export default SearchIcon;
