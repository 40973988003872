import React from "react";
import { ReactComponent as SettingsIconSvg } from "../../../assets/Icons/settings.svg";

const SettingsIcon = (props) => {
  return <SettingsIconSvg {...props} />;
};

SettingsIcon.propTypes = {};

export default SettingsIcon;
