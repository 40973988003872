import Navbar from "components/shared/Navbar/Navbar";
import React from "react";
import { ContentContainer, FinancesPageContainer } from "./FinancesPage.styled";
import { useAllFinancesQuery } from "../../store/financesApiSlice";
import AllFinancesComponent from "./../../components/AllFinancesComponent/AllFinancesComponent";
import HeaderComponent from "components/shared/Header/FinancesPage/HeaderComponent";

const FinancesPage = () => {
  const { data, isLoading } = useAllFinancesQuery();

  console.log(data);
  return (
    <FinancesPageContainer>
      <Navbar />
      <ContentContainer>
        <HeaderComponent/>
        {!isLoading && <AllFinancesComponent finances={data} />}
      </ContentContainer>
    </FinancesPageContainer>
  );
};

export default FinancesPage;
