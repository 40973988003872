import React from "react";
import { ReactComponent as UserIconSvg } from "../../../assets/Icons/user.svg";

const UserIcon = (props) => {
  return <UserIconSvg {...props} />;
};

UserIcon.propTypes = {};

export default UserIcon;
