import React from "react";
import { ReactComponent as ReverseSvg } from "../../../assets/Icons/reverse-left.svg";

const ReverseIcon = (props) => {
  return <ReverseSvg {...props} />;
};

ReverseIcon.propTypes = {};

export default ReverseIcon;
