import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const AddEditApartmentPageContainer = styled(Box)`
  display: flex;
  width:100%;
  min-height:100vh;
  height: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 48px;
  }
`;

export const MainContainer = styled(Box)`
  padding: 36px 34px;
  width:100%;
  @media (max-width: 768px) {
    padding: 16px;
  }
`;

export const NavigationContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

export const NavigationItem = styled(Typography)`
  font-family: ${selectedTheme.type.displaySm.medium.fontFamily};
  font-size: 14px;
  font-style: ${selectedTheme.type.displaySm.medium.fontStyle};
  font-weight: ${selectedTheme.type.displaySm.medium.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
  margin-left: 20px;
`;

export const TopContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 24px;
`;

export const Header = styled(Typography)`
  font-family: ${selectedTheme.type.displaySm.semibold.fontFamily};
  font-size: ${selectedTheme.type.displaySm.semibold.fontSize};
  font-style: ${selectedTheme.type.displaySm.semibold.fontStyle};
  font-weight: ${selectedTheme.type.displaySm.semibold.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
`;

export const ButtonsContainer = styled(Box)`
  @media (max-width: 768px) {
    display: flex;
    gap: 12px;
    width: 100%;
  }`;

export const ContentContainer = styled(Box)`
  margin-top: 32px;
  @media (max-width: 768px) {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

export const ContentSubContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 24px;
  }
`;

export const InformationContainer = styled(Box)`
  min-width: "280px";
`;

export const InformationMainText = styled(Typography)`
  font-family: ${selectedTheme.type.displaySm.medium.fontFamily};
  font-size: 14px;
  font-style: ${selectedTheme.type.displaySm.medium.fontStyle};
  font-weight: ${selectedTheme.type.displaySm.medium.fontWeight};
  color: ${selectedTheme.colors.gray[700]};
`;

export const InformationSubText = styled(Typography)`
  font-family: ${selectedTheme.type.displaySm.regular.fontFamily};
  font-size: 14px;
  font-style: ${selectedTheme.type.displaySm.regular.fontStyle};
  font-weight: ${selectedTheme.type.displaySm.regular.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
`;

export const Table = styled(Box)`
  border: 1px solid ${selectedTheme.colors.gray[200]};
  box-shadow: ${selectedTheme.effects.shadow.sm.boxShadow};
  border-radius: 12px;
  padding: 24px;
  background-color: ${selectedTheme.colors.base.white};
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 0.9;
  position: relative;
`;

export const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  position: relative;
`;

export const FundContainer = styled(Box)`
  display: flex;
  margin-top: 24px;
`;

export const AddressContainer = styled(Box)`
  display: flex;
  position: relative;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 24px;
  }
`;
