import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as DowloadCloudIconSvg } from "../../../assets/Icons/download-cloud-01.svg";

const DowloadCloudIcon = (props) => {
  return <DowloadCloudIconSvg {...props} />;
};

DowloadCloudIcon.propTypes = {};

export default DowloadCloudIcon;
