import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as UploadCloudIconSvg } from "../../../assets/Icons/upload-cloud-02.svg";

const UploadCloudIcon = (props) => {
  return <UploadCloudIconSvg {...props} />;
};

UploadCloudIcon.propTypes = {};

export default UploadCloudIcon;
