import { apiSlice } from "store/apiSlice";

export const citiesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    allCities: builder.query({
      query: () => ({
        url: "/cities",
      }),
    }),
  }),
});

export const { useAllCitiesQuery } = citiesApiSlice;
