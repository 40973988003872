import React from "react";
import { PropTypes } from 'prop-types';
import {
  HintText1,
  Input1,
  InputWithLabel1,
  Label,
  TextareaInputField1Root,
} from "./CustomTextArea.styled";

const CustomTextArea = (props) => {

    const handleChange = (event) => {
        if (!props?.disabledField) {
            var regExp = /[a-zA-Z]/g;
            if (regExp.test(event.target.value) && props?.number) {
              event.target.value = event.target.value.slice(0, -1);
            }
            props?.onChange(event);
          }
    }

  return (
    <TextareaInputField1Root>
      <InputWithLabel1>
        <Label>{props?.label}</Label>
        <Input1 placeholder={props?.placeholder} id={props?.id} name={props?.name} disabled={props?.disabledField} value={props?.value} onChange={(event) => handleChange(event)} error={props?.error} />
      </InputWithLabel1>
      <HintText1>{props?.helperText}</HintText1>
    </TextareaInputField1Root>
  );
};

CustomTextArea.propTypes = {
    id: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.object,
  helperText: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  disabledField: PropTypes.bool,
  value: PropTypes.any,
  name: PropTypes.any,
  requiredField: PropTypes.bool,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  style:PropTypes.object,
  number:PropTypes.number,
}
export default CustomTextArea;
