import React from "react";
import { ReactComponent as ArrowUpIcon2Svg } from "../../../assets/Icons/ArrowUp.svg";

const ArrowUpIcon2 = (props) => {
  return <ArrowUpIcon2Svg {...props} />;
};

ArrowUpIcon2.propTypes = {};

export default ArrowUpIcon2;
