import React, { useState } from "react";
import PropTypes from "prop-types";
import EyeStrikedIcon from "../../../Icon/Icons/EyeStriked";
import EyeIcon from "../../../Icon/Icons/Eye";
import AlertCircle from "../../../Icon/Icons/AlertCircle";
import {
  HintText,
  ErrorMessage,
  InputContainer,
  InputField,
  InputLabel,
  RightInputElementsContainer,
  InputRightElement,
  InputRightElementError,
} from "../../InputFields/InputFields.styled";

const PasswordField = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (event) => {
    props?.onChange(event);
  };
  return (
    <InputContainer
      className={props.className}
      onClick={props?.onClick}
      disabled={props?.disabledField}
      $isErrorShown={props?.error}
    >
      {props?.label && (
        <InputLabel $required={props?.requiredField && !props?.value}>
          {props?.label}
        </InputLabel>
      )}
      <InputField
        id={props?.id}
        name={props?.name}
        type={!showPassword && props?.type}
        value={props?.value}
        onChange={() => handleChange(event)}
        onBlur={props.onBlur}
        placeholder={props?.placeholder}
        error={props?.error}
      />
      {/* show hint text if it exists and not empty in i18n and no formik errors */}
      {props?.helperText && <HintText>{props?.helperText}</HintText>}
      {props?.error && !props?.loginAttempt ? (
        <ErrorMessage>
          {typeof props?.error !== "object" &&
            props?.error !== null &&
            props?.error}
        </ErrorMessage>
      ) : (
        props?.apiError && <ErrorMessage>{props?.apiError}</ErrorMessage>
      )}
      <RightInputElementsContainer>
        {/* error.error is the actual string of error */}
        {props?.error && (
          <InputRightElementError>
            <AlertCircle />
          </InputRightElementError>
        )}
        <InputRightElement
          onClick={() => setShowPassword((prevValue) => !prevValue)}
          isLogin={true}
        >
          {showPassword ? <EyeStrikedIcon /> : <EyeIcon />}
        </InputRightElement>
      </RightInputElementsContainer>
    </InputContainer>
  );
};

PasswordField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  helperText: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  date: PropTypes.bool,
  disabledField: PropTypes.bool,
  value: PropTypes.any,
  name: PropTypes.any,
  number: PropTypes.bool,
  requiredField: PropTypes.bool,
  inputProps: PropTypes.any,
  placeholder: PropTypes.string,
  icon: PropTypes.node,
  fullWidth: PropTypes.bool,
  isSmall: PropTypes.bool,
  loginAttempt: PropTypes.bool,
  apiError: PropTypes.object,
  touched: PropTypes.any,
};

export default PasswordField;
