export const primaryTypography = {
  display2xl: {
    regular: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "72px",
      lineHeight: "90px",
      /* identical to box height, or 125% */
      letterSpacing: "-0.02em",
    },
    medium: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "72px",
      lineHeight: "90px",
      /* identical to box height, or 125% */
      letterSpacing: "-0.02em",
    },
    semibold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "72px",
      lineHeight: "90px",
      /* identical to box height, or 125% */
      letterSpacing: "-0.02em",
    },
    bold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "72px",
      lineHeight: "90px",
      /* identical to box height, or 125% */
      letterSpacing: "-0.02em",
    },
  },
  displayXl: {
    regular: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "60px",
      lineHeight: "72px",
      /* identical to box height, or 125% */
      letterSpacing: "-0.02em",
    },
    medium: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "60px",
      lineHeight: "72px",
      /* identical to box height, or 125% */
      letterSpacing: "-0.02em",
    },
    semibold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "60px",
      lineHeight: "72px",
      /* identical to box height, or 125% */
      letterSpacing: "-0.02em",
    },
    bold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "60px",
      lineHeight: "72px",
      /* identical to box height, or 125% */
      letterSpacing: "-0.02em",
    },
  },
  displayLg: {
    regular: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "48px",
      lineHeight: "60px",
      /* identical to box height, or 125% */
      letterSpacing: "-0.02em",
    },
    medium: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "48px",
      lineHeight: "60px",
      /* identical to box height, or 125% */
      letterSpacing: "-0.02em",
    },
    semibold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "48px",
      lineHeight: "60px",
      /* identical to box height, or 125% */
      letterSpacing: "-0.02em",
    },
    bold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "48px",
      lineHeight: "60px",
      /* identical to box height, or 125% */
      letterSpacing: "-0.02em",
    },
  },
  displayMd: {
    regular: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "36px",
      lineHeight: "44px",
      /* identical to box height, or 125% */
      letterSpacing: "-0.02em",
    },
    medium: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "36px",
      lineHeight: "44px",
      /* identical to box height, or 125% */
      letterSpacing: "-0.02em",
    },
    semibold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "36px",
      lineHeight: "44px",
      /* identical to box height, or 125% */
      letterSpacing: "-0.02em",
    },
    bold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "36px",
      lineHeight: "44px",
      /* identical to box height, or 125% */
      letterSpacing: "-0.02em",
    },
  },
  displaySm: {
    regular: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "30px",
      lineHeight: "38px",
      /* identical to box height, or 125% */
      letterSpacing: "-0.02em",
    },
    medium: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "30px",
      lineHeight: "38px",
      /* identical to box height, or 125% */
      letterSpacing: "-0.02em",
    },
    semibold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "30px",
      lineHeight: "38px",
      /* identical to box height, or 125% */
      letterSpacing: "-0.02em",
    },
    bold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "30px",
      lineHeight: "38px",
      /* identical to box height, or 125% */
      letterSpacing: "-0.02em",
    },
    // italic
    mediumItalic: {
        fontFamily: "Inter",
        fontStyle: "italic",
        fontWeight: 500,
        fontSize: "30px",
        lineHeight: "44px",
        /* identical to box height, or 125% */
        letterSpacing: "-0.02em",
      },
  },
  displayXs: {
    regular: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "24px",
      lineHeight: "32px",
      /* identical to box height, or 125% */
      letterSpacing: "-0.02em",
    },
    medium: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "24px",
      lineHeight: "32px",
      /* identical to box height, or 125% */
      letterSpacing: "-0.02em",
    },
    semibold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "32px",
      /* identical to box height, or 125% */
      letterSpacing: "-0.02em",
    },
    bold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "32px",
      /* identical to box height, or 125% */
      letterSpacing: "-0.02em",
    },
    // italic
    mediumItalic: {
        fontFamily: "Inter",
        fontStyle: "italic",
        fontWeight: 500,
        fontSize: "24px",
        lineHeight: "36px",
        /* identical to box height, or 125% */
        letterSpacing: "-0.02em",
      },
  },
  textXl: {
    regular: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "30px",
      /* identical to box height, or 125% */
    },
    medium: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "30px",
      /* identical to box height, or 125% */
    },
    semibold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "30px",
      /* identical to box height, or 125% */
    },
    bold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "30px",
      /* identical to box height, or 125% */
    },
    // italic
    regularItalic: {
        fontFamily: "Inter",
        fontStyle: "italic",
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "30px",
        /* identical to box height, or 125% */
      },
      mediumItalic: {
        fontFamily: "Inter",
        fontStyle: "italic",
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "30px",
        /* identical to box height, or 125% */
      },
      semiBoldItalic: {
        fontFamily: "Inter",
        fontStyle: "italic",
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: "30px",
        /* identical to box height, or 125% */
      },
      boldItalic: {
        fontFamily: "Inter",
        fontStyle: "italic",
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "30px",
        /* identical to box height, or 125% */
      },
      //underlined
      regularUnderlined: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "30px",
        /* identical to box height, or 125% */
        textDecorationLine: 'underline',
      },
  },
  textLg: {
    regular: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "28px",
      /* identical to box height, or 125% */
    },
    medium: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "28px",
      /* identical to box height, or 125% */
    },
    semibold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "28px",
      /* identical to box height, or 125% */
    },
    bold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "28px",
      /* identical to box height, or 125% */
    },
    // italic
    regularItalic: {
        fontFamily: "Inter",
        fontStyle: "italic",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "28px",
        /* identical to box height, or 125% */
      },
      mediumItalic: {
        fontFamily: "Inter",
        fontStyle: "italic",
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "28px",
        /* identical to box height, or 125% */
      },
      semiBoldItalic: {
        fontFamily: "Inter",
        fontStyle: "italic",
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "28px",
        /* identical to box height, or 125% */
      },
      boldItalic: {
        fontFamily: "Inter",
        fontStyle: "italic",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "28px",
        /* identical to box height, or 125% */
      },
      //underlined
      regularUnderlined: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "28px",
        /* identical to box height, or 125% */
        textDecorationLine: 'underline',
      },
      mediumUnderlined: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "28px",
        /* identical to box height, or 125% */
        textDecorationLine: 'underline',
      },
  },
  textMd: {
    regular: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      /* identical to box height, or 125% */
    },
    medium: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      /* identical to box height, or 125% */
    },
    semibold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px",
      /* identical to box height, or 125% */
    },
    bold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "24px",
      /* identical to box height, or 125% */
    },
    // italic
    regularItalic: {
        fontFamily: "Inter",
        fontStyle: "italic",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        /* identical to box height, or 125% */
      },
      mediumItalic: {
        fontFamily: "Inter",
        fontStyle: "italic",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "24px",
        /* identical to box height, or 125% */
      },
      semiBoldItalic: {
        fontFamily: "Inter",
        fontStyle: "italic",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        /* identical to box height, or 125% */
      },
      boldItalic: {
        fontFamily: "Inter",
        fontStyle: "italic",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        /* identical to box height, or 125% */
      },
      //underlined
      regularUnderlined: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        /* identical to box height, or 125% */
        textDecorationLine: 'underline',
      },
      mediumUnderlined: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "24px",
        /* identical to box height, or 125% */
        textDecorationLine: 'underline',
      },
  },
  textSm: {
    regular: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      /* identical to box height, or 125% */
    },
    medium: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      /* identical to box height, or 125% */
    },
    semibold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
      /* identical to box height, or 125% */
    },
    bold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "20px",
      /* identical to box height, or 125% */
    },
      //underlined
      regularUnderlined: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20px",
        /* identical to box height, or 125% */
        textDecorationLine: 'underline',
      },
      mediumUnderlined: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "20px",
        /* identical to box height, or 125% */
        textDecorationLine: 'underline',
      },
  },
  textXs: {
    regular: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "18px",
      /* identical to box height, or 125% */
    },
    medium: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "18px",
      /* identical to box height, or 125% */
    },
    semibold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "18px",
      /* identical to box height, or 125% */
    },
    bold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "18px",
      /* identical to box height, or 125% */
    },
  },
};
