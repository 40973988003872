import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";

export const HeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap:24px;
  }
`;

export const ButtonRow = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 12px;
  @media (max-width: 768px) {
    flex-direction: row-reverse;
  }
`

export const HeaderTextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const HeaderText = styled(Typography)`
  font-family: ${selectedTheme.type.displaySm.semibold.fontFamily};
  font-size: ${selectedTheme.type.displaySm.semibold.fontSize};
  font-weight: ${selectedTheme.type.displaySm.semibold.fontWeight};
  font-style: ${selectedTheme.type.displaySm.semibold.fontStyle};
  line-height: ${selectedTheme.type.displaySm.semibold.lineHeight};
  color: ${selectedTheme.colors.gray[900]};
`;

export const SubHeaderText = styled(Typography)`
  font-family: ${selectedTheme.type.textMd.regular.fontFamily};
  font-size: ${selectedTheme.type.textMd.regular.fontSize};
  font-weight: ${selectedTheme.type.textMd.regular.fontWeight};
  font-style: ${selectedTheme.type.textMd.regular.fontStyle};
  line-height: ${selectedTheme.type.textMd.regular.lineHeight};
  color: ${selectedTheme.colors.gray[600]};
`;
