import AuthFooter from 'components/shared/Auth/AuthFooter/AuthFooter'
import AuthHeader from 'components/shared/Auth/AuthHeader/AuthHeader'
import React from 'react'
import { RegisterContainer } from './RegisterContent.styled'
import RegisterForm from './RegisterForm/RegisterForm'
//import PropTypes from 'prop-types'

const RegisterContent = () => {
  return (
    <RegisterContainer>
    <AuthHeader />
    <RegisterForm />
    <AuthFooter/>
  </RegisterContainer>
  )
}

RegisterContent.propTypes = {}

export default RegisterContent