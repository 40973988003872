import React from "react";
import { BuildingsOverviewContainer } from "./BuildingsOverviewComponent.styled";
import {
  WidgetContent,
  WidgetHeader,
  WidgetHeaderRightContainer,
  WidgetTitle,
  WidgetTitleContainer,
  WidgetTitleDetails,
} from "components/shared/Widgets/Widgets.styled";
// import { PrimaryButton } from "components/shared/Buttons/Buttons.styled";
// import CalendarIcon from "components/Icon/Icons/CalendarIcon";
import PrimaryTable from "components/shared/Tables/PrimaryTable";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useBuildingsWithoutBillsQuery } from "store/homeApiSlice";
import { getMonth } from "../../util/dateHelpers";
import { useNavigate } from "react-router-dom";

const BuildingsOverviewComponent = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(dayjs());
  const { data, isLoading } = useBuildingsWithoutBillsQuery(
    value.toDate().getMonth() + 1
  );
  const columns = ["ID", "Adresa", "Br.Stanova"];
  const pageSize = 7;

  const handleRowClick = (id) => {
    navigate(`/buildings/${id}`);
  };

  const geStructuredData = () =>
    data.map(({ id, numberOfApartments, address, city }) => [
      id,
      address + ", " + city,
      numberOfApartments,
    ]);

  return isLoading ? (
    <p>Loading...</p>
  ) : (
    <BuildingsOverviewContainer>
      <WidgetContent>
        <WidgetHeader>
          <WidgetTitleContainer>
            <WidgetTitle>Pregled zgrada</WidgetTitle>
            <WidgetTitleDetails>
              Zgrade bez računa za mesec{" "}
              {getMonth(value.toDate().getMonth() + 1)}
            </WidgetTitleDetails>
          </WidgetTitleContainer>
          <WidgetHeaderRightContainer>
            <DatePicker
              label=""
              value={value}
              onChange={(newValue) => setValue(newValue)}
              views={["month"]}
              sx={{ width: "60%" }}
            />
          </WidgetHeaderRightContainer>
        </WidgetHeader>
      </WidgetContent>
      <PrimaryTable
        data={geStructuredData()}
        pageSize={pageSize}
        columns={columns}
        handleRowClick={handleRowClick}
      />
    </BuildingsOverviewContainer>
  );
};
export default BuildingsOverviewComponent;
