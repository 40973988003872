import { Dialog, Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const ImportTenantsContainer = styled(Dialog)``;

export const ImportTenantsSubContainer = styled(Box)``;

export const TopContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 0px 24px;
`;

export const TextContainer = styled(Box)`
  margin-top: 16px;
  padding: 0px 24px 24px 24px;
  border-bottom: 1px solid ${selectedTheme.colors.gray[200]};
`;

export const InputContainer = styled(Box)`
  padding: 0px 24px;
`;

export const ImportTenantsMainText = styled(Typography)`
  font-family: ${selectedTheme.type.textLg.semibold.fontFamily};
  font-size: ${selectedTheme.type.textLg.semibold.fontSize};
  font-style: ${selectedTheme.type.textLg.semibold.fontStyle};
  font-weight: ${selectedTheme.type.textLg.semibold.fontWeight};
  color: ${selectedTheme.colors.gray[900]};
`;

export const ImportTenantsText = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.regular.fontFamily};
  font-size: ${selectedTheme.type.textSm.regular.fontSize};
  font-style: ${selectedTheme.type.textSm.regular.fontStyle};
  font-weight: ${selectedTheme.type.textSm.regular.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
`;

export const DownloadContainer = styled(Box)`
  display: flex;
  align-items: center;
  border: 1px solid ${selectedTheme.colors.gray[200]};
  box-shadow: ${selectedTheme.effects.shadow.sm.boxShadow};
  padding: 20px;
  margin-top: 40px;
  border-radius: 12px;
  margin-left: 20px;
  margin-right: 20px;
`;

export const DownloadTextContainer = styled(Box)`
  margin-left: 24px;
`;

export const DownloadMainText = styled(Typography)`
  font-family: ${selectedTheme.type.textMd.semibold.fontFamily};
  font-size: ${selectedTheme.type.textMd.semibold.fontSize};
  font-style: ${selectedTheme.type.textMd.semibold.fontStyle};
  font-weight: ${selectedTheme.type.textMd.semibold.fontWeight};
  color: ${selectedTheme.colors.gray[700]};
`;

export const DownloadText = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.regular.fontFamily};
  font-size: ${selectedTheme.type.textSm.regular.fontSize};
  font-style: ${selectedTheme.type.textSm.regular.fontStyle};
  font-weight: ${selectedTheme.type.textSm.regular.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
`;

export const FileContainer = styled(Box)`
  display: flex;
  border-radius: 12px;
  border: 1px solid #155eef;
  justify-content: space-between;
  align-items: center;
  padding-left: 14px;
  padding-right: 28px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-top: 16px;
`;

export const LeftFileContainer = styled(Box)`
  display: flex;
  align-items: center;
`;
export const RightFileContainer = styled(Box)`
  display: flex;
`;

export const FileDetailsContainer = styled(Box)`
  margin-left: 14px;
`;

export const FileName = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.medium.fontFamily};
  font-size: ${selectedTheme.type.textSm.medium.fontSize};
  font-style: ${selectedTheme.type.textSm.medium.fontStyle};
  font-weight: ${selectedTheme.type.textSm.medium.fontWeight};
  color: ${selectedTheme.colors.gray[700]};
`;

export const FileSize = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.regular.fontFamily};
  font-size: ${selectedTheme.type.textSm.regular.fontSize};
  font-style: ${selectedTheme.type.textSm.regular.fontStyle};
  font-weight: ${selectedTheme.type.textSm.regular.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
`;

export const CheckIconContainer = styled(Box)`
  display: flex;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #155eef;
  justify-content: center;
  align-items: center;
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  margin-top: 25px;
  padding: 24px;
`;

export const BackContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: 40px;
  cursor: pointer;
  padding: 0px 24px;
`;

export const BackText = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.semibold.fontFamily};
  font-size: ${selectedTheme.type.textSm.semibold.fontSize};
  font-style: ${selectedTheme.type.textSm.semibold.fontStyle};
  font-weight: ${selectedTheme.type.textSm.semibold.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
  margin-left: 12px;
`;

export const TBody = styled("tbody")``;

export const Table = styled("table")`
  border: 1px solid ${selectedTheme.colors.gray[200]};
  box-shadow: ${selectedTheme.effects.shadow.sm.boxShadow};
  border-collapse: collapse;
  border-radius: 12px;
  border-style: none;
`;

export const TableRow = styled("tr")`
  height: 44px;
  background-color: ${selectedTheme.colors.gray[200]};
  border-radius: 12px;
`;

export const TableHeader = styled("th")`
  width: 40%;
  text-align: left;
  padding-left: 24px;
  font-family: ${selectedTheme.type.textXs.medium.fontFamily};
  font-size: ${selectedTheme.type.textXs.medium.fontSize};
  font-style: ${selectedTheme.type.textXs.medium.fontStyle};
  font-weight: ${selectedTheme.type.textXs.medium.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
`;

export const TableRow2 = styled("tr")``;
export const TableCell = styled("td")``;

export const NarrowTableCell = styled("td")`
  padding: 8px 16px;
`;

export const InformationsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
`;

export const InformationsSubContainer = styled(Box)``;

export const InformationText = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.regular.fontFamily};
  font-size: ${selectedTheme.type.textSm.regular.fontSize};
  font-style: ${selectedTheme.type.textSm.regular.fontStyle};
  font-weight: ${selectedTheme.type.textSm.regular.fontWeight};
  color: ${selectedTheme.colors.gray[700]};
  margin-top: 4px;
`;

export const TableCell2 = styled("td")`
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const DataContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

export const DataSubContainer = styled(Box)``;

export const DataText = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.medium.fontFamily};
  font-size: ${selectedTheme.type.textSm.medium.fontSize};
  font-style: ${selectedTheme.type.textSm.medium.fontStyle};
  font-weight: ${selectedTheme.type.textSm.medium.fontWeight};
  color: ${selectedTheme.colors.gray[700]};
`;

export const DataInput = styled("input")`
  border: 1px solid ${selectedTheme.colors.gray[200]};
  box-shadow: ${selectedTheme.effects.shadow.sm.boxShadow};
  border-radius: 8px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: ${selectedTheme.type.textMd.regular.fontFamily};
  font-size: ${selectedTheme.type.textMd.regular.fontSize};
  font-style: ${selectedTheme.type.textMd.regular.fontStyle};
  font-weight: ${selectedTheme.type.textMd.regular.fontWeight};
  color: ${selectedTheme.colors.gray[900]};
  margin-top: 6px;
`;

export const ErrorMessage = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.regular.fontFamily};
  font-size: ${selectedTheme.type.textSm.regular.fontSize};
  font-style: ${selectedTheme.type.textSm.regular.fontStyle};
  font-weight: ${selectedTheme.type.textSm.regular.fontWeight};
  color: ${selectedTheme.colors.error[600]};
`;

export const TableSubheader = styled(Typography)`
  font-family: ${selectedTheme.type.textMd.semibold.fontFamily};
  font-size: ${selectedTheme.type.textMd.semibold.fontSize};
  font-style: ${selectedTheme.type.textMd.semibold.fontStyle};
  font-weight: ${selectedTheme.type.textMd.semibold.fontWeight};
  color: ${selectedTheme.colors.gray[900]};
`;
export const TableSubheaderError = styled(Typography)`
  font-family: ${selectedTheme.type.textMd.semibold.fontFamily};
  font-size: ${selectedTheme.type.textMd.semibold.fontSize};
  font-style: ${selectedTheme.type.textMd.semibold.fontStyle};
  font-weight: ${selectedTheme.type.textMd.semibold.fontWeight};
  color: ${selectedTheme.colors.error[600]};
`;
export const SubheaderTextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const TableParagraph = styled(Box)`
  font-family: ${selectedTheme.type.textSm.regular.fontFamily};
  font-size: ${selectedTheme.type.textSm.regular.fontSize};
  font-style: ${selectedTheme.type.textSm.regular.fontStyle};
  font-weight: ${selectedTheme.type.textSm.regular.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
`;
export const TablesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const BodyContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 24px 0px;
  padding: 0 24px;
  border-bottom: 1px solid ${selectedTheme.colors.gray[200]};
`;
