import React from "react";
import PropTypes from "prop-types";
import {
  Content,
  RegisterLayoutContainer,
} from "./RegisterLayout.styled";

const RegisterLayout = (props) => {
  return (
    <RegisterLayoutContainer>
        <Content   
        >
          {props.content ? 
          
            props.content : props.children
        }
          
        </Content>
    </RegisterLayoutContainer>
  );
};

RegisterLayout.propTypes = {
  content: PropTypes.node,
  children:PropTypes.node,
};

export default RegisterLayout;
