import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "themes";


export const FinancesPageContainer = styled(Box)`
  background-color: ${selectedTheme.colors.gray[25]};
  display: flex;
  min-height:100vh;
  height: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ContentContainer = styled(Box)`
  margin: 32px;
  width: 100%;
  display:flex;
  flex-direction:column;
  @media (max-width: 768px) {
    width: 100%;
    margin: 0px;
    padding-top: 48px;
  }
`;