import Navbar from "components/shared/Navbar/Navbar";
import TabsComponent from "components/shared/Tab/TabsComponent";
import React from "react";
import GeneralSettingsComponent from "components/GeneralSettingsComponent/GeneralSettingsComponent";
import {
  ContentContainer,
  SettingsPageContainer,
  Title,
} from "./SettingsPage.styled";
import { useAgencyDetailsQuery } from "store/settingsApiSlice";
import UserManagementComponent from "components/UserManagementManagement/UserManagementComponent";
import { getUserRole } from "util/userHelpers";

const SettingsPage = () => {
  const { data: agencyDetails, isLoading } = useAgencyDetailsQuery();
  const tabs = [
    {
      label: "Opšta podešavanja",
      component: (
        <GeneralSettingsComponent
          agencySettings={
            isLoading
              ? {
                  pib: "",
                  identificationNumber: "",
                  percentage: 0,
                  minimumAmount: 0,
                }
              : agencyDetails.agency
          }
        />
      ),
    },
    {
      label: "Upravljanje korisnicima",
      component: (
        <UserManagementComponent
          users={isLoading ? [] : agencyDetails.users}
          roles={isLoading ? [] : agencyDetails.roles}
        />
      ),
    },
  ];

  if (getUserRole() === "SuperAdministrator") {
    // superAdministrator doesn't have his own agency
    tabs.splice(0, 1);
  }

  return isLoading ? (
    <p>Loading...</p>
  ) : (
    <SettingsPageContainer>
      <Navbar />
      <ContentContainer>
        <Title>Podešavanja</Title>
        <TabsComponent tabs={tabs} />
      </ContentContainer>
    </SettingsPageContainer>
  );
};

export default SettingsPage;
