import React from "react";
import RegisterLayout from "layouts/RegisterLayout/RegisterLayout";
import ResetPasswordContent from "components/ResetPassword/ResetPasswordContent";



const ResetPasswordPage = () => {

  return (
      <RegisterLayout content={<ResetPasswordContent/>} />
  );
};

export default ResetPasswordPage;
