import React from "react";
import PropTypes from "prop-types";
import { addBuildingInitialConstants } from "initialValues/addBuildingInitialValues";
import TextInputField from "components/shared/InputFields/TextField/TextField";
import { charactersToExlude } from "constants/helpers";
const IdentificationNumberField = (props) => {
  const formik = props?.formik;

  const preventEnteringNonNumericCharacters = (event) => {
    // input will accept only numbers
    if (charactersToExlude.includes(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <TextInputField
      id={addBuildingInitialConstants.identificationNumber}
      name={addBuildingInitialConstants.identificationNumber}
      placeholder="Unesite Matični broj vaše firme"
      label="Matični broj"
      value={formik.values.identificationNumber}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={
        formik.touched?.[addBuildingInitialConstants.identificationNumber] &&
        formik.errors?.[addBuildingInitialConstants.identificationNumber]
      }
      type="number"
      apiError={props.apiError}
      style={props?.style}
      onKeyDown={preventEnteringNonNumericCharacters}
    />
  );
};

IdentificationNumberField.propTypes = {
  formik: PropTypes.object,
  apiError: PropTypes.any,
  style: PropTypes.object,
};

export default IdentificationNumberField;
