import React, { useState } from 'react'
import { ForgotPasswordContentContainer, ForgotPasswordFormContainer, ForgotPasswordFormWrapper, FormSubtitleBoldText, LinkContainer} from './ForgotPasswordContent.styled'
import AuthHeader from 'components/shared/Auth/AuthHeader/AuthHeader'
import { CenteredRow, Column, FormHeader, FormSubtitle, FormTitle, HeaderIconContainer, HeaderWithIconContainer, LoginButton, PreText, RegisterLink } from 'components/Register/RegisterForm/RegisterForm.styled'
import { useFormik } from 'formik'
import forgotPasswordValidation from 'validations/forgotPasswordValidation'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Key } from "../../assets/Icons/key-01.svg";
import { ReactComponent as Mail } from "../../assets/Icons/mail-01-lg.svg";
//import BackLink from 'components/shared/BackLink/BackLink'
import AuthFooter from 'components/shared/Auth/AuthFooter/AuthFooter'
import ForgotPasswordEmailField from './ForgotPasswordFields/ForgotPasswordEmailField'
import BackLink from 'components/shared/BackLink/BackLink'


const ForgotPasswordContent = () => {

    const { t } = useTranslation();
    const [validEmail, setValidEmail] = useState(false);
  
    const handleSubmit = () => {
      setValidEmail(true);
    };
  
    const formik = useFormik({
      initialValues: forgotPasswordValidation,
      validationSchema: forgotPasswordValidation,
      onSubmit: handleSubmit,
      validateOnBlur: true,
      enableReinitialize: true,
    });
    
  return (
    <ForgotPasswordContentContainer>
         <AuthHeader />
      {!validEmail ? (
        <ForgotPasswordFormWrapper>
          <ForgotPasswordFormContainer
            component="form"
            onSubmit={formik.handleSubmit}
          >
            <HeaderWithIconContainer>
              <HeaderIconContainer>
                <Key />
              </HeaderIconContainer>
              <FormHeader>
                <FormTitle>{t("forgotPassword.formTitle")}</FormTitle>
                <FormSubtitle>{t("forgotPassword.formSubtitle")}</FormSubtitle>
              </FormHeader>
            </HeaderWithIconContainer>
            <Column gap={24} w={100}>

              <ForgotPasswordEmailField formik={formik} apiError={false} />
              <LoginButton component="button" type="submit">
                {t("forgotPassword.resetButton")}
              </LoginButton>
            </Column>
            <LinkContainer>
              <BackLink to={"/login"}>{t("forgotPassword.backLink")}</BackLink>
            </LinkContainer>
          </ForgotPasswordFormContainer>
        </ForgotPasswordFormWrapper>
      ) : (
        <ForgotPasswordFormWrapper>
          <ForgotPasswordFormContainer>
            <HeaderWithIconContainer>
              <HeaderIconContainer>
                <Mail />
              </HeaderIconContainer>
              <FormHeader>
                <FormTitle>{t("forgotPassword.confirm.title")}</FormTitle>
                <FormSubtitle>
                  {t("forgotPassword.confirm.subtitle")}
                  &nbsp;
                  <FormSubtitleBoldText>
                    {formik.values.email}
                  </FormSubtitleBoldText>
                </FormSubtitle>
              </FormHeader>
            </HeaderWithIconContainer>
            <LoginButton component="button" type="button">
              {t("forgotPassword.confirm.openMailButton")}
            </LoginButton>
            <RegisterLink to="/reset-password">PRIVREMENO-idi na resetpassword</RegisterLink>

            <CenteredRow gap={4}>
        <PreText>{t("forgotPassword.confirm.sendAgainPreText")}</PreText>
        <RegisterLink to="">{t("forgotPassword.confirm.sendAgainButton")}</RegisterLink>
      </CenteredRow>

            <LinkContainer>
              <BackLink
                onClick={()=>setValidEmail(false)}>{t("forgotPassword.backLink")}</BackLink>
            </LinkContainer>
          </ForgotPasswordFormContainer>
        </ForgotPasswordFormWrapper>
      )}

      <AuthFooter />
    </ForgotPasswordContentContainer>
   
  )
}


export default ForgotPasswordContent