import { apiSlice } from "store/apiSlice";
import { makeErrorToastMessage } from 'util/toastMessage';

export const financesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    allFinances: builder.query({
      query: () => ({
        url: "/finances",
        method: "GET",
      }),
    }),
    importFinances: builder.mutation({
      query: (formData) => ({
        url: "/finances/import",
        method: "POST",

        body: formData,
        prepareHeaders: (headers) => {
          headers.set("Content-Type", "multipart/form-data");
          return headers;
        },
        responseHandler: async (response) => {
          const blob = await response.blob();
          if (blob.size === 1) {
            makeErrorToastMessage("Ne postoje racuni za tekuci mesec");
            return;
          }

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          const contentDisposition = response.headers.get("content-disposition");
          const matches = /filename="([^"]+)"/.exec(contentDisposition);
          link.download = matches && decodeURIComponent(matches[1]);
          link.click();
          window.URL.revokeObjectURL(url);
        },
        cache: "no-cache",
      }),
      invalidatesTags: ["ImportFinances"],
    }),
  }),
});

export const { useAllFinancesQuery, useImportFinancesMutation } = financesApiSlice;
