import { Box, Typography, Select, MenuItem } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const MainContainer = styled(Box)`
  border: 1px solid ${selectedTheme.colors.gray[300]};
  box-shadow: ${selectedTheme.effects.shadow.sm.boxShadow};
  border-radius: 12px;
  margin-top: 24px;
`;

export const MainSubContainer = styled(Box)`
  padding: 20px;
  padding-bottom: 32px;
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const TopMainContainer = styled(Box)`
  display: flex;
  width: 100%;
  align-items: flex-start;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const NumberOfItem = styled(Typography)`
  font-family: ${selectedTheme.type.textLg.semibold.fontFamily};
  font-size: ${selectedTheme.type.textLg.semibold.fontSize};
  font-style: ${selectedTheme.type.textLg.semibold.fontStyle};
  font-weight: ${selectedTheme.type.textLg.semibold.fontWeight};
  color: ${selectedTheme.colors.gray[900]};
  width: 5%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const InputContainer = styled(Box)`
  width: "53%";
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const InputLabel = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.medium.fontFamily};
  font-size: ${selectedTheme.type.textSm.medium.fontSize};
  font-style: ${selectedTheme.type.textSm.medium.fontStyle};
  font-weight: ${selectedTheme.type.textSm.medium.fontWeight};
  color: ${selectedTheme.colors.gray[700]};
`;

export const InputSubLabel = styled("span")`
  font-family: ${selectedTheme.type.textSm.regular.fontFamily};
  font-size: ${selectedTheme.type.textSm.regular.fontSize};
  font-style: ${selectedTheme.type.textSm.regular.fontStyle};
  font-weight: ${selectedTheme.type.textSm.regular.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
`;

export const Input = styled("input")`
  border: 1px solid ${selectedTheme.colors.gray[300]};
  box-shadow: ${selectedTheme.effects.shadow.xs.boxShadow};
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 8px;
  font-family: ${selectedTheme.type.textMd.regular.fontFamily};
  font-size: ${selectedTheme.type.textMd.regular.fontSize};
  font-style: ${selectedTheme.type.textMd.regular.fontStyle};
  font-weight: ${selectedTheme.type.textMd.regular.fontWeight};
  color: ${selectedTheme.colors.gray[900]};
  width: 100%;
`;

export const SelectInput = styled(Select)`
  border: none;
  box-shadow: ${selectedTheme.effects.shadow.xs.boxShadow};
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 8px;
  font-family: ${selectedTheme.type.textMd.medium.fontFamily};
  font-size: ${selectedTheme.type.textMd.medium.fontSize};
  font-style: ${selectedTheme.type.textMd.medium.fontStyle};
  font-weight: ${selectedTheme.type.textMd.medium.fontWeight};
  color: ${selectedTheme.colors.gray[900]};
  width: 100%;
  height: 40px;
  background-color: ${selectedTheme.colors.base.white};
`;

export const SelectItem = styled(MenuItem)``;

export const BottomMainContainer = styled(Box)`
  display: flex;
  margin-top: 16px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const MultipleSelectContainer = styled(Box)`
  margin-left: 4px;
  position: relative;
  width: 97%;
  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }
`;

export const MultipleSelectInput = styled(Box)`
  width: 100%;
  border: 1px solid ${selectedTheme.colors.gray[300]};
  box-shadow: ${selectedTheme.effects.shadow.xs.boxShadow};
  border-radius: 8px;
  background-color: ${selectedTheme.colors.base.white};
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 8px;
  padding-right: 14px;
  display: flex;
  align-items: center;
  height: 44px;
  justify-content: space-between;
`;

export const SelectedItemsContainer = styled(Box)`
  display: flex;
  align-items: center;
  overflow-x: auto;
  width: 455px;
`;

export const SelectedItemContainer = styled(Box)`
  position: relative;
  z-index: 60;
  border-radius: 6px;
  border: 1px solid ${selectedTheme.colors.gray[300]};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  padding-left: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-right: 5px;
  font-family: ${selectedTheme.type.textSm.medium.fontFamily};
  font-size: ${selectedTheme.type.textSm.medium.fontSize};
  font-style: ${selectedTheme.type.textSm.medium.fontStyle};
  font-weight: ${selectedTheme.type.textSm.medium.fontWeight};
  color: ${selectedTheme.colors.gray[700]};
`;

export const SelectItemsListContainer = styled(Box)`
  display: flex;
  width: 100%;
  height: 130px;
  overflow-y: auto;
  flex-direction: column;
  position: absolute;
  border: 1px solid ${selectedTheme.colors.gray[300]};
  box-shadow: ${selectedTheme.effects.shadow.xs.boxShadow};
  border-radius: 8px;
  background-color: ${selectedTheme.colors.base.white};
  margin-top: 1px;
  z-index: 999;
`;

export const SelectItemsListSubContainer = styled(Box)`
  padding: 5px;
`;

export const ListItem = styled(Box)`
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  padding: 5px;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ExcludeApartmentsContainer = styled(Box)`
  display: flex;
  align-items: flex-start;
  @media (max-width: 768px) {
    margin-top: 16px;
  }
`;

export const ExcludeApartmentsTextContainer = styled(Box)`
  margin-left: 8px;
`;

export const ExludeApartmentsTitle = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.medium.fontFamily};
  font-size: ${selectedTheme.type.textSm.medium.fontSize};
  font-style: ${selectedTheme.type.textSm.medium.fontStyle};
  font-weight: ${selectedTheme.type.textSm.medium.fontWeight};
  color: ${selectedTheme.colors.gray[700]};
`;

export const ExcludeApartmentsSubTitle = styled(Typography)`
  font-family: ${selectedTheme.type.textSm.regular.fontFamily};
  font-size: ${selectedTheme.type.textSm.regular.fontSize};
  font-style: ${selectedTheme.type.textSm.regular.fontStyle};
  font-weight: ${selectedTheme.type.textSm.regular.fontWeight};
  color: ${selectedTheme.colors.gray[600]};
`;
