import BuildingNavigationIcon from "components/Icon/Icons/BuildingNavigationIcon";
import DownloadIcon from "components/Icon/Icons/DownloadIcon";
//import FilterIcon from "components/Icon/Icons/FilterIcon";
import RightArrowNavigationIcon from "components/Icon/Icons/RightArrowNavigationIcon";
import { PrimaryButton } from "components/shared/Buttons/Buttons.styled";
import Line from "components/shared/Line/Line";
import Navbar from "components/shared/Navbar/Navbar";
import SearchInputField from "components/shared/SearhInputField/SearchInputField";
import PrimaryTable from "components/shared/Tables/PrimaryTable";
import React, { useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import selectedTheme from "../../themes";
import {
  BillDetailsPageContainer,
  BillDetailsPageSubContainer,
  FilterContainer,
  NavigationContainer,
  NavigationText,
  TitleText,
  TopContainer,
} from "./BillDetailsPage.styled";
import { getMonth } from "util/dateHelpers";
import { useBillDetailsMutation } from "store/exportApiSlice";
import { useBillDetailQuery } from "store/billsApiSlice";
import useMediaQuery from "@mui/material/useMediaQuery";
import BackLink from "components/shared/BackLink/BackLink";

const BillDetailsPage = () => {
  const matches = useMediaQuery("(max-width:768px)");
  const { id } = useParams();
  const { billId } = useParams();
  const [searchValue, setSearchValue] = useState("");
  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };
  const { data, isLoading } = useBillDetailQuery(billId);
  const pageSize = 9;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const month = parseInt(queryParams.get("month"));
  const year = parseInt(queryParams.get("year"));
  const navigate = useNavigate();
  const [downloadBillDetails] = useBillDetailsMutation();

  const formatColumns = () => {
    let newArray = [...data.allColumns];
    newArray.unshift("Stan");
    newArray.unshift("Id");
    newArray.push("Ukupno");
    return newArray;
  };

  const formatRows = () => {
    const arr = [];
    data.billItems.forEach((billItem) => {
      const rows = [billItem.apartmentId, billItem.numberOfApartment];
      const newArray = billItem.columns.map((column) => column.name);
      data.allColumns.forEach((column) => {
        if (newArray.includes(column)) {
          // our billItem have column which is inside allColumns array
          rows.push(
            billItem.columns.find((item) => item["name"] === column).amount
          );
        } else {
          // there is no appropriate column for bill item so set 0 for that column
          rows.push(0);
        }
      });
      rows.push(billItem.totalAmount);
      arr.push(rows);
    });

    return arr;
  };

  const applyFiltering = () => {
    // apply filtering on building address
    return formatRows().filter((d) =>
      d[1].toLowerCase().includes(searchValue.toLowerCase())
    );
  };

  return isLoading ? (
    <p>Loading...</p>
  ) : (
    <BillDetailsPageContainer>
      <Navbar />
      <BillDetailsPageSubContainer>
        <NavigationContainer>
          {matches && (
            <BackLink
              style={{ marginLeft: "16px", marginTop: "32px" }}
              to={`/buildings/${id}`}
            >
              Nazad
            </BackLink>
          )}
          {!matches && (
            <>
              <BuildingNavigationIcon
                onClick={() => navigate("/buildings")}
                style={{ cursor: "pointer" }}
              />
              <RightArrowNavigationIcon style={{ marginLeft: "8px" }} />
              <NavigationText onClick={() => navigate(`/buildings/${id}`)}>
                Zgrada {id}
              </NavigationText>
              <RightArrowNavigationIcon style={{ marginLeft: "8px" }} />
              <NavigationText onClick={() => navigate(`/buildings/${id}`)}>
                Računi
              </NavigationText>
              <RightArrowNavigationIcon style={{ marginLeft: "8px" }} />
              <NavigationText>
                {getMonth(month)} {year}
              </NavigationText>
            </>
          )}
        </NavigationContainer>
        <TitleText>
          Zaduženje {getMonth(month)} {year}
        </TitleText>
        {!matches && <Line />}
        <TopContainer>
          <FilterContainer>
            {/* <PrimaryButton>
              <FilterIcon />
              {matches ? "" : "Filter"}
            </PrimaryButton> */}
            <SearchInputField
              style={{
                marginLeft: matches ? "0px" : "16px",
                marginRight: matches ? "16px": "0px",
                width: matches && "100%",
              }}
              searchValue={searchValue}
              handleInputChange={handleInputChange}
            />
          </FilterContainer>
          <PrimaryButton
            style={{
              border: "none",
              backgroundColor: "transparent",
              boxShadow: "none",
              marginBottom: matches && "16px",
            }}
            onClick={() => downloadBillDetails({ id, month, year })}
          >
            <DownloadIcon />
            Export
          </PrimaryButton>
        </TopContainer>
        <PrimaryTable
          data={applyFiltering()}
          pageSize={pageSize}
          columns={formatColumns()}
          includeNavigation={true}
          style={{
            marginTop: "24px",
            border: `1px solid ${selectedTheme.colors.gray[200]}`,
            boxShadow: `${selectedTheme.effects.shadow.sm.boxShadow}`,
          }}
        />
      </BillDetailsPageSubContainer>
    </BillDetailsPageContainer>
  );
};

export default BillDetailsPage;
