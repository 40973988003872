import React from "react";
import { ReactComponent as RightArrowIconSvg } from "../../../assets/Icons/rightArrow.svg";

const RightArrowIcon = (props) => {
  return <RightArrowIconSvg {...props} />;
};

RightArrowIcon.propTypes = {};

export default RightArrowIcon;
