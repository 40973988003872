export const primaryThemeColors = {
  base: {
    white: "#FFFFFF",
    black: "#000000",
  },
  //old
  // gray: {
  //   25: "#FCFCFD",
  //   50: "#F9FAFB",
  //   100: "#F2F4F7",
  //   200: "#EAECF0",
  //   300: "#D0D5DD",
  //   400: "#98A2B3",
  //   500: "#667085",
  //   600: "#475467",
  //   700: "#344054",
  //   800: "#1D2939",
  //   900: "#101828",
  // },

  //gray modern
  gray: {
    25: "#FCFCFD",
    50: "#F8FAFC",
    100: "#EEF2F6",
    200: "#E3E8EF",
    300: "#CDD5DF",
    400: "#9AA4B2",
    500: "#697586",
    600: "#4B5565",
    700: "#364152",
    800: "#202939",
    900: "#121926",
  },
  // primary: {
  //   25: "#F5F8FF",
  //   50: "#EFF4FF",
  //   100: "#D1E0FF",
  //   200: "#B2CCFF",
  //   300: "#84ADFF",
  //   400: "#528BFF",
  //   500: "#2970FF",
  //   600: "#155EEF",
  //   700: "#004EEB",
  //   800: "#0040C1",
  //   900: "#00359E",
  // },

  //new
  primary: {
    25: "#FCFCFD",
    50: "#F8F9FC",
    100: "#EAECF5",
    200: "#D5D9EB",
    300: "#B3B8DB",
    400: "#717BBC",
    500: "#4E5BA6",
    600: "#3E4784",
    700: "#363F72",
    800: "#293056",
    900: "#101323",
  },
  error: {
    25: "#FFFBFA",
    50: "#FEF3F2",
    100: "#FEE4E2",
    200: "#FECDCA",
    300: "#FDA29B",
    400: "#F97066",
    500: "#F04438",
    600: "#D92D20",
    700: "#B42318",
    800: "#912018",
    900: "#7A271A",
  },
  success: {
    25: "#F6FEF9",
    50: "#ECFDF3",
    100: "#D1FADF",
    200: "#A6F4C5",
    300: "#6CE9A6",
    400: "#32D583",
    500: "#12B76A",
    600: "#039855",
    700: "#027A48",
    800: "#05603A",
    900: "#054F31",
  },

  //old
  primaryLight: "#673ab7",
  primaryDark: "#009688",
  secondaryLight: "#212121",
  secondaryDark: "#f5f5f5",
}