import React from "react";
import { Card, TopContainer, Text, ContentContainer, ContentText } from "./FinantialWidgetComponent.styled";
import PropTypes from "prop-types";
//import ThreeDotsIcon from "components/Icon/Icons/ThreeDotsIcon";

const FinantialWidgetComponent = ({finance}) => {

  return (
    <Card style={{ marginLeft: "0px", minWidth:"400px", }}>
      <TopContainer>
        <Text>Trenutni balans</Text>
        {/* <ThreeDotsIcon /> */}
      </TopContainer>
      <ContentContainer>
        <ContentText>{finance.currentState} din</ContentText>
      </ContentContainer>
    </Card>
  );
};

FinantialWidgetComponent.propTypes = {
  finance: PropTypes.object,
};

export default FinantialWidgetComponent;
