import { primaryThemeColors } from "./primaryThemeColors";

export default {
  focusRing: {
    _4pxPrimary100: {
      background: "#C4C4C4",
      boxShadow: `0px 0px 0px 4px ${primaryThemeColors.primary[100]}`,
    },
    _4pxGray100: {
      background: "#C4C4C4",
      boxShadow: `0px 0px 0px 4px ${primaryThemeColors.gray[100]}`,
    },
    _4pxError100: {
      background: "#C4C4C4",
      boxShadow: `0px 0px 0px 4px ${primaryThemeColors.error[100]}`,
    },
    _4pxGray500: {
      background: "#C4C4C4",
      boxShadow: `0px 0px 0px 4px ${primaryThemeColors.gray[500]}`,
    },
    _4pxPrimary500: {
      background: "#C4C4C4",
      boxShadow: `0px 0px 0px 4px ${primaryThemeColors.primary[500]}`,
    },
  },
  shadow: {
    xs: {
      background: "#C4C4C4",
      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    },
    xsFocused_4pxPrimary100: {
      background: "#C4C4C4",
      boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${primaryThemeColors.primary[100]}`,
    },
    xsFocused_4pxGray100: {
      background: "#C4C4C4",
      boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${primaryThemeColors.gray[100]}`,
    },
    xsFocused_4pxError100: {
      background: "#C4C4C4",
      boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${primaryThemeColors.error[100]}`,
    },
    sm: {
      background: "#C4C4C4",
      boxShadow:
        "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
    },
    smFocused_4pxPrimary100: {
      background: "#C4C4C4",
      boxShadow: `0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06), 0px 0px 0px 4px ${primaryThemeColors.primary[100]}`,
    },
    smFocused_4pxGray100: {
      background: "#C4C4C4",
      boxShadow: `0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06), 0px 0px 0px 4px ${primaryThemeColors.gray[100]}`,
    },
    md: {
      background: "#C4C4C4",
      boxShadow:
        "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
    },
    lg: {
      background: "#C4C4C4",
      boxShadow:
        "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
    },
    xl: {
      background: "#C4C4C4",
      boxShadow:
        "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
    },
    _2xl: {
      background: "#C4C4C4",
      boxShadow: "0px 24px 48px -12px rgba(16, 24, 40, 0.18)",
    },
    _3xl: {
      background: "#C4C4C4",
      boxShadow: "0px 32px 64px -12px rgba(16, 24, 40, 0.14)",
    },
  },
  backgroundBlur: {
    sm: {
      background: "#C4C4C4",
      backdropFilter: "blur(4px)",
      /* Note: backdrop-filter has minimal browser support */
    },
    md: {
      background: "#C4C4C4",
      backdropFilter: "blur(8px)",
      /* Note: backdrop-filter has minimal browser support */
    },
    lg: {
      background: "#C4C4C4",
      backdropFilter: "blur(12px)",
      /* Note: backdrop-filter has minimal browser support */
    },
    xl: {
      background: "#C4C4C4",
      backdropFilter: "blur(20px)",
      /* Note: backdrop-filter has minimal browser support */
    },
  },
};
