import React, { useEffect, useState } from "react";
import { BreadCrumbItem, BreadCrumbItemLink, BreadCrumbsContainer, HeaderArrow } from "./BreadCrumbs.styled";
import { useLocation } from "react-router";
import BuildingsIcon from "components/Icon/Icons/BuildingsIcon";

const BreadCrumbs = () => {
  const location = useLocation();
  let array = [];
  const [breadCrumbNodesArray, setBreadCrumbArray] = useState([]);

  const setupBreadcrumbs = (array) => {
    if (breadCrumbNodesArray.length < array.length) {
      let returnArray = [];
      let buildingId = 0;
      for (let i = 1; i < array.length; i++) {
        if (array[i] === "buildings") {
          returnArray.push(
            <BreadCrumbItemLink to="/buildings" key={i}>
              <BuildingsIcon />
            </BreadCrumbItemLink>
          );
          returnArray.push(<HeaderArrow />);
        } else if (array[i - 1] === "buildings" && /^\d+$/.test(array[i])) {
          buildingId = array[i];
          returnArray.push(
            <BreadCrumbItemLink to={"/buildings/" + buildingId} key={i}>
              Zgrada{buildingId}
            </BreadCrumbItemLink>
          );
          returnArray.push(<HeaderArrow />);
        } else if (array[i - 1] === "apartments" && /^\d+$/.test(array[i])) {
          returnArray.push(
            <BreadCrumbItemLink
              to={"/buildings/" + buildingId + "/apartments/" + array[i]}
              key={i}
            >
              Stan{array[i]}
            </BreadCrumbItemLink>
          );
          returnArray.push(<HeaderArrow />);
        } else if (array[i] !== "apartments"){
          returnArray.push(
            <BreadCrumbItemLink to={"/buildings/" + array[i]} key={i}>
              {array[i]}
            </BreadCrumbItemLink>
          );
          returnArray.push(<HeaderArrow />);
        }
      }
      returnArray.pop();
      setBreadCrumbArray(returnArray);
    }
  };

  useEffect(() => {
    array = location.pathname.split("/");
    setupBreadcrumbs(array);
  }, []);

  return (
    <BreadCrumbsContainer>
      {breadCrumbNodesArray.map((item, index) => (
        <BreadCrumbItem key={index}>{item}</BreadCrumbItem>
      ))}
    </BreadCrumbsContainer>
  );
};

export default BreadCrumbs;
