import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import {registerFormikConstants} from "../../../../initialValues/registerInitialValues";
import TextInputField from "components/shared/InputFields/TextField/TextField";

const RegisterCompanyNameField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
      <TextInputField
        id={registerFormikConstants.companyName}
        name={registerFormikConstants.companyName}
        placeholder={t("register.companyNamePlaceholder")}
        label={t("register.companyNameLabel")}
        value={formik.values.companyName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched?.[registerFormikConstants.companyName] && formik.errors?.[registerFormikConstants.companyName]}
        helperText={i18n.register && i18n.register?.companyNameHintText && i18n.register?.companyNameHintText !== "" && i18n.t("register.companyNameHintText")}
        apiError={props?.apiError}
      />
  );
};

RegisterCompanyNameField.propTypes = {
  formik: PropTypes.object,
  apiError: PropTypes.string,
};

export default RegisterCompanyNameField;
