import React from "react";
import { ReactComponent as UploadIconSvg } from "../../../assets/Icons/upload.svg";

const UploadIcon = (props) => {
  return <UploadIconSvg {...props} />;
};

UploadIcon.propTypes = {};

export default UploadIcon;
