import React from "react";
import PropTypes from "prop-types";
import AddressIcon from "./../../Icon/Icons/AddressIcon";
import {
  RentContainer,
  SectionRoot,
  ImAProduct,
  DetailsWrap,
  Text9,
  DividerIcon,
  TextAndIcon1,
  Details,
  HeadingAndBalance1,
  Balance,
  Heading,
  IconAndBalance1,
  TextAndParagraph1,
  Paragraph,
  Container5,
  Container4,
  Text5,
  FrameParent,
  ContainerParent,
  Image7,
  Container6,
  ImageIcon,
  Content,
  About,
} from "./RentTabContent.styled";
import { formatNumber } from "util/helpers/numberHelpers";

// eslint-disable-next-line no-unused-vars
const RentTabContent = ({ apartment, buildingId }) => {
  return apartment == null ? (
    <p>Loading...</p>
  ) : (
    <RentContainer>
      <SectionRoot>
        <Container4>
          <Text5>Fotografije</Text5>
          <FrameParent>
            <ContainerParent>
              <Container5>
                {apartment.images[0]?.imageLink && (
                  <Image7>
                    <ImageIcon
                      alt=""
                      src={`${apartment.images[0].imageLink}/carousel`}
                    />
                  </Image7>
                )}
              </Container5>
              <Container6 style={{gridArea: "1 / 3 / 2 / 4"}}>
              {apartment.images[1]?.imageLink && (
                    <ImageIcon
                      alt=""
                      src={`${apartment.images[1].imageLink}/carousel`}
                    />
                  )}
              </Container6>
              <Container6 style={{gridArea: "2 / 3 / 3 / 4"}}>
              {apartment.images[2]?.imageLink && (
                    <ImageIcon
                      alt=""
                      src={`${apartment.images[2].imageLink}/carousel`}
                    />
                  )}
              </Container6>
              <Container6 style={{gridArea: "1 / 4 / 2 / 5"}}>
              {apartment.images[3]?.imageLink && (
                    <ImageIcon
                      alt=""
                      src={`${apartment.images[3].imageLink}/carousel`}
                    />
                  )}
              </Container6>
              <Container6 style={{gridArea: "2 / 4 / 3 / 5"}}>
              {apartment.images[4]?.imageLink && (
                    <ImageIcon
                      alt=""
                      src={`${apartment.images[4].imageLink}/carousel`}
                    />
                  )}
              </Container6>
              <Container6 style={{gridArea: "1 / 5 / 2 / 6"}}>
              {apartment.images[5]?.imageLink && (
                    <ImageIcon
                      alt=""
                      src={`${apartment.images[5].imageLink}/carousel`}
                    />
                  )}
              </Container6>
              <Container6 style={{gridArea: "2 / 5 / 3 / 6"}}>
              {apartment.images[6]?.imageLink && (
                    <ImageIcon
                      alt=""
                      src={`${apartment.images[6].imageLink}/carousel`}
                    />
                  )}
              </Container6>
            </ContainerParent>
          </FrameParent>
          <Content>
            <About>
              <TextAndParagraph1>
                <Text5>Uslovi izdavanja Stana</Text5>
                {apartment?.rentalInformations && (
                  <Paragraph>
                    <ImAProduct>{apartment.rentalInformations}</ImAProduct>
                  </Paragraph>
                )}
              </TextAndParagraph1>
            </About>
            <Details>
              <HeadingAndBalance1>
                <Heading>Cena Izdavanja:</Heading>
                <IconAndBalance1>
                  {apartment?.defaultRentPrice && (
                    <Balance>
                      {formatNumber(apartment.defaultRentPrice)}
                    </Balance>
                  )}
                </IconAndBalance1>
              </HeadingAndBalance1>
              <DetailsWrap>
                {apartment?.address && (
                  <TextAndIcon1>
                    <AddressIcon />
                    <Text9>
                      {apartment.address}, {apartment?.city}
                    </Text9>
                  </TextAndIcon1>
                )}
              </DetailsWrap>
            </Details>
          </Content>
          <DividerIcon alt="" src="Divider.svg" />
        </Container4>
      </SectionRoot>
    </RentContainer>
  );
};

RentTabContent.propTypes = {
  apartment: PropTypes.array,
  buildingId: PropTypes.number,
};

export default RentTabContent;
