import React from "react";
import { ReactComponent as UnconfirmedIconSvg } from "../../../assets/Icons/unconfirmed.svg";

const UnconfirmedIcon = (props) => {
  return <UnconfirmedIconSvg {...props} />;
};

UnconfirmedIcon.propTypes = {};

export default UnconfirmedIcon;
