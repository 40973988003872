import React from "react";
import { ReactComponent as ConfirmedIconSvg } from "../../../assets/Icons/confirmed.svg";

const ConfirmedIcon = (props) => {
  return <ConfirmedIconSvg {...props} />;
};

ConfirmedIcon.propTypes = {};

export default ConfirmedIcon;
