import React from "react";
import PropTypes from "prop-types";
import { addBuildingInitialConstants } from "initialValues/addBuildingInitialValues";
import TextInputField from "components/shared/InputFields/TextField/TextField";
//import { charactersToExlude } from "constants/helpers";

const InvestmentFundField = (props) => {
  const formik = props?.formik;

  // const preventEnteringNonNumericCharacters = (event) => {
  //   // input will accept only numbers
  //   //console.log(charactersToExlude.includes(event.key))
  //   //console.log(event.target.value.split('.')[1]?.length > 1)
  //   // if (charactersToExlude.includes(event.key)) {
  //   //   event.preventDefault();
  //   // }
  //   if (event.target.value.includes(".") && event.target.value.split('.').length > 2) {
  //     event.target.value = event.target.value.slice(0, -1);
  //     console.log('idk')
  //     //event.preventDefault();
  //   }
  // };

  return (
    <TextInputField
      id={addBuildingInitialConstants.investment}
      name={addBuildingInitialConstants.investment}
      placeholder=""
      label="Investiciono održavanje"
      value={formik.values.investment}
      onChange={formik.handleChange}
      labelClass={props?.labelClass}
      type="number"
      onBlur={formik.handleBlur}
      error={formik.touched?.[addBuildingInitialConstants.investment] && formik.errors?.[addBuildingInitialConstants.investment]}
      apiError={props.apiError}
      style={props?.style}
      //onKeyDown={preventEnteringNonNumericCharacters}
    />
  );
};

InvestmentFundField.propTypes = {
  formik: PropTypes.object,
  apiError: PropTypes.any,
  style: PropTypes.object,
  labelClass: PropTypes.string,
};

export default InvestmentFundField;
