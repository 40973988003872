import ThreeDotsIcon from "components/Icon/Icons/ThreeDotsIcon";
import React from "react";
import {
  Card,
  TopContainer,
  Text,
  PercentageContainer,
  PercentageText,
  ContentContainer,
  ContentText,
} from "./TotalBuildingsCard.styled";
import ArrowUpIcon from "components/Icon/Icons/ArrowUp";
import PropTypes from "prop-types";

const TotalBuildingsCard = ({ numberOfBuildings }) => {
  return (
    <Card style={{ marginLeft: "0px" }}>
      <TopContainer>
        <Text>Ukupno Zgrada</Text>
        <ThreeDotsIcon />
      </TopContainer>
      <ContentContainer>
        <ContentText>{numberOfBuildings}</ContentText>
        <PercentageContainer>
          <ArrowUpIcon />
          <PercentageText>20%</PercentageText>
        </PercentageContainer>
      </ContentContainer>
    </Card>
  );
};

TotalBuildingsCard.propTypes = {
  numberOfBuildings: PropTypes.number,
};

export default TotalBuildingsCard;
