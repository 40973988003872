import { InputBase } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const SearchInput = styled(InputBase)`
  margin-left: 16px;
  border-radius: 8px;
  border: 1px solid ${selectedTheme.colors.gray[300]};
  box-shadow: ${({ isfocused }) =>
    isfocused
      ? selectedTheme.effects.shadow.xsFocused_4pxPrimary100.boxShadow
      : selectedTheme.effects.shadow.xs.boxShadow};
  background-color: ${selectedTheme.colors.base.white};
  width: 320px;
  height: 44px;
  padding-left: 16.5px;
  input {
    padding-left: 10.5px;
    &::placeholder {
      font-family: ${selectedTheme.type.textMd.regular.fontFamily};
      font-size: ${selectedTheme.type.textMd.regular.fontSize};
      font-weight: ${selectedTheme.type.textMd.regular.fontWeight};
      font-style: ${selectedTheme.type.textMd.regular.fontStyle};
      color: ${selectedTheme.colors.gray[500]};
    }
  }
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;
